import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { OPERATOR } from 'constants/index'
import { Switch } from 'components/Primitives/Switch'
import { useDemo, useRole } from 'hooks'
import { memo } from 'react'

const DemoSwitcher = memo(({ className }) => {
  const { role } = useRole()
  const { enabled, toggle } = useDemo()

  if (![OPERATOR].includes(role)) {
    return null
  }

  return (
    <div className={className}>
      <Switch
        checkedChildren={<EyeInvisibleOutlined />}
        unCheckedChildren={<EyeOutlined />}
        checked={enabled}
        onChange={toggle}
      />
    </div>

  )
})

export default DemoSwitcher
