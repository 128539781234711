import { fromDate } from '@internationalized/date'
import { useCalendarGrid } from 'react-aria'
import { eachWeekOfInterval, startOfMonth, endOfMonth, eachDayOfInterval, endOfWeek, format } from 'date-fns'
import { getWeekDayArray } from 'utils/datetime'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { Text } from 'components/Primitives'
import { CalendarCell } from './CalendarCell'

export const CalendarGrid = ({ state, ...props }) => {
  const intl = useIntl()
  const startDate = state.visibleRange.start.toDate()
  const { gridProps, headerProps } = useCalendarGrid({ ...props }, state)

  const eachWeek = eachWeekOfInterval(
    { start: startOfMonth(startDate), end: endOfMonth(startDate) },
    { weekStartsOn: 1 }
  )

  return (
    <table className='w-64 mx-auto border-collapse' {...gridProps}>
      <thead {...headerProps}>
        <tr>
          <th />
          {getWeekDayArray(intl).map((day, index) => <th className='text-center' key={index}>{day}</th>)}
        </tr>
      </thead>
      <tbody>
        {eachWeek.map((startOfWeek) => {
          const daysInWeek = eachDayOfInterval({ start: startOfWeek, end: endOfWeek(startOfWeek, { weekStartsOn: 1 }) })
          const wn = format(startOfWeek, 'I')
          return (
            <tr key={wn}>
              <td>
                <div className='h-full w-full flex items-center justify-center'>
                  <Text type='caption' color='disabled-10'>{intl.formatMessage(labelMessages.weekNumber)}{wn}</Text>
                </div>
              </td>
              {daysInWeek.map((date, i) => (
                date
                  ? (
                    <CalendarCell
                      key={i}
                      state={state}
                      date={fromDate(date)}
                      currentMonth={state.visibleRange.start}
                    />
                    )
                  : <td key={i} />
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
