import { useMemo } from 'react'
import { useAdminData } from './useAdminData'

const TYPES = [
  'item',
  'saleslocation',
  'user',
  'orderconfig', // FIXME: Not implemented in backend yet
  'todoconfig' // FIXME: Not implemented in backend yet
]

export const useTags = (options) => {
  const opts = {
    remove_orphans: false,
    ...options
  }

  if (!TYPES.includes(opts.type)) {
    throw new Error(`Invalid type ${opts.type}. Allowed types: ${TYPES.join(', ')}`)
  }

  const { data } = useAdminData('tags', opts)

  const result = useMemo(() => ({
    tags: data.items,
    count: data.count,
    status: data.status,
    error: data.error,
    isFetching: data.isFetching,
    isLoading: data.isLoading
  }), [data])

  return result
}
