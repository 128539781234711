import React, { useMemo } from 'react'

import { CUSTOM_RANGE } from 'constants/index'
import cn from 'classnames'
import { getRangePreset } from 'utils'
import messages from './messages'
import s from './Presets.module.scss'
import { useIntl } from 'react-intl'
import { isSameDay } from 'date-fns'
import globalMessages from 'components/globalMessages'

const RadioIcon = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='#A2A9B0' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20.5714C7.26613 20.5714 3.42857 16.7339 3.42857 12C3.42857 7.26613 7.26613 3.42857 12 3.42857C16.7339 3.42857 20.5714 7.26613 20.5714 12C20.5714 16.7339 16.7339 20.5714 12 20.5714Z' />
  </svg>
)
const CheckedRadioIcon = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='#1C5593' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M12 2.45454C6.72819 2.45454 2.45454 6.72819 2.45454 12C2.45454 17.2718 6.72819 21.5455 12 21.5455C17.2718 21.5455 21.5455 17.2718 21.5455 12C21.5455 9.46839 20.5398 7.04046 18.7497 5.25034C16.9595 3.46022 14.5316 2.45454 12 2.45454ZM12 20.1818C7.48131 20.1818 3.81818 16.5187 3.81818 12C3.81818 7.48131 7.48131 3.81818 12 3.81818C16.5187 3.81818 20.1818 7.48131 20.1818 12C20.1818 16.5187 16.5187 20.1818 12 20.1818ZM12 7.90909C9.74065 7.90909 7.90909 9.74065 7.90909 12C7.90909 14.2593 9.74065 16.0909 12 16.0909C14.2593 16.0909 16.0909 14.2593 16.0909 12C16.0909 9.74065 14.2593 7.90909 12 7.90909Z' />
  </svg>
)

const Presets = ({ value, onChange, onOpenChange, options: keys, weekly, selectedPreset, allowReset }) => {
  const intl = useIntl()

  const options = useMemo(() => keys.map((key) => ({ ...getRangePreset(key), key })), [keys])
  const foundPreset = useMemo(() => {
    return selectedPreset || options.find(
      (option) =>
        value.length &&
        option.value.length &&
        isSameDay(value[0], option.value[0]) &&
        isSameDay(value[1], option.value[1])
    )
  }, [value, options, selectedPreset])

  return (
    <div className={s.options}>
      {options.map((option, i) => {
        const checked = foundPreset
          ? option.key === foundPreset.key
          : option.key === CUSTOM_RANGE

        return (
          <button
            type='button'
            key={i}
            className={cn(s.option, checked && s.checked)}
            value={option.value}
            onClick={() => {
              onChange(option.value)
              if (option.key !== CUSTOM_RANGE) {
                onOpenChange(false)
              }
            }}
          >
            {checked ? CheckedRadioIcon : RadioIcon}
            {intl.formatMessage(
              weekly && option.key === CUSTOM_RANGE
                ? messages.customWeek
                : option.label,
              option.params
            )}
          </button>
        )
      })}
      {allowReset && (
        <button
          type='button'
          className={cn(s.option, s.reset)}
          onClick={() => {
            onChange([])
            onOpenChange(false)
          }}
        >
          {intl.formatMessage(
            globalMessages.reset
          )}
        </button>
      )}
    </div>
  )
}

export default Presets
