import { useMutation } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks/useQueryFetcher'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'

export const useDashboardAccessManagement = () => {
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()

  const { mutateAsync: updateAccess, isPending: isPendingAccess } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      fetch(`/dashboard-access/?${queryString.stringify({
        customer
      })}`,
      {
        method: 'POST',
        body: values,
        token,
        success: (res) => resolve(res),
        failure: (errors) => reject(errors)
      })
    })
  })

  const { mutateAsync: deleteAccess, isPending: isPendingDeleteAccess } = useMutation({
    mutationFn: (id) => new Promise((resolve, reject) => {
      fetch(`/dashboard-access/${id}/?${queryString.stringify({
        customer
      })}`,
      {
        method: 'DELETE',
        token,
        success: (res) => resolve(res),
        failure: (errors) => reject(errors)
      })
    })
  })

  return {
    update: {
      mutate: updateAccess,
      isPending: isPendingAccess
    },
    remove: {
      mutate: deleteAccess,
      isPending: isPendingDeleteAccess
    }
  }
}
