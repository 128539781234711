import RoundedButton from 'components/RoundedButton'
import { useIntl } from 'react-intl'
import { Button } from 'components/Button'
import { usePageSettings } from 'hooks/usePageSettings'
import labelMessages from 'components/labelMessages'
import { OpenMeasurementsIndicator } from 'components/OpenMeasurementsIndicator'
import { useOpenMeasurements } from 'hooks/queries/useOpenMeasurements'
import { Filter } from 'hooks/useAvailableFilters'

export default () => {
  const intl = useIntl()
  const { set } = usePageSettings()

  const handleClick = () => {
    set({
      location: [],
      wasteCategory: [],
      [Filter.DATE_RANGE]: {
        value: [],
        option: 'customRange'
      },
      status: 'open'
    })
  }

  const { count } = useOpenMeasurements()

  return (
    <>
      <div className='relative'>
        {/* // FIXME: Use new icon button for mobile */}
        <RoundedButton
          data-action='show-open-measurements'
          className='tablet:hidden desktop:hidden'
          icon='View'
          titlePlacement='left'
          onClick={handleClick}
          name='btn-show-open'
          type='secondary'
        />
        <Button
          data-action='show-open-measurements'
          className='mobile:hidden'
          icon='View'
          type='tertiary'
          onClick={handleClick}
        >
          {intl.formatMessage(labelMessages.showOpenMeasurements)}
        </Button>
        <OpenMeasurementsIndicator className='absolute -top-3 -right-3' count={count} />
      </div>
    </>
  )
}
