import { SearchSelect } from 'components/SearchSelect'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import { useCustomers } from 'hooks'
import titleMessages from 'components/titleMessages'
import labelMessages from 'components/labelMessages'

const CustomerPicker = ({
  value,
  onChange,
  name,
  placeholder,
  className,
  single,
  required,
  optionsLabel,
  mode,
  rounded,
  small,
  withPortal,
  hasError
}) => {
  const intl = useIntl()
  const { data, isLoading } = useCustomers()
  const { demo } = useDemo()

  const defaultLabel = single ? intl.formatMessage(labelMessages.customer) : intl.formatMessage(titleMessages.customers)

  return (
    <SearchSelect
      role='item-picker'
      type={rounded ? 'round' : undefined}
      size={small ? 'small' : undefined}
      className={className}
      name={name}
      placeholder={placeholder || defaultLabel}
      optionsLabel={optionsLabel || defaultLabel}
      value={value}
      onChange={onChange}
      single={single}
      required={required}
      mode={mode}
      loading={isLoading}
      allowClear={single}
      options={data && data.map((customer) => ({
        key: customer.id,
        label: demo(customer.name)
      }))}
      withPortal={withPortal}
      hasError={hasError}
    />
  )
}

export default CustomerPicker
