import { Tags } from 'components/Tags'
import { createPortal } from 'react-dom'

export default ({ value, onValueChange, eGridCell, stopEditing, tagType }) => {
  const cell = eGridCell
  const pos = cell.getBoundingClientRect()

  return createPortal(
    <Tags
      onStopEdit={stopEditing}
      pickerMode
      editable
      type={tagType || 'saleslocation'}
      items={value || []}
      onSelectionChanged={onValueChange}
      style={{
        position: 'fixed',
        width: cell.clientWidth,
        left: pos.left,
        top: pos.top
      }}
      data-name='tags-editor'
    />,
    document.body
  )
}
