import { find, findIndex, orderBy } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useDashboardManagement } from './useDashboardManagement'
import { useUser } from './useUser'
import { extendDashboard, getDashboardIdsInOrder } from 'components/Dashboard/utils'
import { useDashboardStore } from 'hooks/store/useDashboardStore'

export const useDashboards = () => {
  const { user } = useUser()
  const { dashboards } = useDashboardManagement()
  const setAllDashboards = useDashboardStore(state => state.setAllDashboards)

  const extendedDashboards = useMemo(() => {
    if (!dashboards.data) return []
    const extendedDashboards = dashboards.data.map(d => extendDashboard(user, d))
    return orderBy(extendedDashboards, ['title'], ['asc'])
  }, [dashboards.data])

  // in some cases we need access to all extended dashboards, so we always save them in the store
  useEffect(() => {
    if (extendedDashboards.length === 0) return
    setAllDashboards(extendedDashboards)
  }, [extendedDashboards])

  const kebabNameMap = useMemo(() => {
    return extendedDashboards.reduce((acc, dashboard) => {
      acc[dashboard.path] = dashboard.id
      return acc
    }, {})
  }, [extendedDashboards])

  const getDashboard = useCallback((kebabName, ownDashboardsOrder) => {
    if (!kebabName) {
      const firstOwnDashboard = find(extendedDashboards, { isOwner: true })
      if (!ownDashboardsOrder) {
        return firstOwnDashboard
      } else {
        const db = extendedDashboards.filter(d => d.isOwner)
        const order = getDashboardIdsInOrder(db, ownDashboardsOrder)
        const idx = findIndex(extendedDashboards, { id: order[0] })
        return idx !== -1 ? extendedDashboards[idx] : firstOwnDashboard
      }
    }
    const id = kebabNameMap[kebabName || 'null']
    if (!id) {
      return null
    }
    return find(extendedDashboards, { id })
  }, [extendedDashboards, kebabNameMap])

  return { dashboards: extendedDashboards, isLoading: dashboards.isLoading, getDashboard }
}
