import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { useDataQuery } from '..'
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNetwork } from 'ahooks'

export const useItemCategory = (options) => {
  const intl = useIntl()
  const networkState = useNetwork()
  const opts = {
    unfiltered: false,
    enabled: true,
    categoryNo: 1,
    range: null,
    location: null,
    from: options.from ? options.from : 'ItemOffering',
    ...options
  }

  if (![1, 2].includes(opts.categoryNo)) { throw new Error(`Invalid categoryNo ${opts.categoryNo}`) }

  const enabled = opts.enabled && networkState.online

  const { data, status, isFetching, isLoading, isPending, error, fetchStatus } = useDataQuery({
    from: !opts.from ? 'ItemOffering' : opts.from,
    select: [`set(${opts.categoryNo === 1 ? 'item__category_1' : 'item__category_2'})`],
    where: [
      ...(opts.range ? [{ field: 'date', operator: 'range', value: opts.range }] : []),
      ...(opts.location
        ? [
            {
              field: !opts.from || opts.from === 'ItemOffering' ? 'sales_location' : 'location',
              operator: 'in',
              value: opts.location
            }
          ]
        : [])
    ]
  }, opts.unfiltered ? enabled : enabled && opts.range != null && opts.range.length === 2)

  useEffect(() => {
    if (error) {
      message.error(intl.formatMessage(notifyMessages.fetchError, { type: 'item_category', message: error.error ? error.error.message : error }), 15)
    }
  }, [error])

  const filteredAndSorted = useMemo(() => {
    if (data) return data.filter(i => i).sort()
    return data
  }, [data])

  return {
    data: filteredAndSorted,
    status,
    isFetching,
    isLoading,
    isPending,
    error,
    fetchStatus
  }
}
