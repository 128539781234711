import { DEFAULT_FIELDS } from 'constants/index'
import { getRangePreset } from 'utils'

export const GRID_DEFAULT_SETTINGS = {
  pagination: {
    current: 1,
    pageSize: 50
  },
  colView: 'default',
  colDensity: 'regular',
  colStates: []
}

export const ORDER_CONFIG_DEFAULT_SETTINGS = {
  locations: [],
  location_tag: [],
  items: [],
  item_tag: [],
  dateRange: {
    value: [],
    option: null
  },
  weekdays: [],
  ordering: '-rank',
  quantile: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  fulfillmentDelay: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  orderFactor: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  ordersEnabled: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  referenceItem: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  batchRounding: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  multidayTracking: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  }
}

export const TODO_CONFIG_DEFAULT_SETTINGS = {
  locations: [],
  location_tag: [],
  items: [],
  item_tag: [],
  dateRange: {
    value: [],
    option: null
  },
  weekdays: [],
  totalQuantile: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  initialTodos: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  scheduling: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  },
  inventoryGroup: {
    ...GRID_DEFAULT_SETTINGS,
    sortModel: [
      {
        sort: 'desc',
        colId: 'rank'
      }
    ]
  }
}

export const RECIPE_LINES_DEFAULT_SETTINGS = {
  locations: [],
  composites: [],
  components: [],
  pagination: {
    current: 1,
    pageSize: 25
  },
  ordering: 'recipe_remote_pk',
  colView: 'default',
  colDensity: 'regular',
  colStates: []
}
export const EVENTS_DEFAULT_SETTINGS = {
  locations: [],
  category: [],
  items: [],
  search: '',
  pagination: {
    current: 1,
    pageSize: 25
  },
  sortModel: [
    {
      sort: 'asc',
      colId: 'name'
    }
  ],
  dateRange: {
    value: [],
    option: null
  }
}
export const DASHBOARD_DEFAULT_FILTERS = {
  location: [],
  itemCategory1: [],
  itemCategory2: [],
  og1: [],
  og2: [],
  og3: [],
  itemName: [],
  dateRange: {
    value: [],
    option: 'customRange'
  },
  weekdays: [],
  wasteCategory: []
}
export const DASHBOARD_DEFAULT_SETTINGS = {
  refreshInterval: 'off',
  defaultRange: 'yesterday'
}
export const FORECAST_DEFAULT_SETTINGS = {
  view: 'grid',
  location: null,
  itemCategory1: [],
  itemCategory2: [],
  og1: [],
  og2: [],
  itemName: [],
  dateRange: {
    value: getRangePreset('thisWeek', true),
    option: 'thisWeek'
  },
  pagination: {
    current: 1,
    pageSize: 50
  },
  fields: DEFAULT_FIELDS,
  colView: 'default',
  colDensity: 'regular',
  colStates: [],
  sortModel: [
    {
      sort: 'desc',
      colId: 'date'
    }
  ]
}
export const FOODWASTE_DEFAULT_SETTINGS = {
  location: [],
  wasteCategory: [],
  status: null,
  dateRange: {
    value: getRangePreset('thisWeek', true),
    option: 'thisWeek'
  },
  pagination: {
    current: 1,
    pageSize: 25
  },
  colView: 'default',
  colDensity: 'regular',
  colStates: [],
  sortModel: [
    {
      sort: 'desc',
      colId: 'date'
    }
  ]
}
export const ORDERS_DEFAULT_SETTINGS = {
  location: [],
  dateRange: {
    value: getRangePreset('today', true),
    option: 'today'
  },
  item__category_1: [],
  item__category_2: [],
  history: null,
  item: [],
  pagination: {
    current: 1,
    pageSize: 25
  },
  colView: 'default',
  colDensity: 'regular',
  colStates: [],
  sortModel: [
    {
      sort: 'asc',
      colId: 'location_name'
    }
  ]
}
export const TODO_DEFAULT_SETTINGS = {
  location: null,
  scale: 'sm'
}
export const CUSTOMERS_DEFAULT_SETTINGS = {
  search: undefined,
  pagination: {
    current: 1,
    pageSize: 25
  },
  colView: 'default',
  colDensity: 'regular',
  colStates: [],
  sortModel: [
    {
      sort: 'asc',
      colId: 'name'
    }
  ]
}
export const ADMIN_DEFAULT_SETTINGS = {
  tab: 'manageUsers',
  foodwasteConfigs: {
    locations: [],
    users: [],
    waste_category: [],
    colView: 'default',
    colDensity: 'regular',
    colStates: [],
    pagination: {
      current: 1,
      pageSize: 25
    },
    sortModel: [
      {
        sort: 'asc',
        colId: 'category'
      }
    ]
  },
  orderConfigs: ORDER_CONFIG_DEFAULT_SETTINGS,
  todoConfigs: TODO_CONFIG_DEFAULT_SETTINGS,
  recipeLines: RECIPE_LINES_DEFAULT_SETTINGS,
  events: {
    ...EVENTS_DEFAULT_SETTINGS,
    colView: 'default',
    colDensity: 'regular',
    colStates: []
  },
  items: {
    todo_tags: null,
    orders_enabled: null,
    category_1: [],
    category_2: [],
    category_3: [],
    todo_config_category: [],
    order_config_category: [],
    search: undefined,
    pagination: {
      current: 1,
      pageSize: 25
    },
    colView: 'default',
    colDensity: 'regular',
    colStates: [],
    sortModel: [
      {
        sort: 'asc',
        colId: 'name'
      }
    ]
  },
  locations: {
    forecast_enabled: null,
    todo_enabled: null,
    orders_enabled: null,
    search: undefined,
    pagination: {
      current: 1,
      pageSize: 25
    },
    colView: 'default',
    colDensity: 'regular',
    colStates: [],
    sortModel: [
      {
        sort: 'asc',
        colId: 'name'
      }
    ]
  },
  users: {
    locations: [],
    accessRights: [],
    search: undefined,
    pagination: {
      current: 1,
      pageSize: 25
    },
    colView: 'default',
    colDensity: 'regular',
    colStates: [],
    sortModel: [
      {
        sort: 'asc',
        colId: 'email'
      }
    ]
  },
  importedFiles: {
    locations: [],
    format_name: '',
    ordering: '-created_at',
    pagination: {
      current: 1,
      pageSize: 25
    },
    colView: 'default',
    colDensity: 'regular',
    colStates: []
  }
}
export const globalSettingsDefault = {
  sidebarCollapsed: false
}
export const CUSTOMER_DEFAULT_SETTINGS = {
  showLocationRemotePK: true,
  enableIntercom: true,
  returnRatioField: 'num_sold',
  dashboardPreview: false
}
export const USER_DEFAULT_ADMIN_SETTINGS = {
  dashboardPreview: false
}
