import { create } from 'zustand'

export const useSettingsStore = create((set) => ({
  isInitialized: false,
  settings: {},
  globalSettings: {},
  nonPersistentSettings: {},
  setCurrentSettings: (settings) => set(() => ({ settings: settings })),
  setGlobalSettings: (settings) => set(() => ({ globalSettings: settings })),
  setNonPersistentSettings: (settings) => set(() => ({ nonPersistentSettings: settings })),
  setIsInitialized: (isInitialized) => set(() => ({ isInitialized })),
  reset: () => set(() => ({ isInitialized: false, settings: {}, globalSettings: {}, nonPersistentSettings: {} }))
}))
