import { useDebounce } from 'ahooks'
import { WebsocketConnectionStateToString } from 'components/Charts/utils'
import { useUser } from 'hooks'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { useInitializationStore } from 'hooks/store/useInitializationStore'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { useCustomerSettings } from 'hooks/useCustomerSettings'
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useIntercom } from 'react-use-intercom'
import { getAccessCollection, guessName } from 'utils'
import { getSettingsStatistics } from 'utils/settings'

export const Intercom = () => {
  const intl = useIntl()
  const { user } = useUser()
  const locale = useGeneralStore(state => state.locale)
  const intercomBooted = useGeneralStore(state => state.intercomBooted)
  const setIntercomBooted = useGeneralStore(state => state.setIntercomBooted)
  const socketState = useGeneralStore(state => state.socketState)
  const socketStateString = WebsocketConnectionStateToString(socketState)
  const { boot } = useIntercom()

  const settings = useSettingsStore(state => state.settings)
  const globalSettings = useSettingsStore(state => state.globalSettings)
  const loadingDuration = useInitializationStore(state => state.loadingDuration)
  const customerSettings = useCustomerSettings()
  const stats = useMemo(() => getSettingsStatistics(settings, globalSettings, customerSettings), [settings, globalSettings, customerSettings])
  const debouncedStats = useDebounce(stats, { wait: 500 })

  useEffect(() => {
    // Do not boot intercom for intercom disabled accounts.
    if (user && locale && loadingDuration != null && !intercomBooted && debouncedStats && user.customer_settings && user.customer_settings.enableIntercom && socketState !== 0 && socketState !== null) {
      // eslint-disable-next-line camelcase
      const icUserProps = {
        userId: user.id,
        ...(user.ic_hash ? { userHash: user.ic_hash } : undefined),
        email: user.email,
        // eslint-disable-next-line camelcase
        name: guessName(user),
        hideDefaultLauncher: true,
        customAttributes: {
          user_role: user.role,
          user_permissions: getAccessCollection(user, intl).join(', '),
          app_version: window.localStorage.getItem('lastVisitedVersion'),
          loading_duration: loadingDuration,
          websocket: socketStateString,
          ...stats
        },
        languageOverride: locale,
        company: {
          companyId: user.customer_id,
          name: user.customer_name,
          industry: user.customer_type
        }
      }
      boot(icUserProps)
      setIntercomBooted(true)
    }
  }, [user, debouncedStats, loadingDuration, socketState])
}
