import { useMutation } from '@tanstack/react-query'
import { OPERATOR } from 'constants/index'
import { useQueryFetcher } from 'hooks/useQueryFetcher'
import useRole from 'hooks/useRole'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { orderBy } from 'lodash'
import queryString from 'query-string'
import { useCallback } from 'react'

export const useUserSearch = () => {
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const { role } = useRole()

  const performSearch = useCallback((value, customer) => {
    return new Promise((resolve, reject) => {
      fetch(
        `/users/search/?${queryString.stringify({
          customer,
          search: value
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    })
  }, [fetch, token])

  const searchMutation = useMutation({
    mutationFn: async (value) => {
      const customerUsers = await performSearch(value, customer)
      const operatorUsers = role === OPERATOR && customer != 1 ? await performSearch(value, 1) : [] // TODO: we only want operator users with role OPERATOR.
      return orderBy([...customerUsers, ...operatorUsers], ['last_name', 'first_name'], ['asc', 'asc'])
    }
  })

  return searchMutation
}
