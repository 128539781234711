import { formatMoney, formatNumber } from 'accounting'
import { parseISO, format, parse } from 'date-fns'
import { formatLocalized } from '../../utils/datetime'
import { DFNS_WEEK_FORMAT } from 'constants/index'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'

export const numberFormatter = ({ value, colDef, minimize }, format = null, symbol = '') => {
  const precision = colDef && colDef.precision != null
    ? colDef.precision
    : (colDef && colDef.flexibleDecimals && value % 1 === 0)
        ? 0
        : 2
  return value == null
    ? '-'
    : minimize && Math.abs(value) > 1000000
      ? formatMoney(value / 1000000, { decimal: ',', thousand: '.', precision: 1, symbol: `${minimize}${symbol != '' ? ' ' : ''}${symbol}`, format })
      : formatMoney(value, { decimal: ',', thousand: '.', precision, symbol, format })
}

export const baseNumberFormatter = ({ value, minimize }) => {
  return value == null
    ? '-'
    : minimize && Math.abs(value) > 1000000
      ? formatMoney(value / 1000000, { decimal: ',', thousand: '.', precision: 1, symbol: minimize, format: '%v %s' })
      : formatNumber(value, { decimal: ',', thousand: '.', precision: 0 })
}

export const currencyFormatter = (props) =>
  numberFormatter(props, '%v %s', '€')

export const percentFormatter = (props) =>
  numberFormatter({ ...props, value: props.value != null ? props.value * 100 : null }, '%v %s', '%')

export const kgFormatter = (props) =>
  numberFormatter(props, '%v %s', 'kg')

export const gramFormatter = (props) =>
  numberFormatter(props, '%v %s', 'g')

export const liquidFormatter = (props) =>
  numberFormatter(props, '%v %s', 'l')

export const dateFormatter = (props) => {
  if (props.value == null) return null

  const value = props.value

  if (value.includes('-W')) {
    // this is a week date
    return format(parse(value, DFNS_WEEK_FORMAT, new Date()), `'${props.colDef.intl.formatMessage(globalMessages.weekNumber)}' I | dd.MM.yyyy`)
  }

  if (value.includes('T')) {
    // this is a time
    return format(parseISO(value), 'HH:mm')
  }

  const isoDate = parseISO(props.value)
  return formatLocalized(isoDate, 'P', props.colDef.intl)
}

// Only supports values in HH:mm(:ss)? format for now
export const timeRangeFormatter = ({ data, colDef }) => {
  if (!data[colDef.fromField] || !data[colDef.toField]) return null
  return `${data[colDef.fromField].split(':').slice(0, 2).join(':')} - ${data[colDef.toField].split(':').slice(0, 2).join(':')}`
}

export const dateTimeFormatter = (props) => {
  if (props.value == null) return null
  const isoDate = parseISO(props.value)
  return formatLocalized(isoDate, 'P p', props.colDef.intl)
}

export const deviationFormatter = ({ colDef, varianceField, value, data }) => {
  if (value == null) return null
  const vField = colDef && colDef.varianceField ? colDef.varianceField : varianceField || 'variance'
  return `${formatNumber(value, { decimal: ',', thousand: '.', precision: colDef && colDef.precision != null ? colDef.precision : 2 })} ±${formatNumber(data[vField], { decimal: ',', thousand: '.', precision: 0, symbol: colDef && colDef.suffix })}`
}

export const daysFormatter = ({ value, colDef }) => {
  if (value === null || value === undefined) return ''
  if (typeof (value) === 'number') return `${value} ${colDef.intl.formatMessage(labelMessages.days, { count: value })}`
  return ''
}
