import React from 'react'
import { SearchSelect } from 'components/SearchSelect'
import { useItemFilterValues } from 'hooks/queries/useItemFilterValues'
import classNames from 'classnames'

export default ({ value, onChange, className, placeholder, optionsLabel, valueKey, required, single, allowClear }) => {
  const { data, isLoading } = useItemFilterValues()

  return (
    <SearchSelect
      role='filter-value-picker'
      className={classNames('max-w-sm', className)}
      type='round'
      size='small'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      loading={isLoading}
      optionsLabel={optionsLabel || placeholder}
      required={required}
      single={single}
      allowClear={allowClear}
      options={data && (data[valueKey] || []).filter(i => i).map((str) => ({
        key: str,
        label: str
      }))}
    />
  )
}
