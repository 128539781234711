import { useQueryFetcher } from 'hooks'
import queryString from 'query-string'
import { useQuery } from '@tanstack/react-query'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'

/**
 * This hook returns all items.
 * It is used in the todo to perform all mappings.
 */
export const useItemFilterValues = (options) => {
  const opts = {
    enabled: true,
    ...options
  }
  const selectedCustomer = useSelectedCustomer()
  const { fetch, token } = useQueryFetcher()
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['item-filter-values', { selectedCustomer }],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/items/filter-values/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: opts.enabled
  })

  return {
    data,
    error,
    isLoading,
    refetch
  }
}
