import { some, sortBy } from 'lodash'
import { useEffect, useMemo } from 'react'

import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { useIntl } from 'react-intl'
import { useNetwork } from 'ahooks'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useQueryFetcher, useUser } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { qFetchLocations } from 'utils/queries'
import { useGeneralStore } from 'hooks/store'

export const useLocations = (options) => {
  const intl = useIntl()
  const networkState = useNetwork()
  const opts = {
    enabled: true,
    includeDisabled: false,
    onlyTodoEnabled: false,
    setter: null,
    ...options
  }
  const { fetch } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const { user } = useUser()
  const locationsStore = useGeneralStore((state) => state.locationsStore)

  // we only want to use the cache for the default set of locations
  const enableCache = !opts.includeDisabled && !opts.onlyTodoEnabled

  const filter = {
    customer,
    ...(opts.includeDisabled ? undefined : { forecast_enabled: true }),
    ...(opts.onlyTodoEnabled ? { todo_enabled: true } : undefined),
    page_size: 9999,
    ordering: 'name'
  }

  const { data, status, isLoading, error, isFetching } = useQuery({
    queryKey: ['sales-locations', filter],
    queryFn: async () => {
      try {
        const locations = await qFetchLocations(fetch, filter, opts.includeDisabled)
        if (enableCache && locationsStore) {
          locationsStore.setEntries({ [`${customer}_data`]: locations, [`${customer}_timestamp`]: Date.now() })
        }
        return locations
      } catch (ex) {
        return Promise.reject(ex)
      }
    },
    placeholderData: keepPreviousData,
    enabled: opts.enabled && customer != null && user != null && networkState.online,
    retry: false,
    staleTime: 300000
  })

  const hasTodoEnabledLocation = useMemo(() => {
    if (!data) return false
    return some(data, { todo_enabled: true })
  }, [data])

  const sortedData = useMemo(() => {
    return data ? sortBy(data, (i) => i.forecast_enabled ? 1 : 2) : undefined
  }, [data])

  useEffect(() => {
    if (error) {
      message.error(intl.formatMessage(notifyMessages.fetchError, { type: 'locations', message: error.error ? error.error.message : error }), 15)
    }
  }, [error])

  return { data: sortedData, status, isLoading, error, isFetching, hasTodoEnabledLocation }
}
