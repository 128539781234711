import { useQuery } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'

export const useFileFormats = (options) => {
  const opts = {
    enabled: ['formatClasses', 'formatRecords', 'formatAssociations'],
    ...options
  }

  const customer = useSelectedCustomer()
  const { fetch, token } = useQueryFetcher()
  const recordsKey = ['formatRecords', opts.params, customer]
  const associationsKey = ['formatAssociations', opts.params, customer]

  const formatRecords = useQuery({
    queryKey: recordsKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/format-records/?${queryString.stringify({
          ...opts.params,
          customer,
          page_size: 999
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res.results),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: opts.enabled.includes('formatRecords')
  })

  const formatAssociations = useQuery({
    queryKey: associationsKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/format-associations/?${queryString.stringify({
          ...opts.params,
          customer,
          page_size: 999
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res.results),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: opts.enabled.includes('formatAssociations')
  })

  return {
    formatRecords: {
      data: formatRecords.data,
      status: formatRecords.status,
      error: formatRecords.error
    },
    formatAssociations: {
      data: formatAssociations.data,
      status: formatAssociations.status,
      error: formatAssociations.error
    }
  }
}
