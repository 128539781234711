import { defineMessages } from 'react-intl'

export default defineMessages({
  confirmRemoving: {
    id: 'customers.confirmRemoving',
    defaultMessage: 'Are you sure you want to remove this customer?'
  },
  addCustomer: {
    id: 'customers.addCustomer',
    defaultMessage: 'Add customer'
  },
  removeCustomer: {
    id: 'customers.removeCustomer',
    defaultMessage: 'Remove customer'
  },
  studentUnion: {
    id: 'customers.studentUnion',
    defaultMessage: 'Student union'
  },
  business: {
    id: 'customers.business',
    defaultMessage: 'Business'
  },
  bakery: {
    id: 'customers.bakery',
    defaultMessage: 'Bakery'
  },
  updateTrainingDataset: {
    id: 'customers.updateTrainingDataset',
    defaultMessage: 'Update Training Dataset'
  },
  getForecast: {
    id: 'customers.getForecast',
    defaultMessage: 'Get Forecast'
  }
})
