import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { createIntl, createIntlCache } from 'react-intl'
import { getNavigatorsDefaultLocale } from 'utils'
import cookie from 'js-cookie'
import { LOCALE_COOKIE } from 'constants/index'

export const fetchMessages = (locale) => {
  try {
    const data = require(`../messages/${locale}.json`)
    // return bound intl instance at the end
    return data
  } catch (error) {
    console.error('Error importing locale messages:', error)
    return null
  }
}

export const createIntlStore = (locale) => {
  const messages = fetchMessages(locale)
  const defaultLocale = getNavigatorsDefaultLocale()

  const cache = createIntlCache()

  const obj = {
    locale,
    messages,
    defaultLocale
  }

  const intl = createIntl(obj, cache)

  useGeneralStore.setState({ locale })
  useGeneralStore.setState({ intl })

  return intl
}

export const setLocale = (locale) => {
  const intl = useGeneralStore.getState().intl

  // skip and return intl if we already have a state and the locale is the same
  if (intl && intl.locale === locale) {
    return intl
  }

  cookie.set(LOCALE_COOKIE, locale, { expires: 365, path: '/', sameSite: 'strict', secure: true })
  return createIntlStore(locale)
}
