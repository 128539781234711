import React, { memo, useEffect, useMemo } from 'react'

import { SearchSelect } from 'components/SearchSelect'
import messages from './messages'
import { useAdminData } from 'hooks'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import { useLocations } from 'hooks/queries/useLocations'
import labelMessages from 'components/labelMessages'
import { Icon, Text } from 'components/Primitives'
import { getNameWithParantheses } from 'utils'
import { useCustomerSettings } from 'hooks/useCustomerSettings'

const LocationPicker = ({ value, onChange, flat, type, medium, single, placeholder, className, includeDisabled, onlyTodoEnabled, basicPlaceholder, setter, required, onLoaded, allowClear, gray, disabled, only, withPortal, hasError, fitWidth, debugMode }) => {
  const intl = useIntl()
  const { demo } = useDemo()

  // FIXME: when showing only todo enabled items: as soon we have todo_enabled in the response, we should filter it here instead of using the hook which will result in another unnecessary query
  const { data, status, isFetching } = useLocations({ includeDisabled, onlyTodoEnabled, setter })
  const { data: tagsData } = !single ? useAdminData('tags', { type: 'saleslocation' }) : {}

  const customerSettings = useCustomerSettings()

  const filteredData = useMemo(() => {
    if (!data) return data

    if (only) {
      return data.filter((item) => only.includes(item.id))
    }
    return data
  }, [data, only])

  const options = useMemo(() => {
    return filteredData && filteredData.map((item) => {
      return {
        key: item.id,
        tags: item.tags,
        icon: !item.forecast_enabled ? 'Locked' : undefined,
        label: demo(getNameWithParantheses('location', item, customerSettings))
      }
    })
  }, [filteredData])

  useEffect(() => {
    if (data && !isFetching && status === 'success') {
      if (onLoaded) {
        onLoaded(data)
      }
    }
  }, [data, isFetching, status])

  if (single) value = value ? value.toString() : value

  return (
    <SearchSelect
      disabled={disabled}
      role='location-picker'
      className={className}
      type={type || (flat ? undefined : 'round')}
      fitWidth={fitWidth}
      size={medium ? undefined : 'small'}
      placeholder={<Text bold={!flat} color='gray'>{placeholder || intl.formatMessage(basicPlaceholder ? labelMessages.location : single ? messages.locationTitleSingle : messages.locationTitleMulti)}</Text>}
      modalTitle={<Text bold color='brand' size='m'>{intl.formatMessage(single ? labelMessages.location : labelMessages.locations)}</Text>}
      value={value}
      onChange={onChange}
      loading={isFetching}
      optionsLabel={intl.formatMessage(labelMessages.locations)}
      prefixIcon={<Icon name='Location' />}
      required={required}
      single={single}
      tags={tagsData && tagsData.items}
      itemsGroup={intl.formatMessage(labelMessages.locations)}
      allowClear={allowClear}
      graybg={gray}
      options={options}
      withPortal={withPortal}
      hasError={hasError}
      debugMode={debugMode}
    />
  )
}

export default memo(LocationPicker)
