import { Button } from 'components/Button'
import { Popover, RangeCalendar } from 'components/Primitives'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getRangePreset } from 'utils'
import { useDefaultDateRange } from 'components/Report/hooks/useDefaultDateRange'
import { calculateFloatingRange, formatISODate, formatLocalized, formatLocalizedRange } from 'utils/datetime'
import { CUSTOM_RANGE, DFNS_LONG_DATE_FORMAT, FLOATING_RANGE } from 'constants/index'
import { isSameDay, parseISO } from 'date-fns'
import labelMessages from 'components/labelMessages'
import { usePrevious } from 'ahooks'
import { FloatingPeriodPicker, formatFloatingPeriod } from './Partials/FloatingPeriodPicker'
import { TextSelect } from 'components/TextSelect'
import Heading from 'components/Heading'
import { isEqual } from 'lodash'
import { useIsMobile } from 'hooks'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import globalMessages from 'components/globalMessages'
import classNames from 'classnames'
import { FilterFooter } from 'components/FilterFooter'
import { ComparingPeriodPicker } from './Partials/ComparingPeriodPicker'
import { RangePreview } from 'components/RangePreview'

export const RangePicker = ({ onChange, value: range, option: rangeOption, withUnsetPreset, placeholder, weekly, prefixPlaceholder, withComparisonPeriod, presets, allowReset, type, comparisonFilter, disabled }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()

  const presetOptions = useMemo(() => presets.map(p => ({ key: p, ...getRangePreset(p) })), [presets])

  const option = getRangePreset(rangeOption)
  const unsetPreset = useDefaultDateRange()
  const value = useMemo(() => {
    if (rangeOption === FLOATING_RANGE) {
      return calculateFloatingRange(range)
    }
    if (!option) {
      return []
    }
    if (rangeOption !== CUSTOM_RANGE && !!option) {
      return option.value.map((item) => formatISODate(item))
    }
    return range
  }, [range, rangeOption])
  const date = value.map((item) => typeof (item) === 'string' ? parseISO(item) : item)
  const hasValue = !!date.length

  const foundPreset = useMemo(() => {
    let d = date
    // if the picker is resetted, we return the unsetPreset
    if ((!rangeOption || rangeOption === CUSTOM_RANGE) && value.length === 0) {
      d = unsetPreset.value
    }
    return presetOptions.find(
      (option) =>
        d.length &&
      option.value.length &&
      isSameDay(d[0], option.value[0]) &&
      isSameDay(d[1], option.value[1])
    )
  }, [value, presetOptions, unsetPreset])

  const stateDefaults = {
    view: rangeOption === FLOATING_RANGE ? FLOATING_RANGE : foundPreset ? 'default' : CUSTOM_RANGE,
    selectedPreset: foundPreset ? foundPreset.key : (rangeOption || CUSTOM_RANGE),
    selectedRange: foundPreset ? foundPreset.value : date,
    floatingRangeValue: rangeOption === FLOATING_RANGE ? range : null,
    selectedComparisonOption: comparisonFilter.option,
    selectedComparisonRange: comparisonFilter.value,
    selectedPrevYSetting: comparisonFilter.prevYSetting,
    selectedComparisonFloatingValue: comparisonFilter.floatingValue
  }

  const [view, setView] = useState(stateDefaults.view)
  const [selectedPreset, setSelectedPreset] = useState(stateDefaults.selectedPreset)
  const [selectedRange, setSelectedRange] = useState(stateDefaults.selectedRange)
  const [floatingRangeValue, setFloatingRangeValue] = useState(stateDefaults.floatingRangeValue)

  const [selectedComparisonOption, setSelectedComparisonOption] = useState(stateDefaults.selectedComparisonOption)
  const [selectedComparisonRange, setSelectedComparisonRange] = useState(stateDefaults.selectedComparisonRange)
  const [selectedPrevYSetting, setSelectedPrevYSetting] = useState(stateDefaults.selectedPrevYSetting)
  const [selectedComparisonFloatingValue, setSelectedComparisonFloatingValue] = useState(stateDefaults.selectedComparisonFloatingValue)

  const [applied, setApplied] = useState(false)
  const resetDefaults = () => {
    if (applied) {
      setApplied(false)
      return
    }
    setView(stateDefaults.view)
    setSelectedPreset(stateDefaults.selectedPreset)
    setSelectedRange(stateDefaults.selectedRange)
    setFloatingRangeValue(stateDefaults.floatingRangeValue)

    setSelectedComparisonOption(stateDefaults.selectedComparisonOption)
    setSelectedComparisonRange(stateDefaults.selectedComparisonRange)
    setSelectedPrevYSetting(stateDefaults.selectedPrevYSetting)
    setSelectedComparisonFloatingValue(stateDefaults.selectedComparisonFloatingValue)
  }

  useEffect(() => {
    resetDefaults()
  }, [unsetPreset])

  // to be controlled from outside
  useEffect(() => {
    if (range && rangeOption) resetDefaults()
  }, [range, rangeOption])

  const apply = () => {
    const oldValue = [range, rangeOption]
    let newValue
    switch (view) {
      case CUSTOM_RANGE:
        newValue = [selectedRange.map(d => formatISODate(d)), CUSTOM_RANGE]
        break
      case FLOATING_RANGE:
        if (!floatingRangeValue) return // failsafe to prevent invalid settings
        newValue = [floatingRangeValue, FLOATING_RANGE]
        break
      default:
        newValue = [selectedRange.map(d => formatISODate(d)), selectedPreset]
        break
    }

    oldValue.push(comparisonFilter)
    newValue.push(withComparisonPeriod
      ? {
          value: selectedComparisonRange,
          option: selectedComparisonOption,
          prevYSetting: selectedPrevYSetting,
          floatingValue: selectedComparisonFloatingValue
        }
      : undefined) // set to undefined in case we disable the picker (or don't have it)

    if (onChange && !isEqual(oldValue, newValue)) {
      onChange(newValue[0], newValue[1], withComparisonPeriod ? newValue[2] : undefined)
    }
  }

  // Reset defaults when closing the popover
  const previousOpen = usePrevious(open)
  useEffect(() => {
    if (previousOpen && !open) {
      resetDefaults()
    }
  }, [open, isMobile, previousOpen])

  const renderRangeText = () => {
    if (!hasValue) {
      if (withUnsetPreset) {
        return intl.formatMessage(unsetPreset.label)
      }

      return placeholder || intl.formatMessage(
        weekly ? labelMessages.week : labelMessages.dateRange
      )
    }

    if (rangeOption === FLOATING_RANGE) {
      const m = formatFloatingPeriod(range, intl)
      return (intl.locale === 'en-US') ? m.toLowerCase() : m
    }

    if (foundPreset != null && foundPreset !== CUSTOM_RANGE) { return foundPreset ? intl.formatMessage(foundPreset.label, foundPreset.params) : null }

    if (!weekly) {
      return formatLocalizedRange(date, intl)
    }

    return date
      .map((item, i) =>
        formatLocalized(item,
          i === 0
            ? `'${intl.formatMessage(labelMessages.weekNumber)}'I' | '${DFNS_LONG_DATE_FORMAT}`
            : DFNS_LONG_DATE_FORMAT,
          intl
        )
      )
      .join(' - ')
  }

  const onTimeRangeSelect = (key) => {
    switch (key) {
      case FLOATING_RANGE:
      case CUSTOM_RANGE:
        setSelectedPreset(key)
        setView(key)
        break
      default:
        setSelectedPreset(key)
        const preset = presetOptions.find(o => o.key === key)
        if (preset) {
          setSelectedRange(preset.value)
          setView('default')
        }
        break
    }
  }

  const reset = () => {
    onChange([], CUSTOM_RANGE)
    setOpen(false)
  }

  const [textSelectOpen, setTextSelectOpen] = useState(false)

  const renderContent = () => {
    return (
      <>
        <div>
          <div className='mb-4 flex justify-between'>
            <Heading type='h5'>
              <FormattedMessage {...labelMessages.timeRange} />
            </Heading>
            {allowReset && <Button size='s' name='btn-reset' type='tertiary' className='px-0 bg-transparent' disabled={!hasValue} onClick={reset}>{intl.formatMessage(globalMessages.reset)}</Button>}
          </div>
          <TextSelect
            id='timeRange'
            className='w-full'
            value={selectedPreset}
            onChange={onTimeRangeSelect}
            creatable={false}
            allowClear={false}
            options={[
              ...presetOptions.map(o => ({ value: o.key, label: intl.formatMessage(o.label) })),
              { value: CUSTOM_RANGE, label: intl.formatMessage(labelMessages.customPeriod) },
              { value: FLOATING_RANGE, label: intl.formatMessage(labelMessages.floatingPeriod) }
            ]}
            onOpenListener={setTextSelectOpen}
          />
          {view === CUSTOM_RANGE && <RangeCalendar className='mt-4' onChange={setSelectedRange} value={selectedRange.length === 0 ? null : selectedRange} />}
          {view === FLOATING_RANGE && <FloatingPeriodPicker className='mt-4' onChange={setFloatingRangeValue} value={floatingRangeValue} />}
        </div>
        {withComparisonPeriod && (!!selectedRange?.length || view === FLOATING_RANGE) && (
          <div className='flex flex-col'>
            {view !== CUSTOM_RANGE && view !== FLOATING_RANGE && <RangePreview className='mt-4' range={selectedRange} />}
            <ComparingPeriodPicker
              presetOptions={presetOptions}
              selectedPreset={selectedPreset}
              dateRangeValue={view === FLOATING_RANGE ? floatingRangeValue : selectedRange}
              selectedComparisonOption={selectedComparisonOption}
              selectedComparisonRange={selectedComparisonRange}
              selectedPrevYSetting={selectedPrevYSetting}
              selectedComparisonFloatingValue={selectedComparisonFloatingValue}
              setSelectedComparisonOption={setSelectedComparisonOption}
              setSelectedComparisonRange={setSelectedComparisonRange}
              setSelectedPrevYSetting={setSelectedPrevYSetting}
              setSelectedComparisonFloatingValue={setSelectedComparisonFloatingValue}
              onTextSelectOpenListener={setTextSelectOpen}
            />
          </div>
        )}
      </>
    )
  }

  const onApplyButtonClick = () => {
    apply()
    setOpen(false)
    setApplied(true)
  }

  const onCancelButtonClick = () => {
    setOpen(false)
  }

  const buttonDisabled = useMemo(() => {
    let shouldDisable = false
    if (view === CUSTOM_RANGE) {
      shouldDisable = shouldDisable || selectedRange.length !== 2
    }
    if (view === FLOATING_RANGE) {
      shouldDisable = shouldDisable || !floatingRangeValue
    }
    if (withComparisonPeriod) {
      shouldDisable = shouldDisable || !selectedComparisonRange || selectedComparisonRange.length !== 2
    }
    return shouldDisable
  }, [selectedRange, floatingRangeValue, view, selectedComparisonOption, selectedComparisonFloatingValue, selectedComparisonRange, withComparisonPeriod])

  const renderTrigger = () => {
    return (
      <Button
        icon='Calendar'
        type='secondary'
        data-role='range-picker'
        pure
        whiteHoverBg
        className={classNames('mb-2 mr-2', {
          'hover:shadow-grid': !disabled,
          'bg-gray-lighter': type === 'templates' && !disabled,
          ' h-7': type === 'templates',
          'bg-white': type !== 'templates' && !disabled,
          'h-9': type !== 'templates',
          'text-gray-light': !hasValue,
          'bg-disabled cursor-default text-gray-light': disabled
        })}
        onClick={() => setOpen(!open)}
        disabled={disabled}
      >
        {hasValue && placeholder && prefixPlaceholder
          ? `${placeholder}: ${renderRangeText()}`
          : renderRangeText()}
      </Button>
    )
  }

  if (isMobile) {
    return (
      <>
        {renderTrigger()}
        {open && (
          <MobileFriendlyModal
            visible={open}
            title={intl.formatMessage(labelMessages.timeRange)}
            onCancel={onCancelButtonClick}
            onPrimary={onApplyButtonClick}
            footer={{
              primaryText: intl.formatMessage(labelMessages.apply)
            }}
          >
            {renderContent()}
          </MobileFriendlyModal>
        )}
      </>
    )
  }
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger={renderTrigger()}
      inModal={type === 'templates'}
    >
      <div className={classNames('flex flex-col p-4 min-w-72', !textSelectOpen && 'overflow-auto')} style={{ maxHeight: `calc(100vh - ${type === 'templates' ? '320' : '120'}px)` }}>
        {renderContent()}
        <FilterFooter onCancel={onCancelButtonClick} onSubmit={onApplyButtonClick} submitDisabled={buttonDisabled} />
      </div>
    </Popover>
  )
}
