import { useSettingsStore } from './store/useSettingsStore'
import { useCallback } from 'react'
import { globalSettingsDefault } from 'constants/defaultSettings'
import { getDefaultDashboard } from 'services/SettingsProvider'
import { usePermissions } from './usePermissions'
import { useUser } from './queries/useUser'

export const useSettingsManager = () => {
  const { user } = useUser()
  const permissions = usePermissions()
  const setCurrentSettingsInStore = useSettingsStore((state) => state.setCurrentSettings)
  const setGlobalSettingsInStore = useSettingsStore((state) => state.setGlobalSettings)

  const resetGlobalSettings = useCallback(() => setGlobalSettingsInStore(globalSettingsDefault), [setGlobalSettingsInStore])

  const reset = useCallback(() => {
    resetGlobalSettings()
    setCurrentSettingsInStore(getDefaultDashboard(user, permissions))
  }, [user, permissions, resetGlobalSettings])

  return {
    resetGlobalSettings,
    reset
  }
}
