import { ApplicationError } from 'components/ApplicationError'
import { ApplicationUpdate } from 'components/ApplicationUpdate'
import React from 'react'

class PageErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error: {
        message: error.message,
        stack: errorInfo.componentStack
      }
    })
  }

  render () {
    if (this.state.hasError) {
      if (this.state.error) {
        if (this.state.error.message.includes('Loading CSS chunk') || this.state.error.message.includes('Loading chunk')) {
          return <ApplicationUpdate />
        }
        return <ApplicationError error={this.state.error} context={{ type: 'page' }} />
      }
      return null
    }
    return this.props.children
  }
}

export default PageErrorBoundary
