import classNames from 'classnames'
import s from './DropdownMenu.module.scss'
import { Button } from 'components/Button'
import { Checkbox, Icon, Text, Tooltip } from 'components/Primitives'
import { Loader } from 'components/Primitives/Loader'
import * as RadixDropdown from '@radix-ui/react-dropdown-menu'
import { NoData } from 'components/NoData'
import { GroupedVirtuoso } from 'react-virtuoso'
import { omit } from 'lodash'
import { useIsMobile } from 'hooks'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useEffect, useMemo, useState } from 'react'
import { isTouchDevice } from 'utils'
import { TagBadgeNew } from 'components/Badge/TagBadgeNew'

const MenuItem = ({ className, active, label, first, last, type, checkmark, color, icon, asLink, disabled, smallWidth, buttonType, context, objects_count, assignType, ...props }) => {
  switch (type) {
    case 'button':
      return (
        <Button type={buttonType || 'primary'} icon={icon} disabled={disabled} {...props}>
          {label}
        </Button>
      )
    case 'standard':
    case 'tag':
    default:
      const Component = !checkmark && asLink ? 'a' : 'div'
      return (
        <Component {...props} className={s.inner}>
          {icon && <Icon className={s.icon} size='s' name={icon} color={color || 'gray'} />}
          {type == 'tag' ? <TagBadgeNew hex={color} className={smallWidth && s.smallWidthMargin} references={objects_count} assignType={assignType}>{label}</TagBadgeNew> : <Text className={smallWidth && s.smallWidthMargin} color={disabled ? 'disabled-20' : (color?.startsWith('#') ? null : color) || 'gray'}>{label}</Text>}
          {checkmark && active && <Icon className={s.check} size='s' name='Checkmark' />}
        </Component>
      )
  }
}

// `modal=true` causes weird behaviour, probably has to do with it being inside another popover
// when the button triggers a modal, `modal=true` also causes some modals to get confused and then `pointer-events: none` is applied to the body after closing the modal
// `modal=false` causes the dropdown to inmediately close on mobile firefox; fixed by preventing `onFocusOutside`

export const DropdownMenu = ({
  className,
  itemsClassName,
  menuSections,
  children,
  side = 'bottom',
  align = 'center',
  sideOffset = 4,
  alignOffset = 6,
  menuType = 'standard',
  header,
  checkmark,
  checkbox,
  loading,
  tooltip,
  tooltipPlacement,
  onVisibleChange,
  open: openProp,
  onPointerMove,
  noRound,
  noCloseAnimation,
  context,
  firstItemRef,
  withPortal = true,
  mobileFull,
  modalProps,
  virtuosoRef,
  iconsOnly,
  itemMargin = 0, // additional margin of items in px
  fitWidth, // fit the width of the content to the width of the button (important for Forms)
  smallWidth // use a smaller fixed width for the content (important for simple dropdown menus)
}) => {
  const isMobile = useIsMobile()
  const [open, setOpen] = useState(openProp == null ? false : openProp)

  useEffect(() => {
    if (openProp != null) { setOpen(openProp) }
  }, [openProp])

  useEffect(() => {
    if (onVisibleChange) onVisibleChange(open)
  }, [open])

  const [virtuosoLoading, setVirtuosoLoading] = useState(true && open !== undefined)

  const touchDevice = useMemo(isTouchDevice, [])

  useEffect(() => {
    if (!open) return
    const timeout = setTimeout(() => setVirtuosoLoading(false), 100)
    return () => clearTimeout(timeout)
  }, [open])

  const renderContent = () => {
    if (menuSections && menuSections.length) {
      let menuHeight = 0
      const groupCounts = menuSections.map(({ menuItems, title }) => {
        // menuHeight calculation
        if (menuItems && menuItems.length) {
          menuItems.forEach(({ icon }) => {
            if (icon) menuHeight += 40
            else menuHeight += 44
            menuHeight += itemMargin
          })
        } else {
          menuHeight += 95
        }
        if (title) menuHeight += 32
        else menuHeight += 1

        // GroupCount for virtuoso
        if (menuItems && menuItems.length) return menuItems.length
        return 1
      })
      menuHeight += menuSections[0].title ? 0 : 1 // If the first group has no title, we also add a space at the bottom
      return (
        <GroupedVirtuoso
          ref={virtuosoRef}
          style={isMobile && mobileFull
            ? {
                flexGrow: 1,
                visibility: virtuosoLoading ? 'hidden' : 'visible'
              }
            : {
                height: Math.min(menuHeight, 320),
                visibility: virtuosoLoading ? 'hidden' : 'visible'
              }}
          className={s.virtuoso}
          // Render more items than necessary to prevent empty drodowns when there are few items
          increaseViewportBy={45}
          // When there is no data we say there is one item and then render the NoData
          groupCounts={groupCounts}
          groupContent={index => {
            const { title } = menuSections[index]
            return title
              ? (
                <RadixDropdown.Label className={s.title}>
                  <Text color='gray' bold size='xs'>{title}</Text>
                </RadixDropdown.Label>
                )
              : <div style={{ height: '1px', visibility: 'hidden' }} />
          }}
          itemContent={(index, groupIndex) => {
            // FIXME: this seems to be a bug from virtuoso, it doesn't make sense that groupIndex is undefined
            if (groupIndex === undefined) groupIndex = 0

            const { menuItems, type = menuType } = menuSections[groupIndex]
            if (!menuItems || !menuItems.length) {
              return <NoData size='xs' />
            }

            // Viruoso counts index including items from previous groups
            index -= groupCounts.slice(0, groupIndex).reduce((a, b) => a + b, 0)
            const item = menuItems[index]

            const { onClick, disabled, key: itemKey, checked, active, label, color, icon } = item
            const onClickHandler = onClick
              ? (e) => onClick(context || e)
              : undefined

            const isFirst = index == 0 && groupIndex == 0
            const isLast = index == menuItems.length - 1 && groupIndex == menuSections.length - 1

            if (checkbox) {
              return (
                <Checkbox
                  className={classNames(s.item, s.checkboxItem)}
                  forDropdown
                  onSelect={onClickHandler}
                  checked={checked}
                  disabled={disabled}
                  key={itemKey}
                  label={label}
                  asTag={type === 'tag'}
                  color={type === 'tag' ? color : 'gray'}
                  type='body'
                  ref={isFirst ? firstItemRef : undefined}
                  icon={icon}
                  onPointerMove={onPointerMove}
                />
              )
            }

            return (
              <RadixDropdown.Item
                className={classNames(s.item, icon && s.hasIcon, (checked || active) && s.selected, !noRound && !menuSections[groupIndex].title && isFirst && s.first, !noRound && isLast && s.last, item.disabled ? 'cursor-not-allowed' : null, itemsClassName)}
                onSelect={item.disabled ? undefined : onClickHandler}
                onPointerMove={onPointerMove}
                onPointerEnter={onPointerMove}
                onPointerLeave={onPointerMove}
                ref={isFirst ? firstItemRef : undefined}
              >
                <MenuItem checkmark={checkmark} type={type} smallWidth={smallWidth} color={type === 'tag' ? color : (active ? 'blue' : 'gray')} {...omit(item, ['onClick'])} />
              </RadixDropdown.Item>
            )
          }}
        />
      )
    }
    return null
  }

  const VirtuosoLoader = <div className='absolute flex items-center justify-center h-full w-full' data-role='virtuoso-loader'><Loader /></div>
  // The animations cause the dropdown collision detection to fail on Dialogs (when there is not enough space) and it enters a loop of alternating the position
  const Content = (
    <RadixDropdown.Content
      className={classNames(className, 'dropdown-menu', s.content,
        {
          [s.fitWidth]: fitWidth && !iconsOnly,
          [s.smallWidth]: smallWidth && !iconsOnly,
          [s.noCloseAnimation]: noCloseAnimation,
          [s.disableAnimations]: !withPortal,
          [s.iconsOnly]: iconsOnly
        }
      )}
      side={side}
      align={align}
      sideOffset={sideOffset}
      alignOffset={alignOffset}
      hideWhenDetached
      onFocusOutside={(e) => e.preventDefault()}
    >
      {!(isMobile && mobileFull) && header}
      <div className={s.body}>
        {loading
          ? <div className='flex items-center justify-center h-36 w-38' data-role='dropdown-loader'><Loader /></div>
          : isMobile && mobileFull
            ? (
              <MobileFriendlyModal {...modalProps} forDropdown childrenWrapperClass='h-full'>
                <div className='flex flex-col h-full'>
                  <div className='flex-shrink'>
                    {header}
                  </div>
                  {virtuosoLoading && VirtuosoLoader}
                  {renderContent()}
                </div>
              </MobileFriendlyModal>)
            : (
              <>
                {virtuosoLoading && VirtuosoLoader}
                {renderContent()}
              </>
              )}
      </div>
    </RadixDropdown.Content>
  )

  const Dropdown = useMemo(() =>
    <RadixDropdown.Root
      onOpenChange={setOpen} open={open} modal={false}
    >
      <RadixDropdown.Trigger
        asChild
        data-role='trigger'
        className={classNames(s.trigger, iconsOnly && s.triggerArrow)}
        {...(touchDevice
          ? {
              onPointerDown: (e) => e.preventDefault(),
              onClick: () => setOpen(!open)
            }
          : undefined)}
      >
        {/* The child must use React.forwardRef() for radix to work properly */}
        {children}
      </RadixDropdown.Trigger>
      {/* To be able to use it inside Dialog we don't use the Portal. Should get fixed relatively soon by radix and then we can always use the Portal */}
      {withPortal ? <RadixDropdown.Portal>{Content}</RadixDropdown.Portal> : Content}
    </RadixDropdown.Root>
  , [children, Content, open, onVisibleChange, withPortal])

  return tooltip
    ? <Tooltip triggerAsChild title={tooltip} side={tooltipPlacement}>{Dropdown}</Tooltip>
    : Dropdown
}
