import { getRandomItem, pickTextColorBasedOnBgColor } from 'utils'

import { ACCENT_COLORS_LIST } from 'constants/index'
import ConfidenceBadge from './ConfidenceBadge'
import React from 'react'
import SafetySettingBadge from './SafetySettingBadge'
import classNames from 'classnames'
import { find } from 'lodash'

const getRandomColor = () => getRandomItem(ACCENT_COLORS_LIST).bg

const getColorClassName = (color) => {
  return classNames({
    'text-primary bg-primary-tint': color === 'primary',
    'text-accent-hydrangea-75 bg-accent-hydrangea-10': color === 'blue',
    'text-feedback-success bg-feedback-success-tint': color === 'green',
    'text-feedback-warning bg-feedback-warning-tint': color === 'yellow',
    'text-feedback-error bg-feedback-error-lighter': color === 'red',
    'text-gray bg-gray-lighter': !['blue', 'green', 'yellow', 'red', 'primary'].includes(color)
  })
}

const Badge = ({ color, hex, uppercase, children, className, size, padding, bold, fixWidth, style, withAppendix, id }) => {
  let fontColor
  if (hex) {
    const accentColor = find(ACCENT_COLORS_LIST, { bg: hex })
    fontColor = accentColor ? accentColor.fg : pickTextColorBasedOnBgColor(hex)
  }
  return (
    <div
      data-type='badge'
      data-id={id}
      style={{
        ...style,
        ...(hex ? { backgroundColor: hex, color: fontColor } : {})
      }}
      className={classNames('items-center notranslate text-center whitespace-nowrap overflow-ellipsis tracking-wide leading-normal',
        hex ? null : getColorClassName(color),
        withAppendix ? 'rounded-tl rounded-bl' : 'rounded',
        {
          'inline-flex': !fixWidth,
          'font-bold': bold,
          'text-sm my-0 p-1.5': size !== 'small',
          'text-xs px-0.5': size === 'small',
          'px-1.5 py-1': padding,
          'max-w-6': !['wide', 'full', 'small'].includes(size),
          'max-w-xl': size === 'full',
          'max-w-4.5': size === 'small',
          'max-w-10': size === 'wide',
          uppercase: uppercase
        }, className)}
    >
      {children}
    </div>
  )
}

export default Badge

export { SafetySettingBadge, ConfidenceBadge, getColorClassName, getRandomColor }
