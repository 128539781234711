import React from 'react'
import classNames from 'classnames'
import globalMessages from 'components/globalMessages'
import messages from './messages'
import { useIntl } from 'react-intl'
import { TextSelect } from 'components/TextSelect'

export default ({ value, onChange, placeholder, className, asActive, prefixText, trueText, falseText, noMinWidth, type, ...props }) => {
  const intl = useIntl()

  const rTrueText = trueText || (asActive ? intl.formatMessage(globalMessages.active) : intl.formatMessage(globalMessages.yes))
  const rFalseText = falseText || (asActive ? intl.formatMessage(globalMessages.inactive) : intl.formatMessage(globalMessages.no))

  return (
    <TextSelect
      creatable={false}
      allowClear={false}
      className={classNames(noMinWidth ? null : 'min-w-11.5', className)}
      type={type || 'standalone'}
      isSearchable={false}
      size='sm'
      value={value != null ? value.toString() : undefined}
      onChange={(value) => onChange(value === 'undefined' ? undefined : value === 'true')}
      placeholder={placeholder || intl.formatMessage(messages.booleanPicker)}
      options={[
        { value: 'undefined', label: intl.formatMessage(globalMessages.reset) },
        { value: 'true', label: prefixText ? <>{prefixText}<span>: {rTrueText}</span></> : rTrueText },
        { value: 'false', label: prefixText ? <>{prefixText}<span>: {rFalseText}</span></> : rFalseText }
      ]}
      {...props}
      menuPortalTarget={document.body}
      menuPosition='absolute'
    />
  )
}
