import React, { createContext, useCallback, useContext, useState } from 'react'
import { uniqueId } from 'lodash'
import { useGeneralStore } from 'hooks/store/useGeneralStore'

const UIContext = createContext({})

export const UIProvider = ({ children }) => {
  const [debugModal, setDebugModal] = useState(null)
  const [bugReportModal, setBugReportModal] = useState(false)
  const setConfirmationDialog = useGeneralStore((state) => state.setConfirmationDialog)
  const setShowConfirmationDialog = useGeneralStore((state) => state.setShowConfirmationDialog)

  const [chartErrorKey, setChartErrorKey] = useState(uniqueId('chart-error'))
  const resetChartErrorKey = useCallback(() => setChartErrorKey(uniqueId('chart-error'), []))

  const confirm = useCallback((title, children, includeNoUndoHint, onConfirm) => {
    setConfirmationDialog({
      title,
      children,
      noUndoHint: includeNoUndoHint,
      onConfirm: () => {
        setShowConfirmationDialog(false)
        onConfirm()
      }
    })
    setShowConfirmationDialog(true)
  }, [setConfirmationDialog, setShowConfirmationDialog])

  const value = {
    chartErrorKey,
    resetChartErrorKey,
    debugModal,
    setDebugModal,
    bugReportModal,
    setBugReportModal,
    confirm
  }

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  )
}

export const useUI = () => useContext(UIContext)
