import React from 'react'
import { Dialog } from 'components/Primitives/Dialog'

const MobileFriendlyModal = ({ onCancel, onSubmit, visible, ...props }) =>
  <Dialog
    open={visible}
    onClose={onCancel}
    onPrimary={onSubmit}
    {...props}
  />

export default MobileFriendlyModal
