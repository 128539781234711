import classNames from 'classnames'
import { Input, Text } from 'components/Primitives'
import { RangePreview } from 'components/RangePreview'
import { TextSelect } from 'components/TextSelect'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import { BASE_VALUE_FORMAT } from 'constants/index'
import { addDays, isAfter, isSameDay } from 'date-fns'
import { omit } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { calculateFloatingRange } from 'utils/datetime'

const getFloatingRangeValue = (fromValue, fromUnit, toValue, toUnit) => {
  return {
    from: {
      value: fromValue,
      unit: fromUnit
    },
    to: {
      value: toValue,
      unit: toUnit
    }
  }
}

const getDropdownOption = (unit, value, intl) => ({ value: unit, label: intl.formatMessage(labelMessages[unit], { count: value || 10 }) })

const getDropdownFutureOptions = (value, intl) => [
  getDropdownOption('daysFuture', value, intl),
  getDropdownOption('weeksFuture', value, intl)
]
const getDropdownPastOptions = (value, intl) => [
  getDropdownOption('daysPast', value, intl),
  getDropdownOption('weeksPast', value, intl)
]

export const FloatingPeriodPicker = ({ value, className, onChange, maxDate, minDate, fixedRangeLength, invalidRangeMessage }) => {
  const intl = useIntl()
  const [fromValue, setFromValue] = useState(value ? value.from.value : 1)
  const [fromUnit, setFromUnit] = useState(value ? value.from.unit : 'daysPast')
  const [toValue, setToValue] = useState(value ? value.to.value : 1)
  const [toUnit, setToUnit] = useState(value ? value.to.unit : 'daysFuture')

  const calculatedRange = useMemo(
    () => calculateFloatingRange(getFloatingRangeValue(fromValue, fromUnit, toValue, toUnit), false, !!fixedRangeLength),
    [fromValue, fromUnit, toValue, toUnit, fixedRangeLength]
  )

  const isValid = useMemo(() => {
    return calculatedRange &&
      (fixedRangeLength ||
        (isAfter(calculatedRange[1], calculatedRange[0]) ||
          isSameDay(calculatedRange[1], calculatedRange[0]))) &&
      (!minDate ||
        ((isAfter(calculatedRange[0], minDate) || isSameDay(calculatedRange[0], minDate)) &&
          (fixedRangeLength || isAfter(calculatedRange[1], minDate) || isSameDay(calculatedRange[1], minDate)))) &&
      (!maxDate ||
        ((fixedRangeLength || isAfter(maxDate, calculatedRange[1]) || isSameDay(maxDate, calculatedRange[1])) &&
          (isAfter(maxDate, calculatedRange[0]) || isSameDay(maxDate, calculatedRange[0]))))
  }, [calculatedRange, maxDate, minDate, fixedRangeLength])

  // if we select past in the to unit, we don't need future units in the from unit options
  const fromUnitOptions = useMemo(() => [
    ...getDropdownPastOptions(fromValue, intl),
    ...(!toUnit || !toUnit.includes('Past') ? getDropdownFutureOptions(fromValue, intl) : [])
  ], [toUnit, fromValue, intl])

  // if we select future in the from unit, we don't need past units in the to unit options
  const toUnitOptions = useMemo(() => [
    ...(!fromUnit || !fromUnit.includes('Future') ? getDropdownPastOptions(toValue, intl) : []),
    ...getDropdownFutureOptions(toValue, intl)
  ], [fromUnit, toValue, intl])

  useEffect(() => {
    if (onChange) {
      onChange(isValid ? getFloatingRangeValue(fromValue, fromUnit, toValue, toUnit) : null)
    }
  }, [calculatedRange, isValid])

  return (
    <div className={classNames('flex flex-col space-y-4', className)}>
      <div data-role='from'>
        {!fixedRangeLength && (
          <Text title={<FormattedMessage {...labelMessages.from} />} color='black'><FormattedMessage {...labelMessages.from} /></Text>
        )}
        <div className='flex space-x-2'>
          <NumericFormat id='fromValue' className='w-12' selectOnFocus allowNegative={false} {...omit(BASE_VALUE_FORMAT, ['displayType'])} customInput={Input} value={fromValue} onValueChange={(obj) => setFromValue(obj.floatValue)} />
          <TextSelect
            id='fromUnit'
            wrapperClassName='grow'
            value={fromUnit}
            onChange={setFromUnit}
            allowClear={false}
            creatable={false}
            options={fromUnitOptions}
            required
          />
        </div>
      </div>
      {!fixedRangeLength && (
        <>
          <div data-role='to'>
            <Text title={<FormattedMessage {...labelMessages.to} />} color='black'><FormattedMessage {...labelMessages.to} /></Text>
            <div className='flex space-x-2'>
              <NumericFormat id='toValue' className='w-12' selectOnFocus allowNegative={false} {...omit(BASE_VALUE_FORMAT, ['displayType'])} customInput={Input} value={toValue} onValueChange={(obj) => setToValue(obj.floatValue)} />
              <TextSelect
                id='toUnit'
                wrapperClassName='grow'
                value={toUnit}
                onChange={setToUnit}
                allowClear={false}
                creatable={false}
                options={toUnitOptions}
                required
              />
            </div>
          </div>
        </>
      )}
      <RangePreview range={fixedRangeLength && calculatedRange ? [calculatedRange[0], addDays(calculatedRange[0], fixedRangeLength - 1)] : calculatedRange} isValid={isValid} message={invalidRangeMessage} />
    </div>
  )
}

export const formatFloatingPeriod = (value, intl) => {
  const pastUnits = ['weeksPast', 'daysPast']
  const fromIsWeek = value.from.unit.includes('weeks')
  const toIsWeek = value.to.unit.includes('weeks')
  const fromMessage = pastUnits.includes(value.from.unit) ? labelMessages.unitAgo : labelMessages.unitFromNow
  const fromUnit = intl.formatMessage(fromIsWeek ? labelMessages.weeksDative : labelMessages.daysDative, { count: value.from.value })
  const toMessage = pastUnits.includes(value.to.unit) ? labelMessages.unitAgo : labelMessages.unitFromNow
  const toUnit = intl.formatMessage(toIsWeek ? labelMessages.weeksDative : labelMessages.daysDative, { count: value.to.value })

  const fromString = value.from.value === 0 ? intl.formatMessage(globalMessages.today) : intl.formatMessage(fromMessage, { unit: fromUnit, count: value.from.value })
  const toString = value.to.value === 0 ? intl.formatMessage(globalMessages.today) : intl.formatMessage(toMessage, { unit: toUnit, count: value.to.value })

  if (fromString === toString) {
    return fromString
  }
  return `${fromString} → ${toString}`
}
