import { defineMessages } from 'react-intl'

export default defineMessages({
  totalCogs: {
    id: 'chartTitles.totalCogsChart',
    defaultMessage: 'COGS in €'
  },
  relativeCogs: {
    id: 'chartTitles.relativeCogsChart',
    defaultMessage: 'COGS in %'
  },
  forecastDifference: {
    id: 'chartTitles.forecastDifferenceChart',
    defaultMessage: 'Forecast difference'
  },
  detailView: {
    id: 'chartTitles.detailViewChart',
    defaultMessage: 'Detail view'
  },
  composition: {
    id: 'chartTitles.compositionChart',
    defaultMessage: 'Composition'
  },
  foodwasteSalesCosts: {
    id: 'chartTitles.foodwasteSalesCostsChart',
    defaultMessage: 'Cost of Goods'
  },
  todoDoneTasks: {
    id: 'chartTitles.todoDoneTasksChart',
    defaultMessage: 'Done Tasks'
  },
  differenceFrom: {
    id: 'chartTitles.differenceFrom',
    defaultMessage: 'Difference from'
  },
  avgTransactionRevenue: {
    id: 'chartTitles.avgTransactionRevenue',
    defaultMessage: 'Ø revenue per transaction'
  }
})
