import { useCallback } from 'react'
import { useGeneralStore } from './store/useGeneralStore'

const useDemo = () => {
  const enabled = useGeneralStore((state) => state.demo)
  const toggle = useGeneralStore((state) => state.toggleDemo)

  const demo = useCallback((value) =>
    (enabled ? (value ? '*******' : value) : value),
  [enabled])

  return { enabled, toggle, demo }
}

export default useDemo
