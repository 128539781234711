import TagBadge from 'components/Badge/TagBadge'
import s from './TagCollection.module.scss'

export const TagCollection = ({ items, noTruncate }) => {
  if (!items) return null

  const renderItem = (item) => {
    if (!item) return null
    return typeof item === 'string'
      ? <TagBadge noTruncate={noTruncate} key={item}>{item}</TagBadge>
      : <TagBadge noTruncate={noTruncate} key={item.id}>{item.name}</TagBadge>
  }

  return (
    <div className={s.container}>
      <div>
        {Array.isArray(items)
          ? items.map(item => renderItem(item))
          : renderItem(items)}
      </div>
    </div>
  )
}
