import { format, isSameDay, parse, parseISO } from 'date-fns'

import { DFNS_DATE_FORMAT } from 'constants/index'
import React from 'react'
import de from 'date-fns/locale/de'
import { isNaN } from 'lodash'
import { useGeneralStore } from 'hooks/store/useGeneralStore'

export const DateValue = ({
  children,
  date,
  dateFormat = DFNS_DATE_FORMAT,
  fallback,
  iso,
  withTime,
  renderFormat,
  noSameDayDate,
  asString
}) => {
  const appLocale = useGeneralStore((state) => state.locale)
  const locale = appLocale === 'de-DE' ? de : undefined

  let cDate = date
  if (!cDate) {
    cDate = iso ? parseISO(children) : parse(children, dateFormat, new Date())
  }

  const renderTime = (date) => {
    const sameDay = noSameDayDate && isSameDay(date, new Date())
    return format(date, renderFormat || (withTime ? (sameDay ? 'p' : 'P p') : 'P'), { locale })
  }

  return cDate && !isNaN(cDate.getTime())
    ? (
        asString ? renderTime(cDate) : <>{renderTime(cDate)}</>
      )
    : fallback
      ? (
          asString ? fallback : <>{fallback}</>
        )
      : null
}
