import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { sortBy } from 'lodash'
import queryString from 'query-string'
import { usePageSettings } from 'hooks/usePageSettings'
import { handleMutationError } from 'utils'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from './useSelectedCustomer'

export const useItemOrder = (options) => {
  const opts = {
    enabled: true,
    items: [],
    ...options
  }
  const { settings } = usePageSettings()
  const queryClient = useQueryClient()
  const { fetch, token } = useQueryFetcher()
  const selectedCustomer = useSelectedCustomer()

  const sortedItems = sortBy(opts.items)

  const mecosQueryKey = ['mecos-order', selectedCustomer, sortedItems]
  const menulinesQueryKey = ['menulines-order', selectedCustomer, sortedItems]

  const { data: mecosOrderData, isLoading: mecosOrderIsLoading } = useQuery({
    queryKey: mecosQueryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/mecos/sort/?${queryString.stringify({
          customer: selectedCustomer,
          ids: sortedItems.join(',')
        })}`,
        {
          method: 'GET',
          token,
          success: (mecosOrder) => resolve(mecosOrder),
          failure: (err) => {
            console.warn('Error getMecosOrder', err)
            reject(err)
          }
        }
      )
    }),
    enabled: opts.enabled && opts.items.length > 0 && !settings.og2Collapsed
  })

  const { data: menulinesOrderData, isLoading: menulinesOrderIsLoading } = useQuery({
    queryKey: menulinesQueryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/menulines/sort/?${queryString.stringify({
          customer: selectedCustomer,
          ids: sortedItems.join(',')
        })}`,
        {
          method: 'GET',
          token,
          success: (mecosOrder) => resolve(mecosOrder),
          failure: (err) => {
            console.warn('Error getMenulinesOrder', err)
            reject(err)
          }
        }
      )
    }),
    enabled: opts.enabled && opts.items.length > 0 && !!settings.og2Collapsed
  })

  const { mutateAsync: setMecosOrder, isPending: setMecosOrderIsPending } = useMutation({
    mutationFn: (ids) => new Promise((resolve, reject) => {
      fetch(
        `/mecos/update-sort/?${queryString.stringify({
          customer: selectedCustomer,
          ids: ids.join(',')
        })}`,
        {
          method: 'POST',
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    onMutate: (ids) => {
      const previousData = queryClient.getQueryData(mecosQueryKey)
      queryClient.setQueryData(mecosQueryKey, ids)
      return { previousData }
    },
    onError: (_err, newData, context) => queryClient.setQueryData(mecosQueryKey, context.previousData)
  })

  const { mutateAsync: setMenulinesOrder, isPending: setMenulinesOrderIsPending } = useMutation({
    mutationFn: (ids) => new Promise((resolve, reject) => {
      fetch(
        `/menulines/update-sort/?${queryString.stringify({
          customer: selectedCustomer,
          ids: ids.join(',')
        })}`,
        {
          method: 'POST',
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    onMutate: (ids) => {
      const previousData = queryClient.getQueryData(menulinesQueryKey)
      queryClient.setQueryData(menulinesQueryKey, ids)
      return { previousData }
    },
    onError: (_err, newData, context) => queryClient.setQueryData(menulinesQueryKey, context.previousData)
  })

  return {
    setOrder: {
      mutate: settings.og2Collapsed ? setMenulinesOrder : setMecosOrder,
      isPending: settings.og2Collapsed ? setMenulinesOrderIsPending : setMecosOrderIsPending
    },
    data: settings.og2Collapsed ? menulinesOrderData : mecosOrderData,
    isLoading: settings.og2Collapsed ? menulinesOrderIsLoading : mecosOrderIsLoading
  }
}
