import { Icon } from '../Primitives/Icon'
import { forwardRef, useEffect, useState } from 'react'
import { useDebounceEffect } from 'ahooks'
import { Input } from 'components/Primitives'

export const TextField = forwardRef(({ withSearch, searchSide = 'left', value, onChange, debounceWait = 0, onKeyDown, onEnter, spaced, ...props }, forwardedRef) => {
  const handleKeyDown = (e) => {
    if (onKeyDown) onKeyDown(e)
    if (onEnter && e.key === 'Enter') onEnter(e)
  }

  const [innerValue, setInnerValue] = useState('')
  useDebounceEffect(() => {
    if (debounceWait && onChange) onChange(innerValue)
  }, [innerValue], { wait: debounceWait })

  useEffect(() => {
    if (debounceWait && value != null && value != innerValue) setInnerValue(value)
  }, [value])

  const SearchIcon = <Icon size='s' name='Search' color='gray' />

  return (
    <Input
      ref={forwardedRef}
      value={debounceWait ? innerValue : (value == null ? '' : value)}
      onChange={(value) => {
        if (debounceWait) setInnerValue(value)
        else onChange(value)
      }}
      onKeyDown={handleKeyDown}
      inputPrefix={withSearch && searchSide === 'left' ? SearchIcon : null}
      inputSuffix={withSearch && searchSide === 'right' ? SearchIcon : null}
      spaced={withSearch || spaced}
      {...props}
    />
  )
})
