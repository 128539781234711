import RoundedButton from 'components/RoundedButton'
import { useIntl } from 'react-intl'
import titleMessages from 'components/titleMessages'
import { Button } from 'components/Button'
import { useState } from 'react'
import { AddMeasurementForm } from './AddMeasurementForm'

export default ({ columns }) => {
  const intl = useIntl()
  const [showForm, setShowForm] = useState(false)

  return (
    <>
      <div className='relative'>
        {/* // FIXME: Use new icon button for mobile */}
        <RoundedButton
          className='tablet:hidden desktop:hidden'
          icon='Add'
          titlePlacement='left'
          onClick={() => setShowForm(true)}
          name='btn-add'
          type='secondary'
        />
        <Button
          className='mobile:hidden'
          icon='Add'
          type='tertiary'
          onClick={() => setShowForm(true)}
        >
          {intl.formatMessage(titleMessages.addOpenMeasurement)}
        </Button>
      </div>
      <AddMeasurementForm
        visible={showForm}
        onCancel={() => setShowForm(false)}
        columns={columns}
      />
    </>
  )
}
