import { FormattedMessage } from 'react-intl'
import { useUser } from 'hooks'
import { getUserName } from 'utils'
import { Button } from 'components/Button'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { OPERATOR } from 'constants/index'

export const ImpersonationBanner = () => {
  const { user } = useUser()
  const customer = useSelectedCustomer()
  if (!user || !user.is_impersonated) return null

  const stopImpersonation = () => {
    // if user.impersonator is an OPERATOR, we should set the hash to the customer of the impersonated user
    if (user.impersonator.role === OPERATOR) {
      window.location.hash = `#c_${customer}`
    }
    window.location.reload(true)
  }

  return (
    <div className='fixed top-0 left-0 w-full py-1 text-sm font-bold text-center flex justify-between items-center px-6 shadow bg-accent-marigold-10 z-100 text-accent-marigold-100'>
      <FormattedMessage id='impersonationBanner.text' defaultMessage='You are currently impersonating {userName} ({userMail}).' values={{ userName: getUserName(user), userMail: user.email }} />
      <Button size='xs' type='neutral' onClick={stopImpersonation}>Stop impersonating</Button>
    </div>
  )
}
