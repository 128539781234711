import React, { useEffect, useState } from 'react'

import DatePicker from 'antd/lib/date-picker' // We have to import it like this to make sure we have the patched version
import cn from 'classnames'
import m from 'moment'
import messages from './messages'
import s from './DateTimePicker.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import useIsMobile from 'hooks/useIsMobile'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { parseISO, isEqual, endOfMinute, isFuture } from 'date-fns'
import { formatLocalized } from 'utils/datetime'
import labelMessages from 'components/labelMessages'
import { uniqueId } from 'lodash'
import { Icon, Text } from 'components/Primitives'
import { useDebounceEffect } from 'ahooks'
import { Button } from 'components/Button'
import globalMessages from 'components/globalMessages'

export default ({
  value,
  onChange,
  gray,
  getPopupContainer,
  placeholder,
  prefixPlaceholder,
  nullable
}) => {
  const intl = useIntl()
  const isMobile = useIsMobile()
  const [open, onOpenChange] = useState(false)
  const [localDate, setLocalDate] = useState(value ? parseISO(value) : nullable ? null : new Date())

  const hasValue = localDate != null

  const onDateChange = (date) => {
    // support moment objects which are returned by range picker
    const pureDate = date && date._isAMomentObject ? date.toDate() : date
    setLocalDate(pureDate)
  }

  const reset = () => {
    setLocalDate(null)
    onOpenChange(false)
    onChange(null)
  }

  useDebounceEffect(() => {
    if (!open) {
      if (!localDate || isEqual(value, localDate)) {
        return
      }
      const str = endOfMinute(localDate).toISOString().split('Z')
      onChange(`${str[0]}999Z`)
    }
  }, [open], { wait: 300 })

  const renderRangeText = () => {
    if (!hasValue) {
      return placeholder || intl.formatMessage(
        labelMessages.dateRange
      )
    }

    return formatLocalized(localDate, 'P p', intl)
  }

  const renderInput = () => {
    return (
      <div onClick={() => onOpenChange(true)} className={cn(s.picker, gray && s.gray)}>
        <span className={cn(s.selection, hasValue && s.hasValue)}>
          <Icon name='Calendar' />
          <Text bold color={hasValue ? 'blue' : 'gray'}>
            {hasValue && placeholder && prefixPlaceholder
              ? `${placeholder}: ${renderRangeText()}`
              : renderRangeText()}
          </Text>
        </span>
      </div>
    )
  }

  // This is a hack do make AntDesign Calendar picker work in the modal
  const [renderKey, setRenderKey] = useState(uniqueId('range-picker-'))
  const [dialogContainer, setDialogContainer] = useState(null)
  useEffect(() => {
    if (!open) {
      setDialogContainer(null)
      setRenderKey(uniqueId('range-picker-'))
    }
  }, [open])

  const renderPicker = () => {
    const getCalendarContainer = getPopupContainer || (isMobile && dialogContainer ? () => dialogContainer : undefined)
    return (
      <DatePicker
        key={renderKey}
        size='small'
        allowClear={false}
        className={cn(s.picker, gray && s.gray)}
        dropdownClassName={cn('fixed', s.popup, { [s.mobile]: isMobile })}
        value={localDate ? m(localDate) : null} // FIXME: as long as we are using antd 2, we have to pass a moment object
        onChange={onDateChange}
        open={isMobile ? !!(open && dialogContainer) : open}
        getCalendarContainer={getCalendarContainer}
        renderInput={renderInput}
        onOpenChange={onOpenChange}
        disabledDate={(date) => isFuture(date._d)}
        showWeekNumber
        showToday={false}
        showTime={{ defaultValue: m('14:00:00', 'HH:mm:ss') }}
        format='YYYY-MM-DD HH:mm'
        renderExtraFooter={hasValue ? () => <Button type='link' onClick={reset}>{intl.formatMessage(globalMessages.reset)}</Button> : undefined}
        /* custom prop for mobile view added using patch-package */
        compact={isMobile}
      />
    )
  }

  return (
    <>
      <style>
        {`.ant-calendar-week-number-cell:before {
          content: '${intl.formatMessage(messages.week)}';
        }`}
      </style>
      {open ? renderPicker() : renderInput()}
      {isMobile &&
        (
          <MobileFriendlyModal
            visible={open}
            noPadding
            title={<FormattedMessage id='RangePicker.selectRange' defaultMessage='Select Range' />}
            onCancel={() => onOpenChange(false)}
            noFooter
            popupContainerClassName={s.mobileContainer}
          >
            {(popupContainer) => {
              if (!dialogContainer) {
                setDialogContainer(popupContainer)
              }
            }}
          </MobileFriendlyModal>
        )}
    </>
  )
}
