import { Tooltip } from 'components/Primitives'
import { useDemo } from 'hooks'
import { useImportData } from 'hooks/queries/useImportData'

export const DownloadFileRenderer = ({ value, data, colDef }) => {
  const { demo } = useDemo()
  const { downloadFile } = useImportData()

  if (!value) return null

  return (
    <div>
      <Tooltip triggerClassName='w-full overflow-hidden text-ellipsis text-start' triggerAsChild title={colDef.tooltipTitle && colDef.intl.formatMessage(colDef.tooltipTitle)} placement='top'>
        <a onClick={() => downloadFile(data)}>
          {colDef.disguise ? demo(value) : value}
        </a>
      </Tooltip>
    </div>
  )
}
