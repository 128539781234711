import { REFRESH_TOKEN_COOKIE, TOKEN_COOKIE } from 'constants/index'
import React, { useMemo, useState } from 'react'
import { pick } from 'lodash'
import { Button } from 'components/Button'
import { ConnectionState } from './ConnectionState'
import { FormattedMessage, useIntl } from 'react-intl'
import message from 'services/message'
import s from './DebugConsole.module.scss'
import { useAppContext } from 'services/context'
import { useQueryClient } from '@tanstack/react-query'
import { useUI } from 'services/UIProvider'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useNetwork } from 'ahooks'
import { Card } from 'components/Primitives'
import { setSetting } from 'services/SettingsProvider'
import db64 from 'db64'
import { usePermissions } from 'hooks'

export const Debugger = () => {
  const intl = useIntl()
  const [output, setOutput] = useState('')
  const { cookies } = useAppContext()
  const queryClient = useQueryClient()
  const globalSettings = useSettingsStore(state => state.globalSettings)
  const { setBugReportModal } = useUI()
  const networkState = useNetwork()
  const [showInputModal, setShowInputModal] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const messages = useDebugStore(state => state.messages)
  const { isOperator } = usePermissions()

  const setSocketEnabled = useGeneralStore(state => state.setSocketEnabled)
  const socketState = useGeneralStore(state => state.socketState)
  const debugMode = useDebugStore((state) => state.debugMode)
  const setDebugMode = useDebugStore((state) => state.setDebugMode)
  const setDebugChunkError = useDebugStore((state) => state.setDebugChunkError)

  const messageString = useMemo(() => messages.map(m => `${m.time}  ${m.message}`).join('\n'), [messages])

  const version = useGeneralStore(state => state.version)
  const setAppVersion = useGeneralStore(state => state.setVersion)

  const [view, setView] = useState('log')

  const logOutput = (obj) => {
    setView('output')
    setOutput(obj)
  }

  const toggleView = () => setView(view === 'log' ? 'output' : 'log')

  const getSerializableQueryCache = async () => {
    const cache = queryClient.getQueryCache()
    const serializableCache = await Promise.all(cache.queries.map(async q => ({
      cacheTime: q.cacheTime,
      options: pick(q.options, ['enabled', 'staleTime', 'retry']),
      queryKey: q.queryKey,
      queryHash: q.queryHash,
      state: pick(q.state, ['isFetching', 'isInvalidated', 'error', 'status']),
      data: q.promise ? await q.promise.then((d) => d) : null
    })))
    return serializableCache
  }

  const closeInputModal = () => {
    setShowInputModal(false)
    setInputValue('')
  }

  const confirmSettingsOverwrite = () => {
    try {
      const json = JSON.parse(inputValue)
      closeInputModal()
      setDebugMode(true)
      setSetting(json)
    } catch (ex) {
      message.error(ex.message)
    }
  }

  const onAction = (action) => {
    try {
      switch (action) {
        default:
          message.error(`Unknown action »${action}«`)
          break
        case 'print.cookies':
          logOutput(JSON.stringify(cookies.cookies, null, 2))
          break
        case 'print.queryCache':
          getSerializableQueryCache().then(cache => {
            logOutput(JSON.stringify(cache, null, 2))
          })
          break
        case 'print.networkState':
          logOutput(JSON.stringify(networkState, null, 2))
          break
        case 'kill.authToken':
          cookies.set(TOKEN_COOKIE, '')
          message.success('Authentication token has been removed.')
          break
        case 'kill.allAuthToken':
          cookies.set(TOKEN_COOKIE, '')
          cookies.set(REFRESH_TOKEN_COOKIE, '')
          cookies.set(REFRESH_TOKEN_COOKIE, '')
          message.success('All token cookies have been removed.')
          break
        case 'websocket.reconnect':
          setSocketEnabled(false)
          window.setTimeout(() => setSocketEnabled(true), 2000)
          setView('log')
          break
        case 'storage.removeLastVisited':
          window.localStorage.removeItem('lastVisitedVersion')
          message.success('Forgot last visited version.')
          break
        case 'storage.fakeLastVisited':
          window.localStorage.setItem('lastVisitedVersion', '"v1-0-c2195f3b"')
          message.success('Updated last visited version to v1-0-c2195f3b.\nVersion detection will occur on next sideload.')
          break
        case 'state.fakeAppVersion':
          // setting a version here will directly trigger a refresh from the updateHandler
          // setAppVersion({
          //   frontend_version: 'v1-0-c2195f3b',
          //   backend_version: 'v1-0-c2195f3b'
          // })
          // so we set the app version to null to prevent the updateHandler from triggering a refresh
          setAppVersion('v1-0-123456')
          window.localStorage.setItem('lastVisitedVersion', 'v1-0-c2195f3b')
          message.success('Updated version and last visited version.\nNext API request will trigger version change detection.')
          break
        case 'action.bugReport':
          setBugReportModal(true)
          break
        case 'debug.chunkError':
          setDebugChunkError(true)
          message.success('Provoking chunk error noted. Now close this console and navigate to the account page.')
          break
        case 'storage.clearCachedCustomers':
          db64.clear('cache', 'customers').then(() => {
            message.success('Cleared IndexedDB cache/customers.')
          })
          break
        case 'storage.clearCachedLocations':
          db64.clear('cache', 'locations').then(() => {
            message.success('Cleared IndexedDB cache/locations.')
          })
          break
        case 'toggle.debugMode':
          setDebugMode(true)
          break
        case 'toggle.devMode':
          setSetting({ development: !globalSettings.development }, true)
          break
        case 'debug.simulateSettings':
          setShowInputModal(true)
          break
      }
    } catch (ex) {
      message.error(`Error performing action »${action}«: ${ex.message}`)
      console.log(ex)
    }
  }

  return (
    <div className='flex flex-col justify-between flex-grow'>
      <div className='grid flex-grow grid-cols-5 gap-4 px-2 mobile:grid-cols-2 tablet:grid-cols-3 tablet:auto-rows-min mobile:auto-rows-min'>
        <Card
          title={<FormattedMessage id='debugConsole.supportActions' defaultMessage='Support Actions' />}
          bodyClassName='flex flex-col space-y-2 items-stretch'
        >
          <Button
            onClick={() => onAction('action.bugReport')}
            icon='ReportData'
          >
            <FormattedMessage id='bugReport.title' defaultMessage='Bug Report' />
          </Button>
          <Button
            onClick={() => onAction('toggle.devMode')}
            type='secondary'
            icon='Data_1'
          >
            {globalSettings.development
              ? <FormattedMessage id='debugConsole.disableDeveloperMode' defaultMessage='Disable Developer Mode' />
              : <FormattedMessage id='debugConsole.enableDeveloperMode' defaultMessage='Enable Developer Mode' />}

          </Button>
          <Button
            onClick={() => onAction('toggle.debugMode')}
            type='secondary'
            icon='RecentlyViewed'
            disabled={debugMode}
          >
            <FormattedMessage id='debugConsole.enableDebugMode' defaultMessage='Enable Volatile Mode' />
          </Button>
          <Button
            onClick={() => onAction('debug.simulateSettings')}
            type='secondary'
            icon='Activity'
          >
            <FormattedMessage id='debugConsole.simulateSettings' defaultMessage='Simulate Settings' />
          </Button>
          <Button
            onClick={() => onAction('websocket.reconnect')}
            icon='Reset'
            type='secondary'
          >
            <FormattedMessage id='debugConsole.reconnectWebsocket' defaultMessage='Reconnect Websocket' />
          </Button>
          {isOperator && (
            <Button
              onClick={() => onAction('storage.clearCachedCustomers')}
              type='secondary'
              icon='Inventory'
            >
              <FormattedMessage id='debugConsole.clearCustomerStore' defaultMessage='Clear cached customers' />
            </Button>
          )}
          <Button
            onClick={() => onAction('storage.clearCachedLocations')}
            type='secondary'
            icon='Inventory'
          >
            <FormattedMessage id='debugConsole.clearCachedLocations' defaultMessage='Clear cached locations' />
          </Button>
        </Card>
        <Card
          title={<FormattedMessage id='debugConsole.debugState' defaultMessage='Debug State' />}
          bodyClassName='flex flex-col space-y-2 items-stretch'
        >
          <Button
            onClick={() => onAction('print.cookies')}
            type='secondary'
            icon='IbmSecurity'
          >
            <FormattedMessage id='debugConsole.printCookies' defaultMessage='Print Cookies' />
          </Button>
          <Button
            onClick={() => onAction('print.queryCache')}
            type='secondary'
            icon='Catalog'
          >
            <FormattedMessage id='debugConsole.printQueryCache' defaultMessage='Print Query Cache' />
          </Button>
          <Button
            onClick={() => onAction('print.networkState')}
            type='secondary'
            icon='Network_4'
          >
            <FormattedMessage id='debugConsole.printNetworkState' defaultMessage='Print Network State' />
          </Button>
        </Card>
        <Card
          title={<FormattedMessage id='debugConsole.debugActions' defaultMessage='Debug Actions' />}
          bodyClassName='flex flex-col space-y-2 items-stretch'
        >
          <Button
            onClick={() => onAction('kill.authToken')}
            type='secondary'
            icon='IbmSecurity'
          >
            <FormattedMessage id='debugConsole.terminateAuthToken' defaultMessage='Terminate Auth Token' />
          </Button>
          <Button
            onClick={() => onAction('kill.allAuthToken')}
            type='secondary'
            icon='IbmSecurity'
          >
            <FormattedMessage id='debugConsole.terminateAllAuthToken' defaultMessage='Terminate All Auth Token' />
          </Button>
          <Button
            onClick={() => onAction('storage.removeLastVisited')}
            type='secondary'
            icon='TrashCan'
          >
            <FormattedMessage id='debugConsole.forgetLastVisitedVersion' defaultMessage='Forget Last Visited Version' />
          </Button>
          <Button
            onClick={() => onAction('storage.fakeLastVisited')}
            type='secondary'
            icon='Calibrate'
          >
            <FormattedMessage id='debugConsole.fakeLastVisitedVersion' defaultMessage='Fake Last Visited Version' />
          </Button>
          <Button
            onClick={() => onAction('state.fakeAppVersion')}
            type='secondary'
            icon='Calibrate'
          >
            <FormattedMessage id='debugConsole.fakeAppVersion' defaultMessage='Fake App Version' />
          </Button>
          <Button
            onClick={() => onAction('debug.chunkError')}
            type='secondary'
            icon='Warning'
          >
            <FormattedMessage id='debugConsole.provokeChunkError' defaultMessage='Provoke chunk loading error' />
          </Button>
        </Card>
        <Card
          className='col-span-2 flex flex-col max-w-none mobile:h-screen-50 tablet:h-screen-50 tablet:col-span-3 mobile:col-span-3'
          title={view === 'output' ? <FormattedMessage id='debugConsole.output' defaultMessage='Output' /> : <FormattedMessage id='debugConsole.log' defaultMessage='Log' />}
          bodyClassName='flex-grow flex flex-col h-full'
          extra={
            <Button icon='View' size='s' type='tertiary' onClick={toggleView}>
              <FormattedMessage id='debugConsole.toggleView' defaultMessage='Toggle View' />
            </Button>
          }
        >
          {/* TODO: For output we could use the <Json> component, but have to fix it extending the size, also add a copy to clipboard */}
          <textarea className={s.output} value={view === 'log' ? messageString : output} />
        </Card>
      </div>
      <div className={s.footer}>
        <div>
          <span className='mr-2 font-bold'>Websocket:</span>
          <ConnectionState state={socketState} />
        </div>
        <div className='justify-center text-gray'>Delicious Data {version || 'N/A'}</div>
        <div className='justify-end'>
          <span className='mr-2 font-bold'>Network:</span>
          <ConnectionState state={networkState.online ? 'online' : 'offline'} />
        </div>
      </div>
      <MobileFriendlyModal
        className='z-2000'
        visible={showInputModal}
        title={<FormattedMessage id='debugConsole.simulateSettings' defaultMessage='Simulate Settings' />}
        onCancel={closeInputModal}
        onSubmit={confirmSettingsOverwrite}
      >
        <textarea
          className='w-full border h-80 border-gray-light'
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder={intl.formatMessage({ id: 'debugConsole.simulateSettingsPlaceholder', defaultMessage: 'Paste user settings JSON here...' })}
        />
      </MobileFriendlyModal>
    </div>
  )
}
