import { useUpdateEffect } from 'ahooks'
import { useEffect, useState } from 'react'
import { TimePicker } from '.'
import { Button } from 'components/Button'
import { useIntl } from 'react-intl'
import messages from 'components/globalMessages'
import s from './TimeRangePicker.module.scss'
import classNames from 'classnames'

export default ({ value, onChange, ...props }) => {
  const intl = useIntl()
  const [from, setFrom] = useState(value ? value[0] : undefined)
  const [to, setTo] = useState(value ? value[1] : undefined)

  const reset = () => {
    setFrom(undefined)
    setTo(undefined)
  }

  useEffect(() => {
    if (value && value.length === 2) {
      setFrom(value[0])
      setTo(value[1])
    } else {
      reset()
    }
  }, [value])

  useUpdateEffect(() => {
    if (from && to) {
      onChange([from, to])
    } else if (from && !to) {
      setTo(from)
    } else if (!from && to) {
      setFrom(to)
    } else {
      onChange([])
    }
  }, [from, to])

  return (
    <div className={s.wrapper}>
      <TimePicker
        wrapperClassName='flex-shrink'
        customClasses={{ control: classNames(s.control, s.pickerLeft) }}
        value={from}
        onChange={e => setFrom(e)}
        noDropdownIcon
        role='from-time-picker'
        {...props}
      />
      <span className={s.separator}>-</span>
      <TimePicker
        wrapperClassName='flex-grow'
        customClasses={{ control: classNames(s.control, s.pickerRight) }}
        value={to}
        onChange={e => setTo(e)}
        role='to-time-picker'
        {...props}
      />
      <Button type='tertiary' className='px-0 flex-shrink' onClick={reset} data-action='reset-time-picker'>{intl.formatMessage(messages.reset)}</Button>
    </div>
  )
}
