import { useUser } from './queries/useUser'

const useRole = () => {
  const { user } = useUser()
  const isPermitted = (roles) => !roles || (user && roles.includes(user.role))

  return { role: user ? user.role : null, isPermitted }
}

export default useRole
