import * as RadixToggleGroup from '@radix-ui/react-toggle-group'
import classNames from 'classnames'
import s from './ToggleGroup.module.scss'
import { Icon } from '../Icon'
import { Text } from '../Text'

const allowedSizes = ['s', 'm']

const getTextSize = (size) => {
  switch (size) {
    case 's': return 's'
    case 'm': return 's'
  }
}

export const ToggleGroup = ({ children, className, value, onChange, size = 'm', disabled }) => {
  if (!allowedSizes.includes(size)) throw new Error(`Invalid size: ${size}`)

  return (
    <RadixToggleGroup.Root
      className={classNames(s.root, s[size], {
        [s.disabled]: disabled
      }, className)}
      type='single'
      value={value}
      onValueChange={onChange}
      disabled={disabled}
    >
      {children.map(item =>
        <RadixToggleGroup.Item
          key={item.value}
          className={classNames(s.item, {
            [s.iconOnly]: !item.label
            // [s.labelOnly]: !item.icon
          })}
          value={item.value}
        >
          {item.icon && <Icon name={item.icon} size={size === 's' ? 's' : 'm'} />}
          {item.label && <Text type='headline' size={getTextSize(size)}>{item.label}</Text>}
        </RadixToggleGroup.Item>
      )}
    </RadixToggleGroup.Root>
  )
}
