import { defineMessages } from 'react-intl'

export default defineMessages({
  loadingOoo: {
    id: 'grid.loadingOoo',
    defaultMessage: 'Loading...'
  },
  pinColumn: {
    id: 'grid.pinColumn',
    defaultMessage: 'Pin Column'
  },
  pinLeft: {
    id: 'grid.pinLeft',
    defaultMessage: 'Pin Left'
  },
  pinRight: {
    id: 'grid.pinRight',
    defaultMessage: 'Pin Right'
  },
  noPin: {
    id: 'grid.noPin',
    defaultMessage: 'No Pin'
  },
  autosizeThiscolumn: {
    id: 'grid.autosizeThiscolumn',
    defaultMessage: 'Autosize This Column'
  },
  autosizeAllColumns: {
    id: 'grid.autosizeAllColumns',
    defaultMessage: 'Fit column widths to cell contents'
  },
  fitColumns: {
    id: 'grid.fitColumns',
    defaultMessage: 'Fit column widths to table width'
  },
  resetColumns: {
    id: 'grid.resetColumns',
    defaultMessage: 'Reset column order and visibilities'
  },
  ariaLabelColumnMenu: {
    id: 'grid.ariaLabelColumnMenu',
    defaultMessage: 'Column Menu'
  },
  ariaLabelCellEditor: {
    id: 'grid.ariaLabelCellEditor',
    defaultMessage: 'Cell Editor'
  },
  ariaLabelDialog: {
    id: 'grid.ariaLabelDialog',
    defaultMessage: 'Dialog'
  },
  ariaLabelSelectField: {
    id: 'grid.ariaLabelSelectField',
    defaultMessage: 'Select Field'
  },
  ariaLabelTooltip: {
    id: 'grid.ariaLabelTooltip',
    defaultMessage: 'Tooltip'
  },
  ariaLabelContextMenu: {
    id: 'grid.ariaLabelContextMenu',
    defaultMessage: 'Context Menu'
  },
  ariaLabelSubMenu: {
    id: 'grid.ariaLabelSubMenu',
    defaultMessage: 'SubMenu'
  },
  createNewView: {
    id: 'grid.createNewView',
    defaultMessage: 'Create New View'
  },
  deleteCurrentView: {
    id: 'grid.deleteCurrentView',
    defaultMessage: 'Delete Current View'
  },
  deleteCurrentViewDescription: {
    id: 'grid.deleteCurrentViewDescription',
    defaultMessage: 'Are you sure you want to delete the current view <b>»{name}«</b>?'
  },
  saveAsNewViewDescription1: {
    id: 'grid.saveAsNewViewDescription1',
    defaultMessage: 'Use this function to save the currently displayed view under a new name.'
  },
  saveAsNewViewDescription2: {
    id: 'grid.saveAsNewViewDescription2',
    defaultMessage: 'You can switch between the currently displayed view in the »View« panel at any time.'
  },
  viewPanelDescription: {
    id: 'grid.viewPanelDescription',
    defaultMessage: 'All changes are automatically saved in the currently selected view. You can save the current view under a new name and use as many views as you like.'
  },
  exportPanelDescription: {
    id: 'grid.exportPanelDescription',
    defaultMessage: 'You can select the location you want to export the CSV for via the location filter.'
  },
  rowsPerPage: {
    id: 'grid.rowsPerPage',
    defaultMessage: 'Rows per page'
  }
})
