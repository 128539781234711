import { FormattedMessage } from 'react-intl'
import React from 'react'

const Footer = () => (
  <div className='px-20 py-6 text-gray-light mobile:px-0 mobile:text-center'>
    <FormattedMessage id='footer.copyright' defaultMessage='© {year} Delicious Data' values={{ year: new Date().getFullYear() }} />
  </div>
)

export default Footer
