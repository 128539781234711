import MobileFriendlyModal from 'components/MobileFriendlyModal'
import React from 'react'
import globalMessages from 'components/globalMessages'
import classNames from 'classnames'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'

const RemoveConfigModal = ({
  onSubmit,
  onClose,
  intl,
  selectedItem,
  loading
}) => {
  return (
    <MobileFriendlyModal
      visible={selectedItem != null}
      title={intl.formatMessage({ id: 'RemoveTodoConfigModal.title', defaultMessage: 'Do you really want to delete the todo config?' })}
      onCancel={onClose}
      onPrimary={onSubmit}
      footer={{
        primaryText: intl.formatMessage(globalMessages.remove),
        primaryLoading: loading
      }}
      confirm
      danger
      undoWarning
    >
      <div className={classNames('mb-12 text-gray text-base')}>
        <FormattedMessage
          id='RemoveTodoConfigModal.text'
          defaultMessage='The todo config <b>»{id}«</b> will be removed.'
          tagName='p'
          values={{ id: get(selectedItem, 'id', ''), b: chunks => <b>{chunks}</b> }}
        />
      </div>
    </MobileFriendlyModal>
  )
}

export default RemoveConfigModal
