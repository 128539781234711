import { useCurrentDashboard } from 'components/Dashboard/hooks/useCurrentDashboard'
import { useMemo } from 'react'
import { getRangePreset } from 'utils'
import { formatISODate } from 'utils/datetime'

export const useDefaultDateRange = () => {
  const dashboard = useCurrentDashboard()
  const preset = dashboard?.settings?.defaultRange || 'yesterday'
  return useMemo(() => {
    const p = getRangePreset(preset)
    if (p) {
      return {
        ...p,
        value: p.value.map(formatISODate)
      }
    }
    return null
  }, [preset])
}
