import React from 'react'
import s from './SimpleBar.module.scss'
import classNames from 'classnames'

export const SimpleBar = ({ percentage = 0, innerClassName }) => {
  return (
    <div className={s.outer}>
      <div className={classNames(s.inner, innerClassName)} style={{ width: `${percentage}%` }} />
    </div>
  )
}
