import { useDebounceEffect } from 'ahooks'
import { useIntercom } from 'react-use-intercom'
import config from 'config'

const usePageTrack = () => {
  const { update } = useIntercom()

  useDebounceEffect(() => {
    if (config.intercom.appId) { update() }
  }, [], {
    wait: 500
  })
}

export default usePageTrack
