import { DifferenceIndicator } from 'components/DifferenceIndicator'
import s from './CellRenderer.module.scss'
import { percentFormatter } from '../formatter'
import { Tooltip } from 'components/Primitives'
import { DeltaTooltip } from 'components/Report/Partials/DeltaTooltip'
import { getFormatterForReturnType } from 'components/Report/utils'
import { useIntl } from 'react-intl'
import globalMessages from 'components/globalMessages'
import classNames from 'classnames'

export const NumberWithComparisonValueRenderer = (props) => {
  const intl = useIntl()
  const { value, valueFormatted, data, colDef } = props
  if (value == null) return null

  const comparisonValue = data[`${colDef.field}_compare`]
  const diff = value - comparisonValue
  const realDelta = colDef.returnType === 'percent' ? diff : Math.abs(diff / comparisonValue) * Math.sign(diff)
  const formatter = getFormatterForReturnType(colDef.returnType)

  const tooltipContent = (
    <DeltaTooltip
      difference={formatter({ value: diff })}
      prevRangeParams={colDef.prevRangeParams}
      prevValue={formatter({ value: comparisonValue, minimize: intl.formatMessage(globalMessages.millionSymbol) })}
      isFormatted
      v2
    />
  )

  const inner = (
    <div className={classNames(s.flexRightCell, s.monoCell)}>
      {valueFormatted}
      {comparisonValue != null && <DifferenceIndicator className='ml-2' difference={realDelta} inverted={colDef.inverted} size='xs' formatter={percentFormatter} />}
    </div>
  )
  return (
    <div className='w-full'>
      {comparisonValue != null
        ? (
          <Tooltip triggerClassName='w-full' side='top' title={tooltipContent} data-cy='tooltip-to-hover'>
            {inner}
          </Tooltip>
          )
        : inner}
    </div>
  )
}
