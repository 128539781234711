import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { useCustomerFoodWasteMode } from 'hooks'

const dynamicCols = [
  'num_sold',
  'grams_per_num_sold',
  'num_transactions',
  'grams_per_num_transactions',
  'sales_cogs',
  'fraction_of_cogs'
]

const numSoldCols = [
  'num_sold',
  'grams_per_num_sold'
]
const numTransactionsCols = [
  'num_transactions',
  'grams_per_num_transactions'
]
const cogsCols = [
  'sales_cogs',
  'fraction_of_cogs'
]

export const useFoodWasteModeProperties = () => {
  const intl = useIntl()
  const foodWasteMode = useCustomerFoodWasteMode()

  switch (foodWasteMode) {
    default:
    case 'num_sold':
      return {
        goalUnit: intl.formatMessage(labelMessages.unitGramPerSold),
        invalidFoodwasteCols: dynamicCols.filter(col => !numSoldCols.includes(col))
      }
    case 'num_transactions':
      return {
        goalUnit: intl.formatMessage(labelMessages.unitGramPerTransaction),
        invalidFoodwasteCols: dynamicCols.filter(col => !numTransactionsCols.includes(col))
      }
    case 'cogs':
      return {
        goalUnit: '[%]',
        invalidFoodwasteCols: dynamicCols.filter(col => !cogsCols.includes(col))
      }
  }
}
