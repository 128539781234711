import { create } from 'zustand'
import { TEMPLATE_STEPS } from 'constants/'

export const useTemplateFlowStore = create((set) => ({
  templateFilters: {},
  setTemplateFilters: (templateFilters) => set(() => ({ templateFilters })),
  step: TEMPLATE_STEPS.FIRST,
  setStep: (step) => set(() => ({ step })),
  reset: () => set(() => ({ templateFilters: {}, step: TEMPLATE_STEPS.FIRST }))
}))
