import globalMessages from 'components/globalMessages'
import { handleMutationError } from 'utils'
import message from 'services/message'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'

export const useForecasts = () => {
  const { fetch, token } = useQueryFetcher()
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (customerId) => new Promise((resolve, reject) => {
      fetch(
        `/productions/forecast/?${queryString.stringify({
          customer: customerId
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => {
            if (res.status === 'success') {
              message.success(intl.formatMessage(globalMessages.actionSucceeded, { message: res.message }))
            } else {
              message.error(intl.formatMessage(globalMessages.actionFailed, { status: res.status, message: res.message }))
            }
            resolve(res)
          },
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    })
  })

  return {
    getForecasts: {
      mutate: mutateAsync,
      loading: isPending
    }
  }
}
