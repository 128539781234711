import './wdyr'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')
try {
  if (container) {
    const root = createRoot(container)
    root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    )
  } else {
    console.warn('Root container not found.')
  }
} catch (ex) {
  console.log('Error initializing react app:', ex.message)
}
