
import { useDemo, useRole, useUser } from 'hooks'

import React from 'react'
import { SearchSelect } from 'components/SearchSelect'
import { find } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { OPERATOR } from 'constants/index'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useQueryClient } from '@tanstack/react-query'
import queryString from 'query-string'
import { useInitializationStore } from 'hooks/store/useInitializationStore'
import { useMount } from 'ahooks'
import { useLocation } from 'react-router-dom'
import { decompressFromBase64 } from 'utils'

const CustomerSelect = ({ className }) => {
  const queryClient = useQueryClient()
  const { user } = useUser()
  const setCustomer = useGeneralStore((state) => state.setCustomer)
  const resetInitialization = useInitializationStore((state) => state.reset)
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const { role } = useRole()
  const { enabled } = useDemo()
  const { search } = useLocation()

  const reset = (newCustomer) => {
    resetInitialization(true)
    if (newCustomer) {
      setCustomer(newCustomer, user)
    }
    queryClient.removeQueries({ queryKey: ['sales-locations'] })
  }

  const customers = useGeneralStore((state) => state.allCustomers)
  const customer = useSelectedCustomer()

  // the customer select handles the switching of the customer in case of a shared report for another customer
  useMount(() => {
    const queryParams = queryString.parse(search)
    if (queryParams.share) {
      // decode base64 and parse JSON
      decompressFromBase64(queryParams.share)
        .then((base64) => {
          const context = JSON.parse(base64)

          // switch customer if necessary
          if (context.customer !== parseInt(customer) && [OPERATOR].includes(role)) {
            const selected = find(customers, { id: parseInt(context.customer) })
            if (!selected) {
              console.warn('Customer not found', context.customer)
              return
            }

            logDebugMessage(`Switching to customer ${selected.name} [${context.customer}]`)
            window.location.hash = `c_${context.customer}`
            reset(context.customer)
          }
        })
    }
  })

  if (![OPERATOR].includes(role) || enabled) {
    return null
  }

  return (
    <SearchSelect
      className={className}
      size='small'
      placeholder={<FormattedMessage id='CustomerSelect.title' defaultMessage='Select Customer' />}
      loading={!customers}
      value={customers && customers.length ? customer : undefined}
      onChange={(x) => {
        const c = parseInt(x)
        const selected = find(customers, { id: c })
        logDebugMessage(`Switching to customer ${selected.name} [${c}]`)
        if (c !== customer) {
          window.location.hash = `c_${c}`
          reset(c)
        }
      }}
      side='top'
      align='start'
      single
      options={customers && customers.map((item) => {
        return {
          key: item.id,
          value: item.id,
          label: item.name
        }
      })}
    />
  )
}

export default CustomerSelect
