import React from 'react'
import cn from 'classnames'
import s from './LanguageSwitcher.module.scss'
import { LOCALES } from 'constants/index'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { setLocale } from 'services/IntlService'
import { TextSelect } from 'components/TextSelect'
import { Icon } from 'components/Primitives'
import { setSetting } from 'services/SettingsProvider'

const locales = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  'en-US': 'English',
  'de-DE': 'Deutsch'
  /* @intl-code-template-end */
}

const LanguageSwitcher = ({ className }) => {
  const isInitialized = useSettingsStore((state) => state.isInitialized)
  const currentLocale = useGeneralStore((state) => state.locale)

  const onLocaleChanged = (locale) => {
    setLocale(locale)

    // if we are logged in and settings available, we save it in the global settings
    if (isInitialized) {
      setSetting({ locale }, true)
    }

    document.querySelector('html').setAttribute('lang', locale.substring(0, 2))
  }

  return (
    <div className={cn(s.wrapper, className)}>
      <Icon name='Wikis' color='gray' className={s.icon} />
      <TextSelect
        wrapperClassName={s.selectWrapper}
        options={LOCALES.map((locale) => ({ value: locale, label: locales[locale] }))}
        value={currentLocale}
        onChange={onLocaleChanged}
        isSearchable={false}
        allowClear={false}
        creatable={false}
      />
    </div>
  )
}

export default LanguageSwitcher
