import MobileFriendlyModal from 'components/MobileFriendlyModal'

export const ConfirmationDialog = ({ visible, title, size, innerChildren, children, onCancel, onConfirm, confirmButtonText, confirmLoading, noUndoHint, progress }) =>
  <MobileFriendlyModal
    visible={visible}
    title={title}
    onCancel={onCancel}
    onPrimary={onConfirm}
    footer={{
      primaryText: confirmButtonText,
      primaryLoading: confirmLoading
    }}
    confirm
    danger
    undoWarning={noUndoHint}
    text={children}
    size={size}
    progress={progress}
  >
    {innerChildren}
  </MobileFriendlyModal>
