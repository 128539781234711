import React from 'react'
import { SearchSelect } from 'components/SearchSelect'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import { useLocations, useOfferingGroups } from 'hooks'
import labelMessages from 'components/labelMessages'

export default ({ flat, medium, groupNumber, placeholder, className, asNames, value, onChange, range, location, gray, type, withPortal, disabled }) => {
  const intl = useIntl()
  const { demo } = useDemo()

  const { data: allLocations } = useLocations()

  const { data, status, isFetching, fetchStatus, isPending } = useOfferingGroups({
    offeringGroupNo: groupNumber,
    locations: allLocations != null && Array.isArray(location) ? !(!location.length || location.length === allLocations.length) ? location : null : [location],
    range: range,
    enabled: allLocations != null
  })

  if ((isPending && fetchStatus === 'idle') || (status === 'success' && data && data.length === 0)) {
    return null
  }

  if (data && data.length === 1) {
    return null
  }

  return (
    <SearchSelect
      role={`offering-group-${groupNumber}-picker`}
      className={className}
      type={type || (flat ? undefined : 'round')}
      size={medium ? undefined : 'small'}
      placeholder={placeholder || intl.formatMessage(labelMessages.offeringGroup, { no: groupNumber })}
      value={value}
      onChange={onChange}
      loading={isFetching}
      optionsLabel={intl.formatMessage(labelMessages.offeringGroup, { no: groupNumber })}
      options={data && data.map((item) => ({
        key: asNames ? item.name : item.id,
        label: item.name === 'null' ? '(empty)' : demo(item.name || '(empty)')
      }))}
      graybg={gray}
      withPortal={withPortal}
      disabled={disabled}
    />
  )
}
