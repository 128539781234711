import * as yup from 'yup'

export const totalQuantileSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    daily_quantile: yup.number().required().min(0).max(100)
  })

// FIXME: Define which values are required
export const initialTodosSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    scheduled_due_by: yup.string().nullable(),
    scheduled_last_until: yup.string().nullable(),
    config: yup
      .mixed()
      .test(
        'at-least-one-field-set',
        'at-least-one-field-set',
        function () {
          const { scheduled_due_by, scheduled_last_until } = this.parent
          return Boolean(scheduled_due_by || scheduled_last_until)
        }
      )
  })

// FIXME: Define which values are required
export const schedulingSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    lead_quantile: yup.number().nullable().min(0).max(100),
    lead_interval: yup.string().nullable(),
    sustain_quantile: yup.number().nullable().min(0).max(100),
    sustain_interval: yup.string().nullable(),
    config: yup
      .mixed()
      .test(
        'at-least-one-field-set',
        'at-least-one-field-set',
        function () {
          const { lead_quantile, lead_interval, sustain_quantile, sustain_interval } = this.parent
          return Boolean(lead_quantile || lead_interval || sustain_quantile || sustain_interval)
        }
      )
  })

export const inventoryGroupSchema = () =>
  yup.object().shape({
    rank: yup.number().required(),
    item: yup.number().nullable(),
    item_tag: yup.string().nullable(),
    location: yup.number().nullable(),
    inventory_group_enabled: yup.boolean()
  })
