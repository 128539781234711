import { useTimeout } from 'ahooks'
import { Button } from 'components/Button'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { SETTINGS_USER_ROUTE } from 'routes'
import message from 'services/message'

export const DashboardPreviewBanner = ({ onPurge }) => {
  const navigate = useNavigate()
  const loc = useLocation()
  const onSwitchToSettings = () => {
    navigate(`${SETTINGS_USER_ROUTE}${loc.hash}`)
  }

  const banner = (
    <div className='flex justify-between items-center'>
      <FormattedMessage id='DashboardPreview.eligible' defaultMessage='You are eligible to test the preview version of the new dashboard. Bugs may still occur or functions may be missing. You can return to the legacy dashboard at any time.' />
      <div className='flex flex-col space-y-2'>
        <Button className='underline inline-block ml-3' pure type='primary' onClick={onSwitchToSettings}><FormattedMessage id='DashboardPreview.activateInSettings' defaultMessage='Activate in User Settings' /></Button>
        <Button className='underline inline-block ml-3' pure type='primary' onClick={onPurge}><FormattedMessage id='DashboardPreview.doNotAskAgain' defaultMessage="Don't ask again" /></Button>
      </div>
    </div>
  )

  useTimeout(() => {
    message.info(banner, 10)
  }, 4000)

  return null
}
