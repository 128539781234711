import { useEffect } from 'react'
import { useGeneralStore } from './store/useGeneralStore'
import { useDebugStore } from './store/useDebugStore'

export const useUpdateHandler = () => {
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const appVersion = useGeneralStore((state) => state.version)

  useEffect(() => {
    if (!appVersion) {
      return
    }

    const lastVisitedVersion = window.localStorage.getItem('lastVisitedVersion')

    if (appVersion && !lastVisitedVersion) {
      window.localStorage.setItem('lastVisitedVersion', appVersion)
    } else if (appVersion !== lastVisitedVersion) {
      logDebugMessage(`App version changed from ${lastVisitedVersion} to ${appVersion}, trigger update`)
      console.info(`App version changed from ${lastVisitedVersion} to ${appVersion}, trigger update`)
      window.localStorage.setItem('lastVisitedVersion', appVersion)
      window.location.reload(true)
    }
  }, [appVersion])
}
