import { ReactComponent as HistoryIcon } from 'static/history.svg'
import { ReactComponent as AlarmIcon } from 'static/alarm.svg'
import s from './CellRenderer.module.scss'
import { FormattedMessage } from 'react-intl'
import { isPast, parseISO } from 'date-fns'
import classNames from 'classnames'
import { useModalStore } from 'hooks/store/useModalStore'

export const MeasurementRenderer = (props) => {
  const { data, valueFormatted } = props
  const show = useModalStore(state => state.show)

  const isDelayed = data.open && isPast(parseISO(data.date))

  return data.open
    ? (
      <div
        className={classNames(
          s.enterBtn,
          isDelayed && s.delayed
        )}
      >
        {isDelayed && <AlarmIcon className={s.alarmIcon} />}
        <FormattedMessage tagName='span' id='foodwaste.enterMeasurement' defaultMessage='Enter measurement' />
      </div>
      )
    : (
      <div className={s.flexBetweenCell}>
        <button className={s.hoverBtn} onMouseDown={() => show('measurementHistory', props.data)}>
          <HistoryIcon />
        </button>
        <span>{valueFormatted}</span>
      </div>
      )
}
