import { useEffect, useState, useRef, useCallback } from 'react'
import debounce from 'lodash/debounce'

export function useDebouncedCallback(callback, delay = 0, options) {
  return useCallback(debounce(callback, delay, options), [
    callback,
    delay,
    options,
  ])
}

export function useDebounce(value, delay = 0, options) {
  const previousValue = useRef(value)
  const [current, setCurrent] = useState(value)
  const debouncedCallback = useDebouncedCallback(
    (value) => setCurrent(value),
    delay,
    options
  )
  useEffect(() => {
    // does trigger the debounce timer initially
    if (value !== previousValue.current) {
      debouncedCallback(value)
      previousValue.current = value
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel
    }
  }, [value])

  return current
}
