import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Icon, Text } from 'components/Primitives'
import classNames from 'classnames'
import { Button } from 'components/Button'

// sizes = ['xs', 's', 'm', 'l']

const getIconSize = (size) => {
  switch (size) {
    case 'xs':
      return 'l'
    case 's':
      return 'xxl'
    case 'm':
      return 'xxxl'
    case 'l':
    default:
      return 'unset'
  }
}

const getTextSize = (size) => {
  switch (size) {
    case 'xs':
      return 'xs'
    case 's':
    case 'm':
    case 'l':
    default:
      return undefined
  }
}

export const NoData = ({ size, message, className, icon = 'NoData', onClick, buttonMessage }) =>
  <div data-role='no-data-indicator' className={classNames('text-center', className)}>
    <Icon name={icon} size={getIconSize(size)} className='text-gray-light' />
    <Text tagName='div' color='gray' size={getTextSize(size)}>
      {message || <FormattedMessage id='noData.noData' defaultMessage='No Data' />}
    </Text>
    {onClick && <Button onClick={onClick} size='s' type='tertiary' fullWidth>{buttonMessage}</Button>}
  </div>
