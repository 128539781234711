import { defineMessages } from 'react-intl'

export default defineMessages({
  locationTitleSingle: {
    id: 'pickers.locationTitleSingle',
    defaultMessage: 'Select a location'
  },
  locationTitleMulti: {
    id: 'pickers.locationTitleMulti',
    defaultMessage: 'Select one or more locations'
  },
  booleanPicker: {
    id: 'pickers.booleanPicker',
    defaultMessage: 'Select an option'
  },
  accessRightsTitleSingle: {
    id: 'pickers.accessRightsTitleSingle',
    defaultMessage: 'Select an access right'
  },
  accessRightsTitleMulti: {
    id: 'pickers.accessRightsTitleMulti',
    defaultMessage: 'Select one or more access rights'
  },
  addAdditionalCategory: {
    id: 'pickers.addAdditionalCategory',
    defaultMessage: 'Add'
  },
  addRow: {
    id: 'pickers.addRow',
    defaultMessage: 'Add monitoring for additional location'
  },
  matchBy: {
    id: 'pickers.matchBy',
    defaultMessage: 'Match by'
  },
  matchingWeekdays: {
    id: 'label.matchingWeekdays',
    defaultMessage: 'Matching Weekdays'
  },
  isoWeek: {
    id: 'label.isoWeek',
    defaultMessage: 'Iso week number'
  },
  month: {
    id: 'label.month',
    defaultMessage: 'Month'
  },
  quarter: {
    id: 'label.quarter',
    defaultMessage: 'Quarter'
  },
  year: {
    id: 'label.year',
    defaultMessage: 'Year'
  },
  floatingStartDate: {
    id: 'label.floatingStartDate',
    defaultMessage: 'Floating Start Date'
  },
  specificStartDate: {
    id: 'label.specificStartDate',
    defaultMessage: 'Specific Start Date'
  },
  previousWeek: {
    id: 'label.previousWeek',
    defaultMessage: 'Previous Week'
  },
  previousMonth: {
    id: 'label.previousMonth',
    defaultMessage: 'Previous Month'
  },
  previousQuarter: {
    id: 'label.previousQuarter',
    defaultMessage: 'Previous Quarter'
  },
  previousYear: {
    id: 'label.previousYear',
    defaultMessage: 'Previous Year'
  },
  previousNDays: {
    id: 'label.previousNDays',
    defaultMessage: '{days, plural, one {Previous Day} other {Previous {days} Days}}'
  }
})
