import { FOODWASTE_COLUMN_TITLES } from 'constants/index'
import { useTitle } from 'ahooks'

import Actions from './Actions'
import DetailsModal from './DetailsModal'
import { omit, pick } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePageTrack, useFoodWasteModeProperties, usePageSettings } from 'hooks'
import { useWasteMeasurements, useOwnFoodwasteConfigs } from 'hooks/queries'
import titleMessages from 'components/titleMessages'
import { Grid } from 'components/Grid'
import { useMemo } from 'react'
import { useColStateOrdering } from 'components/Grid/hooks/useColStateOrdering'
import { useModalStore } from 'hooks/store'
import { useIntercom } from 'react-use-intercom'
import { useCurrentColState } from 'components/Grid/hooks/useCurrentColState'
import message from 'services/message'
import { useQueryClient } from '@tanstack/react-query'
import { invalidateFoodwasteQueries } from './utils'
import { FilterParamsProvider } from 'services/FilterParamsProvider'

export const INITIAL_FOODWASTE_COLUMNS_ORDER = [
  'location',
  'date',
  'category',
  'amount',
  'open',
  'num_sold',
  'num_transactions',
  'sales_cogs',
  'grams_per_num_sold',
  'grams_per_num_transactions',
  'fraction_of_cogs',
  'cogs_cost',
  'disposal_cost',
  'water',
  'co2',
  'comment',
  'kg_saved',
  'dishes_saved',
  'kg_co2_saved',
  'cogs_saved',
  'l_water_saved'
]

const COLUMN_DEFINITIONS = {
  location: { field: 'sales_location_name', type: Grid.TYPE.TEXTWITHTOOLTIP, lockVisible: true },
  category: { field: 'category_name', type: Grid.TYPE.TAGS, defaultType: 'fbInfo', lockVisible: true },
  date: { field: 'date', type: Grid.TYPE.DATEWEATHER, fromRecord: true, lockVisible: true },
  amount: { field: 'amount', type: Grid.TYPE.MEASUREMENT },
  num_sold: { field: 'num_sold', type: Grid.TYPE.INTEGER, editable: true },
  num_transactions: { field: 'num_transactions', type: Grid.TYPE.INTEGER },
  sales_cogs: { field: 'sales_cogs', type: Grid.TYPE.CURRENCY },
  grams_per_num_sold: { field: 'grams_per_num_sold', type: Grid.TYPE.GRAM },
  grams_per_num_transactions: { field: 'grams_per_num_transactions', type: Grid.TYPE.GRAM },
  fraction_of_cogs: { field: 'fraction_of_cogs', type: Grid.TYPE.PERCENT },
  comment: { field: 'comment', type: Grid.TYPE.TEXT, editable: true },
  open: { field: 'open', type: Grid.TYPE.BOOLEAN, variant: 'opendone', invert: true },
  cogs_cost: { field: 'cogs_cost', type: Grid.TYPE.CURRENCY, hide: true },
  disposal_cost: { field: 'disposal_cost', type: Grid.TYPE.CURRENCY, hide: true },
  water: { field: 'water', type: Grid.TYPE.LIQUID, hide: true },
  co2: { field: 'co2', type: Grid.TYPE.GRAM, hide: true },
  kg_saved: { field: 'kg_saved', type: Grid.TYPE.KG, hide: true },
  dishes_saved: { field: 'dishes_saved', type: Grid.TYPE.INTEGER, hide: true },
  kg_co2_saved: { field: 'kg_co2_saved', type: Grid.TYPE.KG, hide: true },
  cogs_saved: { field: 'cogs_saved', type: Grid.TYPE.CURRENCY, hide: true },
  l_water_saved: { field: 'l_water_saved', type: Grid.TYPE.LIQUID, hide: true }
}

const SORT_KEYS = {
  category_name: 'category__name',
  sales_location_name: 'sales_location__name'
}

const isRowSelectable = (row) => row.data.amount == null

const Foodwaste = () => {
  usePageTrack()
  const { isEditable, status, isEnabled } = useOwnFoodwasteConfigs()
  const intl = useIntl()
  useTitle(intl.formatMessage(titleMessages.foodwaste))
  const { settings, isInitialized } = usePageSettings()
  const measurementHistory = useModalStore(state => state.modals.measurementHistory)
  const { trackEvent } = useIntercom()
  const queryClient = useQueryClient()
  const { invalidFoodwasteCols } = useFoodWasteModeProperties()

  const currentColState = useCurrentColState(settings)
  const ordering = useColStateOrdering(currentColState, [{ key: 'date', desc: false }, { key: 'sales_location__name', desc: false }, { key: 'category__name', desc: false }, { key: 'created_at', desc: true }], SORT_KEYS)

  const requiredCols = ['id', 'category_id', 'sales_location_id', 'created_at', 'user_email', 'user_last_name', 'user_first_name', ...Object.values(COLUMN_DEFINITIONS).filter(col => !col.hide).map(col => col.field)]
  const wasteColumns = useMemo(() => {
    return [
      ...requiredCols,
      ...(currentColState || []).filter(col =>
        !col.hide &&
        col.colId !== 'actions' &&
        !requiredCols.includes(col.colId)
      ).map(col => col.colId)].join(',')
  }, [currentColState])

  const { data, isFetching, count, updateItem, add, average, remove, replaceId } = useWasteMeasurements({
    ordering,
    fields: wasteColumns
  })

  // based on the customer settings we have to filter the column set to include one or another column.
  const foodwasteColumns = useMemo(() => INITIAL_FOODWASTE_COLUMNS_ORDER.filter(col => !invalidFoodwasteCols.includes(col)), [invalidFoodwasteCols])

  const getColumn = (column) => {
    return {
      ...COLUMN_DEFINITIONS[column],
      headerName: FOODWASTE_COLUMN_TITLES[column]
        ? intl.formatMessage(FOODWASTE_COLUMN_TITLES[column])
        : column,
      ...(column === 'amount'
        ? {
            editable: (params) => isEditable(params.data),
            cellClass: (params) => isEditable(params.data) ? 'editable' : null
          }
        : undefined)
    }
  }

  // we cannot use the id as row id, since we are actually creating new items when eddin the measurement value.
  // but since don't have duplicated entries in this view, we can use the date and category id as row id
  const getRowId = params => `${params.data.date}_${params.data.category_id}_${params.data.sales_location_id}`
  const equalFn = (a, b) => a.date === b.date && a.category_id === b.category_id && a.sales_location_id === b.sales_location_id

  // TODO: We probably want to flash cells from here, because the default handler won't be called.
  const replaceMeasurement = (node, data, newData) => {
    const replacementData = {
      ...data,
      open: newData.amount == null,
      ...pick(newData, [
        'id',
        'amount',
        'comment',
        'user_first_name',
        'user_email',
        'user_last_name',
        'num_sold',
        'grams_per_num_sold',
        'sales_cogs',
        'fraction_of_cogs',
        'kg_saved',
        'dishes_saved',
        'kg_co2_saved',
        'cogs_saved',
        'l_water_saved'
      ])
    }
    node.setData(replacementData)
    replaceId(data.id, newData.id)
    invalidateFoodwasteQueries(queryClient)
  }

  // useMemo is super important here, since we extend the column definitions with intl info, but if we ever
  // recreate the columns object, it will result in the sidebars being regenerated and closed.
  const columns = useMemo(() => {
    return [
      ...foodwasteColumns.map((column) => getColumn(column)),
      {
        type: Grid.TYPE.BUTTONS,
        buttons: [
          {
            key: 'measurementActions',
            icon: 'OverflowMenuVertical',
            hidden: (params) => params.open === false || !isEditable(params),
            menuSections: [
              {
                key: 'delete-measurement',
                title: <FormattedMessage id='foodwaste.closedReason' defaultMessage='Were you closed?' />,
                menuItems: [
                  {
                    key: 'safety',
                    label: <FormattedMessage id='foodwaste.deleteMeasurement' defaultMessage='Delete the measurement' />,
                    icon: 'TrashCan',
                    onClick: ({ data }) => {
                      remove.mutate(data.id).then(() => invalidateFoodwasteQueries(queryClient))
                      trackEvent('delete_food_waste_measurement', { id: data.id })
                    }
                  }
                ]
              },
              {
                key: 'use-average',
                title: <FormattedMessage id='foodwaste.forgotReason' defaultMessage='Did you forget to measure?' />,
                menuItems: [
                  {
                    key: 'fields',
                    label: <FormattedMessage id='foodwaste.useAverageValue' defaultMessage='Use average value' />,
                    icon: 'Calculator',
                    onClick: ({ node, data }) => {
                      average.fetch(data.id).then(avgValue => {
                        if (avgValue == null) {
                          message.error(intl.formatMessage({ id: 'foodwaste.averageValueError', defaultMessage: 'Could not calculate average value.' }))
                          return
                        }
                        node.setData({ ...data, open: false, amount: avgValue }) // update the row data with the given value, so it looks updated
                        add.mutate({
                          id: data.id,
                          amount: avgValue,
                          date: data.date,
                          sales_location: data.sales_location_id,
                          category: data.category_id,
                          comment: data.comment
                        })
                          .then((resp) => replaceMeasurement(node, data, resp[0])) // update the row data with the response from the server
                          .catch((e) => node.setData({ ...data, amount: null })) // reset the row data to the old value in
                      })
                      trackEvent('average_food_waste_measurement', { id: data.id })
                    }
                  }
                ]
              }
            ]
          }
        ],
        ...Grid.ACTION_COLUMN_PROPS
      }
    ]
  }, [INITIAL_FOODWASTE_COLUMNS_ORDER, intl, isEditable])

  if (!isInitialized || (isEnabled && status !== 'success')) return null

  // We don't use this for now, since it's fine if the data looks updated and we reset it in error case.
  const onCellEditRequest = ({ colDef, oldValue, value, data, api, node, source }) => {
    switch (colDef.field) {
      case 'comment':
        node.setData({ ...data, [colDef.field]: value }) // update the row data with the given value, so it looks updated
        updateItem.mutate({
          id: data.id,
          comment: value
        })
          .then((resp) => node.setData({ ...data, ...omit(resp, ['category', 'sales_location', 'user']) })) // update the row data with the response from the server
          .catch((e) => node.setData({ ...data, [colDef.field]: oldValue })) // reset the row data to the old value in case of an error
        break
      case 'num_sold':
        node.setData({ ...data, [colDef.field]: value }) // update the row data with the given value, so it looks updated
        updateItem.mutate({
          id: data.id,
          num_sold: value
        })
          .then((resp) => node.setData({ ...data, ...omit(resp, ['category', 'sales_location', 'user']) })) // update the row data with the response from the server
          .catch((e) => node.setData({ ...data, [colDef.field]: oldValue })) // reset the row data to the old value in
        break
      case 'amount':
        node.setData({ ...data, open: false, [colDef.field]: value }) // update the row data with the given value, so it looks updated
        add.mutate({
          id: data.id,
          amount: value,
          date: data.date,
          sales_location: data.sales_location_id,
          category: data.category_id,
          comment: data.comment
        })
          .then((resp) => replaceMeasurement(node, data, resp[0])) // update the row data with the response from the server
          .catch((e) => node.setData({ ...data, [colDef.field]: oldValue })) // reset the row data to the old value in
        break
      default:
        break
    }
  }

  const performingRequest = isFetching || updateItem.isPending || add.isPending || average.isPending || remove.isPending

  return (
    <FilterParamsProvider>
      <div className='flex flex-col flex-grow'>
        <Actions columns={wasteColumns} />
        <Grid
          data={data}
          count={count}
          columns={columns}
          onCellEditRequest={onCellEditRequest}
          exportType='foodwaste'
          getRowId={getRowId}
          equalFn={equalFn}
          performingRequest={performingRequest}
          selectionKey='foodwaste'
          isRowSelectable={isRowSelectable}
        />
        {measurementHistory && <DetailsModal columns={wasteColumns} />}
      </div>
    </FilterParamsProvider>
  )
}

export default Foodwaste
