import { defineMessages } from 'react-intl'

export default defineMessages({
  csv: {
    id: 'exportBtn.csv',
    defaultMessage: 'CSV'
  },
  excel: {
    id: 'exportBtn.excel',
    defaultMessage: 'Excel'
  },
  selectLocations: {
    id: 'exportBtn.selectLocations',
    defaultMessage: 'Select for which locations you want to export the {type}.'
  }
})
