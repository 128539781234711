import { useLocation } from 'react-router-dom'

import Heading from 'components/Heading'
import React from 'react'
import cn from 'classnames'
import s from './BottomMenu.module.scss'
import { Link } from 'components/Link'
import { DASHBOARD_ROUTE, ROOT_ROUTE } from 'routes'
import { Icon } from 'components/Primitives'

const MenuButton = ({ id, route, className, icon, children }) => {
  const { pathname: currentRoute } = useLocation()
  const checkRoute = currentRoute === ROOT_ROUTE ? DASHBOARD_ROUTE : currentRoute
  const isActive = route === checkRoute

  return (
    <Link
      id={id}
      to={route}
      className={cn(
        s.item,
        isActive && s.active,
        className
      )}
    >
      <Icon name={icon} className={s.icon} />
      <Heading className={`${s.name} mobile:hidden`} type='h6' color={isActive ? 'blue' : 'disabled-10'}>
        {children}
      </Heading>
    </Link>
  )
}

export default MenuButton
