import classNames from 'classnames'
import { Badge } from 'components/Primitives'
import { useIntl } from 'react-intl'

const getOrphanMessage = (intl, type) => {
  switch (type) {
    default:
      return intl.formatMessage({ id: 'TagBadge.orphaned', defaultMessage: 'This tag is not assigned to any entity.' })
    case 'item':
      return intl.formatMessage({ id: 'TagBadge.orphanedItem', defaultMessage: 'This tag is not assigned to any item.' })
    case 'saleslocation':
      return intl.formatMessage({ id: 'TagBadge.orphanedLocation', defaultMessage: 'This tag is not assigned to any location.' })
  }
}

export const TagBadgeNew = ({ className, children, hex, references, assignType }) => {
  const intl = useIntl()

  const props = references == null || references > 0
    ? undefined
    : {
        icon: 'WarningAltFilled',
        iconPosition: 'right',
        tooltip: getOrphanMessage(intl, assignType)
      }
  return <Badge className={classNames('inline-flex', className)} size='s' hex={hex} {...props}>{children}</Badge>
}
