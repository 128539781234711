import { useDebounceEffect } from 'ahooks'
import { usePermissions, useUser } from 'hooks'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import message from 'services/message'
import { useAppContext } from './context'
import { BASE_PRESET, FORECAST_ONLY_PRESET, FORECAST_TODO_PRESET, FORECAST_WASTE_PRESET } from 'routes/dashboard/constants'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { useCustomerType } from 'hooks/useCustomerType'
import { DASHBOARD_DEFAULT_SETTINGS, globalSettingsDefault } from 'constants/defaultSettings'
import { LOCALES, OPERATOR } from 'constants/index'
import { validSettings } from 'utils/settingsValidator'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { setLocale } from './IntlService'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { useLocalMode } from 'hooks/useLocalMode'

export const getDefaultDashboard = (user, permissions) => {
  if (permissions.offering && permissions.foodwaste && permissions.todo) {
    console.warn('Have user with offering, foodwaste and todo permission, but no dashboard layout preset defined!')
  }

  const dashboard = [...BASE_PRESET]
  if (!permissions.foodwaste && !permissions.todo) {
    dashboard.push(...FORECAST_ONLY_PRESET)
  } else if (permissions.foodwaste && !permissions.todo) {
    dashboard.push(...FORECAST_WASTE_PRESET)
  } else if (!permissions.foodwaste && permissions.todo) {
    dashboard.push(...FORECAST_TODO_PRESET)
  } else {
    dashboard.push(...FORECAST_ONLY_PRESET)
  }

  return {
    dashboard,
    dashboardSettings: DASHBOARD_DEFAULT_SETTINGS
  }
}

export const setSetting = (partialSetting, global) => {
  const state = useSettingsStore.getState()
  if (global) {
    const newSettings = {
      ...state.globalSettings,
      ...partialSetting
    }

    if (isEqual(state.globalSettings, newSettings)) return
    useSettingsStore.setState({ globalSettings: newSettings })
  } else {
    const customerType = useGeneralStore.getState().customerType
    const permissions = useGeneralStore.getState().permissions
    const newSettings = validSettings({
      ...state.settings,
      ...partialSetting
    }, permissions, customerType)

    if (isEqual(state.settings, newSettings)) return
    useSettingsStore.setState({ settings: newSettings })
  }
}

export const SettingsProvider = (props) => {
  const intl = useIntl()
  const permissions = usePermissions()
  const { user } = useUser()
  const localMode = useLocalMode()

  const { fetch } = useAppContext()

  const [settingsKey, setSettingsKey] = useState(null)
  const currentSettings = useSettingsStore((state) => state.settings)
  const globalSettings = useSettingsStore((state) => state.globalSettings)
  const setCurrentSettingsInStore = useSettingsStore((state) => state.setCurrentSettings)
  const setGlobalSettingsInStore = useSettingsStore((state) => state.setGlobalSettings)
  const isInitialized = useSettingsStore((state) => state.isInitialized)
  const setIsInitialized = useSettingsStore((state) => state.setIsInitialized)

  const selectedCustomer = useSelectedCustomer()
  const customerType = useCustomerType()

  const customer = useMemo(() => {
    if (!user) {
      return null
    }
    if (!user.id) {
      throw new Error('Error on useMemo customer in SettingsProvider. Incomplete user object.')
    }
    if (user.role === OPERATOR) {
      return selectedCustomer
    } else {
      return parseInt(user.customer_id)
    }
  }, [user, selectedCustomer])

  const setCurrentSettings = (settings) => {
    setCurrentSettingsInStore(validSettings(settings, permissions, customerType), false)
  }

  useEffect(() => {
    if (user && !customer) {
      console.warn('SettingsProvider disabled since customer is not set.')
      return
    }
    if (!isInitialized && user && customer && permissions.isInitialized) {
      let settingsKey = 'settings'
      if (localMode) {
        console.info('Smoke Test Account detected. Settings are defaulted and not synced with backend.')
        setLocale('en-US') // Smoke Tests run always in English
        setCurrentSettings(getDefaultDashboard(user, permissions))
        setGlobalSettingsInStore({
          ...globalSettingsDefault,
          ...(user && user.customer_name.includes('2.0')
            ? {
                dashboardPreview: true,
                dashboardPreviewDontAsk: true
              }
            : {})
        })
      } else {
        if (!user.settings && !user.state) {
          console.info('Seeding default settings for new user with given customer...')
        }
        if (user.state) {
          settingsKey = 'state'
          setSettingsKey('state')
        } else {
          settingsKey = 'settings'
          setSettingsKey('settings')
        }
        setCurrentSettings(user[settingsKey] && user[settingsKey][customer] && Object.keys(user[settingsKey][customer]).length > 0
          ? {
              ...getDefaultDashboard(user, permissions),
              ...user[settingsKey][customer]
            }
          : getDefaultDashboard(user, permissions))
        if (user[settingsKey]) {
          if (user[settingsKey].global && user[settingsKey].global.locale && user[settingsKey].global.locale !== intl.locale && LOCALES.includes(user[settingsKey].global.locale)) {
            // when we have a (supported) locale in the user setting, we override the locale from the browser cookie
            setLocale(user[settingsKey].global.locale)
          }
          setGlobalSettingsInStore(user[settingsKey].global || globalSettingsDefault)
        }
      }
      setIsInitialized(true)
    }
  }, [user, customer, permissions, isInitialized])

  const { mutate } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch('/users/me/', {
        method: 'PATCH',
        body: {
          [settingsKey]: data
        },
        success: () => {
          // // Update the user settings in query cache for components using useUser hook
          // // Don't do this, if we don't have anything in cache
          // const meData = queryClient.getQueryData(['me'])
          // if (meData) {
          //   queryClient.setQueryData(['me'], {
          //     ...meData,
          //     settings: data
          //   })
          // }
          resolve()
        },
        failure: (e) => {
          message.error(
            intl.formatMessage({
              id: 'notify.mutateSettingsError',
              defaultMessage: 'Error updating user settings: {message}'
            }, { message: e.error }))
          reject(e)
        }
      })
    })
  })

  useDebounceEffect(() => {
    if (!user || localMode || !permissions.isInitialized) {
      return
    }
    const allSettings = { ...user[settingsKey], [customer]: currentSettings, global: globalSettings }

    if (!isEqual(allSettings, user[settingsKey])) {
      // console.group('Mutate settings')
      // console.log('Old Set', user[settingsKey])
      // console.log('New Set', allSettings)
      // console.groupEnd()

      mutate(allSettings)
    }
  }, [currentSettings, globalSettings, user, localMode, permissions], { wait: 500 })

  return null
}
