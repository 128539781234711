import { useEffect, useMemo, useRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { omit, isNaN } from 'lodash'
import { Input } from 'components/Primitives'
import { useTimeout } from 'ahooks'
import { divide100, multiply100 } from 'utils'

/* This method returns the value to be used when editing starts. */
/* Enter keeps the value, but selects the content, backspace empties the value, any number replaces the value. */
const getInitialValueOnEditStart = (eventKey, value) => {
  // check if eventKey is a number or can be converted to a number
  const parsed = parseInt(eventKey)
  if (!isNaN(parsed)) {
    return {
      value: parsed,
      selectAll: false
    }
  }

  switch (eventKey) {
    case 'Enter':
      return {
        value,
        selectAll: true
      }
    case 'Backspace':
      return {
        value: '',
        selectAll: false
      }
    default:
      return {
        value,
        selectAll: true
      }
  }
}

export default ({ value, onValueChange, eventKey, data, colDef, format, suffix }) => {
  const init = useMemo(() => getInitialValueOnEditStart(eventKey, colDef.colType === 'percent' && value != null ? multiply100(value) : value), [])
  const [innerValue, setInnerValue] = useState(init.value)
  const inputRef = useRef(null)

  useTimeout(() => {
    if (init.selectAll) {
      inputRef.current.select()
    }
  }, 50)

  useEffect(() => {
    onValueChange(colDef.colType === 'percent' && innerValue != null ? divide100(innerValue) : innerValue)
  }, [innerValue])

  const renderSuffix = (suffix) => typeof suffix === 'function' ? suffix(data) : suffix

  return (
    <NumericFormat
      getInputRef={inputRef}
      customInput={Input}
      isManaged
      size='small'
      className='border-none rounded-none py-0 h-full'
      value={innerValue}
      autoFocus
      onValueChange={({ floatValue = null }) => setInnerValue(floatValue)}
      inputMode='decimal'
      decimalSeparator=','
      wrapperClassName='h-full'
      inputSuffix={suffix ? renderSuffix(suffix) : undefined}
      decimalScale={colDef.colType === 'integer' ? 0 : undefined}
      {...omit(format, ['displayType', 'suffix', 'decimalScale'])}
    />
  )
}
