import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { findIndex } from 'lodash'
import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { usePermissions, useQueryFetcher } from 'hooks'
import { getFormErrors } from 'utils'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { useLocations } from './useLocations'

export const useFoodwasteCategories = (options = {}) => {
  const opts = {
    enabled: true,
    forceEnable: false,
    ...options
  }
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { fetch, token } = useQueryFetcher()
  const permissions = usePermissions()
  const customer = useSelectedCustomer()

  const { data: locationsCollection } = useLocations()

  const filters = {
    ...(opts.locations && locationsCollection && Array.isArray(opts.locations) && opts.locations.length !== locationsCollection.length ? { sales_locations: opts.locations.join(',') } : undefined),
    ...(opts.locations && locationsCollection && !Array.isArray(opts.locations) ? { sales_locations: opts.locations } : undefined),
    ...(opts.range ? { date_range: opts.range.join(',') } : undefined)
  }
  const queryKey = ['foodwasteCategories', { customer, filters }]

  const { data, status, error, isFetching, isLoading, fetchStatus, refetch } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/categories/list/?${queryString.stringify({
          customer,
          page_size: 999
        })}`,
        {
          method: 'POST',
          body: {
            query_params: {
              ...filters
            }
          },
          token,
          success: (res) => resolve(res.results),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: customer != null && opts.enabled && (opts.forceEnable ? true : permissions.foodwaste),
    retry: false,
    staleTime: 120000
  })

  const { mutateAsync } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/categories/${data.id}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'PATCH',
          token,
          body: data,
          success: (category) => resolve(category),
          failure: (err) => reject(err)
        }
      ).then(() => refetch())
    }),
    // We use optimistic updating to simulate the new data before we have it
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey })
      const previousData = queryClient.getQueryData(queryKey)
      const newData = [...previousData]

      const index = findIndex(newData, { id: data.id })
      if (index !== -1) {
        newData[index] = {
          ...newData[index],
          ...data
        }
      }
      queryClient.setQueryData(queryKey, newData)
      return { previousData }
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(queryKey, context.previousData)
      const errors = getFormErrors({
        values: [],
        errors: err
      })

      let errorMessage = errors.error
      if (errorMessage && errorMessage.includes('unique set')) {
        errorMessage = intl.formatMessage({ id: 'errors.categoryNameUsed', defaultMessage: 'The category name cannot be changed to a name that already exists.' })
      }

      message.error(intl.formatMessage(notifyMessages.mutateError, { message: errorMessage }), 5)
    },
    onSettled: () => refetch()
  }
  )

  useEffect(() => {
    if (error) {
      message.error(intl.formatMessage(notifyMessages.fetchError, { type: 'foodwaste-categories', message: error.detail }), 15)
    }
  }, [error])

  return {
    data,
    status,
    isLoading,
    error,
    isFetching,
    refetch,
    fetchStatus,
    mutateAsync
  }
}
