import { useItemEntities } from 'hooks/queries/useItemEntities'
import { find } from 'lodash'
import { useMemo } from 'react'
import { isEmpty } from 'utils'

export const Article = ({ id, name, remotePk, showRemotePK }) => {
  const { data, isLoading } = useItemEntities({ ids: id ? [id] : [], enabled: id != null })

  const foundItem = useMemo(() => {
    if (name) {
      return {
        name,
        remote_pk: remotePk
      }
    }
    if (!data) return null
    return find(data, { id })
  }, [data, id, name, remotePk])

  if (id != null && isLoading) {
    return '...'
  }

  if (!name && !data) {
    return null
  }

  return foundItem
    ? showRemotePK && !isEmpty(foundItem.remote_pk) ? `${foundItem.name} (${foundItem.remote_pk})` : foundItem.name
    : null
}
