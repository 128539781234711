import {
  AVG_TRANSACTION_REVENUE_CHART,
  COMPOSITION_CHART,
  DETAIL_VIEW_CHART,
  DIFFERENCE_FROM_CHART,
  FOODWASTE_CHART,
  FOODWASTE_CO2_CHART,
  FOODWASTE_COMPOSITION_CHART,
  FOODWASTE_DETAIL_VIEW_CHART,
  FOODWASTE_LOCATIONS_COMPARISON_CHART,
  FOODWASTE_SALES_COSTS_CHART,
  FOODWASTE_WATER_CHART,
  FORECAST_ACCURACY_CHART,
  FORECAST_CHART,
  LOCATIONS_COMPARISON_CHART,
  NUM_SOLD_CHART,
  REL_COGS_CHART,
  REVENUE_CHART,
  TODO_ITEM_COMPARISON_CHART,
  TODO_LOCATION_COMPARISON_CHART,
  ARTICLE_COMPARISON_CHART,
  TOTAL_COGS_CHART,
  TRANSACTIONS_CHART,
  ORDERED_AMOUNTS_CHART,
  REQUESTED_AMOUNTS_CHART,
  FULFILLED_AMOUNTS_CHART,
  UNSOLD_AMOUNTS_CHART,
  NUM_VANISHED_CHART,
  ORDERS_OVERVIEW_CHART,
  FOODWASTE_AMOUNT_PER_SALE,
  RETURN_RATE_CHART
} from 'components/Charts/constants'
import labelMessages from 'components/labelMessages'
import titleMessages from 'components/titleMessages'

import { TODO_DONE_TASKS_CHART } from '../../components/Charts/constants'

export const FORECAST_CHARTS = [
  REVENUE_CHART,
  REL_COGS_CHART,
  TOTAL_COGS_CHART,
  NUM_SOLD_CHART,
  LOCATIONS_COMPARISON_CHART,
  COMPOSITION_CHART,
  DETAIL_VIEW_CHART,
  DIFFERENCE_FROM_CHART,
  FORECAST_CHART,
  FORECAST_ACCURACY_CHART,
  ARTICLE_COMPARISON_CHART,
  TRANSACTIONS_CHART,
  AVG_TRANSACTION_REVENUE_CHART
]

export const ORDER_CHARTS = [
  ORDERS_OVERVIEW_CHART,
  ORDERED_AMOUNTS_CHART,
  REQUESTED_AMOUNTS_CHART,
  FULFILLED_AMOUNTS_CHART,
  UNSOLD_AMOUNTS_CHART,
  RETURN_RATE_CHART,
  NUM_VANISHED_CHART
]

export const FOODWASTE_CHARTS = [
  FOODWASTE_CHART,
  FOODWASTE_COMPOSITION_CHART,
  FOODWASTE_DETAIL_VIEW_CHART,
  FOODWASTE_AMOUNT_PER_SALE,
  FOODWASTE_LOCATIONS_COMPARISON_CHART,
  FOODWASTE_SALES_COSTS_CHART,
  FOODWASTE_CO2_CHART,
  FOODWASTE_WATER_CHART
]

export const TODO_CHARTS = [
  TODO_LOCATION_COMPARISON_CHART,
  TODO_ITEM_COMPARISON_CHART,
  TODO_DONE_TASKS_CHART
]

const small = { w: 1, h: 1, minW: 1, minH: 1 }
const medium = { w: 2, h: 2, minW: 2, minH: 2 }

// Defaults for newly added charts
export const CHART_DEFAULTS = {
  [REVENUE_CHART]: { ...small, i: REVENUE_CHART },
  [REL_COGS_CHART]: { ...small, i: REL_COGS_CHART },
  [TOTAL_COGS_CHART]: { ...small, i: TOTAL_COGS_CHART },
  [NUM_SOLD_CHART]: { ...small, i: NUM_SOLD_CHART },
  [LOCATIONS_COMPARISON_CHART]: { ...medium, i: LOCATIONS_COMPARISON_CHART },
  [COMPOSITION_CHART]: { w: 2, h: 4, minW: 1, minH: 2, i: COMPOSITION_CHART },
  [DETAIL_VIEW_CHART]: { ...medium, i: DETAIL_VIEW_CHART },
  [DIFFERENCE_FROM_CHART]: { ...medium, i: DIFFERENCE_FROM_CHART },
  [FORECAST_CHART]: { ...medium, i: FORECAST_CHART },
  [FORECAST_ACCURACY_CHART]: { ...medium, i: FORECAST_ACCURACY_CHART },
  [ARTICLE_COMPARISON_CHART]: { ...medium, i: ARTICLE_COMPARISON_CHART },
  [TRANSACTIONS_CHART]: { ...small, i: TRANSACTIONS_CHART },
  [AVG_TRANSACTION_REVENUE_CHART]: { ...small, i: AVG_TRANSACTION_REVENUE_CHART },

  [ORDERS_OVERVIEW_CHART]: { ...medium, i: ORDERS_OVERVIEW_CHART },
  [ORDERED_AMOUNTS_CHART]: { ...small, i: ORDERED_AMOUNTS_CHART },
  [REQUESTED_AMOUNTS_CHART]: { ...small, i: REQUESTED_AMOUNTS_CHART },
  [FULFILLED_AMOUNTS_CHART]: { ...small, i: FULFILLED_AMOUNTS_CHART },
  [UNSOLD_AMOUNTS_CHART]: { ...small, i: UNSOLD_AMOUNTS_CHART },
  [NUM_VANISHED_CHART]: { ...small, i: NUM_VANISHED_CHART },
  [RETURN_RATE_CHART]: { ...small, i: RETURN_RATE_CHART },

  [FOODWASTE_CHART]: { ...small, i: FOODWASTE_CHART },
  [FOODWASTE_COMPOSITION_CHART]: { w: 2, h: 4, minW: 1, minH: 2, i: FOODWASTE_COMPOSITION_CHART },
  [FOODWASTE_DETAIL_VIEW_CHART]: { ...medium, i: FOODWASTE_DETAIL_VIEW_CHART },
  [FOODWASTE_AMOUNT_PER_SALE]: { ...small, i: FOODWASTE_AMOUNT_PER_SALE },
  [FOODWASTE_LOCATIONS_COMPARISON_CHART]: { ...medium, i: FOODWASTE_LOCATIONS_COMPARISON_CHART },
  [FOODWASTE_SALES_COSTS_CHART]: { ...small, i: FOODWASTE_SALES_COSTS_CHART },
  [FOODWASTE_CO2_CHART]: { ...small, i: FOODWASTE_CO2_CHART },
  [FOODWASTE_WATER_CHART]: { ...small, i: FOODWASTE_WATER_CHART },

  [TODO_LOCATION_COMPARISON_CHART]: { ...medium, i: TODO_LOCATION_COMPARISON_CHART },
  [TODO_ITEM_COMPARISON_CHART]: { ...medium, i: TODO_ITEM_COMPARISON_CHART },
  [TODO_DONE_TASKS_CHART]: { ...medium, i: TODO_DONE_TASKS_CHART }
}

/* Default layout presets for new users */
export const BASE_PRESET = [
  {
    i: REVENUE_CHART,
    x: 0,
    y: 0,
    ...small
  },
  {
    i: NUM_SOLD_CHART,
    x: 1,
    y: 0,
    ...small
  },
  {
    i: LOCATIONS_COMPARISON_CHART,
    x: 2,
    y: 0,
    ...medium
  },
  {
    i: COMPOSITION_CHART,
    h: 4,
    w: 2,
    x: 2,
    y: 2,
    minH: 2,
    minW: 1
  }]

export const FORECAST_ONLY_PRESET = [
  {
    i: DETAIL_VIEW_CHART,
    x: 0,
    y: 1,
    option: 'revenue',
    ...medium
  },
  {
    h: 3,
    i: ARTICLE_COMPARISON_CHART,
    w: 2,
    x: 0,
    y: 3,
    minH: 2,
    minW: 2
  }]

export const FORECAST_TODO_PRESET = [
  {
    i: ARTICLE_COMPARISON_CHART,
    x: 0,
    y: 1,
    ...medium
  },
  {
    h: 3,
    i: DETAIL_VIEW_CHART,
    w: 2,
    x: 0,
    y: 3,
    minH: 2,
    minW: 2
  },
  {
    i: TODO_ITEM_COMPARISON_CHART,
    x: 0,
    y: 6,
    tab: 'done',
    mode: 'absolute',
    ...medium
  },
  {
    i: TODO_LOCATION_COMPARISON_CHART,
    x: 2,
    y: 6,
    mode: 'absolute',
    ...medium
  }]

export const FORECAST_WASTE_PRESET = [
  {
    h: 3,
    i: ARTICLE_COMPARISON_CHART,
    w: 2,
    x: 0,
    y: 3,
    minH: 2,
    minW: 2
  },
  {
    i: FOODWASTE_CHART,
    x: 0,
    y: 6,
    ...small
  },
  {
    h: 4,
    i: FOODWASTE_COMPOSITION_CHART,
    w: 2,
    x: 0,
    y: 7,
    minH: 2,
    minW: 1
  },
  {
    h: 3,
    i: FOODWASTE_DETAIL_VIEW_CHART,
    w: 2,
    x: 2,
    y: 8,
    minH: 2,
    minW: 2
  },
  {
    i: FOODWASTE_LOCATIONS_COMPARISON_CHART,
    x: 2,
    y: 6,
    ...medium
  },
  {
    i: DETAIL_VIEW_CHART,
    x: 0,
    y: 1,
    ...medium
  },
  {
    i: FOODWASTE_SALES_COSTS_CHART,
    x: 1,
    y: 6,
    ...small
  }]

export const FORECAST_SECTION = 'forecastSection'
export const FOODWASTE_SECTION = 'foodwasteSection'
export const TODO_SECTION = 'todoSection'
export const ORDER_SECTION = 'orderSection'
export const SECTIONS = [FORECAST_SECTION, ORDER_SECTION, FOODWASTE_SECTION, TODO_SECTION]
export const SECTION_CHARTS = {
  [FORECAST_SECTION]: FORECAST_CHARTS,
  [ORDER_SECTION]: ORDER_CHARTS,
  [FOODWASTE_SECTION]: FOODWASTE_CHARTS,
  [TODO_SECTION]: TODO_CHARTS
}
export const SECTION_TITLES = {
  [FORECAST_SECTION]: titleMessages.offering,
  [ORDER_SECTION]: labelMessages.order,
  [FOODWASTE_SECTION]: titleMessages.foodwaste,
  [TODO_SECTION]: titleMessages.todo
}
