import { find } from 'lodash'
import { isEmpty } from 'utils'
import s from './ChangedFieldsSummary.module.scss'

const findFieldLabelByName = (name, structure) => {
  let label = null
  structure.forEach((item) => {
    if (!label && item.fields) {
      const fieldDef = find(item.fields, { name })
      if (fieldDef) {
        if (isEmpty(fieldDef.label)) {
          label = item.title
        } else {
          label = fieldDef.label
        }
      }
    }
  })
  return label
}

// const renderValue = (intl, value) => {
//   const type = typeof value
//   switch (type) {
//     case 'boolean':
//       return <span className={s.newValue}>{value ? intl.formatMessage(globalMessages.yes) : intl.formatMessage(globalMessages.no)}</span>
//     case 'object':
//       return <span className={`${s.newValue} italic`}>{intl.formatMessage(globalMessages.selectedOptionIsSet)}</span>
//     default:
//       return <span className={s.newValue}>{value}</span>
//   }
// }

export const ChangedFieldsSummary = ({ values, structure }) => {
  return (
    <div className={s.root}>
      <ul className='pl-4'>
        {Object.keys(values).map((name) => {
          if (values[name] === undefined) { return null }
          const fieldLabel = findFieldLabelByName(name, structure)
          // const willDelete = isEmpty(values[name]) || isNullOrEmptyArray(values[name]) || values[name] === 'null'
          return (
            <li key={name}>
              <dt className='font-bold text-gray'>{fieldLabel || name}</dt>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
