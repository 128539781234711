import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks/useQueryFetcher'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { omit } from 'lodash'
import queryString from 'query-string'

export const useReportManagement = () => {
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const queryClient = useQueryClient()

  // Mutation for creating or updating a report
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      fetch(
        values.id
          ? `/dashboard-reports/${values.id}/?${queryString.stringify({
          customer
        })}`
          : `/dashboard-reports/?${queryString.stringify({
          customer
        })}`,
        {
          method: values.id ? 'PATCH' : 'POST',
          body: omit(values, ['dashboardId']),
          token,
          success: (res) => resolve(res),
          failure: (errors) => reject(errors)
        }
      )
    }),
    onMutate: (values) => {
      if (!values.id) return
      queryClient.setQueryData(['dashboard', customer, values.dashboardId], (oldData) => {
        const findIndex = oldData.reports.findIndex((r) => r.id === values.id)
        if (findIndex !== -1) {
          const newReports = [...oldData.reports]
          newReports[findIndex] = {
            ...newReports[findIndex],
            definition: values.definition
          }
          return {
            ...oldData,
            reports: newReports
          }
        }
      })
    },
    onSuccess: (result, values) => {
      if (!values.id) return
      queryClient.setQueryData(['dashboard', customer, values.dashboardId], (oldData) => {
        const findIndex = oldData.reports.findIndex((r) => r.id === result.id)
        if (findIndex !== -1) {
          const newReports = [...oldData.reports]
          newReports[findIndex] = result
          return {
            ...oldData,
            reports: newReports
          }
        }
      })
    }
  })

  // Mutation for deleting a report
  const { mutateAsync: deleteAsync, isPending: deleteIsPending } = useMutation({
    mutationFn: (id) => new Promise((resolve, reject) => {
      fetch(
        `/dashboard-reports/${id}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'DELETE',
          token,
          success: (res) => resolve(res),
          failure: (errors) => reject(errors)
        }
      )
    })
  })

  return {
    update: {
      mutate: mutateAsync,
      isPending
    },
    remove: {
      mutate: deleteAsync,
      isPending: deleteIsPending
    }
  }
}
