import { CUSTOMER_DEFAULT_SETTINGS, USER_DEFAULT_ADMIN_SETTINGS } from 'constants/defaultSettings'
import { orderBy } from 'lodash'
import queryString from 'query-string'
import { SALES_LOCATIONS_ROUTE } from 'routes'

export const qFetchUser = (fetch) => {
  return new Promise((resolve, reject) => {
    fetch('/users/me/', {
      method: 'GET',
      success: (user) => {
        if (user) {
          const extendedUser = {
            ...user,
            customer_settings: {
              ...CUSTOMER_DEFAULT_SETTINGS,
              ...user.customer_settings
            },
            admin_settings: {
              ...USER_DEFAULT_ADMIN_SETTINGS,
              ...user.admin_settings
            }
          }
          resolve(extendedUser)
        } else {
          resolve(null)
        }
      },
      failure: (err) => reject(err)
    })
  })
}

export const qFetchCustomers = (fetch) => {
  return new Promise((resolve, reject) => {
    fetch(
      `/customers/?${queryString.stringify({
        page_size: 999,
        ordering: 'name'
      })}`,
      {
        method: 'GET',
        success: (res) => resolve(res.results.map(c => ({ ...c, settings: { ...CUSTOMER_DEFAULT_SETTINGS, ...c.settings } }))),
        failure: (err) => reject(err)
      }
    )
  })
}

export const qFetchLocations = (fetch, filter, includeDisabled) => {
  return new Promise((resolve, reject) => {
    fetch(
      `/${SALES_LOCATIONS_ROUTE}/?${queryString.stringify(filter)}`,
      {
        method: 'GET',
        success: (res) => {
          const results = includeDisabled ? orderBy(res.results, ['name'], ['asc']) : res.results
          resolve(results)
        },
        failure: (err) => reject(err)
      }
    )
  })
}
