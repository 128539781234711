import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { useDataQuery } from '..'
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { sortBy } from 'lodash'

/**
 * Fetches full item entities from the Item model sorted by name
 * @returns An array of Item entities
 */
export const useItemEntities = (options) => {
  const intl = useIntl()
  const opts = {
    enabled: true,
    from: 'Item',
    location: null,
    ids: null,
    fieldFilters: null,
    ...options
  }

  const whereParams = [
    ...(opts.location
      ? [
          {
            field: 'sales_location',
            operator: 'in',
            value: opts.location
          }
        ]
      : []),
    ...(opts.ids
      ? [
          {
            field: 'id',
            operator: 'in',
            value: opts.ids
          }
        ]
      : []),
    ...(opts.fieldFilters
      ? opts.fieldFilters.map(f => ({
          field: f.field,
          operator: f.operator,
          value: f.value
        }))
      : [])
  ]

  const { data, status, isFetching, isLoading, error } = useDataQuery({
    from: 'Item',
    where: whereParams
  }, opts.enabled, null, { staleTime: 1000 * 60 * 5 })

  useEffect(() => {
    if (error) {
      message.error(intl.formatMessage(notifyMessages.fetchError, { type: 'items', message: error.error ? error.error.message : error }), 15)
    }
  }, [error])

  const sortedItems = useMemo(() => {
    if (!data) return null
    return sortBy(data.filter(i => i), ['name'])
  }, [data])

  return {
    data: sortedItems,
    status,
    isFetching,
    isLoading,
    error
  }
}
