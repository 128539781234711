import { FlexibleScaledNumberFormat } from 'components/FlexibleScaledNumberFormat'
import { BASE_VALUE_FORMAT } from 'constants/index'
import { BellIcon } from '@heroicons/react/solid'

export const OpenMeasurementsIndicator = ({ className, count, tiny }) => {
  if (!count) return
  return (
    <div className={className}>
      <span className='text-right flex items-center justify-center h-5 min-w-1.5 px-2 text-sm font-bold text-white rounded-full shadow bg-feedback-error tracking-wide'>
        {tiny ? <BellIcon className='w-3 h-3' /> : <FlexibleScaledNumberFormat {...BASE_VALUE_FORMAT} value={count} />}
      </span>
    </div>
  )
}
