import { without } from 'lodash'
import { useMemo } from 'react'
import { usePageSettings } from 'hooks/usePageSettings'
import { reorder } from 'utils'

export const useFieldSettingHandler = (key, allItems) => {
  const { set: setSetting, settings } = usePageSettings()

  const selected = settings[key] || []
  const sortedInitial = useMemo(() => [...selected, ...without(allItems, ...selected)], [settings, key, allItems])
  const set = (selection) => setSetting({ [key]: selection })
  const move = (fromIndex, toIndex, withExpandColumn) => {
    const offset = withExpandColumn ? 1 : 0
    set(reorder(selected, fromIndex - offset, toIndex - offset))
  }

  return {
    selected,
    sortedInitial,
    set,
    allItems,
    move
  }
}
