import { T, always, cond, identical, ifElse } from 'ramda'

import { BoldLabel } from '.'
import React from 'react'
import cn from 'classnames'
import { useField, useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { Text } from 'components/Primitives'
import s from './FormField.module.scss'

export const FieldWrapper = ({ children, wrapperClassName, name, label, boldLabel, helpText, showError, hasError, error }) => {
  const intl = useIntl()
  // The label has a preventDefault because for some weird unknown reason it was calling the handleChange in ColumnsToolPanel and toggling the columns
  return (
    <div
      className={cn(s.wrapper, wrapperClassName)}
    >
      <label htmlFor={name} className={s.label} onClick={(e) => e.preventDefault()}>{label ? boldLabel ? <BoldLabel>{label}</BoldLabel> : <Text title={label} color='black'>{label}</Text> : null}
      </label>
      {children}
      {helpText && <Text tagName='p' color='gray'>{helpText}</Text>}
      {showError && hasError && <Text tagName='p' color='danger' className={s.error}>{typeof error === 'string' ? error : intl.formatMessage(error)}</Text>}
    </div>
  )
}

export const FormField = ({
  name,
  label,
  boldLabel,
  inputType,
  className,
  wrapperClassName,
  as: Component,
  showError,
  mode,
  helpText,
  forceValue,
  ...props
}) => {
  const [{ value: fieldValue }, meta, { setValue }] = useField(name)
  const hasWrapper = !!label || showError || !!wrapperClassName || helpText
  const { submitCount } = useFormikContext()
  const hasError = !!meta.error && (meta.touched || submitCount > 0)

  const value = forceValue !== undefined ? forceValue : fieldValue

  const identifierProps = {
    name,
    id: props.id || name,
    'data-type': inputType,
    'data-name': name
  }

  const componentProps = cond([
    [
      identical('checkbox'),
      always({
        checked: value,
        onChange: (e) => setValue(e.target.checked),
        ...identifierProps
      })
    ],
    [
      identical('number-format'),
      always({
        value: value === undefined ? '' : value,
        onValueChange: ({ floatValue = null }) => setValue(floatValue),
        ...identifierProps
      })
    ],
    [
      identical('radio'),
      always({
        value,
        onChange: (e) => setValue(e.target.value),
        ...identifierProps
      })
    ],
    [
      identical('boolean'),
      // This if else is created for nullable boolean and normal boolen form field
      // Second Parameter of ifElse is nullable boolean field, third parameter is normal boolean field.
      // Gets nullable prop from generic form structure.
      ifElse(_ => props.nullable, always({
        value: value != null ? value.toString() : 'null',
        onChange: (value) => {
          if (value === 'null') setValue(null)
          else if (value === 'true') setValue(true)
          else if (value === 'false') setValue(false)
          else {
            setValue(false)
          }
        },
        ...identifierProps
      }), always({
        value: value != null ? value.toString() : undefined,
        onChange: (value) => setValue(value === 'true'),
        ...identifierProps
      })
      )],
    [
      identical('search-select'),
      always({
        value,
        onChange: (value) => setValue(value),
        className: 'w-full',
        options: props.options || [],
        ...identifierProps
      })
    ],
    [
      identical('location'),
      always({
        value,
        onChange: (value) => setValue(value),
        className: 'w-full',
        // Form Location Pickers should always be flat styled and medium sized
        flat: true,
        medium: true,
        single: props.variant !== 'multi',
        allowClear: props.variant !== 'multi',
        ...identifierProps
      })
    ],
    [
      identical('search-tag'),
      always({
        value,
        onChange: (value) => setValue(value),
        className: 'w-full',
        // Form Location Tag Pickers should always be flat styled and medium sized
        flat: true,
        medium: true,
        single: props.variant !== 'multi',
        allowClear: props.variant !== 'multi',
        ...identifierProps
      })
    ],
    [
      identical('item'),
      always({
        value,
        onChange: (value) => setValue(value),
        className: 'w-full',
        single: props.variant !== 'multi',
        allowClear: props.variant !== 'multi',
        mode,
        ...identifierProps
      })
    ],
    [
      identical('customer'),
      always({
        value,
        onChange: (value) => setValue(value),
        className: 'w-full',
        single: props.variant !== 'multi',
        allowClear: props.variant !== 'multi',
        mode,
        ...identifierProps
      })
    ],
    [
      identical('user'),
      always({
        value,
        onChange: (value) => setValue(value),
        className: 'w-full',
        single: props.variant !== 'multi',
        mode,
        ...identifierProps
      })
    ],
    [
      T,
      always({
        value,
        onChange: (value) => setValue(value),
        ...identifierProps
      })
    ]
  ])(inputType)

  const field = (
    <Component
      {...componentProps}
      {...props}
      className={cn(className, 'mt-0.5', componentProps.className, !hasWrapper && hasError && 'has-error', hasWrapper && hasError && s.hasError)}
      hasError={hasError}
    />
  )

  return hasWrapper
    ? <FieldWrapper {...{ wrapperClassName, name, label, boldLabel, helpText, showError, hasError }} error={meta.error}>{field}</FieldWrapper>
    : field
}
