import { createContext, useContext } from 'react'
import { usePageSettings } from 'hooks/usePageSettings'
import { findAndReplaceField } from 'utils'
import { useReportParams } from 'components/Report/hooks/useReportParams'

const FilterParamsContext = createContext()

export const FilterParamsProvider = ({ children, v2 }) => {
  const { settings } = usePageSettings()
  const filterParams = v2 ? null : useReportParams(settings)

  return <FilterParamsContext.Provider value={v2 ? settings : filterParams.filters}>{children}</FilterParamsContext.Provider>
}

export const useFilterParamsContext = () => useContext(FilterParamsContext)

export const removeFields = (params, fieldNames) => params.filter((p) => !fieldNames.includes(p.field))
export const modifyQueryParams = (params, omitFields = [], mapping = {}) => {
  if (!params) return params
  let replaced = removeFields(params, omitFields)
  // for each mapping key-value pair we call findAndReplaceField
  Object.keys(mapping).forEach((key) => {
    replaced = findAndReplaceField(replaced, key, mapping[key])
  })
  return replaced
}
