import { useState } from 'react'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { Input } from 'components/Primitives'

export const TextFieldDialog = ({ title, children, visible, onCancel, onSubmit }) => {
  const [text, setText] = useState('')

  return (
    <MobileFriendlyModal
      visible={visible}
      title={title}
      onCancel={onCancel}
      onSubmit={() => onSubmit(text)}
      width={650}
      okDisabled={text.length === 0}
    >
      <div className='flex flex-col h-full mb-3'>
        {children}
      </div>
      <Input
        autoFocus
        size='small'
        value={text}
        onChange={(text) => setText(text)}
      />
    </MobileFriendlyModal>
  )
}
