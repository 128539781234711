import React from 'react'
import { useIntl } from 'react-intl'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useWasteMeasurements } from 'hooks/queries/useWasteMeasurements'
import labelMessages from 'components/labelMessages'
import { useModalStore } from 'hooks/store/useModalStore'
import { Loader } from 'components/Primitives/Loader'
import { History } from 'components/History'

const DetailsModal = ({ columns }) => {
  const intl = useIntl()
  const hide = useModalStore(state => state.hide)
  const measurementHistory = useModalStore(state => state.modals.measurementHistory)
  const { data, isFetching } = useWasteMeasurements({ item: measurementHistory, fields: columns })

  return (
    <MobileFriendlyModal
      visible
      title={intl.formatMessage(labelMessages.history)}
      onCancel={() => hide('measurementHistory')}
      size='m'
      noFooter
    >
      {isFetching
        ? <div className='py-10 text-center'><Loader /></div>
        : <History
            data={data}
            type='waste'
            diffField='amount'
            diffInverted
          />}
    </MobileFriendlyModal>
  )
}

export default DetailsModal
