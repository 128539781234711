import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useUI } from 'services/UIProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import { useState } from 'react'
import message from 'services/message'
import { collectDebugInformation, submitDebugReport } from 'utils/debug'

export const BugReportModal = () => {
  const intl = useIntl()
  const { bugReportModal, setBugReportModal } = useUI()
  const [errorDescription, setErrorDescription] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const close = () => {
    setBugReportModal(false)
    setErrorDescription('')
    setSubmitting(false)
  }

  const submitReport = () => {
    setSubmitting(true)
    submitDebugReport(collectDebugInformation(null, { type: 'Bug Report', message: errorDescription })).then(res => {
      const identifier = res.file_path ? res.file_path.split('.')[0] : JSON.stringify(res)
      message.success(<FormattedMessage id='bugReport.submitted' defaultMessage='Bug report transmitted: {identifier}' values={{ identifier }} />)
      close()
    })
  }

  return (
    <MobileFriendlyModal
      visible={bugReportModal}
      title={<FormattedMessage id='bugReport.title' defaultMessage='Bug Report' />}
      onCancel={close}
      footer={{
        primaryText: <FormattedMessage id='global.submit' defaultMessage='Submit' />,
        primaryDisabled: errorDescription.trim() === '',
        primaryLoading: submitting
      }}
      onPrimary={submitReport}
    >
      {submitting
        ? <p><FormattedMessage id='bugReport.submitting' defaultMessage='Bug report is being transmitted...' /></p>
        : (
          <>
            <p>
              <FormattedMessage id='bugReport.description' defaultMessage='Use this dialog to send a bug report directly to the Delicious Data development team.' />
            </p>
            <textarea
              className='w-full h-32 p-2 my-4 border rounded-lg outline-none border-gray-border'
              value={errorDescription}
              onChange={e => setErrorDescription(e.target.value)}
              placeholder={intl.formatMessage({ id: 'bugReport.placeholder', defaultMessage: 'Describe the problem you are facing' })}
            />
            <p className='text-sm'>
              <FormattedMessage id='bugReport.privacy' defaultMessage='The following information will be transmitted: App version, device and browser info, screen resolution, internal debug output, user info, user settings including dashboard configuration and filters.' />
            </p>
          </>
          )}
    </MobileFriendlyModal>
  )
}
