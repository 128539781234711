import labelMessages from 'components/labelMessages'
import { Badge, Tooltip } from 'components/Primitives'
import { differenceInHours, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { formatLocalized, formatRelative } from 'utils/datetime'
import { useMemo } from 'react'

export const DatastatusRenderer = ({ value }) => {
  const intl = useIntl()
  if (value == null) return null

  const lastUpdateDate = useMemo(() => parseISO(value), [value])

  const type = useMemo(() => {
    const now = new Date()
    let t = 'fbError'
    if (differenceInHours(now, lastUpdateDate) <= 24) {
    // TODO: This function is unbelievable expensive. And the funniest thing is, that it's assuming a work time from 0 to 24 which makes no sense since it's kind of the same than differenceInHours
    // if (workingHoursDiff(value, now.toISOString()) <= 24) {
      t = 'fbSuccess'
    } else if (differenceInHours(now, lastUpdateDate) <= 72) {
      t = 'fbWarning'
    }
    return t
  }, [value])

  return (
    <Tooltip title={`${intl.formatMessage(labelMessages.lastUpdated)} ${formatLocalized(lastUpdateDate, 'P p', intl)}`}>
      <Badge size='s' type={type}>{formatRelative(lastUpdateDate, intl)}</Badge>
    </Tooltip>
  )
}
