import { BAKERY, BASE_VALUE_FORMAT, CURRENCY_VALUE_FORMAT, PERCENT_VALUE_FORMAT } from 'constants/index'
import React, { useMemo } from 'react'

import { Bar } from '../Bar'
import { LOCATION } from 'hooks/useChartTabs'
import { Spin } from 'antd'
import Tabs from '../Tabs'
import { calcBarChartData } from '../utils'
import cn from 'classnames'
import isNil from 'lodash/isNil'
import messages from '../messages'
import s from '../ChartStylesBar.module.scss'
import { useDashboardRefiner } from 'hooks'
import { NoData } from 'components/NoData'
import labelMessages from 'components/labelMessages'
import { FlexibleScaledNumberFormat } from 'components/FlexibleScaledNumberFormat'
import { useCustomerType } from 'hooks/useCustomerType'
import { isNaN } from 'lodash'
import { useIntl } from 'react-intl'
import { useCustomerSettings } from 'hooks/useCustomerSettings'

export const REVENUE = 'revenue'
export const NUM_SOLD = 'num_sold'
export const TOTAL_COGS = 'total_cogs'
export const REL_COGS = 'rel_cogs'
export const AVG_TRANSACTION_REVENUE = 'avg_transaction_revenue'
export const TRANSACTION_COUNT = 'transaction_count'
export const ORDERED_AMOUNT = 'ordered_amount'
export const REQUESTED_AMOUNT = 'requested_amount'
export const FULFILLED_AMOUNT = 'fulfilled_amount'
export const NUM_UNSOLD = 'num_unsold'
export const NUM_VANISHED = 'num_vanished'
export const RETURN_RATIO = 'return_ratio'

const DEFAULT_TABS = [
  { value: REVENUE, label: labelMessages.revenue },
  { value: REL_COGS, label: labelMessages.relCogs },
  { value: TOTAL_COGS, label: labelMessages.cogs },
  { value: NUM_SOLD, label: labelMessages.soldNo },
  { value: TRANSACTION_COUNT, label: labelMessages.transactionCount },
  { value: AVG_TRANSACTION_REVENUE, label: messages.avgTransactionRevenue }
]
const BAKERY_TABS = [
  { value: REVENUE, label: labelMessages.revenue },
  { value: NUM_SOLD, label: labelMessages.soldNo },
  { value: TRANSACTION_COUNT, label: labelMessages.transactionCount },
  { value: AVG_TRANSACTION_REVENUE, label: messages.avgTransactionRevenue },
  { value: ORDERED_AMOUNT, label: labelMessages.orderedAmount },
  { value: REQUESTED_AMOUNT, label: labelMessages.requestedAmount },
  { value: FULFILLED_AMOUNT, label: labelMessages.fullfilledAmount },
  { value: NUM_UNSOLD, label: labelMessages.unsoldAmount },
  { value: RETURN_RATIO, label: labelMessages.returnRate },
  { value: NUM_VANISHED, label: labelMessages.numVanished }
]

const getTooltipContent = (tab, key, intl, context, returnRatioField) => {
  switch (tab) {
    case REL_COGS:
      return (
        <div>
          <p>{intl.formatMessage(labelMessages.cogs)}: <FlexibleScaledNumberFormat value={context[key].numerator} {...CURRENCY_VALUE_FORMAT} /></p>
          <p>{intl.formatMessage(labelMessages.revenue)}: <FlexibleScaledNumberFormat value={context[key].denominator} {...CURRENCY_VALUE_FORMAT} /></p>
        </div>
      )
    case RETURN_RATIO:
      const denominatorLabel = returnRatioField === 'num_sold' ? labelMessages.soldNo : labelMessages.fullfilledAmount
      return (
        <div>
          <p>{intl.formatMessage(labelMessages.unsoldAmount)}: <FlexibleScaledNumberFormat value={context[key].numerator} {...BASE_VALUE_FORMAT} /></p>
          <p>{intl.formatMessage(denominatorLabel)}: <FlexibleScaledNumberFormat value={context[key].denominator} {...BASE_VALUE_FORMAT} /></p>
        </div>
      )
  }
  return null
}

const Chart2Bars = ({ maxCount, tab, setTab, fixed, data, loading, isInModal, onRefine, decimalScale }) => {
  const intl = useIntl()
  const withDenominator = [REL_COGS, RETURN_RATIO].includes(tab)
  const customerType = useCustomerType()
  const customerSettings = useCustomerSettings()
  const chartData = useMemo(() => calcBarChartData(data, { withAverage: false, valueProp: withDenominator }), [data])
  const refiner = useDashboardRefiner({ tab: LOCATION })

  const CHART_TABS = customerType === BAKERY ? BAKERY_TABS : DEFAULT_TABS
  const reversedArrows = [REL_COGS, TOTAL_COGS, NUM_VANISHED, NUM_UNSOLD, RETURN_RATIO].includes(tab)

  const bars = chartData.map(
    ({ location, value, prevValue, percentage, average, context, ...rest }, index) => {
      if (value == null || isNaN(value) || (maxCount && index > maxCount - 1)) {
        return null
      }
      const difference = value - prevValue
      const delta = Math.abs(difference)
      const formatProps = [REVENUE, TOTAL_COGS, AVG_TRANSACTION_REVENUE].includes(tab) ? CURRENCY_VALUE_FORMAT : [REL_COGS, RETURN_RATIO].includes(tab) ? PERCENT_VALUE_FORMAT : BASE_VALUE_FORMAT

      return (
        <Bar
          key={index}
          title={location}
          total={!isNil(value) && (
            <FlexibleScaledNumberFormat
              value={withDenominator ? value * 100 : value}
              {...formatProps}
            />
          )}
          info={!isNil(prevValue) && (
            <FlexibleScaledNumberFormat
              value={withDenominator ? delta * 100 : delta}
              {...formatProps}
            />
          )}
          infoClassName={!isNil(prevValue) && cn(s.delta,
            difference === 0 && s.left,
            difference > 0 && (reversedArrows ? s.upRed : s.up),
            difference < 0 &&
            (reversedArrows ? s.downGreen : s.down))}
          percentage={percentage}
          isAverage={average}
          onDetailClick={() => {
            if (onRefine) { onRefine() }
            refiner.handleRefine({ name: location })
          }}
          prevTooltipContent={getTooltipContent(tab, 'prev', intl, context, customerSettings.returnRatioField)}
          currentTooltipContent={getTooltipContent(tab, 'current', intl, context, customerSettings.returnRatioField)}
        />
      )
    }
  ).filter(i => i)

  return (
    <Spin spinning={loading} wrapperClassName={cn(s.wrapper, isInModal ? s.inModal : null)}>
      <Tabs fixed={fixed} value={tab} onChange={setTab} options={CHART_TABS} />
      {bars.length > 0 ? <div className={s.bars}>{bars}</div> : <div className={s.noData}><NoData size='m' /></div>}
    </Spin>
  )
}

export default Chart2Bars
