import React, { useCallback, useEffect, useRef } from 'react'

import HelpHeader from 'components/HelpHeader'
import Media from 'components/Media'
import Title from 'components/Title'
import classNames from 'classnames'
import s from './PageActions.module.scss'
import useMobileScrollEvent from 'hooks/useMobileScrollEvent'
import { RenderCount } from 'components/RenderCount'
import { withSize } from 'react-sizeme'
import { Text } from 'components/Primitives'

const PageActions = ({ size, className, title, actions, children, onHeightChange, collapseOnScroll, helpText, helpLink, small, nowrap, forceHeight }) => {
  const onResize = useCallback(({ height }) => onHeightChange ? onHeightChange(height) : undefined, [])
  const ref = useRef()

  useEffect(() => {
    if (size) onResize(size)
  }, [size])

  const scrollEvent = useMobileScrollEvent({ enabled: collapseOnScroll })
  const isCollapsed = scrollEvent ? scrollEvent.direction === 'down' : false

  return (
    <div
      ref={ref} className={classNames(className,
        {
          'transition-all duration-200 ease-in-out transform': collapseOnScroll,
          '-translate-y-14': collapseOnScroll & isCollapsed,
          '-translate-y-0': collapseOnScroll & !isCollapsed,
          'mb-2': !children
        })}
    >
      {title && (
        <div className={classNames('flex items-center justify-between px-3 mobile:pt-4', small ? 'pt-2 h-12 desktop:px-0' : 'pt-6 h-14 desktop:px-6')}>
          {typeof (title) === 'string' ? <Title small={small}>{title}</Title> : title}
          <Media className='mr-auto' greaterThanOrEqual='tablet'>
            {helpLink && helpText && <HelpHeader helpLink={helpLink}>{helpText}</HelpHeader>}
          </Media>
          <RenderCount className='relative' />
          <div className='flex'>{actions}</div>
        </div>
      )}
      {!helpLink && helpText && <Text size='s' color='gray'>{helpText}</Text>}
      {(children || forceHeight) &&
        <div
          className={classNames(small ? s.containerSmall : s.container, 'flex items-center px-3 overflow-auto pb-2', !helpLink && helpText ? 'pt-2' : 'pt-4', nowrap ? null : 'desktop:flex-wrap', small ? 'desktop:pr-0 desktop:pl-4 desktop:-ml-4' : 'min-h-14 desktop:px-6')}
          data-cy='page-actions-filter-container'
        >
          {children}
          <div className='flex-shrink-0 tablet:w-4 mobile:w-1' />
        </div>}
    </div>
  )
}

export default withSize({ monitorWidth: false, monitorHeight: true })(PageActions)
