import * as RadixSwitch from '@radix-ui/react-switch'
import classNames from 'classnames'
import s from './Switch.module.scss'

export const Switch = ({ label, id, disabled, checked, onChange, className, small, checkedChildren, unCheckedChildren, ...props }) => {
  return (
    <div className={className}>
      {label && (
        <label className={s.label} htmlFor={id}>
          {label}
        </label>
      )}
      <RadixSwitch.Root
        className={classNames(s.root, small ? s.small : undefined)}
        id={id}
        disabled={disabled}
        checked={checked}
        onCheckedChange={onChange}
        {...props}
      >
        <RadixSwitch.Thumb className={s.thumb} />
        {checked && checkedChildren ? <div className={s.child}>{checkedChildren}</div> : null}
        {!checked && unCheckedChildren ? <div className={s.child}>{unCheckedChildren}</div> : null}
      </RadixSwitch.Root>
    </div>
  )
}
