import { JsonEditor } from 'json-edit-react'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import s from './Json.module.scss'
import { Button } from 'components/Button'

export const Json = ({ children, className, editable, editModeObserver, onChange, rows = 7, onIsValid }) => {
  const [editMode, setEditMode] = useState(false)
  const [textValue, setTextValue] = useState(JSON.stringify(children, null, 2))
  const [validationError, setValidationError] = useState(null)

  const parsedJSON = useMemo(() => {
    try {
      const j = JSON.parse(textValue)
      setValidationError(null)
      return j
    } catch (ex) {
      setValidationError(ex.message)
      return undefined
    }
  }, [textValue])
  const isValid = parsedJSON !== undefined

  useEffect(() => {
    if (onIsValid) onIsValid(isValid)
  }, [isValid])

  useEffect(() => {
    if (editModeObserver) editModeObserver(editMode)
    if (!editMode) {
      setTextValue(JSON.stringify(children, null, 2))
    }
  }, [children, editMode])

  const handleButtonClick = () => {
    if (!editMode) {
      setEditMode(true)
    } else {
      if (isValid) {
        setEditMode(false)
        onChange(parsedJSON)
      }
    }
  }

  const renderPretty = () => (
    <JsonEditor
      className={s.pretty}
      data={children == null ? 'null' : children === 0 ? '0' : children}
      theme={[
        'githubLight',
        {
          container: {
            padding: 0,
            marginTop: 0,
            fontSize: '0.75rem'
          }
        }
      ]}
      restrictAdd
      restrictDelete
      restrictEdit
      maxWidth='100%'
    />
  )

  const renderArea = () => (
    <div className={s.areaContainer}>
      <textarea
        className={classNames(s.area, 'mono-font')}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        rows={rows}
      />
      <div className={s.validator}>
        {isValid
          ? <span className='text-feedback-success-tint'><FormattedMessage id='json.validMessage' defaultMessage='Valid JSON. Please submit your changes using the ✓ button.' /></span>
          : <span className='text-feedback-error-lighter'>{validationError}</span>}
      </div>
    </div>
  )

  return (
    <div className={classNames('relative', className)}>
      {editable && (
        <Button
          className={classNames(s.button, editMode && !isValid && 'text-feedback-error cursor-not-allowed')}
          type='tertiary'
          onClick={handleButtonClick}
          icon={editMode ? isValid ? 'Checkmark' : 'Error' : 'Edit'}
        />
      )}
      {editMode ? renderArea() : renderPretty()}
    </div>
  )
}
