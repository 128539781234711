import React from 'react'
import messages from './messages'
import s from './Bar.module.scss'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import { SimpleBar } from './SimpleBar'
import { Tooltip } from 'components/Primitives'

export const Bar = ({ title, total, info, infoClassName, isAverage, percentage, onDetailClick, prevTooltipContent, currentTooltipContent }) => {
  const intl = useIntl()
  const { demo } = useDemo()

  return (
    <div className={s.container}>
      <div className={s.labels}>
        <div className={s.title}>
          {onDetailClick && !isAverage
            ? (
              <span role='button' onClick={onDetailClick}>
                {isAverage ? intl.formatMessage(messages.average) : demo(title)}
              </span>
              )
            : isAverage ? intl.formatMessage(messages.average) : demo(title)}
        </div>
        {currentTooltipContent
          ? <Tooltip triggerClassName={`${s.total} ${s.tooltip}`} title={currentTooltipContent} placement='top'><div>{total}</div></Tooltip>
          : <div className={s.total}>{total}</div>}
        {info && !isAverage && (
          prevTooltipContent
            ? <Tooltip triggerClassName={`${infoClassName} ${s.tooltip}`} title={prevTooltipContent} placement='top'><div>{info}</div></Tooltip>
            : <div className={infoClassName}>{info}</div>
        )}
      </div>
      <SimpleBar percentage={percentage} innerClassName={isAverage ? 'bg-accent-hydrangea-25' : 'bg-accent-hydrangea-75'} />
    </div>
  )
}
