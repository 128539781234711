import { ADMIN, OPERATOR } from 'constants/index'
import { anyPass, filter, propEq } from 'ramda'

import React from 'react'
import { SearchSelect } from 'components/SearchSelect'
import { getUserName } from 'utils'
import { useAllUsers } from 'hooks/queries/useAllUsers'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import globalMessages from 'components/globalMessages'
import titleMessages from 'components/titleMessages'
import { Tooltip } from 'components/Primitives'

const UserPicker = ({
  value,
  onChange,
  name,
  placeholder,
  className,
  single,
  optionsLabel,
  mode,
  rounded,
  small,
  filterMode,
  withPortal,
  hasError,
  fitWidth
}) => {
  const intl = useIntl()
  const { data, isLoading } = useAllUsers()
  const { demo } = useDemo()

  let options = []
  if (data) {
    switch (filterMode) {
      case 'foodwasteAccess':
        options = filter(
          anyPass([
            propEq('food_waste_access', true),
            propEq('role', ADMIN),
            propEq('role', OPERATOR)
          ])
        )(data)
        break
      default:
        options = data
        break
    }
  }

  // TODO: In contrary to LocationPicker this component can only provide multi select at the moment
  if (single) { throw new Error('UserPicker in single mode not implemented.') }

  return (
    <SearchSelect
      role='user-picker'
      type={rounded ? 'round' : undefined}
      fitWidth={fitWidth}
      size={small ? 'small' : undefined}
      className={className}
      name={name}
      placeholder={placeholder || intl.formatMessage(globalMessages.select)}
      optionsLabel={optionsLabel || intl.formatMessage(titleMessages.users)}
      value={value}
      onChange={onChange}
      mode={mode}
      loading={isLoading}
      options={options.map((user) => ({
        key: user.id,
        label: user.first_name || user.last_name
          ? (
            <Tooltip title={user.email} side='right'>
              {demo(getUserName(user))}
            </Tooltip>
            )
          : demo(user.email)
      }))}
      withPortal={withPortal}
      hasError={hasError}
    />
  )
}

export default UserPicker
