import { useEffect, useMemo, useState } from 'react'

import Drawing from 'static/undraw_bug_fixing.svg'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { random } from 'utils'
import s from './ApplicationError.module.scss'
import { useDebounceEffect, useTimeout } from 'ahooks'
import { collectDebugInformation, submitDebugReport } from 'utils/debug'

export const ApplicationError = ({ error, inChart, simulate, context, resetErrorBoundary, resetAfterDelay }) => {
  const small = inChart === true
  const [debounceDelay] = useState(inChart ? random(7000, 9000) : 1000)

  const [identifier, setIdentifier] = useState('…')

  const reportError = () => {
    if (simulate) {
      console.info(collectDebugInformation(error, context))
      setIdentifier('foo-bar-baz')
    } else {
      submitDebugReport(collectDebugInformation(error, context)).then(res => {
        setIdentifier(res.file_path ? res.file_path.split('.')[0] : JSON.stringify(res))
      })
    }
  }

  const isNetworkError = useMemo(() => {
    if (!error || !error.message) return false
    return error.message.toLowerCase().includes('failed to fetch') || error.message.includes('NetworkError') || error.message.includes('Load failed')
  }, [error])

  useTimeout(() => {
    if (inChart && isNetworkError && resetErrorBoundary) {
      resetErrorBoundary()
    }
  }, 20000)

  useTimeout(() => {
    if (resetAfterDelay && resetErrorBoundary) {
      resetErrorBoundary()
    }
  }, 3000)

  // Report the error as soon it occures
  useEffect(() => {
    if ((!inChart || (inChart && resetAfterDelay)) && error) {
      reportError()
    }
  }, [error])

  useDebounceEffect(() => {
    if (inChart && error && !isNetworkError) {
      reportError()
    }
  }, [error], { wait: debounceDelay })

  return (
    <div className={classNames(s.container, small ? s.small : null)}>
      {!small && <img src={Drawing} />}
      <p className={s.error}>
        {small
          ? <FormattedMessage id='pageErrorBoundary.smallError' defaultMessage='Error occured.' tagName='span' />
          : <FormattedMessage id='pageErrorBoundary.error' defaultMessage='Sorry, a critical error occured rendering this page.' tagName='span' />}
      </p>
      {identifier && (
        <>
          {!small && (
            <div className={s.code}>
              <svg
                className=''
                fill='currentColor'
                viewBox='0 0 32 32'
                aria-hidden='true'
              >
                <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
              </svg>
              <p className={classNames('z-20 text-lg font-bold min-w-7 text-center', { 'text-gray-lighter': identifier === '…' })}>{identifier || ''}</p>
            </div>
          )}
          <div className={s.description}>
            {inChart
              ? isNetworkError
                  ? <FormattedMessage id='pageErrorBoundary.network' defaultMessage='Connection error. The request will be repeated shortly.' tagName='p' />
                  : <FormattedMessage id='pageErrorBoundary.reported1' defaultMessage='An error report was automatically sent to Delicious Data.' tagName='p' />
              : <FormattedMessage id='pageErrorBoundary.reported1' defaultMessage='An error report was automatically sent to Delicious Data.' tagName='p' />}
            {!small && <FormattedMessage id='pageErrorBoundary.reported2' defaultMessage='If you need to contact the Delicious Data team about this error, the identifier above can be used.' tagName='p' />}
          </div>
        </>
      )}
    </div>
  )
}
