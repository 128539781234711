import { useMemo } from 'react'
import { modifyQueryParams, useFilterParamsContext } from 'services/FilterParamsProvider'

export const DEFAULT_OMIT_FORECAST_CHART = ['category', 'menuline_obj', 'component_obj']
export const DEFAULT_OMIT_FOODWASTE_CHART = ['component', 'menuline', 'menuline_obj', 'component_obj', 'item__name', 'item__category_1', 'item__category_2']

/**
 * Takes the queryParams from context and modifies for the given chart
 */
export const useChartQueryModifier = (omitQueryParams, mapping) => {
  const queryParams = useFilterParamsContext()
  return useMemo(() => modifyQueryParams(queryParams, omitQueryParams, mapping), [queryParams])
}
