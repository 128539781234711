import s from './CellRenderer.module.scss'
import { ConfidenceBadge } from 'components/Badge'

export const ConfidenceRenderer = (props) => {
  const { value } = props
  if (!value) return null

  return (
    <div className={s.tagLine}>
      <ConfidenceBadge confidence={value} />
    </div>
  )
}
