import classNames from 'classnames'
import { isSameDay, isSameMonth } from '@internationalized/date'
import { useRef } from 'react'
import { useCalendarCell } from 'react-aria'

export const CalendarCell = ({ state, date, currentMonth }) => {
  const ref = useRef(null)
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    // isFocused,
    isUnavailable,
    formattedDate
  } = useCalendarCell({ date }, state, ref)
  const isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected
  const isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected
  const isOutsideMonth = !isSameMonth(currentMonth, date)
  const selectionState = isSelected && (isSelectionStart || isSelectionEnd) ? 'cap' : isSelected ? 'middle' : 'none'

  return (
    <td {...cellProps} className='group w-8 h-8 text-base outline outline-0 cursor-default selected:bg-primary-tint invalid:selected:bg-feedback-error-lighter'>
      <div
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={classNames('w-full h-full flex items-center justify-center rounded-lg', {
          'cursor-pointer': !isDisabled,
          'group-hover:bg-gray-lighter group-pressed:bg-gray': selectionState === 'none' && !isDisabled,
          'rounded-l-lg rounded-r-none': isSelectionStart && !isSelectionEnd,
          'rounded-r-lg rounded-l-none': isSelectionEnd && !isSelectionStart,
          'rounded-lg': isSelectionStart && isSelectionEnd,
          'bg-primary-tint2 group-invalid:bg-feedback-error text-primary': selectionState === 'cap',
          'bg-primary-tint group-invalid:bg-feedback-error text-primary rounded-none': selectionState === 'middle',
          'text-disabled cursor-not-allowed': isDisabled,
          hidden: isOutsideMonth,
          'text-feedback-error': isUnavailable
          // 'border-solid border border-primary-lightest': isFocused
        })}
        data-cy='calendar-cell'
      >
        {formattedDate}
      </div>
    </td>
  )
}
