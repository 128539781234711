import s from './ProgressBar.module.scss'
import classNames from 'classnames'
import { clamp } from 'lodash'

export const ProgressBar = ({ className, progress }) => {
  const realProgress = clamp(progress, 0, 100)

  return (
    <div className={classNames(s.root, className)}>
      <div className={s.bar} style={{ width: `${realProgress}%` }} />
    </div>
  )
}
