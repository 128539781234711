import React from 'react'
import { DatePicker } from 'antd'
import cn from 'classnames'
import m from 'moment'
import { DATE_FORMAT, DEFAULT_DATE_FORMAT } from 'constants/index'
import s from './DatePicker.module.scss'
import { Icon } from 'components/Primitives'

export default ({ value, onChange, rounded, className, placeholder, prefixPlaceholder, ...props }) => {
  // FIXME: moment needed for AntDesign
  const date = value ? m(value, DATE_FORMAT) : null

  const onDateChange = (date) => {
    onChange(date ? date.format(DATE_FORMAT) : null)
  }

  return (
    <div className={cn(s.wrapper, {
      [s.filter]: rounded,
      [s.hasValue]: value != null
    }, className)}
    >
      <DatePicker
        value={date}
        onChange={onDateChange}
        format={DEFAULT_DATE_FORMAT}
        showToday={false}
        suffixIcon={<span />}
        {...props}
      />
      <Icon name='Calendar' color='gray' className={s.icon} />
    </div>
  )
}
