import classNames from 'classnames'
import { Button } from 'components/Button'
import { TextField } from 'components/TextField'
import globalMessages from 'components/globalMessages'
import { memo } from 'react'
import { useIntl } from 'react-intl'

export const Header = memo(({ single, allowClear, noSearch, filteredOptions, selectedKeys, text, textRef, onSelectAll, onReset, onTab, onEnter, onTextChange }) => {
  const intl = useIntl()
  const resetDisabled = selectedKeys.length === 0
  const handleReset = () => {
    if (resetDisabled) return
    onReset()
  }

  return (
    <div className={classNames('px-1 py-3 border-t-0 border-b border-l-0 border-r-0 border-solid border-gray-border')}>
      {!single && (
        <div className='flex justify-between mb-2 mx-2'>
          <Button size='s' name='btn-select-all' type='tertiary' className='px-0' disabled={filteredOptions.length === 0} onClick={onSelectAll}>{intl.formatMessage(globalMessages.selectAll)}</Button>
          <Button size='s' name='btn-reset' type='tertiary' className='px-0 bg-transparent' disabled={resetDisabled} onClick={handleReset}>{intl.formatMessage(globalMessages.reset)}</Button>
        </div>)}
      {single && allowClear && (
        <div className={classNames('flex justify-end', !noSearch && 'mb-4')}>
          <Button name='btn-reset' type='tertiary' className='px-0 bg-transparent' disabled={resetDisabled} onClick={handleReset}>{intl.formatMessage(globalMessages.reset)}</Button>
        </div>)}
      {!noSearch && (
        <div className='mx-2'>
          <TextField
            ref={textRef}
            name='search-select-input'
            size='m'
            placeholder={intl.formatMessage(globalMessages.search)}
            value={text}
            onChange={onTextChange}
            onKeyDown={(e) => {
              e.stopPropagation()
              if (e.key === 'Tab') {
                e.preventDefault()
                onTab()
              }
            }}
            onEnter={onEnter}
            withSearch
            debounceWait={500}
            autoFocus
          />
        </div>
      )}
    </div>
  )
})
