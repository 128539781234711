/* eslint-disable max-len */

module.exports = {
  nginxAuth: process.env.REACT_APP_NGINX_AUTH,

  api: {
    url:
      process.env.REACT_APP_API_URL ||
      'https://api.develop.review.delicious-data.com/api/v1',
    wsUrl:
      process.env.REACT_APP_WS_URL || 'wss://api.develop.review.delicious-data.com/ws',
    // oauth keys
    clientId:
      process.env.REACT_APP_CLIENT_ID || '4XnsRQTxcUp1TO3WCnOpll1THzLNmjcfqU5Keh5R',
    clientSecret:
      process.env.REACT_APP_CLIENT_SECRET ||
      'ugpViM0R29NiKi7089IlPI126gFygDkHau7Ztf11L9yuQ9c8uWIzXExhoMetIIpV1YAS9szAsjs6KmCM4IIl8Y5zFhmUDBfj07o7nUCQp2MmM66GBvn3lvSCY3z2WfPn'
  },

  intercom: {
    appId: process.env.REACT_APP_INTERCOM_APP_ID || null
  },

  agGridLicense: process.env.REACT_APP_AG_GRID_LICENSE
}
