import { divide100, getConfigDialogTitle, getPatchValues, isEmpty, multiply100 } from 'utils'
import { defaultTo, get, omit, pickBy, some } from 'lodash'
import { GenericForm } from 'components/Form'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { initialTodosSchema, inventoryGroupSchema, schedulingSchema, totalQuantileSchema } from './validationSchema'
import labelMessages from 'components/labelMessages'
import { getConfigFields } from 'components/ConfigFieldsAndColumns/Fields'
import formMessages from 'components/formMessages'

const getConfigFieldKeysByTableType = (tableType) => {
  switch (tableType) {
    case 'totalQuantile':
    default:
      return ['daily_quantile']
    case 'initialTodos':
      return ['scheduled_due_by', 'scheduled_last_until']
    case 'scheduling':
      return ['lead_quantile', 'lead_interval', 'sustain_quantile', 'sustain_interval']
    case 'inventoryGroup':
      return ['inventory_group_enabled']
  }
}

const getValidationSchema = (tableType) => {
  switch (tableType) {
    case 'totalQuantile':
      return totalQuantileSchema
    case 'initialTodos':
      return initialTodosSchema
    case 'scheduling':
      return schedulingSchema
    case 'inventoryGroup':
      return inventoryGroupSchema
  }
}

const TodoConfigForm = ({ visible, onCancel, initialValues, onSubmit, bulkEditingCount, confirmLoading, tableType, duplicate }) => {
  const bulkEditing = bulkEditingCount != null
  const intl = useIntl()

  const validationSchema = useMemo(() => getValidationSchema(tableType), [tableType])

  const initValues = bulkEditing
    ? {}
    : {
        itemOrGroup: tableType === 'inventoryGroup' ? 'group' : defaultTo(get(initialValues, 'item_tag', ''), '') === '' ? 'item' : 'group',
        rank: defaultTo(get(initialValues, 'rank', ''), ''),
        item: defaultTo(get(initialValues, 'item', ''), ''),
        item_tag: defaultTo(get(initialValues, 'item_tag', ''), ''),
        locationOrGroup: defaultTo(get(initialValues, 'location_tag', ''), '') === '' ? 'location' : 'group',
        location: defaultTo(get(initialValues, 'location', ''), ''),
        location_tag: defaultTo(get(initialValues, 'location_tag', ''), ''),
        date: initialValues && initialValues.from_date
          ? [initialValues.from_date, initialValues.to_date]
          : [],
        time: initialValues && initialValues.from_time
          ? [initialValues.from_time, initialValues.to_time]
          : [],
        from_date: defaultTo(get(initialValues, 'from_date', null), null),
        to_date: defaultTo(get(initialValues, 'to_date', null), null),
        from_time: defaultTo(get(initialValues, 'from_time', ''), ''),
        to_time: defaultTo(get(initialValues, 'to_time', ''), ''),
        weekdays: defaultTo(get(initialValues, 'weekdays', []), []).map(i => i.toString()), // AntSelect needs strings :(
        inventory_group_enabled: defaultTo(get(initialValues, 'inventory_group_enabled', null), null),
        lead_interval: defaultTo(get(initialValues, 'lead_interval', ''), ''),
        sustain_interval: defaultTo(get(initialValues, 'sustain_interval', ''), ''),
        lead_quantile: multiply100(defaultTo(get(initialValues, 'lead_quantile', null), null)),
        sustain_quantile: multiply100(defaultTo(get(initialValues, 'sustain_quantile', null), null)),
        daily_quantile: multiply100(defaultTo(get(initialValues, 'daily_quantile', null), null)),
        scheduled_due_by: defaultTo(get(initialValues, 'scheduled_due_by', ''), ''),
        scheduled_last_until: defaultTo(get(initialValues, 'scheduled_last_until', ''), '')
      }

  const handleSubmit = (values) => {
    const initValuesForSubmit = initValues && {
      ...initValues,
      daily_quantile: divide100(initValues.daily_quantile),
      lead_quantile: divide100(initValues.lead_quantile),
      sustain_quantile: divide100(initValues.sustain_quantile),
      inventory_group_enabled: initValues.inventory_group_enabled === '-' ? null : initValues.inventory_group_enabled
    }

    const modifiedValues = {
      ...values,
      daily_quantile: divide100(values.daily_quantile),
      lead_quantile: divide100(values.lead_quantile),
      sustain_quantile: divide100(values.sustain_quantile),
      item: values.itemOrGroup === 'item' ? values.item : null,
      item_tag: values.itemOrGroup === 'group' ? values.item_tag : null,
      location: values.locationOrGroup === 'location' ? values.location : null,
      location_tag: values.locationOrGroup === 'group' ? values.location_tag : null,
      ...(values.date && values.date.length === 2
        ? { from_date: values.date[0], to_date: values.date[1] }
        : { from_date: null, to_date: null }
      ),
      ...(values.time && values.time.length === 2
        ? { from_time: values.time[0], to_time: values.time[1] }
        : { from_time: '', to_time: '' }
      ),
      inventory_group_enabled: values.inventory_group_enabled === '-' ? null : values.inventory_group_enabled
    }

    const bulkEditValues = {
      ...omit(values, ['scheduled_due_by', 'scheduled_last_until']),
      ...(values.itemOrGroup === 'item' ? { item: values.item, item_tag: null } : {}),
      ...(values.itemOrGroup === 'group' ? { item_tag: values.item_tag, item: null } : {}),
      ...(values.locationOrGroup === 'location' ? { location: values.location, location_tag: null } : {}),
      ...(values.locationOrGroup === 'group' ? { location_tag: values.location_tag, location: null } : {}),
      ...(values.date !== undefined ? values.date.length === 2 ? { from_date: values.date[0], to_date: values.date[1] } : { from_date: null, to_date: null } : {}),
      // Make sure no invalid settings are set (would "delete" the configs)
      ...(values.daily_quantile !== undefined ? { daily_quantile: divide100(values.daily_quantile) || 0 } : {}),
      ...(some(['scheduled_due_by', 'scheduled_last_until'], (key) => values[key] !== undefined && values[key] !== null && values[key] !== '')
        ? {
            scheduled_due_by: values.scheduled_due_by,
            scheduled_last_until: values.scheduled_last_until
          }
        : {}),
      ...(some(['lead_quantile', 'lead_interval', 'sustain_quantile', 'sustain_interval'], (key) => values[key] !== undefined && values[key] !== null)
        ? {
            lead_quantile: divide100(values.lead_quantile),
            lead_interval: values.lead_interval,
            sustain_quantile: divide100(values.sustain_quantile),
            sustain_interval: values.sustain_interval
          }
        : {}),
      ...(values.inventory_group_enabled !== undefined ? { inventory_group_enabled: values.inventory_group_enabled === '-' ? false : values.inventory_group_enabled || false } : {})
    }

    let submitValues = bulkEditing
      ? omit(bulkEditValues, Object.keys(bulkEditValues).filter((key) => bulkEditValues[key] === undefined))
      : duplicate
        ? modifiedValues
        : getPatchValues(initValuesForSubmit, modifiedValues, [
          'rank',
          'item',
          'item_tag',
          'location',
          'location_tag',
          'from_date',
          'to_date',
          'from_time',
          'to_time',
          'weekdays',
          'inventory_group_enabled',
          'lead_interval',
          'sustain_interval',
          'lead_quantile',
          'sustain_quantile',
          'daily_quantile',
          'scheduled_due_by',
          'scheduled_last_until'
        ])

    // when duplicating, all fields are somehow set, mostly with empty strings.
    // we only want to submit fields which are not nulls, empty strings or empty arrays
    if (duplicate) {
      submitValues = pickBy(submitValues, (v) => (Array.isArray(v) && v.length > 0) || !isEmpty(v))
    }
    if (onSubmit) {
      onSubmit(submitValues)
    }
  }

  const structure = [{
    title: intl.formatMessage(labelMessages.limitingFactors),
    fields: [
      {
        type: 'number-format',
        decimalSeparator: ',',
        decimalScale: 0,
        label: intl.formatMessage(labelMessages.rank),
        name: 'rank'
      },
      ...(tableType !== 'inventoryGroup'
        ? [{
            type: 'text-select',
            required: !bulkEditing,
            allowClear: false,
            creatable: false,
            name: 'itemOrGroup',
            label: intl.formatMessage(labelMessages.items),
            options: [
              { value: 'item', label: intl.formatMessage(labelMessages.limitByArticle) },
              { value: 'group', label: intl.formatMessage(labelMessages.limitByItemTag) }
            ]
          },
          {
            type: 'item',
            variant: 'single',
            name: 'item',
            visible: (values) => values.itemOrGroup === 'item',
            filters: [{ field: 'todo_tags', operator: '!=', value: [] }]
          }]
        : []),
      {
        type: 'search-tag',
        variant: 'single',
        name: 'item_tag',
        tagType: 'item',
        visible: (values) => values.itemOrGroup === 'group',
        label: tableType === 'inventoryGroup' ? intl.formatMessage(labelMessages.items) : undefined,
        placeholder: intl.formatMessage(labelMessages.selectTag)
      },
      {
        type: 'text-select',
        required: !bulkEditing,
        allowClear: false,
        creatable: false,
        name: 'locationOrGroup',
        label: intl.formatMessage(labelMessages.locations),
        options: [
          { value: 'location', label: intl.formatMessage(labelMessages.limitByLocation) },
          { value: 'group', label: intl.formatMessage(labelMessages.limitByLocationTag) }
        ]
      },
      {
        type: 'location',
        variant: 'single',
        name: 'location',
        visible: (values) => values.locationOrGroup === 'location'
      },
      {
        type: 'search-tag',
        variant: 'single',
        name: 'location_tag',
        tagType: 'saleslocation',
        visible: (values) => values.locationOrGroup === 'group',
        placeholder: intl.formatMessage(labelMessages.selectTag)
      },
      {
        type: 'daterange',
        label: intl.formatMessage(labelMessages.dateRangeOptional),
        name: 'date',
        allowReset: true
      },
      {
        type: 'timerange',
        label: intl.formatMessage(labelMessages.timeRangeOptional),
        name: 'time'
      },
      {
        type: 'weekday',
        label: intl.formatMessage(labelMessages.weekdaysOptional),
        name: 'weekdays',
        variant: 'multi',
        withHoliday: true,
        placeholder: intl.formatMessage(formMessages.selectWeekdays)
      }
    ]
  },
  {
    title: intl.formatMessage(labelMessages.configuration),
    fields: [
      {
        type: 'error-indicator',
        errorKey: 'config'
      },
      ...getConfigFields(getConfigFieldKeysByTableType(tableType), intl, bulkEditing)
    ]
  }]

  return (
    <GenericForm
      asModal
      title={getConfigDialogTitle(intl, 'todo', tableType, initialValues != null && !duplicate)}
      visible={visible}
      structure={structure}
      validationSchema={bulkEditing ? undefined : validationSchema}
      initialValues={initValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      bulkEditing={bulkEditing}
      bulkEditingCount={bulkEditingCount}
    />
  )
}

export default TodoConfigForm
