import React from 'react'
import cn from 'classnames'
import { useIntl } from 'react-intl'
import { Tabs } from 'components/Tabs'

export default ({ fixed = true, value, options, onChange, className }) => {
  const intl = useIntl()
  return (
    <Tabs
      className={cn('chart-card-tabs', fixed && 'fixed', className)}
      activeTab={value}
      onChange={onChange}
      tabs={options
        .filter((option) => !option.hidden)
        .map(
          (option) => ({
            key: option.value,
            label: intl.formatMessage(option.label)
          })
        )}
    />
  )
}
