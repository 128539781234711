import { create } from 'zustand'

export const useFileImportStore = create((set) => ({
  file: null,
  config: {},
  setFile: (file) => set(() => ({ file })),
  setConfig: (config) => set(() => ({ config })),
  updateConfig: (key, value) => {
    set((old) => {
      let val
      switch (typeof value) {
        case 'object':
          val = {
            ...(old.config[key] || {}),
            ...value
          }
          break
        case 'function':
          val = value(old.config[key])
          break
        default:
          val = value
      }
      return {
        config: {
          ...old.config,
          [key]: val
        }
      }
    })
  },
  reset: () => set(() => ({ file: null, config: {} }))
}))
