import { useState } from 'react'
import { useUI } from 'services/UIProvider'
import { useSettingsStore } from './store/useSettingsStore'

export const useChartQueryDebugger = (identifier, queryData) => {
  const { setDebugModal } = useUI()
  const globalSettings = useSettingsStore((state) => state.globalSettings)
  const [response, setResponse] = useState(null)
  const [result, setResult] = useState(null)

  const onDebugClick =
  globalSettings.development
    ? () => {
        setDebugModal({
          title: identifier,
          payload: queryData,
          result,
          response
        })
      }
    : undefined

  return {
    onDebugClick,
    queryData,
    setResult,
    setResponse,
    response,
    result
  }
}
