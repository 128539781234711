const useHomebarHeight = () => {
  let height = null
  if (typeof document === 'object') { height = window.getComputedStyle(document.documentElement).getPropertyValue('--sab') }

  if (height) {
    const m = height.match(/[0-9\.]+/)
    if (m && m.length > 0) {
      const parsed = parseFloat(m[0])
      return parsed
    }
  }
  return height
}

export default useHomebarHeight
