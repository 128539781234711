import { CONFIDENT, NOT_CONFIDENT, VERY_CONFIDENT } from 'constants/index'
import { Badge } from 'components/Primitives'
import React from 'react'

const ConfidenceBadge = ({ confidence, small, className }) => {
  let type
  switch (confidence) {
    case VERY_CONFIDENT:
      type = 'accentHydrangea'
      break
    case CONFIDENT:
      type = 'fbSuccess'
      break
    case NOT_CONFIDENT:
      type = 'fbWarning'
      break
  }
  return (
    <Badge className={className} size={small ? 's' : 'm'} type={type}>{confidence}</Badge>
  )
}

export default ConfidenceBadge
