import { cloneElement, useMemo, useRef } from 'react'
import { Popover as AriaPopover } from 'react-aria-components'

export const Popover = ({ open, onOpenChange, children, trigger, inModal }) => {
  const triggerRef = useRef()

  const clonedTrigger = cloneElement(trigger, { ref: triggerRef })

  const handleOpenChange = (o) => {
    // we fire this with a small delay. Reason is, that if the trigger is clicked (which toggles the open state),
    // onOpenChange would fire first setting open: false which would result in the trigger directly reopening.
    window.setTimeout(() => onOpenChange(o), 50)
  }

  const portalContainer = useMemo(() => document.querySelector('#dialogPopupContainer'), [open])

  // This is kind of a hack, but seems good enough. Fixes TEC-1893. Closing a TextSelect allows you to click through to the popover and closed it. The dialog wrapper is never clicked otherwise (as far as I could tell).
  const shouldCloseOnInteractOutside = (element) => {
    if (element.getAttribute('role') === 'dialog') return false
    return true
  }

  return (
    <>
      {clonedTrigger}
      <AriaPopover
        triggerRef={triggerRef} isOpen={open} onOpenChange={handleOpenChange} UNSTABLE_portalContainer={inModal ? portalContainer : undefined} shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
      >
        <div className='rounded-lg p-0 text-base bg-white shadow'>
          {children}
        </div>
      </AriaPopover>
    </>
  )
}
