import labelMessages from 'components/labelMessages'
import { PERCENT_VALUE_FORMAT, BASE_VALUE_FORMAT, OVERPLAN_TYPES, OVERPLAN_TYPES_MESSAGES } from 'constants/index'

const getField = (key, intl, bulkEditing) => {
  switch (key) {
    case 'overplan_type':
      return {
        type: 'text-select',
        label: intl.formatMessage(labelMessages.type),
        name: 'overplan_type',
        required: !bulkEditing,
        allowClear: false,
        creatable: false,
        options: [
          { value: OVERPLAN_TYPES.QUANTILE, label: intl.formatMessage(OVERPLAN_TYPES_MESSAGES[OVERPLAN_TYPES.QUANTILE]) },
          { value: OVERPLAN_TYPES.ABSOLUTE_VALUE, label: intl.formatMessage(OVERPLAN_TYPES_MESSAGES[OVERPLAN_TYPES.ABSOLUTE_VALUE]) },
          { value: OVERPLAN_TYPES.SALES_PERCENTAGE, label: intl.formatMessage(OVERPLAN_TYPES_MESSAGES[OVERPLAN_TYPES.SALES_PERCENTAGE]) },
          { value: OVERPLAN_TYPES.ORDER_PERCENTAGE, label: intl.formatMessage(OVERPLAN_TYPES_MESSAGES[OVERPLAN_TYPES.ORDER_PERCENTAGE]) }
        ]
      }
    case 'overplan_value_1':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.value),
        name: 'overplan_value',
        props: (values) => {
          switch (values.overplan_type) {
            case OVERPLAN_TYPES.ABSOLUTE_VALUE:
              return {
                ...BASE_VALUE_FORMAT,
                fixedDecimalScale: false,
                decimalScale: 2,
                displayType: 'input'
              }
            case OVERPLAN_TYPES.QUANTILE:
              return {
                ...PERCENT_VALUE_FORMAT,
                displayType: 'input',
                helpText: intl.formatMessage({ id: 'OrderConfigTable.overplanTypesQuantile', defaultMessage: 'Enter a quantile between 0% and 100%' })
              }
            case OVERPLAN_TYPES.SALES_PERCENTAGE:
              return {
                ...PERCENT_VALUE_FORMAT,
                displayType: 'input'
              }
            case OVERPLAN_TYPES.ORDER_PERCENTAGE:
              return {
                ...PERCENT_VALUE_FORMAT,
                displayType: 'input',
                helpText: intl.formatMessage({ id: 'OrderConfigTable.overplanTypesOrderPercentage', defaultMessage: 'Enter a relative target returns value between 0% and 50%' })
              }
          }
        },
        visible: (values) => [OVERPLAN_TYPES.ABSOLUTE_VALUE, OVERPLAN_TYPES.QUANTILE, OVERPLAN_TYPES.SALES_PERCENTAGE, OVERPLAN_TYPES.ORDER_PERCENTAGE].includes(values.overplan_type)
      }
    case 'order_factor':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.orderFactor),
        name: 'order_factor',
        props: () => ({
          ...PERCENT_VALUE_FORMAT,
          displayType: 'input'
        })
      }
    case 'fulfillment_delay':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.fulfillmentDelay) + ' (' + intl.formatMessage(labelMessages.inDays) + ')',
        name: 'fulfillment_delay',
        decimalSeparator: ',',
        decimalScale: 0,
        fixedDecimalScale: false,
        displayType: 'input',
        thousandSeparator: '.'
      }
    case 'daily_quantile':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.dailyQuantile),
        name: 'daily_quantile',
        props: () => ({
          ...PERCENT_VALUE_FORMAT,
          displayType: 'input'
        })
      }
    case 'scheduled_due_by':
      return {
        type: 'text',
        label: intl.formatMessage(labelMessages.scheduledDueBy),
        name: 'scheduled_due_by'
      }
    case 'scheduled_last_until':
      return {
        type: 'text',
        label: intl.formatMessage(labelMessages.scheduledLastUntil),
        name: 'scheduled_last_until'
      }
    case 'lead_interval':
      return {
        type: 'number-format',
        decimalSeparator: ',',
        decimalScale: 0,
        fixedDecimalScale: false,
        label: intl.formatMessage(labelMessages.leadInterval),
        name: 'lead_interval'
      }
    case 'lead_quantile':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.leadQuantile),
        name: 'lead_quantile',
        props: () => ({
          ...PERCENT_VALUE_FORMAT,
          displayType: 'input'
        })
      }
    case 'sustain_interval':
      return {
        type: 'number-format',
        decimalSeparator: ',',
        decimalScale: 0,
        fixedDecimalScale: false,
        label: intl.formatMessage(labelMessages.sustainInterval),
        name: 'sustain_interval'
      }
    case 'sustain_quantile':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.sustainQuantile),
        name: 'sustain_quantile',
        props: () => ({
          ...PERCENT_VALUE_FORMAT,
          displayType: 'input'
        })
      }
    case 'inventory_group_enabled':
      return {
        type: 'boolean',
        label: intl.formatMessage(labelMessages.inventoryGroupEnabled),
        name: 'inventory_group_enabled',
        nullable: bulkEditing
      }
    case 'orders_enabled':
      return {
        type: 'boolean',
        label: intl.formatMessage(labelMessages.order),
        name: 'orders_enabled',
        nullable: bulkEditing
      }
    case 'multiday_tracking':
      return {
        type: 'boolean',
        label: intl.formatMessage(labelMessages.multiDayTracking),
        name: 'multiday_tracking',
        nullable: bulkEditing
      }
    case 'inventory_tracking':
      return {
        type: 'boolean',
        label: intl.formatMessage(labelMessages.inventoryTracking),
        name: 'inventory_tracking',
        nullable: bulkEditing
      }
    case 'reference_item':
      return {
        type: 'item',
        variant: 'single',
        name: 'reference_item',
        label: intl.formatMessage(labelMessages.referenceItem)
      }
    case 'batch_rounding_cutoff':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.batchRoundingCutoff),
        name: 'batch_rounding_cutoff',
        helpText: intl.formatMessage({ id: 'OrderConfigTable.batchRoundingConfigHint', defaultMessage: '0% = always rounding up, 50% = commercial rounding, 100% = always rounding down.' }),
        props: () => ({
          ...PERCENT_VALUE_FORMAT,
          displayType: 'input'
        })
      }
    case 'min_amount_rounding_cutoff':
      return {
        type: 'number-format',
        label: intl.formatMessage(labelMessages.minAmountRoundingCutoff),
        name: 'min_amount_rounding_cutoff',
        props: () => ({
          ...PERCENT_VALUE_FORMAT,
          displayType: 'input'
        })
      }
  }
}

export const getConfigFields = (keys, intl, bulkEditing) =>
  keys.map((key) => getField(key, intl, bulkEditing))
