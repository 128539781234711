import { create } from 'zustand'
import { uniq } from 'lodash'

const defaultInitialization = [
  'user',
  'locations'
]

export const useInitializationStore = create((set) => ({
  toInitialize: defaultInitialization,
  initialized: [],
  finished: false,
  loadingDuration: null,
  addInitialization: (key, logger) => set((state) => {
    const toInitialize = uniq([...state.toInitialize, key])
    if (logger) {
      logger(`Loading Screen: Added ${key} to initialization queue. Now expecting ${toInitialize.length} [${toInitialize.toString()}]`)
    }
    return ({ toInitialize })
  }),
  addLoaded: (key, duration, logger) => set((state) => {
    if (state.initialized.includes(key)) {
      return state
    }
    const initialized = uniq([...state.initialized, key])
    const allLoaded = initialized.length >= state.toInitialize.length

    if (logger) {
      logger(`Loading Screen: Loaded ${key} [${initialized.length}/${state.toInitialize.length}]${duration != null ? ` (${duration}ms)` : ''}`)
    }

    return {
      initialized: initialized,
      finished: allLoaded
    }
  }),
  reset: (isOperator, fullReset) => set((state) => ({
    toInitialize: isOperator ? [...defaultInitialization, 'customers'] : defaultInitialization,
    initialized: fullReset ? [] : isOperator ? ['user', 'customers'] : ['user'],
    finished: false
  })),
  setFinished: (finished) => set(() => ({ finished })),
  setLoadingDuration: (loadingDuration) => set(() => ({ loadingDuration }))
}))
