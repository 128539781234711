import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

export const RadioButton = ({ value, options, onChange, className, right }) => {
  const intl = useIntl()

  return (
    <span className={classNames('h-7 relative z-0 inline-flex rounded-md', { 'ml-auto': right })}>
      {options.map((option, idx) => (
        <button
          key={option.value}
          type='button'
          className={classNames('cursor-pointer relative inline-flex items-center justify-center px-4 py-1.5 mobile:px-2 text-sm font-bold border border-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0 min-w-4 mobile:min-w-0', {
            'rounded-l-md': idx === 0,
            '-ml-px': idx !== 0,
            'rounded-r-md': idx === options.length - 1,
            'text-gray-light bg-gray-lighter': option.value !== value,
            'text-primary-link bg-primary-lightest': option.value === value
          }, className)}
          onClick={() => onChange(option.value)}
        >
          {intl.formatMessage(option.label)}
        </button>)
      )}
    </span>
  )
}
