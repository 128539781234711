import React from 'react'
import cn from 'classnames'
import s from './VarianceChart.module.scss'

const halfBarWidth = 50 // %

// bar should not be larger than half bar width
// values are in range -100 ~ +100
export const getProgressWidth = (value) =>
  value / 2 < halfBarWidth ? value / 2 : halfBarWidth

const getBarType = (value) => {
  if (value < 20) {
    return s.small
  } else if (value >= 20 && value < 50) {
    return s.middle
  } else if (value >= 50) {
    return s.big
  }
}

const VarianceChart = ({ className, absoluteDifference }) => {
  const isPositive = absoluteDifference >= 0
  const absValue = Math.abs(absoluteDifference)
  const barType = getBarType(absValue)
  const progressBarWidth = getProgressWidth(absValue)

  return (
    <div className={cn(className, s.wrapper)}>
      <div
        className={cn(s.bar, barType)}
        style={{
          width: `${progressBarWidth}%`,
          marginLeft: isPositive
            ? `${halfBarWidth}%`
            : `${halfBarWidth - progressBarWidth}%`
        }}
      >
        {absoluteDifference != null && (
          <div
            className={cn(
              s.value,
              absValue < 50 && (isPositive ? s.positive : s.negative)
            )}
          >
            {absoluteDifference}
          </div>
        )}
      </div>
    </div>
  )
}

export default VarianceChart
