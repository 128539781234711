import { FormattedMessage } from 'react-intl'
import { useDebugStore } from 'hooks/store/useDebugStore'

export const DebugBanner = () => {
  const debugMode = useDebugStore((state) => state.debugMode)
  if (!debugMode) return null

  return (
    <div className='fixed top-0 left-0 w-full py-1 text-sm font-bold text-center shadow bg-feedback-error-lighter z-100 text-feedback-error'>
      <FormattedMessage id='debugBanner.text' defaultMessage='Volatile Mode active. Your setting changes will not be synced and are reset on page refresh.' />
    </div>
  )
}
