import { Dropdown, Menu } from 'antd'

import { FormattedMessage } from 'react-intl'
import globalMessages from 'components/globalMessages'
import { Icon } from 'components/Primitives'

export const OptionMenuButton = ({ onAction, inline }) => {
  const onMenuClick = (item) => {
    item.domEvent.preventDefault()
    item.domEvent.stopPropagation()
    if (!onAction) return

    switch (item.key) {
      case 'edit':
        onAction('edit')
        break
      case 'delete':
        onAction('delete')
        break
      default:
        break
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key='delete' onClick={onMenuClick}>
        <a className='flex items-center text-feedback-error'><Icon name='TrashCan' className='w-4 h-4 mr-1' /> <FormattedMessage {...globalMessages.remove} /></a>
      </Menu.Item>
      <Menu.Item key='edit' onClick={onMenuClick}>
        <a className='flex items-center'><Icon name='Edit' className='w-4 h-4 mr-1' /> <FormattedMessage id='global.edit' defaultMessage='Edit' /></a>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown className={inline ? 'flex-shrink-0 hidden h-4 pr-0 leading-4 text-current cursor-pointer group-hover:block' : null} overlay={menu} trigger={['click']}>
        <button
          className='cursor-pointer group' type='button' onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Icon name='OverflowMenuVertical' className='w-4 h-4' />
        </button>
      </Dropdown>
    </>
  )
}
