import { useAppContext } from 'services/context'
import { useGeneralStore } from './store/useGeneralStore'

/** Returns everything needed for making authenticated queries to the backend */
export const useQueryFetcher = () => {
  const { fetch } = useAppContext()
  const token = useGeneralStore(state => state.token)

  return { fetch, token }
}
