import { useLocation } from 'react-router-dom'
import { useCustomerType } from './useCustomerType'
import { CUSTOMERS_ROUTE, DASHBOARD_ROUTE, FOODWASTE_ROUTE, FORECASTS_ROUTE, ORDERS_ROUTE, ROOT_ROUTE } from 'routes'
import { BAKERY } from 'constants/index'
import { usePermissions } from './usePermissions'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { usePageSettings } from './usePageSettings'
import { useCallback, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { getInternalPathName } from 'utils'

// TODO: move imports to constants/index
export const Filter = {
  LOCATIONS: 'locations',
  LOCATION: 'location',
  DATE_RANGE: 'dateRange',
  COMPARING_PERIOD: 'comparingPeriod',
  WEEKDAY: 'weekday',
  ITEM: 'item',
  ITEM_TAG: 'itemTag',
  ITEM_GROUP_1: 'itemGroup1',
  ITEM_GROUP_2: 'itemGroup2',
  OFFERING_GROUP_1: 'offeringGroup1',
  OFFERING_GROUP_2: 'offeringGroup2',
  OFFERING_GROUP_1_NAMES: 'offeringGroup1Names',
  OFFERING_GROUP_2_NAMES: 'offeringGroup2Names',
  ORDER_ACTIVE: 'orderActive',
  FOODWASTE_CATEGORY: 'foodwasteCategory',
  STATUS: 'status',
  AS_OF: 'as_of'
}

// This is the mapping from the filter key to the setting.
// Needs some general approach, but at the moment it's only used to cleanup allowed user settings.
export const FilterSettingsMap = {
  [DASHBOARD_ROUTE]: {
    [Filter.ITEM_GROUP_1]: 'itemCategory1',
    [Filter.ITEM_GROUP_2]: 'itemCategory2',
    [Filter.OFFERING_GROUP_1]: 'og1',
    [Filter.OFFERING_GROUP_2]: 'og2',
    [Filter.OFFERING_GROUP_1_NAMES]: 'og1Names',
    [Filter.OFFERING_GROUP_2_NAMES]: 'og2Names',
    [Filter.FOODWASTE_CATEGORY]: 'wasteCategory',
    [Filter.ITEM]: 'itemName',
    [Filter.WEEKDAY]: 'weekdays',
    [Filter.LOCATIONS]: 'location'
  },
  [FORECASTS_ROUTE]: {
    [Filter.ITEM_GROUP_1]: 'itemCategory1',
    [Filter.ITEM_GROUP_2]: 'itemCategory2',
    [Filter.OFFERING_GROUP_1]: 'og1',
    [Filter.OFFERING_GROUP_2]: 'og2',
    [Filter.ITEM]: 'itemName'
  },
  [FOODWASTE_ROUTE]: {
    [Filter.LOCATIONS]: 'location',
    [Filter.FOODWASTE_CATEGORY]: 'wasteCategory'
  },
  [ORDERS_ROUTE]: {
    [Filter.LOCATIONS]: 'location',
    [Filter.ITEM_GROUP_1]: 'item__category_1',
    [Filter.ITEM_GROUP_2]: 'item__category_2',
    [Filter.ORDER_ACTIVE]: 'orders_enabled'
  }
}

export const getMappedFilterSetting = (key, pathname) => {
  const settings = FilterSettingsMap[pathname]
  return settings ? settings[key] || key : key
}

export const getAvailableFilterKeys = (pathname, customerType, gridView, permissions) => {
  let base
  switch (pathname) {
    case ROOT_ROUTE:
    case DASHBOARD_ROUTE:
      base = [
        Filter.LOCATIONS,
        Filter.DATE_RANGE,
        Filter.WEEKDAY,
        ...(true || permissions.hasProPlan ? [Filter.COMPARING_PERIOD] : []) // FIXME: permission to use comparing period
      ]
      if (customerType === BAKERY) {
        return [
          ...base,
          Filter.ITEM_GROUP_1,
          Filter.ITEM_GROUP_2,
          Filter.ITEM,
          Filter.ITEM_TAG
          // Filter.ORDER_ACTIVE // FIXME: Not finished
        ]
      } else {
        return [
          ...base,
          Filter.OFFERING_GROUP_1_NAMES,
          Filter.OFFERING_GROUP_2_NAMES,
          Filter.ITEM,
          Filter.ITEM_TAG,
          ...(permissions.foodwaste ? [Filter.FOODWASTE_CATEGORY] : [])
        ]
      }
    case FORECASTS_ROUTE:
      base = [
        Filter.LOCATION,
        Filter.DATE_RANGE
        // Filter.WEEKDAY, // FIXME: Not finished
      ]
      if (gridView) {
        if (customerType === BAKERY) {
          return [
            ...base,
            Filter.ITEM_GROUP_1,
            Filter.ITEM_GROUP_2,
            Filter.ITEM
          ]
        } else {
          return [
            ...base,
            Filter.OFFERING_GROUP_1,
            Filter.OFFERING_GROUP_2,
            Filter.ITEM
          ]
        }
      }
      return base
    case FOODWASTE_ROUTE:
      return [
        Filter.LOCATIONS,
        Filter.DATE_RANGE,
        Filter.FOODWASTE_CATEGORY,
        Filter.STATUS
      ]
    case ORDERS_ROUTE:
      return [
        Filter.LOCATIONS,
        Filter.DATE_RANGE,
        Filter.AS_OF,
        // Filter.WEEKDAY, // TODO: Not yet implemented for Order
        Filter.ITEM_GROUP_1,
        Filter.ITEM_GROUP_2,
        Filter.ITEM,
        Filter.ORDER_ACTIVE
      ]
    case CUSTOMERS_ROUTE:
      return []
  }
}

export const getFilterProps = (key, pathname, intl, rangePresets, gridView, configurator) => {
  switch (key) {
    case Filter.DATE_RANGE:
      let props = {
        key,
        props: {
          presets: rangePresets,
          weekly: gridView,
          allowReset: pathname !== FORECASTS_ROUTE && pathname !== ORDERS_ROUTE,
          ...((pathname === DASHBOARD_ROUTE || pathname === ROOT_ROUTE) && !configurator ? { withUnsetPreset: true } : undefined)
        }
      }
      if (pathname === FOODWASTE_ROUTE) {
        props = {
          ...props,
          allowReset: true
        }
      }
      if (pathname === ORDERS_ROUTE) {
        props = {
          ...props,
          placeholder: intl.formatMessage(labelMessages.delivery),
          prefixPlaceholder: true
        }
      }
      return props
    case Filter.FOODWASTE_CATEGORY:
      if (pathname === FOODWASTE_ROUTE) {
        return {
          key,
          props: {
            onlyOwn: true
          }
        }
      }
      return key
    case Filter.ITEM_GROUP_1:
    case Filter.ITEM_GROUP_2:
      if (pathname === ORDERS_ROUTE) {
        return {
          key,
          props: {
            from: 'Order'
          }
        }
      }
      return key
    default:
      return key
  }
}

/**
 * Return the set of available filters for the current page and customer type
 */
export const useAvailableFilters = (options) => {
  const opts = {
    onlyKeys: false,
    ...options
  }
  const intl = useIntl()
  const { pathname: corePathName } = useLocation()
  const pathname = getInternalPathName(corePathName)
  const customerType = useCustomerType()
  const permissions = usePermissions()
  const { settings, rangePresets } = usePageSettings()
  const gridView = settings.view === 'grid'

  const getFilterSet = useCallback(() => {
    const availableFilters = getAvailableFilterKeys(pathname, customerType, gridView, permissions)
    if (opts.onlyKeys) return availableFilters
    return availableFilters.map(key => getFilterProps(key, pathname, intl, rangePresets, gridView))
  }, [pathname, customerType, gridView, permissions, rangePresets, opts.onlyKeys])

  const [storedSet, setStoredSet] = useState(getFilterSet())

  useEffect(() => {
    const f = getFilterSet()
    if (!isEqual(storedSet, f)) {
      setStoredSet(f)
    }
  }, [pathname, customerType, gridView, permissions])

  return storedSet
}
