import * as RadixCheckbox from '@radix-ui/react-checkbox'
import { CheckboxItem, ItemIndicator } from '@radix-ui/react-dropdown-menu'
import s from './Checkbox.module.scss'
import { Icon, Text } from '../'
import classNames from 'classnames'
import TagBadge from 'components/Badge/TagBadge'
import { forwardRef } from 'react'

const SIZES = ['s', 'm']

export const Checkbox = forwardRef(({ size = 'm', id, label, disabled, checked, onCheckedChange, className, type, color, forDropdown, onSelect, asTag, icon, onPointerMove, name, ...props }, forwardedRef) => {
  const Root = forDropdown ? CheckboxItem : RadixCheckbox.Root
  const Indicator = forDropdown ? ItemIndicator : RadixCheckbox.Indicator

  if (!SIZES.includes(size)) {
    throw new Error(`Checkbox size must be one of ${SIZES.join(', ')}`)
  }
  return (
    <div className={classNames(className, s.root, s[size], disabled ? s.disabled : null, checked === 'indeterminate' && s.indeterminate)} {...props}>
      <div className={s.boxContainer}>
        <Root
          id={id}
          ref={forwardedRef}
          className={s.checkbox}
          checked={checked}
          onCheckedChange={onCheckedChange}
          onSelect={onSelect}
          onPointerMove={onPointerMove}
          onPointerEnter={onPointerMove}
          onPointerLeave={onPointerMove}
          disabled={disabled}
          data-name={name}
        >
          <Indicator asChild className={s.indicator}>
            <div>
              {checked === true ? <Icon name='Checkmark' className='w-full h-full' omitFill /> : <div />}
            </div>
          </Indicator>
        </Root>
      </div>
      {icon && <Icon className={s.icon} size='m' name={icon} color={color || 'gray'} />}
      {label && (asTag ? <TagBadge noTooltip hex={color} className='pointer-events-none'>{label}</TagBadge> : <Text color={disabled ? 'disabled-10' : color || 'brand'} type={type || 'headline'} size={size === 'm' ? 's' : 'xs'} className={s.label} htmlFor={id}>{label}</Text>)}
    </div>
  )
})
