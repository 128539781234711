import { uniqBy } from 'lodash'

import queryString from 'query-string'
import { useLocations } from './useLocations'
import { useNetwork } from 'ahooks'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'

export const useOfferingGroups = (options) => {
  const networkState = useNetwork()
  const opts = {
    enabled: true,
    offeringGroupNo: 1,
    locations: [],
    range: null,
    ...options
  }

  const { fetch, token } = useQueryFetcher()
  const { data: locationsCollection } = useLocations()
  const selectedCustomer = useSelectedCustomer()

  // FIXME: Implement filtering by range and OG1, see https://app.clickup.com/t/2z4jhn7

  const qOpts = {
    placeholderData: keepPreviousData,
    enabled: opts.enabled && networkState.online && selectedCustomer != null && locationsCollection != null && locationsCollection.length > 0
  }

  const isFirstGroup = opts.offeringGroupNo !== 2

  const joinedLocations = Array.isArray(opts.locations) ? opts.locations.join(',') : opts.locations

  const { data, status, error, isFetching, fetchStatus, isPending } = useQuery({
    queryKey: [isFirstGroup ? 'menulines' : 'components', { selectedCustomer, joinedLocations }],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/${isFirstGroup ? 'menulines/list' : 'components/list'}/?${queryString.stringify({
          customer: selectedCustomer,
          page_size: 9999
        })}`,
        {
          method: 'POST',
          body: {
            query_params: {
              sales_locations: joinedLocations,
              forecasts: true
            }
          },
          token,
          success: (res) => {
            // we get plenty of duplicates (same name, different id)
            // so we have to make them uniq
            const data = uniqBy(res.results.map(i => ({
              ...i,
              name: i.name == null ? 'null' : i.name
            })), i => i.name)

            /**
             * The backend returns a single 'null' menuline even
             * if there are no menulines for that location.
             * We have to filter that out.
             **/
            resolve(data.length === 1 && data[0].name === 'null' ? [] : data)
          },
          failure: (err) => reject(err)
        }
      )
    }),
    ...qOpts
  })
  return { data, status, error, isFetching, isPending, fetchStatus }
}
