import { GregorianCalendar } from '@internationalized/date'

export const createCalendar = (identifier) => {
  switch (identifier) {
    case 'gregory':
      return new GregorianCalendar()
    default:
      throw new Error(`Unsupported calendar ${identifier}`)
  }
}
