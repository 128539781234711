import { create } from 'zustand'
import { v4 as uuidv4 } from 'uuid'

export const useDashboardStore = create((set) => ({
  breakpoint: null,
  cachedItems: null,
  allDashboards: null,
  currentDashboard: null,
  layoutId: uuidv4(),
  setBreakpoint: (breakpoint) => set(() => ({ breakpoint })),
  setCachedItems: (cachedItems) => set(() => ({ cachedItems })),
  setCurrentDashboard: (currentDashboard) => set(() => ({ currentDashboard })),
  setAllDashboards: (allDashboards) => set(() => ({ allDashboards })),
  setLayoutId: (layoutId) => set(() => ({ layoutId }))
}))
