import React from 'react'
import globalMessages from 'components/globalMessages'
import { useIntl } from 'react-intl'
import { BAKERY, EVENT_TYPES, STUDENT_UNION } from 'constants/index'
import { useCustomerType } from 'hooks/useCustomerType'
import labelMessages from 'components/labelMessages'
import { SearchSelect } from 'components/SearchSelect'

export default ({ value, onChange, flat, medium, placeholder, className, gray }) => {
  const intl = useIntl()
  const customerType = useCustomerType()

  const options = [
    ...(customerType === STUDENT_UNION ? [{ key: EVENT_TYPES.SEMESTER_BREAK, label: intl.formatMessage(globalMessages.eventSemesterBreak) }] : []),
    ...(customerType === BAKERY ? [{ key: EVENT_TYPES.PROMOTION, label: intl.formatMessage(globalMessages.eventPromotion) }] : []),
    { key: EVENT_TYPES.OTHER, label: intl.formatMessage(globalMessages.eventOther) }
  ]

  return (
    <SearchSelect
      role='event-type-picker'
      className={className}
      type={flat ? undefined : 'round'}
      size={medium ? undefined : 'small'}
      placeholder={placeholder || intl.formatMessage(labelMessages.eventCategory)}
      value={value}
      onChange={onChange}
      optionsLabel={intl.formatMessage(labelMessages.eventCategories)}
      graybg={gray}
      options={options}
    />
  )
}
