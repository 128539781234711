import { useDebounceEffect, usePrevious } from 'ahooks'
import { GenericForm } from 'components/Form'
import labelMessages from 'components/labelMessages'
import titleMessages from 'components/titleMessages'
import { isBefore, isAfter, startOfDay } from 'date-fns'
import { useAdminData, usePermissions, usePageSettings } from 'hooks'
import { useOwnFoodwasteConfigs, useWasteMeasurements } from 'hooks/queries'
import { groupBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useIntercom } from 'react-use-intercom'
import message from 'services/message'
import { formatISODate } from 'utils/datetime'
import * as yup from 'yup'
import m from 'moment'
import { invalidateFoodwasteQueries } from './utils'
import { useQueryClient } from '@tanstack/react-query'

const schema = yup.object().shape({
  date: yup.string().required(),
  sales_location: yup.string().required(),
  category: yup.string().required()
})

export const AddMeasurementForm = ({ visible, onCancel, columns }) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { isSuperUser } = usePermissions()
  const { trackEvent } = useIntercom()
  const { settings } = usePageSettings()

  const { data } = useOwnFoodwasteConfigs({ enabled: !isSuperUser && visible })
  const { data: allConfigs } = useAdminData('foodwasteConfigs', { enabled: isSuperUser && visible, filters: { page: 1, pageSize: 9999, is_active: true } })
  const [initValues, setInitValues] = useState({
    date: null,
    sales_location: null,
    category: null
  })

  const hasData = (isSuperUser && allConfigs.items) || (!isSuperUser && data)

  const groupedConfigs = useMemo(() => {
    if (!hasData) return null
    return groupBy(isSuperUser ? allConfigs.items : data, 'sales_location')
  }, [data, allConfigs.items])

  const [values, setValues] = useState(initValues)
  const previousValues = usePrevious(values)

  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [checkDateRange, setCheckDateRange] = useState(null)

  const { data: wasteData, status, add, invalidate } = useWasteMeasurements({
    enabled: checkDateRange != null && values.sales_location !== null && values.category !== null,
    filters: {
      open: true,
      sales_locations: [values.sales_location],
      wasteCategory: [values.category],
      range: checkDateRange
    },
    fields: columns
  })

  const existingMeasurementDays = useMemo(() => {
    if (!wasteData) return []

    return wasteData.map((item) => item.date)
  }, [wasteData])

  useEffect(() => {
    if (!previousValues || !values) return
    if (previousValues.sales_location !== values.sales_location) {
      setInitValues({ ...values, category: null, date: null })
    }
    if (previousValues.category !== values.category) {
      setInitValues({ ...values, date: null })
    }
  }, [values, previousValues])

  const handleSubmit = (values) => {
    add.mutate({
      ...values,
      sales_location: parseInt(values.sales_location, 10),
      date: formatISODate(values.date._d)
    })
      .then(() => {
        onCancel()
        invalidate(true)
        invalidateFoodwasteQueries(queryClient)
        message.success(intl.formatMessage({ id: 'AddMeasurementForm.success', defaultMessage: 'The open measurement was created successfully.' }))
      })
    trackEvent('added_food_waste_record')
  }

  const allowedLocations = useMemo(() => {
    if (!groupedConfigs) return []
    return Object.keys(groupedConfigs).map(i => parseInt(i, 10))
  }, [groupedConfigs])

  // autoselect the current location from view settings
  useEffect(() => {
    const intLoc = settings.location ? parseInt(settings.location, 10) : null
    if (!visible || !intLoc || initValues.sales_location === settings.location) return
    if (allowedLocations.includes(intLoc)) {
      setInitValues({
        ...initValues,
        sales_location: intLoc.toString()
      })
    }
  }, [visible, allowedLocations, settings.location])

  const allowedCategories = useMemo(() => {
    if (values.sales_location == null) return []
    return groupedConfigs[values.sales_location]
      ? groupedConfigs[values.sales_location].map((config) => config.category)
      : []
  }, [values])

  useEffect(() => {
    if (!visible || values.date != null || values.sales_location == null || values.category == null) return
    const today = formatISODate(new Date())

    if (!existingMeasurementDays.includes(today)) {
      setInitValues({
        ...values,
        date: m()
      })
    }
  }, [existingMeasurementDays, visible])

  const isDateDisabled = (date) => {
    const s = startOfDay(date._d)
    if (!minDate) setMinDate(s)
    if (!maxDate) setMaxDate(s)
    if (minDate && isBefore(s, minDate)) {
      setMinDate(s)
    }
    if (maxDate && isAfter(s, maxDate)) {
      setMaxDate(s)
    }
    const isoDate = formatISODate(s)
    return (existingMeasurementDays.includes(isoDate))
  }

  useDebounceEffect(() => {
    setCheckDateRange([
      formatISODate(minDate),
      formatISODate(maxDate)
    ])
  }, [minDate, maxDate], { wait: 200 })

  const isValid = useMemo(() => {
    if (status !== 'success') return false
    if (!values) return false
    if (values.sales_location == null) return false
    if (values.category == null) return false
    if (values.date == null) return false
    if (existingMeasurementDays.includes(formatISODate(values.date._d))) return false
    return true
  }, [values, existingMeasurementDays])

  const structure = [{
    fields: [
      {
        type: 'location',
        label: intl.formatMessage(labelMessages.location),
        name: 'sales_location',
        only: allowedLocations
      },
      {
        type: 'foodwaste-category',
        label: intl.formatMessage(labelMessages.wasteCategory),
        name: 'category',
        disabled: values.sales_location == null,
        only: allowedCategories,
        valueAsId: true,
        readonly: true
      },
      {
        type: 'date',
        label: intl.formatMessage(labelMessages.date),
        name: 'date',
        disabled: values.category == null || values.sales_location == null,
        disabledDate: isDateDisabled,
        onPanelChange: (value, mode) => { console.log('onPanelChange', value, mode) }
      }
    ]
  }]

  return (
    <GenericForm
      asModal
      title={intl.formatMessage(titleMessages.addOpenMeasurement)}
      visible={visible}
      structure={structure}
      validationSchema={schema}
      initialValues={initValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      confirmLoading={add.isPending}
      captureRender={({ values }) => setValues(values)}
      okDisabled={!isValid}
      reinitialize
      loading={!hasData}
    />
  )
}
