import { some, find } from 'lodash'
import { DEFAULT_PAGE } from './dashbord'
import { getByPath } from 'utils'

const hasCustomViews = (subSettings) =>
  subSettings && subSettings.colStates && subSettings.colStates.filter(s => s.id !== 'default').length > 0

const getChartsAmount = (settings, dashboardBeta) => {
  if (!dashboardBeta) {
    return settings.dashboard ? settings.dashboard.length : null
  }
  const pages = getByPath(settings, 'dashboardPages', null)
  const page = pages ? find(pages, { name: DEFAULT_PAGE }) : null
  return page ? page.reports.length : null
}

export const getSettingsStatistics = (settings, globalSettings, customerSettings) => {
  if (!settings || !globalSettings || Object.keys(settings).length === 0 || !customerSettings) return null

  const dashboardBeta = customerSettings && customerSettings.dashboardPreview === true && globalSettings.dashboardPreview === true

  return {
    charts_amount: getChartsAmount(settings, dashboardBeta),
    // charts_placed: settings.dashboard ? settings.dashboard.map(chart => chart.i).sort().join(',') : null,
    charts_refresh_interval: settings.dashboardSettings && settings.dashboardSettings.refreshInterval !== 'off' ? settings.dashboardSettings.refreshInterval : null,
    uses_custom_grid_views: some([
      hasCustomViews(settings.forecastFilters),
      hasCustomViews(settings.foodwasteFilters)
    ]),
    dashboardVersion: dashboardBeta ? 2 : 1
  }
}
