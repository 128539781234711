import classNames from 'classnames'
import { useUserSearch } from 'hooks/queries/useUserSearch'
import { filter } from 'lodash'
import AsyncSelect from 'react-select/async'
import { getUserName } from 'utils'
import { components } from 'react-select'
import { Badge, Icon, Text } from 'components/Primitives'
// This component shares stuff with TextSelect
import s from 'components/TextSelect/TextSelect.module.scss'
import { FormattedMessage } from 'react-intl'
import { Option } from './Option'
import { useEffect, useState } from 'react'
import { customClassNames, customStyles } from 'components/TextSelect'
import { useTags } from 'hooks'
import { MultiValueRemove } from 'components/Tags/MultiValueRemove'
import { ACCENT_COLORS } from 'constants/index'

const ValueContainer = (props) => (
  <div role='valueContainer' className='flex-1'>
    <components.ValueContainer {...props} />
  </div>
)

const SingleValue = (props) => {
  return <components.SingleValue className={s.singleValue} {...props} />
}

const IndicatorSeparator = () => null

const DropdownIndicator = (props) => {
  const open = props.selectProps.menuIsOpen
  return !props.selectProps.noDropdownIcon && (
    <components.DropdownIndicator {...props}>
      <Icon name={props.selectProps.dropdownIcon || 'ChevronDown'} size={(props.selectProps.dropdownIcon && 's') || undefined} className={classNames(s.dropdownIndicator, open && !props.selectProps.noIconAnimation && s.dropdownIndicatorOpen)} />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Icon name='CloseFilled' size='xxs' />
  </components.ClearIndicator>
)

const LoadingMessage = (props) => (
  <components.LoadingMessage {...props}>
    <Text color='gray'>
      <FormattedMessage id='select.loading' defaultMessage='Loading...' />
    </Text>
  </components.LoadingMessage>
)

const NoOptionsMessage = (props) => {
  return (
    <div>
      <components.NoOptionsMessage {...props}>
        <Text color='gray'>
          <FormattedMessage
            id='select.noResults'
            defaultMessage='No results found'
          />
        </Text>
      </components.NoOptionsMessage>
    </div>
  )
}

const MultiValueLabel = (props) => {
  const { children, data } = props
  return (
    <Badge
      withAppendix
      noTooltip
      hex={data.color}
      id={data.id}
    >
      {data.label || data.name || children}
    </Badge>
  )
}

const getTagOptions = (tags) => {
  if (!tags) return []
  return tags.map(t => ({
    label: t.name,
    value: t.id,
    item: t,
    color: t.color
  }))
}

export const UserSearchPicker = ({ omit, omitTags, ...rest }) => {
  const userSearch = useUserSearch()
  const [allOptions, setAllOptions] = useState(null)
  const [defaultOptions, setDefaultOptions] = useState(null)

  const { tags } = useTags({ type: 'user', remove_orphans: true })

  const performSearch = (value) => {
    return userSearch.mutateAsync(value).then((users) => {
      const options = users.map(u => ({
        label: getUserName(u),
        value: u.id,
        item: u,
        color: ACCENT_COLORS.NEUTRAL[10]
      }))
      const tagOptions = getTagOptions(tags)
      const filteredOptions = omit ? filter(options, (u) => !omit.includes(u.value)) : options
      const filteredTagOptions = omitTags ? filter(tagOptions, (t) => !omitTags.includes(t.value)) : tagOptions
      if (defaultOptions == null && value === '') {
        setAllOptions(options)
        setDefaultOptions([...filteredTagOptions, ...filteredOptions])
      }
      return [...filteredTagOptions.filter(t => t.label.toLowerCase().includes(value.toLowerCase())), ...filteredOptions]
    })
  }

  useEffect(() => {
    if (allOptions) {
      const tagOptions = getTagOptions(tags)
      const filteredOptions = omit ? filter(allOptions, (u) => !omit.includes(u.value)) : allOptions
      const filteredTagOptions = omitTags ? filter(tagOptions, (t) => !omitTags.includes(t.value)) : tagOptions
      setDefaultOptions([...filteredTagOptions, ...filteredOptions])
    }
  }, [omit, omitTags])

  useEffect(() => {
    if (tags && allOptions) {
      const tagOptions = getTagOptions(tags)
      const filteredOptions = omit ? filter(allOptions, (u) => !omit.includes(u.value)) : allOptions
      const filteredTagOptions = omitTags ? filter(tagOptions, (t) => !omitTags.includes(t.value)) : tagOptions
      setDefaultOptions([...filteredTagOptions, ...filteredOptions])
    }
  }, [tags, allOptions])

  return (
    <AsyncSelect
      defaultOptions={defaultOptions || true}
      isDisabled={!tags}
      loadOptions={performSearch}
      isMulti
      {...rest}
      theme={(theme) => ({
        ...theme,
        borderRadius: '0.25rem',
        colors: {
          ...theme.colors,
          primary: '#4094da',
          primary75: '#c1e3ff',
          primary50: '#c1e3ff',
          primary25: '#eef7ff',
          danger: '#da1e28',
          dangerLight: '#fed7d4'
        }
      })}
      components={{
        Option,
        ValueContainer,
        SingleValue,
        IndicatorSeparator,
        DropdownIndicator,
        ClearIndicator,
        NoOptionsMessage,
        LoadingMessage,
        MultiValueLabel,
        MultiValueRemove: MultiValueRemove
      }}
      styles={customStyles}
      classNames={customClassNames}
    />
  )
}
