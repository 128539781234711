import { useMutation, useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query'

import { handleMutationError } from 'utils'
import globalMessages from 'components/globalMessages'
import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'

export const useActiveSessions = (options) => {
  const intl = useIntl()
  const opts = {
    enabled: true,
    user: null,
    cache: false,
    ...options
  }

  const queryClient = useQueryClient()
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()

  // TODO: Either we are getting the sessions for the current user or we are passing a user id
  // and if we are an admin or operator, we should receive the sessions of the given user

  const queryKey = ['active-sessions', opts.user]

  const filter = {
    customer,
    ...(opts.user ? { user: opts.user } : undefined)
  }

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        opts.user === 'me' ? '/login-sessions/me/' : `/login-sessions/?${queryString.stringify(filter)}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(opts.user === 'me' ? res : res.results),
          failure: (err) => reject(err)
        }
      )
    }),
    ...(opts.cache ? { staleTime: Infinity } : undefined),
    placeholderData: keepPreviousData,
    enabled: opts.enabled,
    retry: false
  })

  const { mutateAsync: revokeMutation, isPending: revokeIsPending } = useMutation({
    mutationFn: (id) => new Promise((resolve, reject) => {
      fetch(
        `/login-sessions/${id}/?${queryString.stringify({ customer })}`,
        {
          method: 'DELETE',
          token,
          success: (res) => {
            resolve(res)
          },
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    // We use optimistic updating to simulate the new data before we have it
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey })
      const previousData = queryClient.getQueryData(queryKey)
      const newData = previousData.filter(i => i.id !== id)

      queryClient.setQueryData(queryKey, newData)
      return { previousData }
    },
    onError: (_err, _newData, context) => queryClient.setQueryData(queryKey, context.previousData)
  })

  const revokeSession = (id) => revokeMutation(id).then(() => {
    message.success(intl.formatMessage(globalMessages.sessionRevoked))
  })

  const revokeAllButCurrent = () => {
    const list = data.filter(i => !i.is_current)
    const promises = list.map(i => revokeMutation(i.id))
    return Promise.all(promises).then(() => {
      message.success(intl.formatMessage(globalMessages.allSessionsRevoked))
    })
  }

  useEffect(() => {
    if (error) {
      message.error(intl.formatMessage(notifyMessages.fetchError, { type: 'sessions', message: error.error ? error.error.message : error }), 15)
    }
  }, [error])

  return {
    data,
    isLoading,
    error,
    isFetching,
    revoke: {
      session: revokeSession,
      allButCurrent: revokeAllButCurrent,
      isPending: revokeIsPending
    }
  }
}
