import * as RadixTooltip from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import s from './Tooltip.module.scss'

export const Tooltip = ({ children, title, overlayClassName, side, triggerAsChild, open, triggerClassName, ...props }) =>
  <RadixTooltip.Root open={open}>
    <RadixTooltip.Trigger className={classNames(s.trigger, triggerClassName)} asChild={triggerAsChild} {...props}>
      {children}
    </RadixTooltip.Trigger>
    <RadixTooltip.Portal>
      <RadixTooltip.Content side={side} className={classNames(s.root, overlayClassName, 'TooltipContent')} sideOffset={5}>
        {title}
        <RadixTooltip.Arrow className={s.arrow} height={4} />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  </RadixTooltip.Root>
