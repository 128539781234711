import Heading from 'components/Heading'
import { Json } from 'components/Json'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useUI } from 'services/UIProvider'
import { Tabs } from 'components/Tabs'
import { CopyToClipboardButton } from 'components/CopyToClipboardButton'
import s from './DebugModal.module.scss'
import { Collapsible } from 'components/Primitives'

export const DebugModal = () => {
  const { debugModal, setDebugModal } = useUI()
  const close = () => setDebugModal(null)
  const payloadKeys = debugModal ? Object.keys(debugModal.payload) : []
  const responseKeys = debugModal && debugModal.response ? Object.keys(debugModal.response) : []
  const resultKeys = debugModal && debugModal.result ? Object.keys(debugModal.result) : []

  const renderContent = () => {
    if (!debugModal) return null
    console.log(debugModal)

    const tabs = [
      {
        key: 'payload',
        label: 'Payload',
        content: (
          <>
            {payloadKeys.map((key, idx) => (
              <div className='mb-2' key={key}>
                <div className={s.buttons}>
                  <Heading type='h5' className={s.heading}>{key}:</Heading>
                  <CopyToClipboardButton className={s.copy} value={JSON.stringify(debugModal.payload[key], null, 2)} />
                </div>
                <Collapsible contentClassName={s.content} triggerClassName={s.trigger} defaultOpen={idx === 0}>
                  <Json>{debugModal.payload[key]}</Json>
                </Collapsible>
              </div>))}
          </>
        )
      },
      {
        key: 'response',
        label: 'Response',
        content: (
          <>
            {responseKeys.map((key, idx) => (
              <div className='mb-2' key={key}>
                <div className={s.buttons}>
                  <Heading type='h5' className={s.heading}>{key}:</Heading>
                  <CopyToClipboardButton className={s.copy} value={JSON.stringify(debugModal.response[key], null, 2)} />
                </div>
                <Collapsible contentClassName={s.content} triggerClassName={s.trigger} defaultOpen={idx === 0}>
                  <Json>{debugModal.response[key]}</Json>
                </Collapsible>
              </div>))}
          </>
        )
      },
      {
        key: 'chart-data',
        label: 'Chart Data',
        content: (
          <>
            {resultKeys.map((key, idx) => (
              <div className='mb-2' key={key}>
                <div className={s.buttons}>
                  <Heading type='h5' className={s.heading}>{key}:</Heading>
                  <CopyToClipboardButton className={s.copy} value={JSON.stringify(debugModal.result[key], null, 2)} />
                </div>
                <Collapsible contentClassName={s.content} triggerClassName={s.trigger} defaultOpen={idx === 0}>
                  <Json>{debugModal.result[key]}</Json>
                </Collapsible>
              </div>))}
          </>
        )
      }
    ]

    return <Tabs tabs={tabs} spaced />
  }

  return (
    <MobileFriendlyModal
      visible={debugModal != null}
      title={debugModal ? debugModal.title : null}
      onCancel={close}
      noFooter
    >
      {renderContent()}
    </MobileFriendlyModal>
  )
}
