import classNames from 'classnames'
import s from './Input.module.scss'
import { forwardRef, useState } from 'react'
import { usePrevious } from 'ahooks'
import { Icon } from 'components/Primitives/Icon'

export const Input = forwardRef(({ className, wrapperClassName, size = 'lg', inputPrefix, inputSuffix, onChange, withEvent, stylesType, spaced, hasError, value, type, selectOnFocus, ...props }, forwardedRef) => {
  const [showPassword, setShowPassword] = useState(false)
  const withWrapper = wrapperClassName || inputPrefix || inputSuffix || type === 'password'

  // For react-number-format
  withEvent = withEvent || (props.hasOwnProperty('inputMode') && onChange)

  // we need this to catch setting value to undefined, otherwise the text field won't be empty
  const prevValue = usePrevious(value)
  const internalValue = prevValue == null ? value : value == null ? '' : value

  const input = (
    <input
      ref={forwardedRef}
      className={classNames(className, s.root, s[size], s[stylesType], inputPrefix && s.hasPrefix, inputSuffix && s.hasSuffix, spaced && s.spaced, hasError && s.hasError)}
      onChange={(e) => {
        if (onChange) onChange(withEvent ? e : e.target.value)
      }}
      value={internalValue}
      type={type === 'password' && showPassword ? 'text' : type}
      {...props}
      onFocus={selectOnFocus ? (e) => e.target.select() : props.onFocus}
    />
  )

  if (!withWrapper) return input

  return (
    <div className={classNames(s.wrapper, wrapperClassName, s[stylesType], s[size])}>
      {inputPrefix && <span className={s.prefix}>{inputPrefix}</span>}
      {input}
      {inputSuffix
        ? <span className={s.suffix}>{inputSuffix}</span>
        : type === 'password' && (
          <button type='button' className={s.suffix} onClick={() => setShowPassword(!showPassword)}>
            <Icon name='View' color='gray' />
          </button>
        )}
    </div>
  )
})
