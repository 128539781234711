import { FormattedMessage } from 'react-intl'
import React, { useMemo, useState } from 'react'
import { useActiveSessions } from 'hooks/queries/useActiveSessions'
import { get, find } from 'lodash'
import { useLocalStorageState } from 'ahooks'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useUser } from 'hooks'

const AddToHomescreenNotification = () => {
  const { user } = useUser()
  const { data } = useActiveSessions({
    cache: true,
    user: 'me',
    enabled: user != null
  })
  const [closeCounter, setCloseCounter] = useLocalStorageState(
    'ios-homescreen-notification-counter',
    {
      defaultValue: 0
    }
  )

  const [closed, setClosed] = useState(false)

  const iOSBrowser = useMemo(() => {
    if (!data || data.length === 0) { return null }

    const session = find(data, { is_current: true })

    const os = get(session, 'info.os.name', null)
    const browser = get(session, 'info.browser.name', null)
    return (os === 'iOS'
      ? browser === 'Safari' ? 'safari' : 'other'
      : null)
  }, [data])
  const isStandalone = window.navigator.standalone === true

  const handleClose = () => {
    setClosed(true)
    setCloseCounter(closeCounter + 1)
  }

  const renderSafariMessage = () =>
    <div className='text-base text-center'>
      <FormattedMessage tagName='p' id='AddToHomescreenNotification.description' defaultMessage='Add this app to your home screen for easy access and a better experience.' />
      <p className='mt-4'>
        <FormattedMessage id='AddToHomescreenNotification.tap' defaultMessage='Tap' />
        <svg className='mx-1' xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0V0z' fill='none' /><path d='M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z' /></svg>
        <FormattedMessage id='AddToHomescreenNotification.addToScreen' defaultMessage='then "Add to Home Screen"' />
      </p>
    </div>

  const renderOtherMessage = () =>
    <div className='text-base text-center'>
      <FormattedMessage tagName='p' id='AddToHomescreenNotification.description' defaultMessage='Add this app to your home screen for easy access and a better experience.' />
      <p className='mt-4 font-bold'>
        <FormattedMessage id='AddToHomescreenNotification.openInSafari' defaultMessage='First open this website in Safari.' />
      </p>
      <p className='font-bold'>
        <FormattedMessage id='AddToHomescreenNotification.thenTap' defaultMessage='Then tap' />
        <svg className='mx-1' xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0V0z' fill='none' /><path d='M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z' /></svg>
        <FormattedMessage id='AddToHomescreenNotification.addToScreen' defaultMessage='then "Add to Home Screen"' />
      </p>
    </div>

  return (
    <MobileFriendlyModal
      onCancel={handleClose}
      visible={iOSBrowser != null && closeCounter < 2 && !closed && !isStandalone}
      noFooter
      title={<FormattedMessage id='AddToHomescreenNotification.dialogTitle' defaultMessage='Add Delicious Data to Home Screen' />}
    >
      <div className='flex flex-col items-center justify-center mt-3 mb-6'>
        <img className='w-16 m-2 mx-auto border rounded-lg shadow-grid border-gray-border' src='/apple-touch-icon.png' alt='iOS Home Screen Icon' />
        <p className='text-base text-black'>
          <FormattedMessage id='AddToHomescreenNotification.title' defaultMessage='Delicious Data' />
        </p>
      </div>
      {iOSBrowser === 'safari' ? renderSafariMessage() : renderOtherMessage()}
    </MobileFriendlyModal>
  )
}

export default AddToHomescreenNotification
