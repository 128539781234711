import { useCallback, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useAuth } from 'hooks/useAuth'
import { qFetchUser } from 'utils/queries'

export const useUser = () => {
  const queryClient = useQueryClient()
  const { fetch, token } = useQueryFetcher()
  const { logout } = useAuth()

  const fetchUser = useCallback(() => qFetchUser(fetch), [fetch])

  const enabled = (token != null && token !== '')

  const { data, error, isFetching } = useQuery({
    queryKey: ['me', token],
    queryFn: fetchUser,
    enabled: enabled,
    retry: 2,
    staleTime: 120000
  })

  const overwrite = useCallback((object) => {
    // we need to keep is_impersonated and impersonator from the current data
    const current = queryClient.getQueryData(['me', token])
    queryClient.setQueryData(['me', token], { ...current, ...object })
  }, [queryClient])

  const isAuthenticated = useMemo(() => {
    if (token == null || token === '') return false
    if (data) return true
    return null
  }, [token, data])

  useEffect(() => {
    if (error && logout) {
      logout()
    }
  }, [error])

  return {
    user: data,
    error,
    overwrite,
    isAuthenticated,
    isFetching
  }
}
