import DatePicker from 'components/DatePicker'
import React from 'react'
import { TimePicker } from 'components/Pickers'
import classNames from 'classnames'

export default ({ value, onChange, className, getPopupContainer }) => {
  const handleChange = (props) => {
    if (onChange) {
      onChange({
        date: props.date || value.date,
        time: props.time || value.time
      })
    }
  }

  return (
    <div className={classNames('flex', className)}>
      <DatePicker className='w-full' value={value.date} onChange={(e) => handleChange({ date: e })} getCalendarContainer={getPopupContainer} />
      <TimePicker
        wrapperClassName='w-full'
        value={value.time}
        onChange={(time) => handleChange({ time })}
      />
    </div>
  )
}
