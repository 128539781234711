import * as React from 'react'
import { createIntervalledTimeValues } from 'utils/datetime'
import { useMemo, useEffect, useState } from 'react'
import { TextSelect } from 'components/TextSelect'

export default ({ interval = 15, value, placeholder, ...props }) => {
  const slots = useMemo(() => createIntervalledTimeValues(interval), [interval])
  const [internalVal, setInternalVal] = useState(undefined)

  useEffect(() => {
    if (value && typeof value === 'string') {
      const t = value.split(':')
      if (t.length === 3) {
        setInternalVal(`${t[0]}:${t[1]}`)
        console.log('TimePicker: Time value has seconds, removing them.', value, `${t[0]}:${t[1]}`)
      } else {
        setInternalVal(value)
      }
    } else {
      setInternalVal(undefined)
    }
  }, [value])

  useEffect(() => {
    if (internalVal) {
      const slot = slots.find(s => s === internalVal)
      if (!slot) console.warn(`TimePicker: Time ${internalVal} is not in the list of available slots.`)
    }
  }, [internalVal, slots])

  return (
    <TextSelect
      options={slots.map(s => ({ value: s, label: s }))}
      creatable={false}
      allowClear={false}
      dropdownIcon='Time'
      noIconAnimation
      placeholder={placeholder || '00:00'}
      value={internalVal}
      {...props}
    />
  )
}
