import { useUser } from './queries/useUser'
import { useDebugStore } from './store/useDebugStore'

export const useLocalMode = () => {
  const { user } = useUser()
  const debugMode = useDebugStore((state) => state.debugMode)

  const localMode = debugMode || (user ? user.customer_name.includes('Smoke Test') : false)

  return localMode
}
