import { useHomebarHeight, useIsMobile } from 'hooks'

const useAppleHomebar = (additionalOffsetRem = 0, additionalHeighPx = 0) => {
  const homebarHeight = useHomebarHeight()
  const isMobile = useIsMobile()

  const os = (isMobile ? 3 : 4) + additionalOffsetRem
  const offset = homebarHeight > 0 ? `calc(${os}rem + ${homebarHeight}px)` : `${os}rem`

  const height = (homebarHeight || 0) + additionalHeighPx

  return {
    height: `${height}px`,
    offset: offset
  }
}

export default useAppleHomebar
