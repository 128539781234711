import { Button } from 'components/Button'
import { Tooltip } from 'components/Primitives'
import { useDemo } from 'hooks'

export const LinkRenderer = ({ value, data, colDef }) => {
  const { demo } = useDemo()
  if (!value) return null

  const Component = (
    <Button
      type='link'
      onClick={colDef.onClick ? () => colDef.onClick(data, colDef) : undefined}
      data-action={colDef.dataAction || undefined}
    >
      {colDef.disguise ? demo(value) : value}
    </Button>
  )
  return colDef.tooltip || colDef.tooltipField
    ? (
      <Tooltip title={colDef.tooltip || data[colDef.tooltipField]} side='right'>
        {Component}
      </Tooltip>
      )
    : Component
}
