import classNames from 'classnames'
import { Badge, Text } from 'components/Primitives'
import { omit } from 'lodash'

export const Option = ({ innerProps, isFocused, isSelected, data }) => {
  const isTag = data.item.color != null
  return (
    <div
      data-role='option'
      {...omit(innerProps, ['onClick'])}
      onClick={innerProps.onClick}
      className={classNames(
        innerProps.className,
        'w-full px-2 cursor-pointer p-3 flex',
        {
          'flex-col text-gray-light justify-start text-base py-1': !isTag,
          'py-3': isTag,
          'bg-gray-lighter': isFocused,
          'bg-primary-tint': isSelected
        }
      )}
    >
      {isTag
        ? <Badge size='s' hex={data.item.color}>{data.label}</Badge>
        : (
          <>
            <Text>{data.label}</Text>
            <Text color='gray'>{data.item.email}</Text>
          </>
        )
      }
    </div>
  )
}
