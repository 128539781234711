import * as yup from 'yup'
import formMessages from 'components/formMessages'

export const PASSWORD_RULES = [
  // TODO reuse regexes on password schema below
  { key: 'passwordMinLength', pattern: /.{12,}/, message: formMessages.passwordMinLength },
  { key: 'passwordLetters', pattern: /(?=.*[a-z])(?=.*[A-Z])/, message: formMessages.passwordLetters },
  { key: 'passwordNumber', pattern: /.*\d/, message: formMessages.passwordNumber },
  {
    key: 'passwordSpecialChar',
    pattern: /.*[$@!%*?&_~`#^\(\)+=_\-\{\}\[\]\\|;:\'\"/<>,.]/,
    message: formMessages.passwordSpecialChar
  }
]

export const email = yup
  .string()
  .email(formMessages.emailInvalid)
  .required(formMessages.required)

export const passwordConfirmation = (password) =>
  yup
    .string()
    .oneOf([password], formMessages.passwordDoesNotMatch)
    .required(formMessages.required)

export const password = yup
  .string()
  .matches(/.{12,}/, formMessages.passwordMinLength)
  .matches(/(?=.*[a-z])(?=.*[A-Z])/, formMessages.passwordLetters)
  .matches(/.*\d/, formMessages.passwordNumber)
  .matches(
    /.*[$@!%*?&_~`#^\(\)+=_\-\{\}\[\]\\|;:\'\"/<>,.]/,
    formMessages.passwordSpecialChar
  )
  .required(formMessages.required)
