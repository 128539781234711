import React, { useMemo } from 'react'

import { FormField } from 'components/Form'
import useDemo from 'hooks/useDemo'
import { useFoodwasteCategories } from 'hooks/queries/useFoodwasteCategories'
import { FormattedMessage, useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { TextSelect } from 'components/TextSelect'
import TagBadge from 'components/Badge/TagBadge'

const FoodwasteCategoryPicker = ({ className, name: nameProp, disabled, only, readonly, valueAsId, label, forceEnable }) => {
  const intl = useIntl()

  const { data, isLoading } = useFoodwasteCategories({ forceEnable })

  const { demo } = useDemo()

  const filteredData = useMemo(() => {
    if (!data) return []
    if (only) {
      return data.filter((item) => item && only.includes(item.id))
    }
    return data.filter((item) => item)
  }, [data, only])

  return (
    <FormField
      label={label}
      name={nameProp}
      as={TextSelect}
      placeholder={intl.formatMessage(labelMessages.selectWasteCategory)}
      isLoading={isLoading}
      className={className}
      disabled={disabled}
      options={filteredData.map((item) => ({ value: valueAsId ? item.id : item.name, label: demo(item.name) }))}
      creatable={!readonly}
      createNewMessage={(tag) => (
        <FormattedMessage
          id='pickers.createWasteCategory'
          defaultMessage='Create new waste category <tag>{group}</tag>'
          values={{
            group: JSON.stringify(tag),
            tag: chunks => {
              const t = JSON.parse(chunks)
              return <TagBadge noTooltip noTruncate className='mx-1' color='blue'>{t.name || t.value}</TagBadge>
            }
          }}
        />
      )}
      menuPortalTarget={document.body}
    />
  )
}

export default FoodwasteCategoryPicker
