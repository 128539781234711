import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import message from 'services/message'
import { replaceProductionInGridData, replaceProductionInTableData } from 'utils'

export const useProduction = (options) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const opts = {
    enabled: true,
    id: null,
    ...options
  }

  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const { data, isLoading } = useQuery({
    queryKey: ['production-statistics', opts.id],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/productions/${opts.id}/statistics/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: opts.enabled && opts.id != null
  })

  const { mutateAsync: mutateProduction, isPending: mutateProductionIsPending } = useMutation({
    mutationFn: (data) => new Promise((resolve, reject) => {
      const { productionId, name, value } = data
      fetch(
        `/productions/${productionId}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'PATCH',
          body: {
            [name]: value
          },
          token,
          success: (updatedProduction) => resolve(updatedProduction),
          failure: (errors) => {
            message.error(intl.formatMessage({
              id: 'home.updatingProductionError',
              defaultMessage: 'Updating production went wrong. Please try again.'
            }))
            reject(errors)
          }
        }
      )
    }),
    onSuccess: (updatedProduction) => {
      const gridDataCache = queryClient.getQueriesData({ queryKey: ['offerings-grid'] })
      const tableDataCache = queryClient.getQueriesData({ queryKey: ['offerings-table'] })

      const updatedGridDataCache = gridDataCache.map((d) => d[1] ? replaceProductionInGridData(d[1], updatedProduction) : null)
      const updatedTableDataCache = tableDataCache.map((d) => d[1] ? replaceProductionInTableData(d[1], updatedProduction) : null)

      updatedGridDataCache.forEach((data, idx) => {
        if (data) {
          queryClient.setQueryData(gridDataCache[idx][0], data)
        }
      })
      updatedTableDataCache.forEach((data, idx) => {
        if (data) {
          queryClient.setQueryData(tableDataCache[idx][0], data)
        }
      })
    }
  })

  return { data, isLoading, update: { mutate: mutateProduction, isPending: mutateProductionIsPending } }
}
