import { create } from 'zustand'
import { uniqBy } from 'lodash'

export const useGridSelectionStore = create((set) => ({
  selection: {},
  reset: (key) => set((state) => ({ selection: { ...state.selection, [key]: [] } })),
  setSelection: (key, selection) => set((state) => ({ selection: { ...state.selection, [key]: selection } })),
  addToSelection: (key, item) => set((state) => ({ selection: { ...state.selection, [key]: uniqBy([...(state.selection[key] || []), item], 'id') } })),
  removeFromSelection: (key, item) => set((state) => ({ selection: { ...state.selection, [key]: uniqBy((state.selection[key] || []).filter((i) => i.id !== item.id), 'id') } }))
}))
