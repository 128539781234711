import { GenericForm } from 'components/Form'
import TagBadge from 'components/Badge/TagBadge'
import { pick } from 'lodash'
import { pickDefined } from 'utils'
import { tagSchema } from './validationSchema'

const TagForm = ({ title, visible, onCancel, initialValues, onSubmit, confirmLoading }) => {
  const initValues = initialValues
    ? {
        ...pick(initialValues, ['name', 'color'])
      }
    : {
        name: '',
        color: ''
      }

  const handleSubmit = (values) => {
    // only these props are changeable by the user
    const picking = []
    if (values.name !== initValues.name) picking.push('name')
    if (values.color !== initValues.color) picking.push('color')

    if (onSubmit) onSubmit(pickDefined(pick(values, picking)))
  }

  const structure = [{
    fields: [
      {
        type: 'component',
        render: ({ value }) => <div key='tag' className='w-full h-10 mb-4'>{value && <TagBadge noTooltip hex={value.color}>{value.name}</TagBadge>}</div>,
        passValues: true,
        name: 'preview'
      },
      {
        type: 'text',
        name: 'name'
      },
      {
        type: 'color',
        name: 'color'
      }
    ]
  }]

  return (
    <GenericForm
      asModal
      title={title}
      visible={visible}
      structure={structure}
      validationSchema={tagSchema}
      initialValues={initValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    />
  )
}

export default TagForm
