import { every, map } from 'lodash'
import { useEffect } from 'react'

export const useChartQueryResultSetter = (setterMethod, resultObj) => {
  const arr = map(resultObj, i => i)
  useEffect(() => {
    if (every(arr, (i) => i !== undefined)) {
      setterMethod(resultObj)
    }
  }, arr)
}
