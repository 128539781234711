import { getDomain, getSimpleChartDataLegacy, shouldFilterWeekdays } from 'utils'
import { useChartGrouping, useDashboardRefreshInterval, useDataQuery } from '../'
import { useEffect, useMemo } from 'react'

import message from 'services/message'
import notifyMessages from 'components/notifyMessages'
import { useInterval } from 'ahooks'
import { useIntl } from 'react-intl'
import { useUI } from 'services/UIProvider'
import { forEach } from 'lodash'
import { usePageSettings } from 'hooks/usePageSettings'
import { useChartQueryDebugger } from 'hooks/useChartQueryDebugger'
import { useChartQueryResultSetter } from 'hooks/useChartQueryResultSetter'
import { useChartExport } from 'hooks/useChartExport'
import { Filter } from 'hooks/useAvailableFilters'
import { useDefaultDateRange } from 'components/Report/hooks/useDefaultDateRange'

export const useRelativeChartData = (identifier, dataOptions, totalValueOptions, prevValueOptions, throwOnError) => {
  if (!Array.isArray(dataOptions) || dataOptions.length !== 2 ||
  !Array.isArray(totalValueOptions) || totalValueOptions.length !== 2 ||
  !Array.isArray(prevValueOptions) || prevValueOptions.length !== 2
  ) {
    throw new Error('Variables dataOptions, totalValueOptions and prevValueOptions must be an array with two items.')
  }
  const intl = useIntl()
  const opts = { staleTime: 300000 }

  const { onDebugClick, setResult, setResponse, result } = useChartQueryDebugger(identifier, { dataOptions, totalValueOptions, prevValueOptions })
  const { data: data1, isFetching: data1IsFetching, refetch: data1Refetch, error: data1Error } = useDataQuery(dataOptions[0], true, null, opts)
  const { data: total1Value, isFetching: totalValue1IsFetching, refetch: total1Refetch, error: total1Error } = useDataQuery(totalValueOptions[0], true, null, opts)
  const { data: prev1Value, isFetching: prevValue1IsFetching, refetch: prev1Refetch, error: prev1Error } = useDataQuery(prevValueOptions[0], true, null, opts)

  const { data: data2, isFetching: data2IsFetching, refetch: data2Refetch, error: data2Error } = useDataQuery(dataOptions[1], true, null, opts)
  const { data: total2Value, isFetching: totalValue2IsFetching, refetch: total2Refetch, error: total2Error } = useDataQuery(totalValueOptions[1], true, null, opts)
  const { data: prev2Value, isFetching: prevValue2IsFetching, refetch: prev2Refetch, error: prev2Error } = useDataQuery(prevValueOptions[1], true, null, opts)

  const { settings } = usePageSettings()
  const { resetChartErrorKey } = useUI()

  const interval = useDashboardRefreshInterval()
  useInterval(() => {
    data1Refetch()
    total1Refetch()
    prev1Refetch()
    data2Refetch()
    total2Refetch()
    prev2Refetch()
    window.setTimeout(() => resetChartErrorKey(), 500)
  }, interval)

  const postprocessedData = useMemo(() => {
    if (!data1 || !data2) {
      return null
    }
    const avg = {}
    forEach(data2, (value, key) => {
      avg[key] = value === 0 ? null : (data1[key] || 0) / value
    })
    return {
      data: avg,
      totalValue: total1Value / total2Value,
      prevValue: prev1Value / prev2Value
    }
  }, [data1, data2, total1Value, total2Value, prev1Value, prev2Value])

  useChartQueryResultSetter(setResponse, { data1, data2, total1Value, total2Value, prev1Value, prev2Value })

  const difference = postprocessedData != null ? postprocessedData.totalValue - postprocessedData.prevValue : 0
  const delta = Math.abs(difference)

  const loading = data1IsFetching || totalValue1IsFetching || prevValue1IsFetching || data2IsFetching || totalValue2IsFetching || prevValue2IsFetching

  const groupBy = useChartGrouping(postprocessedData ? postprocessedData.data : null)
  const { exportData } = useChartExport(identifier, result, { groupBy })
  const defaultDateRange = useDefaultDateRange()
  const range = settings[Filter.DATE_RANGE]?.value.length === 0 ? defaultDateRange.value : settings[Filter.DATE_RANGE]?.value

  const chartData = useMemo(() => getSimpleChartDataLegacy(postprocessedData ? postprocessedData.data : {}, groupBy, range, shouldFilterWeekdays(settings.weekdays) ? settings.weekdays : null), [postprocessedData, groupBy])
  const domain = getDomain(chartData.data)
  useChartQueryResultSetter(setResult, { chartData: chartData.data })

  const errors = loading
    ? { data: null, totalValue: null, prevValue: null }
    : {
        data1: data1Error,
        totalValue1: total1Error,
        prevValue1: prev1Error,
        data2: data2Error,
        totalValue2: total2Error,
        prevValue2: prev2Error
      }

  useEffect(() => {
    if (!loading) {
      const eMessages = []

      if (data1Error) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'data1', message: typeof (data1Error) === 'object' ? data1Error.message || data1Error.error : data1Error }))
      }
      if (total1Error) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'totalValue1', message: typeof (total1Error) === 'object' ? total1Error.message || total1Error.error : total1Error }))
      }
      if (prev1Error) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'prevValue1', message: typeof (prev1Error) === 'object' ? prev1Error.message || prev1Error.error : prev1Error }))
      }
      if (data2Error) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'data2', message: typeof (data2Error) === 'object' ? data2Error.message || data2Error.error : data2Error }))
      }
      if (total2Error) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'totalValue2', message: typeof (total2Error) === 'object' ? total2Error.message || total2Error.error : total2Error }))
      }
      if (prev2Error) {
        eMessages.push(intl.formatMessage(notifyMessages.chartError, { identifier: identifier, type: 'prevValue2', message: typeof (prev2Error) === 'object' ? prev2Error.message || prev2Error.error : prev2Error }))
      }

      if (eMessages.length > 0) {
        if (throwOnError) {
          throw new Error(eMessages.join(','))
        } else {
          message.error(eMessages, 15)
          eMessages.forEach(m => console.error(m))
        }
      }
    }
  }, [loading, data1Error, total1Error, prev1Error])

  return {
    difference,
    delta,
    loading,
    domain,

    hasDataWithoutTimestamp: chartData.hasDataWithoutTimestamp || false,
    chartData: chartData.data,
    totalValue: postprocessedData ? postprocessedData.totalValue : 0,
    prevValue: postprocessedData ? postprocessedData.prevValue : 0,
    realGroupBy: groupBy,
    errors,
    onDebugClick,
    exportData
  }
}
