import { forEach, endsWith } from 'lodash'
import { useMemo } from 'react'

const omitKeys = ['columns']

/* This function converts the filters from the user settings into a proper filter query for the latest API. */
const buildQueryFilters = (filters) => {
  const keys = Object.keys(filters)
  const updatedFilters = {}

  forEach(keys, (key) => {
    if (filters[key] === undefined || filters[key] === '') return
    if (key === 'pagination') {
      updatedFilters.page = filters[key].current
      updatedFilters.page_size = filters[key].pageSize || 25
      return
    }

    // FIXME: Support some secondary/tertiary default filters
    if (omitKeys.includes(key)) return

    if (endsWith(key, 'RangeOption')) return

    if (endsWith(key, 'Range')) {
      if (filters[key].length === 0) { return }
      const field = key.replace('Range', '')
      updatedFilters[`${field}__date__range`] = filters[key].join(',')
      return
    }

    if (Array.isArray(filters[key])) {
      if (filters[key].length === 0) { return }
      if (filters[key].length === 1) {
        updatedFilters[key] = filters[key][0]
      } else {
        updatedFilters[`${key}__in`] = filters[key].join(',')
      }
      return
    }

    updatedFilters[key] = filters[key]
  })

  return updatedFilters
}

/* This hook memoize the settings and converts it to complex API query filters */
export const useQueryFilter = (settings, defaultOrdering) => useMemo(() => {
  if (!settings) return {}
  return buildQueryFilters(settings, defaultOrdering)
}, [settings])
