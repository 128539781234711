import { useEffect, useState } from 'react'

import { ACCENT_COLORS_LIST } from 'constants/index'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { find } from 'lodash'
import { pickTextColorBasedOnBgColor } from 'utils'

export const MultiValueRemove = (props) => {
  const accentColor = find(ACCENT_COLORS_LIST, { bg: props.data.color })
  const fontColor = accentColor ? accentColor.fg : pickTextColorBasedOnBgColor(props.data.color || '#b5bcc2')

  const initialStyle = () => ({ backgroundColor: props.data.color, color: fontColor })

  const [style, setStyle] = useState(initialStyle())

  const onMouseEnter = () => setStyle({ backgroundColor: fontColor, color: '#fff' })
  const onMouseLeave = () => setStyle(initialStyle())

  useEffect(() => {
    setStyle(initialStyle())
  }, [props.data])

  return (
    <button
      data-action='remove-badge'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props.innerProps}
      style={style}
      className={classNames(props.innerProps.className, 'rounded-tr rounded-br rounded-tl-none rounded-bl-none cursor-pointer leading-normal pt-0.5')}
    >
      <XIcon className='w-3 h-3' />
    </button>
  )
}
