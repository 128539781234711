import { useQueries } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'
import { findIndex } from 'lodash'

/**
 * This hook takes a bunch of oder ids
 * and fetches them all in multiple requests
 */
export const useOrderHistoryCollector = (records) => {
  const { fetch, token } = useQueryFetcher()
  const selectedCustomer = useSelectedCustomer()

  const queries = useQueries({
    queries: records.map((record) => ({
      queryKey: ['order-history', record.order_id, record.start],
      queryFn: () => new Promise((resolve, reject) => {
        fetch(
          `/orders/?${queryString.stringify({
            customer: selectedCustomer,
            order_id: record.order_id,
            ordering: '-history_date',
            page: 1,
            page_size: 500
          })}`,
          {
            method: 'GET',
            token,
            success: (res) => {
              if (record.start) {
                const index = findIndex(res.results, { history_id: record.start })
                if (index === -1) {
                  console.warn('Could not find index for', record.start, res.results)
                  return resolve(record.order_id)
                }

                // we now return the items starting AFTER the found item
                const returnData = res.results.slice(index + 1)
                if (returnData.length === 0) return resolve(record.order_id)
                return resolve(returnData)
              }
            },
            failure: (err) => reject(err)
          }
        )
      }),
      enabled: !!record.order_id
    }))

  })

  return queries
}
