import { defineMessages } from 'react-intl'

export default defineMessages({
  required: {
    id: 'form.required',
    defaultMessage: 'The field is required.'
  },
  emailInvalid: {
    id: 'form.emailInvalid',
    defaultMessage: 'The email is invalid.'
  },
  invalid: {
    id: 'form.invalid',
    defaultMessage: 'The field is invalid.'
  },
  passwordMinLength: {
    id: 'form.passwordMinLength',
    defaultMessage: 'At least 12 characters'
  },
  passwordLetters: {
    id: 'form.passwordLetters',
    defaultMessage: 'At least 1 uppercase and lowercase letters'
  },
  passwordNumber: {
    id: 'form.passwordNumber',
    defaultMessage: 'At least 1 number'
  },
  passwordSpecialChar: {
    id: 'form.passwordSpecialChar',
    defaultMessage: 'At least 1 special character'
  },
  passwordDoesNotMatch: {
    id: 'form.passwordDoesNotMatch',
    defaultMessage: 'Password does not match the confirm password.'
  },
  selectColumns: {
    id: 'form.selectColumns',
    defaultMessage: 'Select which columns you want to see in the table.'
  },
  allowedExtensions: {
    id: 'form.allowedExtensions',
    defaultMessage: 'Allowed extensions: {extensions}'
  },
  invalidFile: {
    id: 'form.invalidFile',
    defaultMessage: 'Invalid file format.'
  },
  selectWeekdays: {
    id: 'form.selectWeekdays',
    defaultMessage: 'Select Weekdays'
  },
  metrics: {
    id: 'foodwasteForm.metrics',
    defaultMessage: 'Metrics'
  },
  setBenchmarkPeriod: {
    id: 'foodwasteForm.setBenchmarkPeriod',
    defaultMessage: 'Set benchmark period'
  },
  setBenchmark: {
    id: 'foodwasteForm.setBenchmark',
    defaultMessage: 'Set benchmark'
  },
  customBenchmark: {
    id: 'foodwasteForm.customBenchmark',
    defaultMessage: 'Custom benchmark'
  },
  addConfigInfo: {
    id: 'foodwasteForm.addConfigInfo',
    defaultMessage:
      'The benchmark period for recording the actual situation before measures are implemented. The benchmark values measured during the benchmark period can later be used to calculate how much food waste was saved by the measures.'
  },
  measuringDaysInfo: {
    id: 'foodwasteForm.measuringDaysInfo',
    defaultMessage:
      'The measuring days can be defined via the planned offering or via weekdays. If the measuring days are defined via the offering, then measurements are only created for days for which there are meals listed in the offering view.'
  },
  personnelCosts: {
    id: 'foodwasteForm.personnelCosts',
    defaultMessage: 'Personnel costs [€/kg]'
  },
  salesCosts: {
    id: 'foodwasteForm.salesCosts',
    defaultMessage: 'Sales costs [€/kg]'
  },
  disposalCosts: {
    id: 'foodwasteForm.disposalCosts',
    defaultMessage: 'Disposal costs [€/kg]'
  },
  co2: {
    id: 'foodwasteForm.co2',
    defaultMessage: 'CO₂ [kg/kg]'
  },
  water: {
    id: 'foodwasteForm.water',
    defaultMessage: 'Drinking water [l/kg]'
  },
  dishes: {
    id: 'foodwasteForm.dishes',
    defaultMessage: 'Dishes [dishes/kg]'
  },
  measuringDays: {
    id: 'foodwasteForm.measuringDays',
    defaultMessage: 'Measuring days'
  },
  defineViaMenuPlanning: {
    id: 'foodwasteForm.defineViaMenuPlanning',
    defaultMessage: 'Define via offering'
  },
  defineViaWeekdays: {
    id: 'foodwasteForm.defineViaWeekdays',
    defaultMessage: 'Define via weekdays'
  },
  determineResponsibles: {
    id: 'foodwasteForm.determineResponsibles',
    defaultMessage: 'Determine responsibles'
  },
  goalSettings: {
    id: 'foodwasteForm.goalSettings',
    defaultMessage: 'Goal settings'
  },
  goalMet: {
    id: 'foodwasteForm.goalMet',
    defaultMessage: 'Goal met'
  },
  goalSatisfactory: {
    id: 'foodwasteForm.goalSatisfactory',
    defaultMessage: 'Satisfactory'
  },
  confirmationQuestion: {
    id: 'foodwasteForm.confirmationQuestion',
    defaultMessage: 'Are you sure you want to adjust the following fields for <b>{amount, plural, one {# foodwaste config} other {# foodwaste configs}}</b>?'
  }
})
