export const invalidateFoodwasteQueries = (queryClient) => {
  queryClient.invalidateQueries({ queryKey: ['open-measurements'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'foodwaste_locations_comparison'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'foodwaste_co2'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'foodwaste_sales_costs'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'foodwaste_water'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'foodwaste_composition'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'foodwaste_detail_view'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'foodwaste'] })
  queryClient.invalidateQueries({ queryKey: ['data-query', 'Waste'] })
}
