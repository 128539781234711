import { usePrevious } from 'ahooks'
import { Tags } from 'components/Tags'
import { uniqueId } from 'lodash'
import { useEffect, useState } from 'react'

export const TagManagement = ({ record, value, onTagsChange, onChange, tagType, ...props }) => {
  const [pickerRecord, setPickerRecord] = useState(null)
  const [renderKey, setRenderKey] = useState(uniqueId(`tag-management-${tagType}-`))
  const prevValue = usePrevious(value)

  const asFormField = record == null

  const handleTagsChange = (s) => {
    // if the value is undefined and s is an empty array, we don't want to call the onChange handler to not destroy bulk editing
    if (asFormField && (value === undefined && Array.isArray(s) && s.length === 0)) {
      return
    }

    if (onTagsChange) {
      onTagsChange(s, record)
    }
    if (onChange) {
      onChange(s)
    }
  }

  // our items are only initial values, so we need to re-initialize the component when the value changes from outside
  useEffect(() => {
    if (value === undefined && prevValue !== undefined) {
      setRenderKey(uniqueId(`tag-management-${tagType}-`))
    }
  }, [prevValue, value])

  return (
    <Tags
      key={renderKey}
      asFormField={asFormField}
      onStopEdit={() => setPickerRecord(null)}
      onStartEdit={() => setPickerRecord(record ? record.id : true)}
      // this is for debugginr and developing the options to keep it open
      // pickerMode={pickerRecord === true || record.name === 'Glockenbachviertel' || (record && pickerRecord === record.id)}
      pickerMode={pickerRecord === true || (record && pickerRecord === record.id)}
      editable
      type={tagType}
      items={record ? record.tags : (value || [])}
      onSelectionChanged={handleTagsChange}
      {...props}
    />
  )
}
