import { Text, Icon } from 'components/Primitives'

const HelpHeader = ({ helpLink, children }) => {
  return (
    <a
      target='_blank'
      rel='noreferrer'
      className='flex ml-6 text-gray-light hover:text-gray hover:underline items-center'
      href={helpLink}
    >
      <Icon name='Book' className='mr-1.5' />
      <Text type='buttonLabel' size='m' className='leading-4 mb-1'>
        {children}
      </Text>
    </a>
  )
}

export default HelpHeader
