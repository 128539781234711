import classNames from 'classnames'
import { Tooltip } from 'components/Primitives'
import s from './CellRenderer.module.scss'

export const AddressRenderer = ({ value, colDef }) => {
  const obj = value
    ? ({
        address1: value.address_one,
        address2: value.address_two,
        zip: value.zip_code,
        city: value.city,
        country: value.country
      })
    : {}

  if (Object.values(obj).filter(i => i).length === 0) { return null }

  let inner
  const address2 = obj.address2 ? ` (${obj.address2})` : null
  const lineString = [
    obj.address1
      ? `${obj.address1}${address2 ? `${address2}` : ''}`
      : address2,
    obj.zip
      ? `${obj.zip}${obj.city ? ` ${obj.city}` : ''}`
      : obj.city,
    obj.country].filter(i => i && i !== '').join(', ')

  if (colDef.line) {
    inner = <address className={classNames(s.address, s.oneline)}>{lineString}</address>
  } else {
    inner = (
      <address className={s.address}>
        {obj.address1 && <span>{obj.address1}</span>}
        {obj.address2 && <span>{obj.address2}</span>}
        {(obj.zip || obj.city) && <span>{obj.zip} {obj.city}</span>}
        {obj.country && <span>{obj.country}</span>}
      </address>
    )
  }

  return (
    <div>
      <Tooltip triggerAsChild triggerClassName='w-full overflow-hidden text-ellipsis text-start cursor-pointer' title={lineString} placement='top'>
        {inner}
      </Tooltip>
    </div>
  )
}
