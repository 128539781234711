import React from 'react'
import { ReadyState } from 'react-use-websocket'
import { WebsocketConnectionStateToString } from 'components/Charts/utils'
import classNames from 'classnames'
import s from './DebugConsole.module.scss'

export const ConnectionState = ({ state }) => {
  let colorClass

  switch (state) {
    case ReadyState.CONNECTING:
    case 'connecting':
      colorClass = 'bg-accent-cornflower-75'
      break
    case ReadyState.OPEN:
      colorClass = 'bg-feedback-success'
      break
    case 'online':
      colorClass = 'bg-feedback-success'
      break
    case ReadyState.CLOSING:
      colorClass = 'bg-feedback-warning'
      break
    case ReadyState.CLOSED:
      colorClass = 'bg-feedback-error'
      break
    case 'offline':
      colorClass = 'bg-feedback-error'
      break
    case ReadyState.UNINSTANTIATED:
      colorClass = 'bg-gray-light'
      break
  }

  return (
    <div className={s.connectionStatus}>
      <div className={classNames(s.connectionIcon, 'animate-pulsate-fwd', colorClass)} />
      <span>{WebsocketConnectionStateToString(state)}</span>
    </div>
  )
}
