import TagBadge from 'components/Badge/TagBadge'
import { TagPicker } from './TagPicker'
import classNames from 'classnames'
import { find, sortBy } from 'lodash'
import s from './Tags.module.scss'
import { useAdminData } from 'hooks'
import { TagCollection } from 'components/TagCollection'
import { useMemo } from 'react'
import { Icon } from 'components/Primitives'

export const Tags = ({ items, editable, onSelectionChanged, type, onStartEdit, onStopEdit, pickerMode, noFetch, asFormField, ...props }) => {
  const { data } = useAdminData('tags', { enabled: !noFetch, type })

  if (!editable || !onSelectionChanged) {
    return <TagCollection items={items} />
  }

  const realItems = useMemo(() => {
    const asObject = typeof (items[0]) === 'object'

    if (asObject) {
      return sortBy(items, ['name']) || []
    }
    if (data.items) {
      return sortBy(items.map((id) => find(data.items, { id })).filter(i => i), ['name'])
    }
    return []
  }, [items, data.items])

  return (
    (pickerMode || asFormField)
      ? <TagPicker
          type={type}
          initialSelection={realItems}
          onSelectionChanged={onSelectionChanged}
          onShouldCancelEditMode={onStopEdit}
          asFormField={asFormField}
          {...props}
        />
      : (
        <div className={classNames('group', s.tagLine)}>
          <div>
            {realItems.map(item => {
              if (typeof (item) === 'object') {
                if (item.id === -1) {
                  return <TagBadge key={item.id} hex={item.color}>{item.name}</TagBadge>
                } else {
                  const tag = find(data.items, { id: item.id })
                  return tag && <TagBadge key={tag.id} id={tag.id} hex={tag.color}>{tag.name}</TagBadge>
                }
              } else {
                const tag = find(data.items, { id: item })
                return tag && <TagBadge key={tag.id} id={tag.id} hex={tag.color}>{tag.name}</TagBadge>
              }
            })}
          </div>
          <button
            type='button'
            data-action='edit-tags'
            className={classNames(s.editIcon, 'group-hover:visible')}
            onClick={() => onStartEdit()}
          >
            <Icon name='Edit' />
          </button>
        </div>
        )
  )
}
