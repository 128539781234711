import { defineMessages } from 'react-intl'

export default defineMessages({
  id: {
    id: 'label.id',
    defaultMessage: 'ID'
  },
  name: {
    id: 'label.name',
    defaultMessage: 'Name'
  },
  category: {
    id: 'label.category',
    defaultMessage: 'Category'
  },
  customer: {
    id: 'label.customer',
    defaultMessage: 'Customer'
  },
  itemGroup1: {
    id: 'label.itemGroup1',
    defaultMessage: 'Item Group 1'
  },
  itemGroup2: {
    id: 'label.itemGroup2',
    defaultMessage: 'Item Group 2'
  },
  itemGroup: {
    id: 'label.itemGroup',
    defaultMessage: 'Item Group {no}'
  },
  itemGroupSingular: {
    id: 'label.itemGroupSingular',
    defaultMessage: 'Item Group'
  },
  itemGroups: {
    id: 'label.itemGroups',
    defaultMessage: 'Item Groups'
  },
  order: {
    id: 'label.order',
    defaultMessage: 'Order'
  },
  multiDayTracking: {
    id: 'label.multiDayTracking',
    defaultMessage: 'Multiday order coverage'
  },
  inventoryTracking: {
    id: 'label.inventoryTracking',
    defaultMessage: 'Inventory tracking'
  },
  offeringGroup1: {
    id: 'label.offeringGroup1',
    defaultMessage: 'Offering Group 1'
  },
  offeringGroup2: {
    id: 'label.offeringGroup2',
    defaultMessage: 'Offering Group 2'
  },
  offeringGroup: {
    id: 'label.offeringGroup',
    defaultMessage: 'Offering Group {no}'
  },
  description: {
    id: 'label.description',
    defaultMessage: 'Description'
  },
  plu: {
    id: 'label.plu',
    defaultMessage: 'PLU'
  },
  color: {
    id: 'label.color',
    defaultMessage: 'Color'
  },
  firstName: {
    id: 'label.firstName',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'label.lastName',
    defaultMessage: 'Last name'
  },
  company: {
    id: 'label.company',
    defaultMessage: 'Company'
  },
  locations: {
    id: 'label.locations',
    defaultMessage: 'Locations'
  },
  returnRatioField: {
    id: 'label.returnRatioField',
    defaultMessage: 'Return amount rate field'
  },
  foodWasteMode: {
    id: 'label.foodWasteMode',
    defaultMessage: 'Food Waste Amount Calculation Metric'
  },
  locationGroup: {
    id: 'label.locationGroup',
    defaultMessage: 'Location Group'
  },
  locationGroups: {
    id: 'label.locationGroups',
    defaultMessage: 'Location Groups'
  },
  wasteCategory: {
    id: 'label.wasteCategory',
    defaultMessage: 'Waste Category'
  },
  wasteCategories: {
    id: 'label.wasteCategories',
    defaultMessage: 'waste categories'
  },
  group: {
    id: 'label.group',
    defaultMessage: 'Group'
  },
  groups: {
    id: 'label.groups',
    defaultMessage: 'Groups'
  },
  itemTags: {
    id: 'label.itemTags',
    defaultMessage: 'Item Tags'
  },
  itemTag: {
    id: 'label.itemTag',
    defaultMessage: 'Item Tag'
  },
  locationTags: {
    id: 'label.locationTags',
    defaultMessage: 'Location Tags'
  },
  userTag: {
    id: 'label.userTag',
    defaultMessage: 'User Tag'
  },
  userTags: {
    id: 'label.userTags',
    defaultMessage: 'User Tags'
  },
  locationTag: {
    id: 'label.locationTag',
    defaultMessage: 'Location Tag'
  },
  co2: {
    id: 'label.co2',
    defaultMessage: 'CO₂'
  },
  water: {
    id: 'label.water',
    defaultMessage: 'Drinking Water'
  },
  format: {
    id: 'label.format',
    defaultMessage: 'Format'
  },
  date: {
    id: 'label.date',
    defaultMessage: 'Date'
  },
  week: {
    id: 'label.week',
    defaultMessage: 'Week'
  },
  location: {
    id: 'label.location',
    defaultMessage: 'Location'
  },
  forecastLimit: {
    id: 'label.forecastLimit',
    defaultMessage: 'Forecast Limit'
  },
  createdAt: {
    id: 'label.createdAt',
    defaultMessage: 'Created at'
  },
  importTime: {
    id: 'label.importTime',
    defaultMessage: 'Import time'
  },
  status: {
    id: 'label.status',
    defaultMessage: 'Status'
  },
  safetySuperSafe: {
    id: 'safetySetting.safetySuperSafe',
    defaultMessage: 'Super safe'
  },
  safetySafe: {
    id: 'safetySetting.safetySafe',
    defaultMessage: 'Safe'
  },
  safetyNormal: {
    id: 'safetySetting.safetyNormal',
    defaultMessage: 'Normal'
  },
  safetyClose: {
    id: 'safetySetting.safetyClose',
    defaultMessage: 'Close'
  },
  planned: {
    id: 'label.planned',
    defaultMessage: 'No. Planned'
  },
  produced: {
    id: 'label.produced',
    defaultMessage: 'No. Produced'
  },
  totalProduced: {
    id: 'label.totalProduced',
    defaultMessage: 'Total No. Produced'
  },
  soldNo: {
    id: 'label.soldNo',
    defaultMessage: 'Sold No.'
  },
  totalSoldNo: {
    id: 'label.totalSoldNo',
    defaultMessage: 'Total No. Sold'
  },
  totalTransactionsNo: {
    id: 'label.totalTransactionsNo',
    defaultMessage: 'Total No. Transactions'
  },
  forecastNo: {
    id: 'label.forecastNo',
    defaultMessage: 'Forecast No.'
  },
  totalForecastNo: {
    id: 'label.totalForecastNo',
    defaultMessage: 'Total Forecast No.'
  },
  totalPlannedNo: {
    id: 'label.totalPlannedNo',
    defaultMessage: 'Total No. Planned'
  },
  amount: {
    id: 'label.amount',
    defaultMessage: 'Amount'
  },
  wasteAmount: {
    id: 'label.wasteAmount',
    defaultMessage: 'Waste Amount'
  },
  totalAmount: {
    id: 'label.totalAmount',
    defaultMessage: 'Total amount'
  },
  price: {
    id: 'label.price',
    defaultMessage: 'Price'
  },
  orderedAmount: {
    id: 'label.orderedAmount',
    defaultMessage: 'Order amount'
  },
  requestedAmount: {
    id: 'label.requestedAmount',
    defaultMessage: 'Order proposal'
  },
  fullfilledAmount: {
    id: 'label.fullfilledAmount',
    defaultMessage: 'Delivery amount'
  },
  unsoldAmount: {
    id: 'label.unsoldAmount',
    defaultMessage: 'Return amount'
  },
  returnRate: {
    id: 'label.returnRate',
    defaultMessage: 'Return amount rate'
  },
  numVanished: {
    id: 'label.numVanished',
    defaultMessage: 'Depreciated'
  },
  avgTransactionRevenue: {
    id: 'label.avgTransactionRevenue',
    defaultMessage: 'Ø revenue per transaction'
  },
  ordersOverview: {
    id: 'label.ordersOverview',
    defaultMessage: 'Orders Overview'
  },
  lastUpdated: {
    id: 'label.lastUpdated',
    defaultMessage: 'Last Updated'
  },
  cogs: {
    id: 'label.cogs',
    defaultMessage: 'COGS'
  },
  relCogs: {
    id: 'label.relCogs',
    defaultMessage: 'Relative COGS'
  },
  posOfferingGroup: {
    id: 'label.posOfferingGroup',
    defaultMessage: 'POS Offering Group'
  },
  productionLocation: {
    id: 'label.productionLocation',
    defaultMessage: 'Production Location'
  },
  productionLocationId: {
    id: 'label.productionLocationId',
    defaultMessage: 'Production Location ID'
  },
  difference: {
    id: 'label.difference',
    defaultMessage: 'Difference'
  },
  forecastVariance: {
    id: 'label.forecastVariance',
    defaultMessage: 'Forecast Variance'
  },
  variance: {
    id: 'label.variance',
    defaultMessage: 'Variance'
  },
  confidence: {
    id: 'label.confidence',
    defaultMessage: 'Forecast Confidence'
  },
  revenue: {
    id: 'label.revenue',
    defaultMessage: 'Revenue'
  },
  salesLocationId: {
    id: 'label.salesLocationId',
    defaultMessage: 'Sales Location ID'
  },
  dispositionId: {
    id: 'label.dispositionId',
    defaultMessage: 'Disposition ID'
  },
  notes: {
    id: 'label.notes',
    defaultMessage: 'Notes'
  },
  forecastHistory: {
    id: 'label.forecastHistory',
    defaultMessage: 'Forecast history'
  },
  measurement: {
    id: 'label.measurement',
    defaultMessage: 'Measurement'
  },
  measurements: {
    id: 'label.measurements',
    defaultMessage: 'Measurements'
  },
  amountPerSale: {
    id: 'label.amountPerSale',
    defaultMessage: 'Amount per sale'
  },
  amountPerTransaction: {
    id: 'label.amountPerTransaction',
    defaultMessage: 'Amount per transaction'
  },
  comment: {
    id: 'label.comment',
    defaultMessage: 'Comment'
  },
  personnelCosts: {
    id: 'label.personnelCosts',
    defaultMessage: 'Personnel costs'
  },
  salesCosts: {
    id: 'label.salesCosts',
    defaultMessage: 'Sales costs'
  },
  disposalCosts: {
    id: 'label.disposalCosts',
    defaultMessage: 'Disposal costs'
  },
  modifiedBy: {
    id: 'label.modifiedBy',
    defaultMessage: 'Modified by'
  },
  history: {
    id: 'label.history',
    defaultMessage: 'History'
  },
  email: {
    id: 'label.email',
    defaultMessage: 'E-Mail'
  },
  phone: {
    id: 'label.phone',
    defaultMessage: 'Phone'
  },
  password: {
    id: 'label.password',
    defaultMessage: 'Password'
  },
  open: {
    id: 'label.open',
    defaultMessage: 'Open'
  },
  inProgress: {
    id: 'label.inProgress',
    defaultMessage: 'In Progress'
  },
  time: {
    id: 'label.time',
    defaultMessage: 'Time'
  },
  currentPassword: {
    id: 'label.currentPassword',
    defaultMessage: 'Current Password'
  },
  newPassword: {
    id: 'label.newPassword',
    defaultMessage: 'New Password'
  },
  confirmPassword: {
    id: 'label.confirmPassword',
    defaultMessage: 'Confirm password'
  },
  responsibles: {
    id: 'label.responsibles',
    defaultMessage: 'Responsibles'
  },
  responsible: {
    id: 'label.responsible',
    defaultMessage: 'Responsible'
  },
  previousMeasurements: {
    id: 'label.previousMeasurements',
    defaultMessage: 'Previous Measurements'
  },
  goal: {
    id: 'label.goal',
    defaultMessage: 'Goal'
  },
  benchmark: {
    id: 'label.benchmark',
    defaultMessage: 'Benchmark'
  },
  item: {
    id: 'label.item',
    defaultMessage: 'Item'
  },
  itemOrGroup: {
    id: 'label.itemOrGroup',
    defaultMessage: 'Item / Tag'
  },
  locationOrGroup: {
    id: 'label.locationOrGroup',
    defaultMessage: 'Location / Tag'
  },
  items: {
    id: 'label.items',
    defaultMessage: 'Items'
  },
  todoGroups: {
    id: 'label.todoGroups',
    defaultMessage: 'Todo Groups'
  },
  configGroup: {
    id: 'label.configGroup',
    defaultMessage: 'Config Group'
  },
  orderConfigGroup: {
    id: 'label.orderConfigGroup',
    defaultMessage: 'Order Config Group'
  },
  orderConfigGroups: {
    id: 'label.orderConfigGroups',
    defaultMessage: 'Order Config Groups'
  },
  sheetSize: {
    id: 'label.sheetSize',
    defaultMessage: 'Pieces per sheet'
  },
  longevity: {
    id: 'label.longevity',
    defaultMessage: 'Longevity'
  },
  todoBatchSize: {
    id: 'label.todoBatchSize',
    defaultMessage: 'Todo Batch Size'
  },
  orderBatchSize: {
    id: 'label.orderBatchSize',
    defaultMessage: 'Order Batch Size'
  },
  todoNeedThreshold: {
    id: 'label.todoNeedThreshold',
    defaultMessage: 'Todo Demand Threshold'
  },
  todoMinSize: {
    id: 'label.todoMinSize',
    defaultMessage: 'Todo Minimum Size'
  },
  needThreshold: {
    id: 'label.needThreshold',
    defaultMessage: 'Demand Threshold'
  },
  minSize: {
    id: 'label.minSize',
    defaultMessage: 'Minimum order amount'
  },
  role: {
    id: 'label.role',
    defaultMessage: 'Role'
  },
  transactionCount: {
    id: 'label.transactionCount',
    defaultMessage: 'Transaction count'
  },
  intercom: {
    id: 'label.intercom',
    defaultMessage: 'Intercom'
  },
  dashboardPreview: {
    id: 'label.accessToNewDashboardPreview',
    defaultMessage: 'Access to new dashboard preview'
  },
  showLocationRemotePK: {
    id: 'label.showLocationRemotePK',
    defaultMessage: 'Show remote PK of locations'
  },
  type: {
    id: 'label.type',
    defaultMessage: 'Type'
  },
  types: {
    id: 'label.types',
    defaultMessage: 'Types'
  },
  usersLimit: {
    id: 'label.usersLimit',
    defaultMessage: 'Users Limit'
  },
  dataStatus: {
    id: 'label.dataStatus',
    defaultMessage: 'Data Status'
  },
  startDate: {
    id: 'label.startDate',
    defaultMessage: 'Start Date'
  },
  endDate: {
    id: 'label.endDate',
    defaultMessage: 'End Date'
  },
  startDateEndDate: {
    id: 'label.startDateEndDate',
    defaultMessage: 'Start date - End date'
  },
  selectWasteCategory: {
    id: 'label.selectWasteCategory',
    defaultMessage: 'Select waste category'
  },
  address: {
    id: 'label.address',
    defaultMessage: 'Address'
  },
  searchGroup: {
    id: 'label.searchGroup',
    defaultMessage: 'Select group…'
  },
  selectTag: {
    id: 'label.selectTag',
    defaultMessage: 'Select tag…'
  },
  options: {
    id: 'label.options',
    defaultMessage: 'Options'
  },
  searchOption: {
    id: 'label.searchOption',
    defaultMessage: 'Select option…'
  },
  searchOrCreateTag: {
    id: 'label.searchOrCreateTag',
    defaultMessage: 'Search or create tag…'
  },
  searchOrCreateGroup: {
    id: 'label.searchOrCreateGroup',
    defaultMessage: 'Search or create group…'
  },
  searchOrCreateOption: {
    id: 'label.searchOrCreateOption',
    defaultMessage: 'Search or create option…'
  },
  adress1: {
    id: 'label.address1',
    defaultMessage: 'Street'
  },
  adress2: {
    id: 'label.address2',
    defaultMessage: '2nd address line'
  },
  zip: {
    id: 'label.zip',
    defaultMessage: 'Postal Code'
  },
  city: {
    id: 'label.city',
    defaultMessage: 'City'
  },
  country: {
    id: 'label.country',
    defaultMessage: 'Country'
  },
  lastOn: {
    id: 'label.lastOn',
    defaultMessage: 'Last on'
  },
  weekday: {
    id: 'label.weekday',
    defaultMessage: 'Weekday'
  },
  weekdays: {
    id: 'label.weekdays',
    defaultMessage: 'Weekdays'
  },
  image: {
    id: 'label.image',
    defaultMessage: 'Image'
  },
  insertImage: {
    id: 'label.insertImage',
    defaultMessage: 'Insert Image'
  },
  url: {
    id: 'label.url',
    defaultMessage: 'URL'
  },
  urlOfYouTubeVideo: {
    id: 'label.urlOfYouTubeVideo',
    defaultMessage: 'URL of YouTube video'
  },
  embed: {
    id: 'label.embed',
    defaultMessage: 'Embed'
  },
  embedYouTubeVideo: {
    id: 'label.embedYouTubeVideo',
    defaultMessage: 'Embed YouTube Video'
  },
  orderConfig: {
    id: 'label.orderConfig',
    defaultMessage: 'Order Config'
  },
  todoConfig: {
    id: 'label.todoConfig',
    defaultMessage: 'Todo Config'
  },
  rank: {
    id: 'label.rank',
    defaultMessage: 'Rank'
  },
  value: {
    id: 'label.value',
    defaultMessage: 'Value'
  },
  dateRange: {
    id: 'label.dateRange',
    defaultMessage: 'Date Range'
  },
  timeRange: {
    id: 'label.timeRange',
    defaultMessage: 'Time Range'
  },
  dateRangeOptional: {
    id: 'label.dateRangeOptional',
    defaultMessage: 'Date Range (optional)'
  },
  timeRangeOptional: {
    id: 'label.timeRangeOptional',
    defaultMessage: 'Time Range (optional)'
  },
  weekdayOptional: {
    id: 'label.weekdayOptional',
    defaultMessage: 'Weekday (optional)'
  },
  weekdaysOptional: {
    id: 'label.weekdaysOptional',
    defaultMessage: 'Weekdays (optional)'
  },
  quantile: {
    id: 'label.quantile',
    defaultMessage: 'Quantile'
  },
  absoluteValue: {
    id: 'label.absoluteValue',
    defaultMessage: 'Absolute Target Returns'
  },
  salesPercentage: {
    id: 'label.salesPercentage',
    defaultMessage: 'Relative Target Returns (Sold)'
  },
  orderPercentage: {
    id: 'label.orderPercentage',
    defaultMessage: 'Relative Target Returns (Delivery Amount)'
  },
  limitingFactors: {
    id: 'label.limitingFactors',
    defaultMessage: 'Limiting Factors'
  },
  configuration: {
    id: 'label.configuration',
    defaultMessage: 'Configuration'
  },
  inventoryGroupEnabled: {
    id: 'label.inventoryGroupEnabled',
    defaultMessage: 'Inventory group enabled'
  },
  leadInterval: {
    id: 'label.leadInterval',
    defaultMessage: 'Lead interval'
  },
  sustainInterval: {
    id: 'label.sustainInterval',
    defaultMessage: 'Sustain interval'
  },
  leadQuantile: {
    id: 'label.leadQuantile',
    defaultMessage: 'Lead quantile'
  },
  sustainQuantile: {
    id: 'label.sustainQuantile',
    defaultMessage: 'Sustain quantile'
  },
  dailyQuantile: {
    id: 'label.dailyQuantile',
    defaultMessage: 'Daily quantile'
  },
  scheduledDueBy: {
    id: 'label.scheduledDueBy',
    defaultMessage: 'Scheduled due by'
  },
  scheduledLastUntil: {
    id: 'label.scheduledLastUntil',
    defaultMessage: 'Scheduled last until'
  },
  limitByArticle: {
    id: 'label.limitByArticle',
    defaultMessage: 'Limit by article'
  },
  limitByLocation: {
    id: 'label.limitByLocation',
    defaultMessage: 'Limit by location'
  },
  limitByLocationTag: {
    id: 'label.limitByLocationTag',
    defaultMessage: 'Limit by location tag'
  },
  limitByItemTag: {
    id: 'label.limitByItemTag',
    defaultMessage: 'Limit by item tag'
  },
  columns: {
    id: 'label.columns',
    defaultMessage: 'Columns'
  },
  defaultView: {
    id: 'label.defaultView',
    defaultMessage: 'Default View'
  },
  untitled: {
    id: 'label.untitled',
    defaultMessage: 'Untitled'
  },
  orderFactor: {
    id: 'label.orderFactor',
    defaultMessage: 'Order Factor'
  },
  fulfillmentDelay: {
    id: 'label.fulfillmentDelay',
    defaultMessage: 'Fulfillment Delay'
  },
  days: {
    id: 'label.days',
    defaultMessage: '{count, plural, one {Day} other {Days}}'
  },
  daysPast: {
    id: 'label.daysPast',
    defaultMessage: '{count, plural, one {Day} other {Days}} in the past'
  },
  daysFuture: {
    id: 'label.daysFuture',
    defaultMessage: '{count, plural, one {Day} other {Days}} in the future'
  },
  weeksPast: {
    id: 'label.weeksPast',
    defaultMessage: '{count, plural, one {Week} other {Weeks}} in the past'
  },
  weeksFuture: {
    id: 'label.weeksFuture',
    defaultMessage: '{count, plural, one {Week} other {Weeks}} in the future'
  },
  weeks: {
    id: 'label.weeks',
    defaultMessage: '{count, plural, one {Week} other {Weeks}}'
  },
  daysDative: {
    id: 'label.daysDative',
    defaultMessage: '{count, plural, one {Day} other {Days}}'
  },
  weeksDative: {
    id: 'label.weeksDative',
    defaultMessage: '{count, plural, one {Week} other {Weeks}}'
  },
  inDays: {
    id: 'label.inDays',
    defaultMessage: 'in Days'
  },
  version: {
    id: 'label.version',
    defaultMessage: 'Version'
  },
  components: {
    id: 'label.components',
    defaultMessage: 'Components'
  },
  composites: {
    id: 'label.composites',
    defaultMessage: 'Composites'
  },
  composite: {
    id: 'label.composite',
    defaultMessage: 'Composite'
  },
  component: {
    id: 'label.component',
    defaultMessage: 'Component'
  },
  priceReduction: {
    id: 'label.priceReduction',
    defaultMessage: 'Price Reduction'
  },
  batchSize: {
    id: 'label.batchSize',
    defaultMessage: 'Batch Size'
  },
  eventCategory: {
    id: 'label.eventCategory',
    defaultMessage: 'Event Category'
  },
  eventCategories: {
    id: 'label.eventCategories',
    defaultMessage: 'Event Categories'
  },
  referenceEvents: {
    id: 'label.referenceEvents',
    defaultMessage: 'Reference Events'
  },
  referenceItem: {
    id: 'label.referenceItem',
    defaultMessage: 'Reference Item'
  },
  deleteRow: {
    id: 'label.deleteRow',
    defaultMessage: 'Delete Row'
  },
  editRow: {
    id: 'label.editRow',
    defaultMessage: 'Edit Row'
  },
  duplicateRow: {
    id: 'label.duplicateRow',
    defaultMessage: 'Duplicate Row'
  },
  showSessions: {
    id: 'label.showSessions',
    defaultMessage: 'Show active sessions'
  },
  delivery: {
    id: 'label.delivery',
    defaultMessage: 'Delivery Date'
  },
  orderCoverage: {
    id: 'label.orderCoverage',
    defaultMessage: 'Order Coverage'
  },
  updatedAt: {
    id: 'label.updatedAt',
    defaultMessage: 'Updated at'
  },
  stockAtUpdate: {
    id: 'label.stockAtUpdate',
    defaultMessage: 'Stock at update'
  },
  stockAtDelivery: {
    id: 'label.stockAtDelivery',
    defaultMessage: 'Stock at delivery'
  },
  orderProposal: {
    id: 'label.orderProposal',
    defaultMessage: 'Order Proposal'
  },
  neededAmount: {
    id: 'label.neededAmount',
    defaultMessage: 'Needed amount'
  },
  as_of: {
    id: 'label.as_of',
    defaultMessage: 'At the time of'
  },
  addConfig: {
    id: 'label.addConfig',
    defaultMessage: 'Add new rule'
  },
  publicHoliday: {
    id: 'label.publicHoliday',
    defaultMessage: 'Public Holiday'
  },
  publicHolidayShort: {
    id: 'label.publicHolidayShort',
    defaultMessage: 'Hd'
  },
  densityCondensed: {
    id: 'label.densityCondensed',
    defaultMessage: 'Short'
  },
  densityRegular: {
    id: 'label.densityRegular',
    defaultMessage: 'Medium'
  },
  densityRelaxed: {
    id: 'label.densityRelaxed',
    defaultMessage: 'Tall'
  },
  showOpenMeasurements: {
    id: 'label.showOpenMeasurements',
    defaultMessage: 'Show open measurements'
  },
  batchRoundingCutoff: {
    id: 'label.batchRoundingCutoff',
    defaultMessage: 'Batch Size Rounding Cut Off'
  },
  minAmountRoundingCutoff: {
    id: 'label.minAmountRoundingCutoff',
    defaultMessage: 'Minimum Order Rounding Cut Off'
  },
  nEntries: {
    id: 'label.nEntries',
    defaultMessage: '{amount, plural, one {# entry} other {# entries}}'
  },
  nEntriesMore: {
    id: 'label.nEntriesMore',
    defaultMessage: '{amount, plural, one {# more entry…} other {# more entries…}}'
  },
  nItems: {
    id: 'label.nItems',
    defaultMessage: '{amount, plural, one {# item} other {# items}}'
  },
  nItemsMore: {
    id: 'label.nItemsMore',
    defaultMessage: '{amount, plural, one {# more item…} other {# more items…}}'
  },
  nLocations: {
    id: 'label.nLocations',
    defaultMessage: '{amount, plural, one {# location} other {# locations}}'
  },
  nLocationsMore: {
    id: 'label.nLocationsMore',
    defaultMessage: '{amount, plural, one {# more location…} other {# more locations…}}'
  },
  nGroups: {
    id: 'label.nGroups',
    defaultMessage: '{amount, plural, one {# group} other {# groups}}'
  },
  nGroupsMore: {
    id: 'label.nGroupsMore',
    defaultMessage: '{amount, plural, one {# more group…} other {# more groups…}}'
  },
  nItemTags: {
    id: 'label.nItemTags',
    defaultMessage: '{amount, plural, one {# item tag} other {# item tags}}'
  },
  nWeekdays: {
    id: 'label.nWeekdays',
    defaultMessage: '{amount, plural, one {# weekday} other {# weekdays}}'
  },
  nWeekdaysMore: {
    id: 'label.nWeekdaysMore',
    defaultMessage: '{amount, plural, one {# more weekday…} other {# more weekdays…}}'
  },
  nWasteCategories: {
    id: 'label.nWasteCategories',
    defaultMessage: '{amount, plural, one {# waste category} other {# waste categories}}'
  },
  nWasteCategoriesMore: {
    id: 'label.nWasteCategoriesMore',
    defaultMessage: '{amount, plural, one {# more waste category…} other {# more waste categories…}}'
  },
  nResponsibles: {
    id: 'label.nResponsibles',
    defaultMessage: '{amount, plural, one {# responsible} other {# responsibles}}'
  },
  nResponsiblesMore: {
    id: 'label.nResponsiblesMore',
    defaultMessage: '{amount, plural, one {# more responsible…} other {# more responsibles…}}'
  },
  nAccessRights: {
    id: 'label.nAccessRights',
    defaultMessage: '{amount, plural, one {# access right} other {# access rights}}'
  },
  nAccessRightsMore: {
    id: 'label.nAccessRightsMore',
    defaultMessage: '{amount, plural, one {# more access right…} other {# more access rights…}}'
  },
  plannedSoldDiff: {
    id: 'label.plannedSoldDiff',
    defaultMessage: 'Planned No. and Sold No.'
  },
  forecastSoldDiff: {
    id: 'label.forecastSoldDiff',
    defaultMessage: 'Forecast and Sold No.'
  },
  diffPlannedSold: {
    id: 'label.diffPlannedSold',
    defaultMessage: 'Difference btw. Planned No. and Sold No.'
  },
  diffForecastSold: {
    id: 'label.diffForecastSold',
    defaultMessage: 'Difference btw. Forecast and Sold No.'
  },
  download: {
    id: 'label.download',
    defaultMessage: 'Download'
  },
  sortBy: {
    id: 'label.sortBy',
    defaultMessage: 'Sort By'
  },
  sortOrder: {
    id: 'label.sortOrder',
    defaultMessage: 'Sort Order'
  },
  descending: {
    id: 'label.descending',
    defaultMessage: 'Descending'
  },
  ascending: {
    id: 'label.ascending',
    defaultMessage: 'Ascending'
  },
  doneTasks: {
    id: 'label.doneTasks',
    defaultMessage: 'Done Tasks'
  },
  doneTasksInPercent: {
    id: 'label.doneTasksInPercent',
    defaultMessage: 'Done Tasks in %'
  },
  planningDeviation: {
    id: 'label.planningDeviation',
    defaultMessage: 'Planning Deviation'
  },
  planningDeviationInPercent: {
    id: 'label.planningDeviationInPercent',
    defaultMessage: 'Planning Deviation in %'
  },
  forecastDeviation: {
    id: 'label.forecastDeviation',
    defaultMessage: 'Forecast Deviation'
  },
  forecastDeviationInPercent: {
    id: 'label.forecastDeviationInPercent',
    defaultMessage: 'Forecast Deviation in %'
  },
  forecastAccuracy: {
    id: 'label.forecastAccuracy',
    defaultMessage: 'Forecast Accuracy'
  },
  dueTasks: {
    id: 'label.dueTasks',
    defaultMessage: 'Due Tasks'
  },
  dueTasksInPercent: {
    id: 'label.dueTasksInPercent',
    defaultMessage: 'Due Tasks in %'
  },
  lateTasks: {
    id: 'label.lateTasks',
    defaultMessage: 'Late Tasks'
  },
  lateTasksInPercent: {
    id: 'label.lateTasksInPercent',
    defaultMessage: 'Late Tasks in %'
  },
  deletedTasks: {
    id: 'label.deletedTasks',
    defaultMessage: 'Deleted Tasks'
  },
  deletedTasksInPercent: {
    id: 'label.deletedTasksInPercent',
    defaultMessage: 'Deleted Tasks in %'
  },
  correctedTasks: {
    id: 'label.correctedTasks',
    defaultMessage: 'Corrected Tasks'
  },
  correctedTasksInPercent: {
    id: 'label.correctedTasksInPercent',
    defaultMessage: 'Corrected Tasks in %'
  },
  manuallyAddedTasks: {
    id: 'label.manuallyAddedTasks',
    defaultMessage: 'Manually Added Tasks'
  },
  manuallyAddedTasksInPercent: {
    id: 'label.manuallyAddedTasksInPercent',
    defaultMessage: 'Manually Added Tasks in %'
  },
  enteredMeasurements: {
    id: 'label.enteredMeasurements',
    defaultMessage: 'Entered Measurements'
  },
  openMeasurements: {
    id: 'label.openMeasurements',
    defaultMessage: 'Open Measurements'
  },
  defaultDateRange: {
    id: 'label.defaultDateRange',
    defaultMessage: 'Default Date Range'
  },
  sharedBy: {
    id: 'label.sharedBy',
    defaultMessage: 'Shared by'
  },
  sharedOn: {
    id: 'label.sharedOn',
    defaultMessage: 'Shared on'
  },
  dimensions: {
    id: 'label.dimensions',
    defaultMessage: 'Dimensions'
  },
  deleteMeasurements: {
    id: 'label.deleteMeasurements',
    defaultMessage: 'Delete Measurements'
  },
  avgPrice: {
    id: 'label.avgPrice',
    defaultMessage: 'Ø Price'
  },
  useAvgValues: {
    id: 'label.useAvgValues',
    defaultMessage: 'Use average values'
  },
  metricWasteAmount: {
    id: 'label.metricWasteAmount',
    defaultMessage: 'Food Waste Amount'
  },
  metricWasteAmountPerSale: {
    id: 'label.metricWasteAmountPerSale',
    defaultMessage: 'Food Waste Amount per Sale'
  },
  metricWasteAmountPerTransaction: {
    id: 'label.metricWasteAmountPerTransaction',
    defaultMessage: 'Food Waste Amount per Transaction'
  },
  metricWasteFractionOfCogs: {
    id: 'label.metricWasteFractionOfCogs',
    defaultMessage: 'Food Waste Fraction of COGS'
  },
  metricOpenWasteMeasurements: {
    id: 'label.metricOpenWasteMeasurements',
    defaultMessage: 'No. Open Food Waste Measurements'
  },
  metricFoodWasteMeasurements: {
    id: 'label.metricFoodWasteMeasurements',
    defaultMessage: 'No. Food Waste Measurements'
  },
  metricSalesCosts: {
    id: 'label.metricSalesCosts',
    defaultMessage: 'Food Waste COGS'
  },
  metricCO2: {
    id: 'label.metricCO2',
    defaultMessage: 'Food Waste CO2'
  },
  apply: {
    id: 'label.apply',
    defaultMessage: 'Apply'
  },
  pointInTime: {
    id: 'label.pointInTime',
    defaultMessage: 'Time'
  },
  weekNumber: {
    id: 'label.weekNumber',
    defaultMessage: 'WN '
  },
  customPeriod: {
    id: 'label.customPeriod',
    defaultMessage: 'Custom Period'
  },
  floatingPeriod: {
    id: 'label.floatingPeriod',
    defaultMessage: 'Floating Period'
  },
  from: {
    id: 'label.from',
    defaultMessage: 'From'
  },
  to: {
    id: 'label.to',
    defaultMessage: 'To'
  },
  perspectivePast: {
    id: 'label.perspectivePast',
    defaultMessage: 'in the past'
  },
  perspectiveFuture: {
    id: 'label.perspectiveFuture',
    defaultMessage: 'in the future'
  },
  comparisonPeriod: {
    id: 'label.comparisonPeriod',
    defaultMessage: 'Comparison Period'
  },
  unitAgo: {
    id: 'label.unitAgo',
    defaultMessage: '{count} {unit} ago'
  },
  unitFromNow: {
    id: 'label.unitFromNow',
    defaultMessage: 'in {count} {unit}'
  },
  user: {
    id: 'label.user',
    defaultMessage: 'User'
  },
  noAccess: {
    id: 'label.noAccess',
    defaultMessage: 'No Access'
  },
  viewer: {
    id: 'label.viewer',
    defaultMessage: 'Viewer'
  },
  editor: {
    id: 'label.editor',
    defaultMessage: 'Editor'
  },
  owner: {
    id: 'label.owner',
    defaultMessage: 'Owner'
  },
  fractionOfCogs: {
    id: 'label.fractionOfCogs',
    defaultMessage: 'Fraction of COGS'
  },
  totalCogs: {
    id: 'label.totalCogs',
    defaultMessage: 'Total COGS'
  },
  unitGramPerSold: {
    id: 'label.unitGramPerSold',
    defaultMessage: '[g/sale]'
  },
  unitGramPerTransaction: {
    id: 'label.unitGramPerTransaction',
    defaultMessage: '[g/transaction]'
  },
  benchmarkCalculationMode: {
    id: 'label.benchmarkCalculationMode',
    defaultMessage: 'Benchmark calculation'
  },
  kgSavedMetric: {
    id: 'label.kgSavedMetric',
    defaultMessage: 'Food saved'
  },
  dishesSavedMetric: {
    id: 'label.dishesSavedMetric',
    defaultMessage: 'Dishes saved'
  },
  cogsSavedMetric: {
    id: 'label.cogsSavedMetric',
    defaultMessage: 'COGS saved'
  },
  co2SavedMetric: {
    id: 'label.co2SavedMetric',
    defaultMessage: 'CO₂ saved'
  },
  waterSavedMetric: {
    id: 'label.waterSavedMetric',
    defaultMessage: 'Drinking water saved'
  },
  linkTo: {
    id: 'label.linkTo',
    defaultMessage: 'Remap to'
  },
  selectRole: {
    id: 'label.selectRole',
    defaultMessage: 'Select Role'
  },
  add: {
    id: 'label.add',
    defaultMessage: 'Add'
  },
  companyWide: {
    id: 'label.companyWide',
    defaultMessage: 'Company-wide'
  },
  exportAsFile: {
    id: 'label.exportAsFile',
    defaultMessage: 'Export as file'
  },
  importFromFile: {
    id: 'label.importFromFile',
    defaultMessage: 'Import from file'
  },
  benchmarkPeriod: {
    id: 'label.benchmarkPeriod',
    defaultMessage: 'Benchmark Period'
  },
  customBatchSize: {
    id: 'label.customBatchSize',
    defaultMessage: 'Batch size'
  },
  minAmount: {
    id: 'label.minAmount',
    defaultMessage: 'Minimum Order'
  }
})
