import { defineMessages } from 'react-intl'

export default defineMessages({
  thisWeek: {
    id: 'rangePicker.thisWeek',
    defaultMessage: 'This week'
  },
  weekToDate: {
    id: 'rangePicker.weekToDate',
    defaultMessage: 'Week to date'
  },
  lastWeek: {
    id: 'rangePicker.lastWeek',
    defaultMessage: 'Last week'
  },
  nextWeek: {
    id: 'rangePicker.nextWeek',
    defaultMessage: 'Next week'
  },
  weekNumber: {
    id: 'rangePicker.weekNumber',
    defaultMessage: 'WN {weekNumber}'
  },
  week: {
    id: 'rangePicker.week',
    defaultMessage: 'WN '
  },
  customRange: {
    id: 'rangePicker.customRange',
    defaultMessage: 'Custom range'
  },
  customWeek: {
    id: 'rangePicker.customWeek',
    defaultMessage: 'Custom week'
  },
  thisMonth: {
    id: 'rangePicker.thisMonth',
    defaultMessage: 'This month'
  },
  monthToDate: {
    id: 'rangePicker.monthToDate',
    defaultMessage: 'Month to date'
  },
  lastMonth: {
    id: 'rangePicker.lastMonth',
    defaultMessage: 'Last month'
  },
  nextMonth: {
    id: 'rangePicker.nextMonth',
    defaultMessage: 'Next month'
  },
  thisYear: {
    id: 'rangePicker.thisYear',
    defaultMessage: 'This year'
  },
  yearToDate: {
    id: 'rangePicker.yearToDate',
    defaultMessage: 'Year to date'
  },
  lastYear: {
    id: 'rangePicker.lastYear',
    defaultMessage: 'Last year'
  },
  thisQuarter: {
    id: 'rangePicker.thisQuarter',
    defaultMessage: 'This quarter'
  },
  quarterToDate: {
    id: 'rangePicker.quarterToDate',
    defaultMessage: 'Quarter to date'
  },
  lastQuarter: {
    id: 'rangePicker.lastQuarter',
    defaultMessage: 'Last quarter'
  },
  nextQuarter: {
    id: 'rangePicker.nextQuarter',
    defaultMessage: 'Next quarter'
  },
  lastTwoWeeks: {
    id: 'rangePicker.lastTwoWeeks',
    defaultMessage: 'Last two weeks'
  }
})
