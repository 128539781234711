import { CATEGORY1, CATEGORY2, COMPONENT, FOODWASTE_CATEGORY, ITEM, LOCATION, MENULINE } from 'hooks/useChartTabs'
import { DFNS_WEEK_FORMAT } from 'constants/index'
import { endOfWeek, formatISO, parse } from 'date-fns'

import { filter } from 'lodash'
import { useFoodwasteCategories } from './queries/useFoodwasteCategories'
import { useLocations, usePermissions } from './'
import { usePageSettings } from 'hooks/usePageSettings'
import { useIntl } from 'react-intl'
import globalMessages from 'components/globalMessages'

export const useDashboardRefiner = (options = {}) => {
  const intl = useIntl()
  const opts = {
    enabled: true,
    tab: options.tab || null,
    ...options
  }

  const permissions = usePermissions()
  const { data: locations } = useLocations()
  const { data: foodwasteCategories } = useFoodwasteCategories({ enabled: permissions.foodwaste })
  const { set } = usePageSettings()
  if (!opts.enabled) return { ready: false }

  const setType = (tab, value) => {
    let key
    switch (tab) {
      case LOCATION:
        key = 'location'
        break
      case ITEM:
        key = 'itemName'
        break
      case MENULINE:
        key = 'og1'
        break
      case COMPONENT:
        key = 'og2'
        break
      case CATEGORY1:
        key = 'itemCategory1'
        break
      case CATEGORY2:
        key = 'itemCategory2'
        break
      case FOODWASTE_CATEGORY:
        key = 'wasteCategory'
        break
      default:
        break
    }
    if (!key) return
    set({ [key]: [value.toString()] }) // our pickers can only deal with string values
  }

  const ready = locations != null && (!permissions.foodwaste || (permissions.foodwaste && foodwasteCategories != null))

  const handleRangeRefine = (entry, isByWeek) => {
    // support for numeric date points
    if (typeof (entry.date) === 'number') {
      const d = new Date(entry.date)
      if (isByWeek) {
        set({
          range: [formatISO(d, { representation: 'date' }), formatISO(endOfWeek(d), { representation: 'date' })],
          rangeOption: 'customRange'
        })
      } else {
        set({
          range: [formatISO(d, { representation: 'date' }), formatISO(d, { representation: 'date' })],
          rangeOption: 'customRange'
        })
      }
      return
    }

    const byHour = entry.date.includes('T')
    const byWeek = entry.date.includes('W')
    const byDay = !byHour && !byWeek

    // clicking on hourly view is not supported
    if (byDay) {
      set({
        range: [entry.date, entry.date],
        rangeOption: 'customRange'
      })
    } else if (byWeek) {
      const weekBegin = parse(entry.date, DFNS_WEEK_FORMAT, new Date())
      const weekEnd = endOfWeek(weekBegin)
      set({
        range: [formatISO(weekBegin, { representation: 'date' }), formatISO(weekEnd, { representation: 'date' })],
        rangeOption: 'customRange'
      })
    }
  }

  const handleRefine = (entry) => {
    if (!ready) {
      console.warn('Refiner is not ready!')
      return
    }

    if (![LOCATION, ITEM, MENULINE, COMPONENT, CATEGORY1, CATEGORY2, FOODWASTE_CATEGORY].includes(opts.tab)) {
      console.error(`Refining on tab ${opts.tab} is not supported`)
      return
    }

    if (entry.name === intl.formatMessage(globalMessages.other) && (!entry.group || entry.group.length === 0)) {
      console.error('Tried to refine on Other, but have no group attribute.')
      return
    }

    let matches
    switch (opts.tab) {
      case LOCATION:
        if (entry.group) {
          matches = filter(locations, (i) => entry.group.includes(i.name))
          if (entry.group.length !== matches.length) {
            console.warn(`Having ${entry.group.length} entries in OTHER group, but only found ${matches.length} matches in collection. This should not happen!`)
          }
        } else {
          matches = filter(locations, { name: entry.name })
        }
        if (matches && matches.length > 0) {
          setType(opts.tab, matches.map(i => i.id))
        }
        break
      case FOODWASTE_CATEGORY:
        if (entry.group) {
          matches = filter(foodwasteCategories, (i) => entry.group.includes(i.name))
          if (entry.group.length !== matches.length) {
            console.warn(`Having ${entry.group.length} entries in OTHER group, but only found ${matches.length} matches in collection. This should not happen!`)
          }
        } else {
          matches = filter(foodwasteCategories, { name: entry.name })
        }
        if (matches && matches.length > 0) {
          // FIXME: Foodwaste Category Picker works with strings
          setType(opts.tab, matches.map(i => i.id.toString()))
        }
        break
      case ITEM:
        if (entry.group) {
          return
        }
        setType(opts.tab, entry.group ? entry.group : [entry.name])
        break
      case MENULINE:
        setType(opts.tab, entry.group ? entry.group : [entry.name])
        break
      case COMPONENT:
        setType(opts.tab, entry.group ? entry.group : [entry.name])
        break
      case CATEGORY1:
        setType(opts.tab, entry.group ? entry.group : [entry.name])
        break
      case CATEGORY2:
        setType(opts.tab, entry.group ? entry.group : [entry.name])
        break
    }
  }

  return {
    ready,
    handleRefine,
    handleRangeRefine
  }
}
