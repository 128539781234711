import cn from 'classnames'
import s from './Card.module.scss'
import Heading from 'components/Heading'
import { LoadingArea } from 'components/Primitives/LoadingArea'

export const Card = ({ className, bodyClassName, title, extra, children, hasChart, loading, ref, square, type, ...props }) => (
  <div ref={ref} className={cn(s.card, hasChart && s.chartCard, square && s.square, type ? `type-${type}` : null, className)} {...props}>
    {title
      ? (
        <div className={s.head}>
          <div className={s.headWrapper}>
            <div className={s.title}>
              {hasChart
                ? (
                  <Heading className='chart-card-title' type='h5'>
                    {title}
                  </Heading>
                  )
                : title}
            </div>
            {extra && (
              <div className={s.extra}>
                {extra}
              </div>
            )}
          </div>
        </div>
        )
      : extra && (
        <div className={s.extraFloating}>
          {extra}
        </div>
      )}
    <LoadingArea loading={loading}>
      <div className={cn(s.body, bodyClassName)}>
        {children}
      </div>
    </LoadingArea>
  </div>
)
