import { differenceInWeeks, parseISO } from 'date-fns'
import { flatten, keyBy, uniqBy, values } from 'lodash'
import { useDataQuery } from '..'
import { useMemo } from 'react'

import { DEFAULT_WHERE } from 'constants/index'
import { usePageSettings } from 'hooks/usePageSettings'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { DEFAULT_OMIT_FORECAST_CHART, useChartQueryModifier } from 'hooks/useChartQueryModifier'
import { Filter } from 'hooks/useAvailableFilters'
import { useItemEntities } from './useItemEntities'

const useOfferingsGrid = (options) => {
  const opts = {
    hasMenulines: true,
    enabled: true,
    ...options
  }

  const { settings } = usePageSettings()
  const selectedCustomer = useSelectedCustomer()

  const range = settings[Filter.DATE_RANGE]?.value || [null, null]
  const diffInWeeks = differenceInWeeks(parseISO(range[0]), parseISO(range[1]))
  const whereParams = useChartQueryModifier(DEFAULT_OMIT_FORECAST_CHART, { item__name: 'item' })

  const params = whereParams
    ? {
        cacheKey: 'offerings-grid',
        group_by: [
          opts.hasMenulines ? settings.og2Collapsed ? 'menuline_item_id' : 'meco_item' : 'item__name',
          'date'
        ],
        order_by: [
          'item__name',
          opts.hasMenulines ? settings.og2Collapsed ? 'menuline_item_id' : 'meco_item' : 'item__name',
          'date'
        ],
        where: [
          ...DEFAULT_WHERE,
          ...whereParams
        ]
      }
    : null

  const isEnabled = opts.enabled &&
    selectedCustomer != null &&
    diffInWeeks === 0 &&
    settings.location != null &&
    params != null

  const { data, status, error, isFetching } = useDataQuery(params,
    isEnabled, null, { staleTime: 300000 }
  )

  const flatEntries = useMemo(() => {
    if (!data) { return }

    const flat = flatten(flatten(values(data)).map(i => flatten(values(i))))
    return uniqBy(flat, 'id')
  }, [data])

  const { data: itemsData } = useItemEntities({
    enabled: flatEntries != null,
    ids: flatEntries ? flatEntries.map(i => i.item) : []
  })

  return {
    data: data && itemsData ? data : null,
    entries: flatEntries,
    items: keyBy(itemsData, 'id'),
    status,
    error,
    isFetching
  }
}

export default useOfferingsGrid
