import { BAKERY, BUSINESS, STUDENT_UNION } from 'constants/index'
import { FIELD } from './constants'

export const PROPERTIES =
  // Open Points: define what properties can group by hour, define what groupings are available, default where clauses
  [
    {
      key: 'transaction_revenue',
      color: 'accentCornflower',
      strokeShade: 50,
      fillShade: 5,
      returnType: 'currency',
      labelKey: 'avgTransactionRevenue',
      dataSources: [
        {
          from: 'Transaction',
          select: 'revenue',
          aggregate: 'avg',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'item_offering__meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'num_vanished',
      color: 'accentLavender',
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'numVanished',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'num_vanished',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'fulfilled_amount',
      color: 'accentMarigold',
      strokeShade: 50,
      fillShade: 5,
      customerTypes: [BAKERY],
      returnType: 'number',
      labelKey: 'fullfilledAmount',
      dataSources: [
        {
          from: 'Order',
          select: 'fulfilled_amount',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'ordered_amount',
      color: 'accentLilac',
      strokeShade: 50,
      fillShade: 5,
      customerTypes: [BAKERY],
      returnType: 'number',
      labelKey: 'orderedAmount',
      dataSources: [
        {
          from: 'Order',
          select: 'ordered_amount',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'requested_amount',
      color: 'accentGras',
      strokeShade: 50,
      fillShade: 5,
      customerTypes: [BAKERY],
      returnType: 'number',
      labelKey: 'requestedAmount',
      dataSources: [
        {
          from: 'Order',
          select: 'requested_amount',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'revenue',
      color: 'accentHydrangea',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'currency',
      labelKey: 'revenue',
      dataSources: [
        {
          from: 'Sale',
          select: 'revenue',
          aggregate: 'sum',
          condition: 'groupByHour',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'item_offering__meco_item__forecasts', operator: '=', value: true },
            { field: 'amount', operator: '!=', value: 0 }
          ]
        },
        {
          from: 'ItemOffering',
          select: 'revenue',
          aggregate: 'sum',
          condition: 'default',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        }
      ]
    },
    {
      key: 'num_sold',
      color: 'accentHydrangea',
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'soldNo',
      line: {
        compType: 'area'
      },
      dataSources: [
        {
          from: 'Sale',
          select: 'amount',
          aggregate: 'sum',
          condition: 'groupByHour',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'item_offering__meco_item__forecasts', operator: '=', value: true },
            { field: 'amount', operator: '!=', value: 0 }
          ]
        },
        {
          from: 'ItemOffering',
          select: 'num_sold',
          aggregate: 'sum',
          condition: 'default',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        }
      ]
    },
    {
      key: 'num_planned',
      color: 'accentLavender',
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'planned',
      customerTypes: [STUDENT_UNION, BUSINESS],
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'num_planned',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'num_forecast',
      color: 'accentGras',
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'forecastNo',
      customerTypes: [STUDENT_UNION, BUSINESS],
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'num_forecast',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    // {
    //   key: 'planned_sold_diff',
    //   color: 'accentMarigold',
    //   strokeShade: 50,
    //   fillShade: 5,
    //   returnType: 'number',
    //   labelKey: 'diffPlannedSold',
    //   customerTypes: [STUDENT_UNION, BUSINESS],
    //   dataSources: [
    //     {
    //       from: 'ItemOffering',
    //       select: 'planned_sold_diff',
    //       aggregate: 'sum',
    //       allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
    //       allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
    //       defaultRangeGroup: 'date',
    //       defaultWhere: [
    //         { field: 'meco_item__forecasts', operator: '=', value: true },
    //         { field: 'num_sold', operator: '!in', value: [0, null] },
    //         { field: 'num_planned', operator: '!=', value: null }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   key: 'forecast_sold_diff',
    //   color: 'accentPeachrose',
    //   strokeShade: 50,
    //   fillShade: 5,
    //   returnType: 'number',
    //   labelKey: 'diffForecastSold',
    //   customerTypes: [STUDENT_UNION, BUSINESS],
    //   dataSources: [
    //     {
    //       from: 'ItemOffering',
    //       select: 'forecast_sold_diff',
    //       aggregate: 'sum',
    //       allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
    //       allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
    //       defaultRangeGroup: 'date',
    //       defaultWhere: [
    //         { field: 'meco_item__forecasts', operator: '=', value: true },
    //         { field: 'num_sold', operator: '!in', value: [0, null] },
    //         { field: 'forecast_sold_diff', operator: '!=', value: null }
    //       ]
    //     }
    //   ]
    // },
    {
      key: 'total_cogs',
      color: 'accentLilac',
      strokeShade: 50,
      fillShade: 5,
      returnType: 'currency',
      labelKey: 'cogs',
      customerTypes: [STUDENT_UNION, BUSINESS],
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Sale',
          select: 'total_cogs',
          aggregate: 'sum',
          condition: 'groupByHour',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'item_offering__meco_item__forecasts', operator: '=', value: true },
            { field: 'amount', operator: '!=', value: 0 }
          ]
        },
        {
          from: 'ItemOffering',
          select: 'total_cogs',
          aggregate: 'sum',
          condition: 'default',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        }
      ]
    },
    {
      key: 'transactions',
      color: 'accentSpringstar',
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'transactionCount',
      dataSources: [
        {
          from: 'Transaction',
          select: 'name',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'item_offering__meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'num_unsold',
      color: 'accentRose',
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'unsoldAmount',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'num_unsold',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'co2',
      color: 'accentLilac',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'weight',
      labelKey: 'metricCO2',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'co2',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'waste_amount',
      color: 'accentRose',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'weight',
      labelKey: 'metricWasteAmount',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'amount',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'cogs_cost',
      color: 'accentPeachrose',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'currency',
      labelKey: 'metricSalesCosts',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'cogs_cost',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'water',
      color: 'accentSpringstar',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'liquid',
      labelKey: 'water',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'water',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'amountPerSale',
      color: 'accentRose',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      foodWasteModes: ['num_sold'],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'gram',
      labelKey: 'metricWasteAmountPerSale',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'amount',
          aggregate: 'sum',
          allowedGrouping: [{ field: FIELD.LOCATION, reportTypes: ['table', 'list'] }, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '!=', value: null },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        },
        {
          from: 'Waste',
          select: 'fixed_num_sold',
          aggregate: 'sum',
          allowedGrouping: [{ field: FIELD.LOCATION, reportTypes: ['table', 'list'] }, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '!=', value: null },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        }
      ],
      arithmetic: [
        {
          operator: '*',
          operands: [
            {
              operator: '/',
              operands: [
                '0',
                '1'
              ]
            },
            1000
          ]
        }
      ]
    },
    {
      key: 'amountPerTransaction',
      color: 'accentRose',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      foodWasteModes: ['num_transactions'],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'gram',
      labelKey: 'metricWasteAmountPerTransaction',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'amount',
          aggregate: 'sum',
          allowedGrouping: [{ field: FIELD.LOCATION, reportTypes: ['table', 'list'] }, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '!=', value: null },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        },
        {
          from: 'Waste',
          select: 'fixed_num_transactions',
          aggregate: 'sum',
          allowedGrouping: [{ field: FIELD.LOCATION, reportTypes: ['table', 'list'] }, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '!=', value: null },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        }
      ],
      arithmetic: [
        {
          operator: '*',
          operands: [
            {
              operator: '/',
              operands: [
                '0',
                '1'
              ]
            },
            1000
          ]
        }
      ]
    },
    {
      key: 'fractionOfCogs',
      color: 'accentRose',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      foodWasteModes: ['cogs'],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'metricWasteFractionOfCogs',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'cogs_cost',
          aggregate: 'sum',
          allowedGrouping: [{ field: FIELD.LOCATION, reportTypes: ['table', 'list'] }, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '!=', value: null },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        },
        {
          from: 'Waste',
          select: 'fixed_sales_cogs',
          aggregate: 'sum',
          allowedGrouping: [{ field: FIELD.LOCATION, reportTypes: ['table', 'list'] }, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '!=', value: null },
            { field: 'num_sold', operator: '!in', value: [0, null] }
          ]
        }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: [
            '0',
            '1'
          ]
        }
      ]
    },
    {
      key: 'planningDeviation',
      color: 'accentLavender',
      returnType: 'number',
      strokeShade: 50,
      fillShade: 5,
      labelKey: 'planningDeviation',
      customerTypes: [STUDENT_UNION, BUSINESS],
      dataSources: [
        { propKey: 'num_planned' },
        { propKey: 'num_sold' }
      ],
      arithmetic: [
        {
          operator: '-',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'planningDeviationInPercent',
      color: 'accentLavender',
      returnType: 'percent',
      strokeShade: 100,
      fillShade: 25,
      labelKey: 'planningDeviationInPercent',
      customerTypes: [STUDENT_UNION, BUSINESS],
      dataSources: [
        { propKey: 'num_planned' },
        { propKey: 'num_sold' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: [
            {
              operator: '-',
              operands: ['0', '1']
            },
            '1'
          ]
        }
      ]
    },
    {
      key: 'forecastDeviation',
      color: 'accentGras',
      returnType: 'number',
      strokeShade: 50,
      fillShade: 5,
      labelKey: 'forecastDeviation',
      customerTypes: [STUDENT_UNION, BUSINESS],
      dataSources: [
        { propKey: 'num_forecast' },
        { propKey: 'num_sold' }
      ],
      arithmetic: [
        {
          operator: '-',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'forecastDeviationInPercent',
      color: 'accentGras',
      returnType: 'percent',
      strokeShade: 100,
      fillShade: 25,
      labelKey: 'forecastDeviationInPercent',
      customerTypes: [STUDENT_UNION, BUSINESS],
      dataSources: [
        { propKey: 'num_forecast' },
        { propKey: 'num_sold' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: [
            {
              operator: '-',
              operands: ['0', '1']
            },
            '1'
          ]
        }
      ]
    },
    // {
    //   key: 'forecast_variance',
    //   color: 'accentMarigold',
    //   strokeShade: 50,
    //   fillShade: 5,
    //   returnType: 'number',
    //   labelKey: 'forecastVariance',
    //   customerTypes: [STUDENT_UNION, BUSINESS],
    //   line: {
    //     compType: 'area',
    //     strokeWidth: 0
    //   },
    //   dataSources: [
    //     {
    //       propKey: 'num_forecast'
    //     },
    //     {
    //       from: 'ItemOffering',
    //       select: 'forecast_variance',
    //       aggregate: 'sum',
    //       allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
    //       allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
    //       defaultRangeGroup: 'date',
    //       defaultWhere: [
    //         { field: 'meco_item__forecasts', operator: '=', value: true }
    //       ]
    //     }
    //   ],
    //   arithmetic: [
    //     {
    //       operator: '-',
    //       operands: ['0', '1']
    //     },
    //     {
    //       operator: '+',
    //       operands: ['0', '1']
    //     }
    //   ]
    // },
    {
      key: 'tasksDue',
      color: 'accentMarigold',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'dueTasks',
      dataSources: [
        {
          from: 'ToDo',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          modifyParams: {
            date: 'due_by__date'
          }
        }
      ]
    },
    {
      key: 'tasksDone',
      color: 'accentGras',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'doneTasks',
      dataSources: [
        {
          from: 'ToDo',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'status', operator: '=', value: 'done' }
          ],
          modifyParams: {
            date: 'due_by__date'
          }
        }
      ]
    },
    {
      key: 'tasksDoneInPercent',
      color: 'accentGras',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'doneTasksInPercent',
      dataSources: [
        { propKey: 'tasksDone' },
        { propKey: 'tasksDue' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'tasksLate',
      color: 'accentPeachrose',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'lateTasks',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ToDo',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'status', operator: '=', value: 'open' },
            { field: 'due_by', operator: 'lte', value: '$now' }
          ],
          modifyParams: {
            date: 'due_by__date'
          }
        }
      ]
    },
    {
      key: 'tasksLateInPercent',
      color: 'accentPeachrose',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'lateTasksInPercent',
      dataSources: [
        { propKey: 'tasksLate' },
        { propKey: 'tasksDue' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'tasksDeleted',
      color: 'accentRose',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'deletedTasks',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ToDo',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'status', operator: '=', value: 'deleted' }
          ],
          modifyParams: {
            date: 'due_by__date'
          }
        }
      ]
    },
    {
      key: 'tasksDeletedInPercent',
      color: 'accentRose',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'deletedTasksInPercent',
      dataSources: [
        { propKey: 'tasksDeleted' },
        { propKey: 'tasksDue' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'tasksCorrected',
      color: 'accentHydrangea',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'correctedTasks',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ToDo',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'status', operator: '=', value: 'done' },
            { field: 'requested_amount', operator: '!=', value_lookup: 'fulfilled_amount' }
          ],
          modifyParams: {
            date: 'due_by__date'
          }
        }
      ]
    },
    {
      key: 'tasksCorrectedInPercent',
      color: 'accentHydrangea',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'correctedTasksInPercent',
      dataSources: [
        { propKey: 'tasksCorrected' },
        { propKey: 'tasksDue' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'tasksManuallyAdded',
      color: 'accentLavender',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'manuallyAddedTasks',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ToDo',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', 'hour', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'status', operator: '!=', value: 'deleted' },
            { field: 'created_by_type', operator: '=', value: 'user' }
          ],
          modifyParams: {
            date: 'due_by__date'
          }
        }
      ]
    },
    {
      key: 'tasksManuallyAddedInPercent',
      color: 'accentLavender',
      permissions: ['todo'],
      customerTypes: [BAKERY],
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'manuallyAddedTasksInPercent',
      dataSources: [
        { propKey: 'tasksManuallyAdded' },
        { propKey: 'tasksDue' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'returnRate',
      color: 'accentPeachrose',
      customerTypes: [BAKERY],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'percent',
      labelKey: 'returnRate',
      invertedDeltaArrow: true,
      dataSources: [
        {
          propKey: 'num_unsold'
        },
        {
          propKey: '$returnRatioField'
        }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: [
            '0',
            '1'
          ]
        }
      ]
    },
    {
      key: 'relCogs',
      color: 'accentLilac',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'relCogs',
      customerTypes: [STUDENT_UNION, BUSINESS],
      invertedDeltaArrow: true,
      dataSources: [
        {
          propKey: 'total_cogs'
        },
        {
          propKey: 'revenue'
        }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: [
            '0',
            '1'
          ]
        }
      ]
    },
    {
      key: 'enteredMeasurements',
      color: 'accentGerbera',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'metricFoodWasteMeasurements',
      dataSources: [
        {
          from: 'Waste',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '!=', value: null }
          ]
        }
      ]
    },
    {
      key: 'openMeasurements',
      color: 'accentLavender',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      strokeShade: 50,
      fillShade: 5,
      returnType: 'number',
      labelKey: 'metricOpenWasteMeasurements',
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'Waste',
          select: 'id',
          aggregate: 'count',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'amount', operator: '=', value: null }
          ]
        }
      ]
    },
    {
      key: 'price',
      color: 'accentGerbera',
      strokeShade: 50,
      fillShade: 5,
      returnType: 'currency',
      labelKey: 'avgPrice',
      dataSources: [
        {
          propKey: 'revenue'
        },
        {
          propKey: 'num_sold'
        }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: ['0', '1']
        }
      ]
    },
    {
      key: 'returned_value',
      color: 'accentRose',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'currency',
      labelKey: 'returnValue',
      customerTypes: [BAKERY],
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'returned_value',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'returned_value_rate',
      color: 'accentPeachrose',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'percent',
      labelKey: 'returnValueRate',
      customerTypes: [BAKERY],
      invertedDeltaArrow: true,
      dataSources: [
        {
          propKey: 'returned_value'
        },
        {
          propKey: '$returnValueRatioField'
        }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: [
            '0',
            '1'
          ]
        }
      ]
    },
    {
      key: 'requested_value',
      color: 'accentGras',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'currency',
      labelKey: 'requestedValue',
      customerTypes: [BAKERY],
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'requested_value',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'ordered_value',
      color: 'accentLilac',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'currency',
      labelKey: 'orderValue',
      customerTypes: [BAKERY],
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'ordered_value',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'fulfilled_value',
      color: 'accentMarigold',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'currency',
      labelKey: 'fulfilmentValue',
      customerTypes: [BAKERY],
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'fulfilled_value',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'vanished_value',
      color: 'accentLavender',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'currency',
      labelKey: 'vanishedValue',
      customerTypes: [BAKERY],
      invertedDeltaArrow: true,
      dataSources: [
        {
          from: 'ItemOffering',
          select: 'vanished_value',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2, FIELD.OFFERING_GROUP_1, FIELD.OFFERING_GROUP_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date',
          defaultWhere: [
            { field: 'meco_item__forecasts', operator: '=', value: true }
          ]
        }
      ]
    },
    {
      key: 'kg_saved',
      color: 'accentRose',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'weight',
      labelKey: 'kgSavedMetric',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      dataSources: [
        {
          from: 'Waste',
          select: 'kg_saved',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'dishes_saved',
      color: 'accentCornflower',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'number',
      labelKey: 'dishesSavedMetric',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      dataSources: [
        {
          from: 'Waste',
          select: 'dishes_saved',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'kg_co2_saved',
      color: 'accentGerbera',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'weight',
      labelKey: 'co2SavedMetric',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      dataSources: [
        {
          from: 'Waste',
          select: 'kg_co2_saved',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'cogs_saved',
      color: 'accentMarigold',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'currency',
      labelKey: 'cogsSavedMetric',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      dataSources: [
        {
          from: 'Waste',
          select: 'cogs_saved',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'l_water_saved',
      color: 'accentHydrangea',
      strokeShade: 100,
      fillShade: 25,
      returnType: 'liquid',
      labelKey: 'waterSavedMetric',
      customerTypes: [STUDENT_UNION, BUSINESS],
      permissions: ['foodwaste'],
      dataSources: [
        {
          from: 'Waste',
          select: 'l_water_saved',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.WASTE_CATEGORY],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'net_flow',
      color: 'accentLilac',
      strokeShade: 75,
      fillShade: 10,
      customerTypes: [BAKERY],
      permissions: ['isSuperUser'],
      returnType: 'number',
      labelKey: 'netFlow',
      dataSources: [
        {
          from: 'Order',
          select: 'net_flow',
          aggregate: 'sum',
          allowedGrouping: [FIELD.LOCATION, FIELD.ITEM, FIELD.ITEM_CATEGORY_1, FIELD.ITEM_CATEGORY_2],
          allowedGroupingInternal: ['week', 'date', FIELD.LOCATION_PK, FIELD.ITEM_PK],
          defaultRangeGroup: 'date'
        }
      ]
    },
    {
      key: 'relative_net_flow',
      color: 'accentLilac',
      strokeShade: 100,
      fillShade: 25,
      customerTypes: [BAKERY],
      permissions: ['isSuperUser'],
      returnType: 'percent',
      labelKey: 'relativeNetFlow',
      dataSources: [
        { propKey: 'net_flow' },
        { propKey: 'fulfilled_amount' }
      ],
      arithmetic: [
        {
          operator: '/',
          operands: ['0', '1']
        }
      ]
    }
  ]
