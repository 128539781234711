import { Badge, Tooltip } from 'components/Primitives'
import s from './CellRenderer.module.scss'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { truncateItems } from 'utils'

const renderTag = (tag, type, colDef, intl, single) => {
  const t = typeof (tag)
  const tagsCollection = colDef.tagCollection || []

  const realType = colDef.colorMap ? colDef.colorMap[tag] || type : type
  const realValue = colDef.localizationMap
    ? colDef.localizationMap[tag]
        ? intl.formatMessage(colDef.localizationMap[tag])
        : tag
    : tag
  const valueProp = colDef.valueProp || 'name'

  const hasTooltip = colDef.singleWithTooltip && single

  switch (t) {
    case 'string':
      return [<Badge key={valueProp} size='s' type={realType} tooltip={hasTooltip ? realValue : undefined} tooltipSide='top'>{realValue}</Badge>, realValue]
    case 'number':
      const tagObj = tagsCollection.find(t => t.id === tag)
      return tagObj
        ? [<Badge key={valueProp} size='s' hex={tagObj.color} tooltip={hasTooltip ? tagObj[valueProp] : undefined} tooltipSide='top'>{tagObj[valueProp]}</Badge>, tagObj[valueProp]]
        : [<Badge key={valueProp} size='s' type={realType} tooltip={hasTooltip ? realValue : undefined} tooltipSide='top'>{realValue}</Badge>, realValue]
    case 'object':
      return [<Badge key={valueProp} size='s' hex={tag.color} type={tag.color ? undefined : realType} tooltip={hasTooltip ? tag[valueProp] : undefined} tooltipSide='top'>{tag[valueProp]}</Badge>, tag[valueProp]]
  }
}

const renderArray = (tags, type, colDef, intl) => {
  if (tags.length === 0) return null
  if (!colDef.groupWithTooltip || (colDef.groupWithTooltip && tags.length === 1)) {
    return tags
      .map(tag => renderTag(tag, type, colDef, intl, tags.length === 1))
      .sort((a, b) => `${a[1]}`.localeCompare(`${b[1]}`))
      .map(t => t[0])
  }

  // in groupWithTooltip mode we only show one badge for the whole group and show the items in the tooltip
  let nMoreItems, nItems
  if (['Locations', 'AccessRights', 'Responsibles', 'Weekdays', 'Items'].includes(colDef.groupType)) {
    nMoreItems = labelMessages[`n${colDef.groupType}More`] || labelMessages.nEntriesMore
    nItems = labelMessages[`n${colDef.groupType}`] || labelMessages.nEntries
  } else {
    nMoreItems = labelMessages.nEntriesMore
    nItems = labelMessages.nEntries
  }

  const tooltipList = truncateItems(tags)

  const ttLines = tooltipList.items.map(item => {
    const isObj = typeof (item) === 'object'
    const itemValue = isObj ? item[colDef.valueProp || 'name'] : item
    const itemKey = isObj ? item[colDef.keyProp || 'id'] : item
    const lValue = colDef.localizationMap
      ? colDef.localizationMap[itemValue]
          ? intl.formatMessage(colDef.localizationMap[itemValue])
          : itemValue
      : itemValue
    return [itemKey, lValue]
  }).sort((a, b) => `${a[1]}`.localeCompare(`${b[1]}`)
  ).map(([itemKey, lValue]) => {
    return <div key={itemKey}>{lValue}</div>
  })
  if (tooltipList.leftOver > 0) { ttLines.push(<div key='more-items' className='italic'>{intl.formatMessage(nMoreItems, { amount: tooltipList.leftOver })}</div>) }

  return (
    <Tooltip
      side={colDef.tooltipSide || 'left'}
      title={<div className='flex flex-col'>{ttLines}</div>}
    >
      {renderTag(intl.formatMessage(nItems, { amount: tags.length }), type, colDef, intl, tags.length === 1)[0]}
    </Tooltip>
  )
}

export const TagsRenderer = (props) => {
  const intl = useIntl()
  const { value, colDef } = props
  if (!value) return null
  const type = colDef.defaultType || 'accentNeutral'
  return (
    <div className={s.tagLine}>
      {Array.isArray(value)
        ? renderArray(value, type, colDef, intl)
        : renderTag(value, type, colDef, intl, true)[0]}
    </div>
  )
}
