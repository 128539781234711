import s from './Dialog.module.scss'
import { Button } from 'components/Button'
import { FormattedMessage } from 'react-intl'
import globalMessages from 'components/globalMessages'
import { useAppleHomebar, useIsMobile } from 'hooks'
import classNames from 'classnames'
import { ProgressBar } from '../ProgressBar'

export const DialogFooter = ({ onCancel, onPrimary, danger, confirm, primaryText, cancelText, primaryLoading, primaryDisabled, hideCancel, primaryIcon, progress }) => {
  const homebar = useAppleHomebar()
  const isMobile = useIsMobile()

  const inner = (
    <div className={classNames('modal-footer', s.footer, hideCancel || !onPrimary ? s.oneCol : null)}>
      {progress != null && <ProgressBar progress={progress} className='absolute bottom-0 left-0' />}
      {!hideCancel && (
        <Button data-action='cancel' size='l' type='secondary' onClick={onCancel} fullWidth>
          {cancelText || <FormattedMessage {...confirm ? globalMessages.no : globalMessages.cancel} />}
        </Button>
      )}
      {onPrimary && (
        <Button data-action='submit' size='l' fullWidth disabled={primaryDisabled || primaryLoading} type={danger ? 'danger-secondary' : 'primary'} onClick={onPrimary} loading={primaryLoading} icon={primaryIcon}>
          {primaryText || <FormattedMessage {...confirm ? globalMessages.yes : globalMessages.save} />}
        </Button>
      )}
    </div>
  )

  if (isMobile) {
    // FIXME: Test and fix on iOS
    // style={isMobile ? { height: homebar.offset } : undefined}
    return (
      <div className='flex flex-col justify-center w-full'>
        {inner}
        {/* Home Bar Spacer for iOS Devices */}
        <div className='w-full transition-all duration-200 transform' style={{ height: homebar.height }} />
      </div>
    )
  }

  return inner
}
