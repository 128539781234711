import React from 'react'
import { SearchSelect } from 'components/SearchSelect'
import globalMessages from 'components/globalMessages'
import messages from './messages'
import { useIntl } from 'react-intl'

export default ({ value, onChange, flat, medium, single, placeholder, className, altPlaceholder, required }) => {
  const intl = useIntl()

  const options = [
    { key: 'safety_setting_rights', label: intl.formatMessage(globalMessages.accessSafetySettings) },
    { key: 'food_waste_access', label: intl.formatMessage(globalMessages.accessFoodwaste) },
    { key: 'offering_view_access', label: intl.formatMessage(globalMessages.accessOfferingView) }
  ]

  return (
    <SearchSelect
      className={className}
      type={flat ? undefined : 'round'}
      size={medium ? undefined : 'small'}
      placeholder={placeholder || intl.formatMessage(altPlaceholder ? messages.accessRightsTitleMulti : globalMessages.accessRights)}
      value={value}
      onChange={onChange}
      optionsLabel={intl.formatMessage(globalMessages.accessRights)}
      prefixIcon='Locked'
      single={single}
      options={options}
      required={required}
    />
  )
}
