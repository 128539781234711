import { Checkbox, Tooltip } from 'components/Primitives'
import s from './CellRenderer.module.scss'

export const CheckboxRenderer = (props) => {
  const { value, colDef } = props
  if (!colDef.nullAsFalse && value == null) return null

  const cellClass = colDef.editable
    ? 'editable'
    : typeof (colDef.cellClass) === 'function'
      ? colDef.cellClass(props)
      : colDef.cellClass
  const isDisabled = cellClass !== 'editable'

  const val = value == null ? false : value

  const handleChange = (newValue) => props.setValue(newValue)

  const checkboxComponent = <Checkbox onCheckedChange={handleChange} checked={val} disabled={isDisabled} />

  const inner = isDisabled && colDef.nonEditableText
    ? (
      <Tooltip title={colDef.nonEditableText}>
        {checkboxComponent}
      </Tooltip>
      )
    : checkboxComponent

  return (
    <div className={s.flexCenterCell}>{inner}</div>
  )
}
