import React from 'react'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import cn from 'classnames'
import { DEFAULT_DATE_FORMAT } from 'constants/index'
import { FormField } from 'components/Form'
import PeriodPicker from 'components/PeriodPicker'
import s from './DateRangePicker.module.scss'
import labelMessages from 'components/labelMessages'
import { formatLocalized, parseDate } from 'utils/datetime'
import { Icon, Text } from 'components/Primitives'

/** This DateRangePicker is used in Foodwaste Monitoring form */
const DateRangePicker = ({ className, name, label, placeholder, allowReset, getCalendarContainer, helpText }) => {
  const intl = useIntl()
  const { values } = useFormikContext()

  const hasValue = values[name] && values[name].length > 0

  const renderInput = () => {
    return (
      <div className={cn(s.selection, hasValue && s.hasValue)}>
        <Icon name='Calendar' className='mr-2' />
        <Text color='gray'>{hasValue
          ? values[name]
              .map((item) => formatLocalized(parseDate(item), undefined, intl))
              .join(' - ')
          : placeholder || intl.formatMessage(labelMessages.startDateEndDate)}
        </Text>
      </div>
    )
  }

  return (
    <FormField
      name={name}
      as={PeriodPicker}
      label={<Text title={label} color='black'>{label}</Text>}
      renderInput={renderInput}
      className={cn(s.picker, className)}
      dropdownClassName={s.popup}
      separator=''
      format={DEFAULT_DATE_FORMAT}
      allowReset={allowReset}
      getCalendarContainer={getCalendarContainer}
      helpText={helpText}
    />
  )
}

export default DateRangePicker
