import { defineMessages } from 'react-intl'

export default defineMessages({
  fromDate: {
    id: 'value.fromDate',
    defaultMessage: 'from {date}'
  },
  untilDate: {
    id: 'value.untilDate',
    defaultMessage: 'until {date}'
  },
  processed: {
    id: 'value.processed',
    defaultMessage: 'Processed'
  },
  unprocessed: {
    id: 'value.unprocessed',
    defaultMessage: 'Unprocessed'
  }
})
