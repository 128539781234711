import { create } from 'zustand'

export const useChartConfiguratorStore = create((set, get) => ({
  definition: {},
  definitionCallback: null,
  setDefinition: (definition) => set(() => {
    if (!definition) return { definition: {} }
    return { definition }
  }),
  updateDefinition: (definition) => set((state) => ({ definition: { ...state.definition, ...definition } })),
  setDefinitionCallback: (definitionCallback) => set(() => ({ definitionCallback })),
  grouping: null,
  setGrouping: (grouping) => {
    const state = get()
    if (!state || grouping != state.grouping) set(() => ({ grouping }))
  },
  valuesLeft: [],
  setValuesLeft: (valuesLeft) => {
    const state = get()
    if (!state || (valuesLeft != state.valuesLeft && JSON.stringify(valuesLeft) != JSON.stringify(state.valuesLeft))) set(() => ({ valuesLeft }))
  },
  valuesRight: [],
  setValuesRight: (valuesRight) => {
    const state = get()
    if (!state || (valuesRight != state.valuesRight && JSON.stringify(valuesRight) != JSON.stringify(state.valuesRight))) set(() => ({ valuesRight }))
  },
  error: 'loading',
  setError: (error) => set(() => ({ error })),
  resetConfigurator: () => set(() => ({
    definition: {},
    definitionCallback: null,
    grouping: null,
    valuesLeft: [],
    valuesRight: [],
    error: 'loading',
    shouldGoBackToTemplates: false
  })),
  shouldGoBackToTemplates: false,
  setShouldGoBackToTemplates: (shouldGoBackToTemplates) => set(() => ({ shouldGoBackToTemplates }))
}))
