import { omit } from 'lodash'
import { usePageSettings } from 'hooks/usePageSettings'

export const useFilterSetter = (key, externalSetter, externalSettings) => {
  const { settings, set } = usePageSettings()
  const relevantSettings = externalSettings || (settings ? settings[key] || {} : {})

  const setFilters = (filters) => {
    if (filters.range !== undefined || filters.rangeOption !== undefined) console.warn('Filters have old range structure, correcting...', filters)

    const f = { ...filters }
    const keys = Object.keys(f)

    if (!keys.includes('page') && !keys.includes('pageSize') && !keys.includes('sorter')) {
      if (relevantSettings.pagination) {
        f.pagination = {
          current: 1,
          pageSize: relevantSettings.pagination.pageSize
        }
      } else {
        f.page = 1
      }
    }

    const obj = {
      [key]: {
        ...relevantSettings,
        ...omit(f, ['sorter', 'range', 'rangeOption']),
        ...(f.sorter && f.sorter.field ? { ordering: `${f.sorter.order === 'descend' ? '-' : ''}${f.sorter.field}` } : undefined)
      }
    }

    if (externalSetter) {
      externalSetter(obj[key])
    } else {
      set(obj)
    }
  }

  return setFilters
}
