import React, { useMemo } from 'react'
import { SearchSelect } from 'components/SearchSelect'
import useDemo from 'hooks/useDemo'
import { useIntl } from 'react-intl'
import { useLocations } from 'hooks'
import labelMessages from 'components/labelMessages'
import { useItems } from 'hooks/queries/useItems'
import { getNameWithParantheses } from 'utils'

// FIXME: remove v1 when Dashboard v1 is gone
export const ItemPicker = ({ name, type, alwaysOn, allowClear, variant, value, onChange, gray, withPortal, location, range, offeringGroup1, offeringGroup2, itemGroup1, itemGroup2, fromItemOffering, fromOrder, required, single, placeholder, optionsLabel, hideWhenEmpty, hasError, isFormField, todoEnabled, v1, role, disabled }) => {
  const intl = useIntl()
  const { demo } = useDemo()

  const allLocations = useLocations()

  const { data, loading, isFetching } = useItems({
    enabled: alwaysOn ? true : (range || location) != null,
    range,
    locations: Array.isArray(location) ? !(!location.length || location.length === allLocations.length) ? location : null : [location],
    fromItemOffering,
    fromOrder,
    offeringGroup1,
    offeringGroup2,
    itemGroup1,
    itemGroup2,
    todoEnabled
  })

  const isSingle = (variant && variant === 'single') || single

  const options = useMemo(() => {
    if (!data) return []
    // FIXME: remove when dashboard v1 is gone
    if (v1) {
      return data.map((e) => ({
        key: e.name,
        label: e.name
      }))
    }
    return data
      ? data.map((e) => ({
          key: e.id,
          label: demo(getNameWithParantheses('item', e, null))
        }))
      : []
  }, [data, demo, v1])
  const defaultLabel = single ? intl.formatMessage(labelMessages.item) : intl.formatMessage(labelMessages.items)

  if (hideWhenEmpty && (!loading && data && data.length === 0)) {
    return null
  }

  return (
    <SearchSelect
      role={role || 'item-picker'}
      className={isFormField ? undefined : 'max-w-sm'}
      name={name}
      fitWidth={isFormField}
      type={type || (isFormField ? undefined : 'round')}
      size={isFormField ? undefined : 'small'}
      placeholder={placeholder || defaultLabel}
      optionsLabel={optionsLabel || defaultLabel}
      value={value}
      onChange={onChange}
      loading={isFetching}
      options={options}
      graybg={gray}
      withPortal={withPortal}
      required={required}
      single={isSingle}
      allowClear={allowClear != null ? allowClear : isSingle}
      hasError={hasError}
      disabled={disabled}
    />
  )
}
