import Heading from 'components/Heading'
import { Calendar, Text } from 'components/Primitives'
import { ProIcon } from 'components/ProIcon'
import { TextSelect } from 'components/TextSelect'
import labelMessages from 'components/labelMessages'
import { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FloatingPeriodPicker } from './FloatingPeriodPicker'
import messages from 'components/Pickers/messages'
import { CUSTOM_RANGE, FLOATING_RANGE } from 'constants/index'
import { calculateComparisonRange, getDateRangeValue, getWeekdayInPreviousYear } from 'utils/datetime'
import { addDays, differenceInCalendarDays, isAfter, min as minDate, startOfDay } from 'date-fns'
import { RenderCount } from 'components/RenderCount'
import { RangePreview } from 'components/RangePreview'
import { getRangePreset } from 'utils'
import globalMessages from 'components/globalMessages'
import rangePickerMessages from 'components/RangePicker/messages'
import { isEqual } from 'lodash'

export const OPTION_VALUES = {
  previousYear: 'previousYear',
  previousPeriod: 'previousPeriod',
  custom: 'custom',
  floating: 'floating'
}

const getOptionLabel = (option, preset, intl, days) => {
  if (option === OPTION_VALUES.previousYear) return intl.formatMessage(messages.previousYear)
  if (option === OPTION_VALUES.previousPeriod) {
    switch (preset) {
      case 'today':
        return intl.formatMessage(globalMessages.yesterday)
      case 'yesterday':
        return intl.formatMessage(messages.previousNDays, { days: 1 })
      case 'tomorrow':
        return intl.formatMessage(globalMessages.today)
      case 'thisWeek':
      case 'weekToDate':
        return intl.formatMessage(rangePickerMessages.lastWeek)
      case 'lastWeek':
        return intl.formatMessage(messages.previousWeek)
      case 'nextWeek':
        return intl.formatMessage(rangePickerMessages.thisWeek)
      case 'thisMonth':
      case 'monthToDate':
        return intl.formatMessage(rangePickerMessages.lastMonth)
      case 'lastMonth':
        return intl.formatMessage(messages.previousMonth)
      case 'nextMonth':
        return intl.formatMessage(rangePickerMessages.thisMonth)
      case 'thisQuarter':
      case 'quarterToDate':
        return intl.formatMessage(rangePickerMessages.lastQuarter)
      case 'lastQuarter':
        return intl.formatMessage(messages.previousQuarter)
      case 'nextQuarter':
        return intl.formatMessage(rangePickerMessages.thisQuarter)
      case 'thisYear':
      case 'yearToDate':
        return intl.formatMessage(rangePickerMessages.lastYear)
      case 'lastYear':
        return intl.formatMessage(messages.previousYear)
      case CUSTOM_RANGE:
      case FLOATING_RANGE:
      default:
        return intl.formatMessage(messages.previousNDays, { days })
    }
  }
  if (option === OPTION_VALUES.custom) {
    return intl.formatMessage(messages.specificStartDate)
  }
  if (option === OPTION_VALUES.floating) {
    return intl.formatMessage(messages.floatingStartDate)
  }
}

const getSettingLabel = (preset) => {
  switch (preset) {
    default:
    case 'today':
    case 'yesterday':
    case 'tomorrow':
    case 'thisYear':
    case 'yearToDate':
    case 'lastYear':
    case 'nextYear':
    case CUSTOM_RANGE:
    case FLOATING_RANGE:
      return labelMessages.date
    case 'thisWeek':
    case 'weekToDate':
    case 'lastWeek':
    case 'nextWeek':
      return messages.isoWeek
    case 'thisMonth':
    case 'monthToDate':
    case 'lastMonth':
    case 'nextMonth':
      return messages.month
    case 'thisQuarter':
    case 'quarterToDate':
    case 'lastQuarter':
    case 'nextQuarter':
      return messages.quarter
  }
}

export const getRangeLength = (range) => {
  if (!range || !range.length) return 0
  return differenceInCalendarDays(range[1], range[0]) + 1
}

export const YearPresets = ['thisYear', 'yearToDate', 'lastYear']

export const ComparingPeriodPicker = ({ selectedPreset, dateRangeValue, selectedComparisonOption, selectedComparisonRange, selectedPrevYSetting, selectedComparisonFloatingValue, setSelectedComparisonOption, setSelectedComparisonRange, setSelectedPrevYSetting, setSelectedComparisonFloatingValue, onTextSelectOpenListener }) => {
  const intl = useIntl()
  const targetRange = useMemo(() => getDateRangeValue({ value: dateRangeValue, option: selectedPreset }), [dateRangeValue, selectedPreset])

  const rangeLenght = getRangeLength(targetRange)
  const maxStart = targetRange && targetRange.length ? addDays(targetRange[0], -rangeLenght) : null

  const handleChange = (key, value) => {
    switch (key) {
      case 'option':
        setSelectedComparisonOption(value || OPTION_VALUES.previousYear)
        break
      case 'setting':
        setSelectedPrevYSetting(value || 'proximity')
        break
      case 'start':
        const newStart = minDate([
          maxStart,
          value || getWeekdayInPreviousYear(getRangePreset('today', true)[0], true)
        ])
        setSelectedComparisonRange([newStart, newStart])
        break
      case 'floating':
        setSelectedComparisonFloatingValue(value)
        break
    }
  }

  useEffect(() => {
    const newRange = calculateComparisonRange(
      selectedPreset,
      dateRangeValue,
      selectedComparisonOption,
      selectedComparisonRange,
      selectedComparisonFloatingValue,
      selectedPrevYSetting
    )
    if (!isEqual(newRange?.map(startOfDay), selectedComparisonRange?.map(startOfDay))) setSelectedComparisonRange(newRange)
  }, [selectedPreset, dateRangeValue, selectedComparisonOption, selectedComparisonRange, selectedComparisonFloatingValue, selectedPrevYSetting])

  // When we select a year preset, we should check if the comparison is valid
  useEffect(() => {
    if (YearPresets.includes(selectedPreset) && selectedComparisonOption === OPTION_VALUES.previousPeriod) setSelectedComparisonOption(OPTION_VALUES.previousYear)
  }, [selectedPreset, selectedComparisonOption])
  // When the target range changes, we should check if the comparison start is valid
  useEffect(() => {
    if (selectedComparisonOption !== OPTION_VALUES.custom) return
    if (isAfter(startOfDay(selectedComparisonRange[0]), startOfDay(maxStart))) handleChange('start', maxStart)
  }, [maxStart, selectedComparisonRange, selectedComparisonOption])

  const options = useMemo(() => {
    return [
      { value: OPTION_VALUES.previousYear, label: getOptionLabel(OPTION_VALUES.previousYear, selectedPreset, intl) },
      ...(!YearPresets.includes(selectedPreset) ? [{ value: OPTION_VALUES.previousPeriod, label: getOptionLabel(OPTION_VALUES.previousPeriod, selectedPreset, intl, rangeLenght) }] : []),
      { value: OPTION_VALUES.custom, label: getOptionLabel(OPTION_VALUES.custom, selectedPreset, intl) },
      { value: OPTION_VALUES.floating, label: getOptionLabel(OPTION_VALUES.floating, selectedPreset, intl) }
    ]
  }, [selectedPreset, rangeLenght, selectedPreset])

  return (
    <>
      <RenderCount />
      <Heading type='h5' className='my-4'>
        {intl.formatMessage(labelMessages.comparisonPeriod)}
        <ProIcon />
      </Heading>
      <TextSelect
        className='w-full'
        value={selectedComparisonOption}
        onChange={(v) => handleChange('option', v)}
        creatable={false}
        allowClear={false}
        required
        options={options}
        onOpenListener={onTextSelectOpenListener}
      />
      {selectedComparisonOption === OPTION_VALUES.previousYear && (
        <>
          <Text className='mt-4' title={intl.formatMessage(messages.matchBy)} color='black'>{intl.formatMessage(messages.matchBy)}</Text>
          <TextSelect
            className='w-full'
            value={selectedPrevYSetting}
            onChange={(v) => handleChange('setting', v)}
            creatable={false}
            allowClear={false}
            required
            options={[
              { value: 'proximity', label: intl.formatMessage(messages.matchingWeekdays) },
              { value: 'date', label: intl.formatMessage(getSettingLabel(selectedPreset)) }
            ]}
            onOpenListener={onTextSelectOpenListener}
          />
        </>
      )}
      {selectedComparisonOption === OPTION_VALUES.custom && <Calendar className='mt-4' onChange={(v) => handleChange('start', v)} value={selectedComparisonRange[0]} maxValue={maxStart} />}
      {selectedComparisonOption === OPTION_VALUES.floating && <FloatingPeriodPicker className='mt-4' onChange={(v) => handleChange('floating', v)} value={selectedComparisonFloatingValue} maxDate={maxStart} fixedRangeLength={rangeLenght} invalidRangeMessage={<FormattedMessage {...globalMessages.invalidComparingPeriod} />} />}
      {selectedComparisonOption !== OPTION_VALUES.floating && <RangePreview className='mt-4' range={selectedComparisonRange} />}
    </>
  )
}
