import { ReactComponent as Icon113 } from 'static/weather/113.svg'
import { ReactComponent as Icon116 } from 'static/weather/116.svg'
import { ReactComponent as Icon119 } from 'static/weather/119.svg'
import { ReactComponent as Icon122 } from 'static/weather/122.svg'
import { ReactComponent as Icon143 } from 'static/weather/143.svg'
import { ReactComponent as Icon176 } from 'static/weather/176.svg'
import { ReactComponent as Icon179 } from 'static/weather/179.svg'
import { ReactComponent as Icon182 } from 'static/weather/182.svg'
import { ReactComponent as Icon185 } from 'static/weather/185.svg'
import { ReactComponent as Icon200 } from 'static/weather/200.svg'
import { ReactComponent as Icon227 } from 'static/weather/227.svg'
import { ReactComponent as Icon230 } from 'static/weather/230.svg'
import { ReactComponent as Icon284 } from 'static/weather/284.svg'
import { ReactComponent as Icon314 } from 'static/weather/314.svg'
import { ReactComponent as Icon320 } from 'static/weather/320.svg'
import { ReactComponent as Icon329 } from 'static/weather/329.svg'
import { ReactComponent as Icon335 } from 'static/weather/335.svg'
import { ReactComponent as Icon392 } from 'static/weather/392.svg'
import React from 'react'
import { WEATHER_CODES } from 'constants/index'
import cn from 'classnames'
import messages from './messages'
import { useIntl } from 'react-intl'
import { Tooltip } from 'components/Primitives'

const weatherIcons = {
  113: Icon113,
  116: Icon116,
  119: Icon119,
  122: Icon122,
  143: Icon143,
  176: Icon176,
  179: Icon179,
  182: Icon182,
  185: Icon185,
  200: Icon200,
  227: Icon227,
  230: Icon230,
  284: Icon284,
  314: Icon314,
  320: Icon320,
  329: Icon329,
  335: Icon335,
  392: Icon392
}

export default ({ className, code, description }) => {
  const intl = useIntl()
  const group = WEATHER_CODES.find((codes) => codes.includes(code))

  if (!group) {
    console.warn(`Cannot group weather code to a specific icon: ${code}, ${description}`)
    return null
  }

  const Icon = weatherIcons[code]

  if (!Icon) {
    console.warn(`Cannot find weather icon for code: ${code}, ${description}`)
    return null
  }

  return (
    <Tooltip
      triggerAsChild
      title={messages[code] ? intl.formatMessage(messages[code]) : description}
    >
      <Icon className={cn('w-5 h-5 flex-shrink-0', className)} />
    </Tooltip>
  )
}
