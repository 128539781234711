import React from 'react'
import { createMedia } from '@artsy/fresnel'
import cn from 'classnames'
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from 'constants/index'

const AppMedia = createMedia({
  breakpoints: {
    base: 0,
    mobile: MOBILE_BREAKPOINT,
    tablet: TABLET_BREAKPOINT
  }
})

export const mediaStyle = AppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = AppMedia
// TODO use own solution to use custom component
export default ({ className, children, ...props }) => (
  <Media {...props}>
    {(mediaClassName, renderChildren) => (
      <div className={cn(mediaClassName, className)}>
        {renderChildren && children}
      </div>
    )}
  </Media>
)
