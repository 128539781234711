import { DateValue } from 'components/DateValue'
import Weather from 'components/Weather'
import s from './CellRenderer.module.scss'

export const DateWeatherRenderer = (props) => {
  const data = props.colDef.field && !props.colDef.fromRecord ? props.data[props.colDef.field] : props.data
  return (
    <div className={s.flexBetweenCell}>
      <span className='mr-2'><DateValue iso>{data.date}</DateValue></span>
      <Weather code={data.weather_code} description={data.weather_description} />
    </div>
  )
}
