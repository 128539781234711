import { Tooltip } from 'components/Primitives'
import { useDemo } from 'hooks'
import { isEmpty } from 'utils'

export const TextWithTooltipRenderer = ({ value, data, colDef }) => {
  const { demo } = useDemo()
  if (!value) return null

  const renderValue = colDef.remotePkField && !isEmpty(data[colDef.remotePkField]) && data[colDef.remotePkField] !== value ? `${value} (${data[colDef.remotePkField]})` : value

  return (
    <div>
      <Tooltip triggerClassName='w-full overflow-hidden text-ellipsis text-start' title={renderValue} placement='top'>
        {colDef.disguise ? demo(renderValue) : renderValue}
      </Tooltip>
    </div>
  )
}
