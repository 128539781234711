import Drawing from 'static/undraw_update.svg'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import s from './ApplicationUpdate.module.scss'
import { useTimeout } from 'ahooks'
import Heading from 'components/Heading'
import { HashLoader, BarLoader } from 'react-spinners'

export const ApplicationUpdate = ({ inChart }) => {
  useTimeout(() => {
    window.location.reload(true)
  }, 4000)

  return (
    <div className={classNames(s.container, inChart ? s.small : null)}>
      {!inChart && <BarLoader color='#113e6f' cssOverride={{ position: 'fixed', top: 0, left: 0 }} height={6} width='100%' speedMultiplier={0.5} />}
      {!inChart && (
        <Heading type='h2' className='text-center'>
          <FormattedMessage id='pageErrorBoundary.appUpdate' defaultMessage='Update available! 🎉' />
        </Heading>
      )}

      {!inChart && <img src={Drawing} />}
      <p className={s.message}>
        <FormattedMessage id='pageErrorBoundary.updateMessage' defaultMessage={'An update for Delicious Data has been released. Please keep patient while we\'re updating…'} tagName='span' />
      </p>
      <div className={classNames('relative', inChart ? 'w-10 h-10 mt-4' : 'w-20 h-20 mt-8')}>
        <HashLoader color='#1c5593' size={inChart ? 30 : 80} cssOverride={{ position: 'static' }} />
      </div>
    </div>
  )
}
