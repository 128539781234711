import 'styles/global.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/toastify-theme.css'
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'
import 'styles/ag-theme-dd.scss'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ConfigProvider } from 'antd'
import { IntercomProvider } from 'react-use-intercom'
import { IntlProvider } from 'react-intl'
import { MediaContextProvider } from 'components/Media'
// in dev mode the browser is almost dying when ReactQueryDevtools are added, so let'r not import it unless really needed.
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { UIProvider } from 'services/UIProvider'
import de from 'antd/lib/locale/de_DE'
import en from 'antd/lib/locale/en_US'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { ScriptProvider } from 'services/ScriptProvider'
import config from 'config'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { Intercom, Root, AuthManager } from 'components/AppCore'
import { LoadingWrapper } from './LoadingWrapper'

const antLocales = {
  'de-DE': de,
  'en-US': en
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
})
window.queryClient = queryClient

export const Providers = ({ children, intl }) => {
  const locale = useGeneralStore(state => state.locale)
  const intlState = useGeneralStore(state => state.intl)

  if (!intlState) {
    return null
  }

  return (
    <IntlProvider
      locale={intlState.locale}
      formats={intl.formats}
      messages={intlState.messages}
      defaultLocale={intl.defaultLocale}
      defaultFormats={intl.defaultFormats}
      timeZone={intl.timeZone}
      textComponent={intl.textComponent}
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          return
        }
        throw err
      }}
    >
      <ConfigProvider locale={antLocales[locale]}>
        <MediaContextProvider>
          <IntercomProvider appId={config.intercom.appId} apiBase='https://api-iam.eu.intercom.io' shouldInitialize={config.intercom.appId != null}>
            <QueryClientProvider client={queryClient}>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <LoadingWrapper>
                <UIProvider>
                  <ScriptProvider>
                    <RadixTooltip.Provider delayDuration={300}>
                      {children}
                    </RadixTooltip.Provider>
                  </ScriptProvider>
                  <Root />
                  <Intercom />
                  <AuthManager />
                </UIProvider>
              </LoadingWrapper>
            </QueryClientProvider>
          </IntercomProvider>
        </MediaContextProvider>
      </ConfigProvider>
    </IntlProvider>
  )
}
