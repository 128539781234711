import { OptionMenuButton } from './OptionMenuButton'
import TagBadge from 'components/Badge/TagBadge'
import classNames from 'classnames'
import { omit } from 'lodash'
import { FormattedMessage } from 'react-intl'

export const Option = ({ innerProps, isFocused, data, selectProps }) => {
  const onAction = (action) => {
    if (selectProps.onMenuAction) {
      selectProps.onMenuAction(action, data)
    }
  }

  return (
    <div
      {...omit(innerProps, ['onClick'])}
      onClick={innerProps.onClick}
      className={classNames(
        innerProps.className,
        'w-full py-2 px-2 flex cursor-pointer',
        {
          'bg-gray-lighter': isFocused,
          'justify-between': !data.draft,
          'text-sm text-gray-light justify-start flex-nowrap space-x-1 items-center': data.draft
        }
      )}
    >
      {data.draft
        ? (
          <>
            <FormattedMessage
              id='tags.createGroup'
              defaultMessage='Create new group <tag>{group}</tag>'
              values={{
                group: JSON.stringify(data),
                tag: chunks => {
                  const t = JSON.parse(chunks)
                  return <TagBadge className='mx-1' hex={t.color}>{t.name}</TagBadge>
                }
              }}
            />
          </>)
        : (
          <>
            <TagBadge hex={data.color}>{data.name}</TagBadge>
            {isFocused && !selectProps.asFormField && (<OptionMenuButton onAction={onAction} />)}
          </>
          )}
    </div>
  )
}
