import { NumericFormat } from 'react-number-format'

export const FlexibleScaledNumberFormat = ({ absolute, value, ...formatProps }) => {
  let decimalScale = 0
  if (!absolute && Math.abs(value) < 1000) {
    decimalScale = 2
  }

  if (value == null) { return null }

  return (
    <NumericFormat
      value={value}
      {...formatProps}
      decimalScale={decimalScale}
    />
  )
}
