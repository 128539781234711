import { ClipboardCopyIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { copyToClipboard, isEmpty } from 'utils'

export const CopyToClipboardButton = ({ value, disabled, className, type }) => {
  const intl = useIntl()
  const isDisabled = disabled || isEmpty(value)
  return (
    <button
      type='button'
      title={intl.formatMessage({ id: 'copyToClipboardButton.hover', defaultMessage: 'Copy to clipboard' })}
      className={classNames('flex items-center cursor-pointer ',
        {
          'text-gray-light hover:text-primary': !isDisabled,
          'text-disabled cursor-not-allowed': isDisabled
        },
        className)}
      disabled={isDisabled}
      onClick={() => copyToClipboard(value, type, intl)}
    >
      <ClipboardCopyIcon className='w-5 h-5' />
    </button>
  )
}
