import { useQuery } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'

export const useOpenMeasurements = () => {
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()

  const queryKey = ['open-measurements', customer]

  const { data, status } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/wastes/count/?${queryString.stringify(
          {
            customer,
            actions: 'group,weather,num_sold',
            open: true,
            page_size: 1
          }
        )}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    }),
    staleTime: 120000
  })

  return {
    count: data ? data.count : data,
    status
  }
}
