import { Text } from 'components/Primitives/Text'

const getSize = (type) => {
  switch (type) {
    case 'h1':
      return 'xxl'
    case 'h2':
      return 'xl'
    case 'h3':
      return 'l'
    case 'h4':
    default:
      return 'm'
    case 'h5':
      return 's'
    case 'h6':
      return 'xs'
  }
}

const Heading = ({ className, type, children, onClick, id, color, ...rest }) =>
  <Text id={id} onClick={onClick} color={color || 'brand'} className={className} type='headline' size={getSize(type)} tagName={type} {...rest}>
    {children}
  </Text>

export default Heading
