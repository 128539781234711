import { addDays, parseISO } from 'date-fns'
import { DateRangeValue } from 'components/DateRangeValue'

export const DateRangeRenderer = (props) => {
  const { colDef, data } = props
  if (!data) return null

  const from = colDef.rangeIsArray
    ? data[colDef.field].length === 2
        ? data[colDef.field][0]
        : null
    : data[colDef.fromField]

  const to = colDef.rangeIsArray
    ? data[colDef.field].length === 2
        ? data[colDef.field][1]
        : null
    : data[colDef.toField]

  return (
    <DateRangeValue from={from} to={to ? addDays(parseISO(to), colDef.excludeLastDay ? -1 : 0) : null} />
  )
}
