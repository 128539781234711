import { usePrevious } from 'ahooks'
import { intersection, sortBy, isEqual, forEach } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useGeneralStore } from './store/useGeneralStore'
import { determineColor } from 'components/Charts/utils'
import globalMessages from 'components/globalMessages'
import { useIntl } from 'react-intl'

const determinePersistentColors = (colorTable, intl, group, keys) => {
  if (!keys || keys.length === 0) return
  const latestTable = colorTable

  const colors = []
  forEach(keys, k => {
    colors.push(determineColor(latestTable, k, group, colors.map(i => i.color), intl.formatMessage(globalMessages.other), intl.formatMessage(globalMessages.ungrouped)))
  })

  const newData = {}
  forEach(colors, i => {
    newData[i.key] = {
      hash: i.hash,
      color: i.color
    }
  })

  return {
    ...latestTable[group],
    ...newData
  }
}

export const usePersistentColorSet = (group, keySet) => {
  const intl = useIntl()
  const colorTable = useGeneralStore(state => state.colorTable)
  const setColorTableForGroup = useGeneralStore(state => state.setColorTableForGroup)
  // keySet changes on each render, with that state and
  // our hook we enforce a performant approach for expensive calculation
  const [stableKeySet, setStableKeyset] = useState()

  const previousKeySet = usePrevious(keySet)

  useEffect(() => {
    if ((keySet && !previousKeySet) || !isEqual(keySet, previousKeySet)) {
      const sortedKeySet = sortBy(keySet)
      const table = determinePersistentColors(colorTable, intl, group, sortedKeySet)
      setColorTableForGroup(group, table)
      setStableKeyset(sortedKeySet)
    }
  }, [group, previousKeySet, keySet])

  // determine if colors really have been initialized
  return useMemo(() => {
    const tableGroup = colorTable[group]
    if (!tableGroup || !stableKeySet) {
      return null
    }

    // make sure all keys of our keyset exist in the color table for the group
    const sortedTableKeys = sortBy(Object.keys(tableGroup))
    const intersect = intersection(stableKeySet, sortedTableKeys)
    if (tableGroup && stableKeySet && isEqual(intersect, stableKeySet)) {
      return tableGroup
    }
    console.warn('[usePersistentColorSet]: Return null => end', { group, keySet, sortedTableKeys, intersect, stableKeySet, tableGroup })
    return null
  }, [colorTable, group, stableKeySet])
}
