import s from './Collapsible.module.scss'
import * as RadixCollapsible from '@radix-ui/react-collapsible'
import { useState } from 'react'
import classNames from 'classnames'
import { Icon } from '../Icon'

export const Collapsible = ({ className, triggerClassName, contentClassName, label, openedLabel, closedLabel, indicatorSide = 'left', noIndicator, children, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen || false)

  const getLabel = () => {
    if (openedLabel && open) {
      return openedLabel
    }
    if (closedLabel && !open) {
      return closedLabel
    }
    return label
  }

  return (
    <RadixCollapsible.Root className={className} open={open} onOpenChange={setOpen}>
      <RadixCollapsible.Trigger className={classNames(s.trigger, triggerClassName, s[noIndicator ? 'left' : indicatorSide])} asChild>
        <button data-role='collapsible'>
          {!noIndicator && <Icon name='ChevronDown' className={classNames('mx-2', open ? s.rotated : null)} />}
          {getLabel()}
        </button>
      </RadixCollapsible.Trigger>
      <RadixCollapsible.Content className={classNames(s.content, contentClassName)}>
        {children}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  )
}
