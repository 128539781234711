import React, { useEffect, useMemo } from 'react'

import { SearchSelect } from 'components/SearchSelect'
import { useTags } from 'hooks'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'

const convertRouteTypeToEntityType = (type) => {
  switch (type) {
    case 'locations':
      return 'saleslocation'
    case 'items':
      return 'item'
    case 'todo-configs':
      return 'todoconfig'
    case 'order-configs':
      return 'orderconfig'
    default:
      return type
  }
}

const getTypeSpecificPickerProps = (entityType, intl, placeholder, single) => {
  switch (entityType) {
    case 'saleslocation':
      return {
        placeholder: placeholder || (single ? intl.formatMessage(labelMessages.locationTag) : intl.formatMessage(labelMessages.locationTags)),
        optionsLabel: intl.formatMessage(labelMessages.locationTags),
        itemsGroup: intl.formatMessage(labelMessages.locationTags)
      }
    case 'item':
      return {
        placeholder: placeholder || (single ? intl.formatMessage(labelMessages.itemTag) : intl.formatMessage(labelMessages.itemTags)),
        optionsLabel: intl.formatMessage(labelMessages.itemTags),
        itemsGroup: intl.formatMessage(labelMessages.itemTags)
      }
    case 'user':
      return {
        placeholder: placeholder || (single ? intl.formatMessage(labelMessages.userTag) : intl.formatMessage(labelMessages.userTags)),
        optionsLabel: intl.formatMessage(labelMessages.userTags),
        itemsGroup: intl.formatMessage(labelMessages.userTags)
      }
    default:
      return {}
  }
}

/**
 * This is the readonly TagPicker for generic tags.
 * In some cases we'll pass just the browser's admin route, which will be converted to the entity type for fetch.
 **/
export default ({ value, onChange, type, flat, medium, single, placeholder, className, required, onLoaded, allowClear, gray, disabled, tagType, ...rest }) => {
  const intl = useIntl()

  const entityType = useMemo(() => tagType || convertRouteTypeToEntityType(type), [tagType, type])
  const pickerProps = useMemo(() => getTypeSpecificPickerProps(entityType, intl, placeholder, single), [entityType, intl, placeholder, single])

  const { isFetching, tags, status, isLoading } = useTags({ type: entityType, remove_orphans: true })

  useEffect(() => {
    if (tags && !isFetching && status === 'success') {
      if (onLoaded) {
        onLoaded(tags)
      }
    }
  }, [tags, isFetching, status])

  return (
    <SearchSelect
      disabled={disabled}
      role='generic-tag-picker'
      className={className}
      type={gray ? 'templates' : flat ? undefined : 'round'}
      size={medium ? undefined : 'small'}
      value={value}
      onChange={onChange}
      loading={isLoading}
      required={required}
      single={single}
      allowClear={allowClear}
      graybg={gray}
      prefixIcon='Tag'
      options={tags && tags.map((item) => ({
        key: item.id,
        label: item.name,
        color: item.color,
        objects_count: item.objects_count
      }))}
      menuType='tag'
      {...pickerProps}
    />
  )
}
