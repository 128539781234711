import { Tooltip } from 'components/Primitives'
import labelMessages from 'components/labelMessages'
import { useLocations, useTags, useCustomerSettings } from 'hooks'
import { useItems, useFoodwasteCategories, useItemEntities } from 'hooks/queries'
import { FilterSettingsMap } from 'hooks/useAvailableFilters'
import { Filter } from 'constants/index'
import { orderBy, find, filter } from 'lodash'
import { useIntl } from 'react-intl'
import { DASHBOARD_ROUTE } from 'routes'
import { getNameWithParantheses, truncateItems } from 'utils'
import { getWeekdays } from 'utils/datetime'

const renderTooltipEntries = (list, type, intl) => {
  let nMoreItems
  if (['Locations', 'Items', 'Groups'].includes(type)) {
    nMoreItems = labelMessages[`n${type}More`] || labelMessages.nItemsMore
  } else {
    nMoreItems = labelMessages.nItemsMore
  }

  const tooltipList = truncateItems(list)

  const ttLines = tooltipList.items.map(i => <p className='whitespace-nowrap overflow-hidden text-ellipsis' key={i}>{i}</p>)
  if (tooltipList.leftOver > 0) { ttLines.push(<div key='more-items' className='italic'>{intl.formatMessage(nMoreItems, { amount: tooltipList.leftOver })}</div>) }

  const tooltipContent = (
    <div className='text-left w-full'>
      {ttLines}
    </div>
  )

  return tooltipContent
}

const LocationWrapper = ({ children, value, wrapTrigger }) => {
  const intl = useIntl()
  const { data } = useLocations()
  const customerSettings = useCustomerSettings()

  const locationList = orderBy(value.map(locId => find(data, { id: parseInt(locId) })).filter(i => i).map(l => getNameWithParantheses('location', l, customerSettings)), ['name'], ['asc'])
  return (
    <Tooltip triggerAsChild side='bottom' title={renderTooltipEntries(locationList, 'Locations', intl)}>
      {wrapTrigger ? <div>{children}</div> : children}
    </Tooltip>
  )
}

const WeekdayWrapper = ({ children, value, wrapTrigger }) => {
  const intl = useIntl()
  const weekdays = getWeekdays(intl.locale)
  const weekdayList = orderBy(filter(weekdays, w => value.includes(w.key.toString())), ['sortIndex'], ['asc']).map(w => w.label)

  return (
    <Tooltip triggerAsChild side='bottom' title={renderTooltipEntries(weekdayList, 'Weekdays', intl)}>
      {wrapTrigger ? <div>{children}</div> : children}
    </Tooltip>
  )
}

const ItemWrapper = ({ children, value, wrapTrigger }) => {
  const intl = useIntl()

  const itemList = [...value]
  const { data } = useItemEntities({ ids: itemList })
  if (!data || data.isFetching) return null

  const values = data.map(i => getNameWithParantheses('item', i)).sort()

  return (
    <Tooltip triggerAsChild side='bottom' title={renderTooltipEntries(values, 'Items', intl)}>
      {wrapTrigger ? <div>{children}</div> : children}
    </Tooltip>
  )
}

const WasteCategoryWrapper = ({ children, value, wrapTrigger }) => {
  const intl = useIntl()
  const { data } = useFoodwasteCategories()
  const wasteCategoryList = orderBy(value.map(id => find(data, { id: parseInt(id) })).filter(i => i).map(l => l.name), ['name'], ['asc'])

  return (
    <Tooltip triggerAsChild side='bottom' title={renderTooltipEntries(wasteCategoryList, 'WasteCategories', intl)}>
      {wrapTrigger ? <div>{children}</div> : children}
    </Tooltip>
  )
}

const ItemGroupWrapper = ({ children, value, wrapTrigger, groupNo }) => {
  const intl = useIntl()
  const list = [...value].sort()

  return (
    <Tooltip triggerAsChild side='bottom' title={renderTooltipEntries(list, 'Groups', intl)}>
      {wrapTrigger ? <div>{children}</div> : children}
    </Tooltip>
  )
}

const OfferingGroupWrapper = ({ children, value, wrapTrigger, groupNo }) => {
  const intl = useIntl()
  const list = [...value].sort()

  return (
    <Tooltip triggerAsChild side='bottom' title={renderTooltipEntries(list, 'Groups', intl)}>
      {wrapTrigger ? <div>{children}</div> : children}
    </Tooltip>
  )
}

const ItemTagWrapper = ({ children, value, wrapTrigger }) => {
  const intl = useIntl()
  const { tags } = useTags({ type: 'item', remove_orphans: true })

  const list = orderBy(value.map(id => find(tags, { id: parseInt(id) })).filter(i => i).map(l => l.name), ['name'], ['asc'])

  return (
    <Tooltip triggerAsChild side='bottom' title={renderTooltipEntries(list, 'ItemTags', intl)}>
      {wrapTrigger ? <div>{children}</div> : children}
    </Tooltip>
  )
}

export const FilterValueTooltipWrapper = ({ children, value, type, wrapTrigger }) => {
  switch (type) {
    case Filter.LOCATION:
    case Filter.LOCATIONS:
      return <LocationWrapper wrapTrigger={wrapTrigger} value={value}>{children}</LocationWrapper>
    case Filter.ITEM:
    case FilterSettingsMap[DASHBOARD_ROUTE][Filter.ITEM]:
      return <ItemWrapper wrapTrigger={wrapTrigger} value={value}>{children}</ItemWrapper>
    case Filter.ITEM_GROUP_1:
    case FilterSettingsMap[DASHBOARD_ROUTE][Filter.ITEM_GROUP_1]:
      return <ItemGroupWrapper wrapTrigger={wrapTrigger} value={value} groupNo={1}>{children}</ItemGroupWrapper>
    case Filter.ITEM_GROUP_2:
    case FilterSettingsMap[DASHBOARD_ROUTE][Filter.ITEM_GROUP_2]:
      return <ItemGroupWrapper wrapTrigger={wrapTrigger} value={value} groupNo={2}>{children}</ItemGroupWrapper>
    case Filter.OFFERING_GROUP_1_NAMES:
    case Filter.OFFERING_GROUP_1:
    case FilterSettingsMap[DASHBOARD_ROUTE][Filter.OFFERING_GROUP_1_NAMES]:
      return <OfferingGroupWrapper wrapTrigger={wrapTrigger} value={value} groupNo={1}>{children}</OfferingGroupWrapper>
    case Filter.OFFERING_GROUP_2_NAMES:
    case Filter.OFFERING_GROUP_2:
    case FilterSettingsMap[DASHBOARD_ROUTE][Filter.OFFERING_GROUP_2_NAMES]:
      return <OfferingGroupWrapper wrapTrigger={wrapTrigger} value={value} groupNo={2}>{children}</OfferingGroupWrapper>
    case Filter.WEEKDAY:
    case FilterSettingsMap[DASHBOARD_ROUTE][Filter.WEEKDAY]:
      return <WeekdayWrapper wrapTrigger={wrapTrigger} value={value}>{children}</WeekdayWrapper>
    case Filter.FOODWASTE_CATEGORY:
    case FilterSettingsMap[DASHBOARD_ROUTE][Filter.FOODWASTE_CATEGORY]:
      return <WasteCategoryWrapper wrapTrigger={wrapTrigger} value={value}>{children}</WasteCategoryWrapper>
    case Filter.ITEM_TAG:
      return <ItemTagWrapper wrapTrigger={wrapTrigger} value={value}>{children}</ItemTagWrapper>
    default:
      return children
  }
}
