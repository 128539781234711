import * as RadixForm from '@radix-ui/react-form'
import { Text } from '../Text'
import s from './Form.module.scss'

const Field = ({ name, label, children, valueMissingMessage, typeMismatchMessage, appendix }) => {
  return (
    <RadixForm.Field className={s.field} name={name}>
      <RadixForm.Label asChild>
        <Text className='mb-0.5'>{label}</Text>
      </RadixForm.Label>
      <RadixForm.Control asChild>
        {children}
      </RadixForm.Control>
      {valueMissingMessage && (
        <RadixForm.Message className={s.message} match='valueMissing'>
          <Text type='caption' size='m' color='danger'>{valueMissingMessage}</Text>
        </RadixForm.Message>
      )}
      {typeMismatchMessage && (
        <RadixForm.Message className={s.message} match='typeMismatch'>
          <Text type='caption' size='m' color='danger'>{typeMismatchMessage}</Text>
        </RadixForm.Message>
      )}
      {appendix && <div className='mt-2'>{appendix}</div>}
    </RadixForm.Field>
  )
}

export const Form = ({ onSubmit, submit, children }) => {
  const handleSubmit = (event) => {
    const data = Object.fromEntries(new FormData(event.currentTarget))
    if (onSubmit) onSubmit(data)

    // prevent default form submission
    event.preventDefault()
  }

  return (
    <RadixForm.Root onSubmit={handleSubmit} className={s.root}>
      {children}
      <RadixForm.Submit asChild>
        {submit}
      </RadixForm.Submit>
    </RadixForm.Root>
  )
}

Form.Field = Field
