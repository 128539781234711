import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { useQueryFetcher } from 'hooks'
import queryString from 'query-string'
import { handleMutationError } from 'utils'
import { useIntl } from 'react-intl'
import message from 'services/message'

export const useSubscriptions = (options) => {
  const opts = {
    enabled: true,
    type: null,
    ...options
  }
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { fetch } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const queryKey = ['subscriptions', customer, opts.type]

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => {
      switch (opts.type) {
        case 'forecast':
          return new Promise((resolve, reject) => {
            fetch(`/subscriptions/?${queryString.stringify({ customer })}`, {
              method: 'GET',
              success: (res) => {
                if (res.results[0]) {
                  res.results[0].sales_locations = res.results[0].sales_locations.map((l) => l.toString())
                }
                resolve(res.results[0] || null)
              },
              failure: (err) => reject(err)
            })
          })
        case 'foodwaste':
          return new Promise((resolve, reject) => {
            fetch(`/users/me/?${queryString.stringify({ customer })}`, {
              method: 'GET',
              success: (res) => {
                resolve({
                  subscriptionActive: res.food_waste_email_is_active,
                  time: res.food_waste_email_time
                })
              },
              failure: (err) => reject(err)
            })
          })
      }
    },
    enabled: opts.enabled && opts.type != null
  })

  const { mutate: update, isPending: updateIsPending } = useMutation({
    mutationFn: (values) => {
      switch (opts.type) {
        case 'forecast':
          return new Promise((resolve, reject) => {
            if (data?.id && !values.length) {
              fetch(`/subscriptions/${data.id}/?${queryString.stringify({ customer })}`, {
                method: 'DELETE',
                success: () => {
                  resolve()
                  message.success(
                    intl.formatMessage({
                      id: 'user.updateSubscriptionSuccess',
                      defaultMessage: 'Subscription updated.'
                    })
                  )
                },
                failure: (err) => reject(err)
              })
            } else {
              const endpoint = data?.id ? `/subscriptions/${data.id}/` : '/subscriptions/'
              const body = {
                lang: intl.locale.substring(0, 2),
                sales_locations: values.map((l) => parseInt(l))
              }
              fetch(`${endpoint}?${queryString.stringify({ customer })}`, {
                method: data?.id ? 'PATCH' : 'POST',
                body: body,
                success: () => {
                  resolve()
                  message.success(
                    intl.formatMessage({
                      id: 'user.updateSubscriptionSuccess',
                      defaultMessage: 'Subscription updated.'
                    })
                  )
                },
                failure: (err) => reject(err)
              })
            }
          })
        case 'foodwaste':
          return new Promise((resolve, reject) => {
            fetch('/users/me/', {
              method: 'PATCH',
              body: values,
              success: (res) => {
                resolve(res)
                message.success(
                  intl.formatMessage({
                    id: 'user.updateSubscriptionSuccess',
                    defaultMessage: 'Subscription updated.'
                  })
                )
              },
              failure: (errors) => handleMutationError(errors, reject)
            })
          })
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey })
  })

  return {
    data,
    isLoading,
    update: {
      mutate: update,
      isPending: updateIsPending
    }
  }
}
