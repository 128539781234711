import { useIntl } from 'react-intl'
import { getWeekdays } from 'utils/datetime'
import labelMessages from 'components/labelMessages'
import { find, sortBy } from 'lodash'
import { useMemo } from 'react'
import { Badge, Tooltip } from 'components/Primitives'

export const WeekdaysRenderer = ({ value }) => {
  const intl = useIntl()

  const wd = useMemo(() => [
    ...getWeekdays(intl.locale).map(wd => ({
      ...wd,
      key: wd.sortIndex
    })),
    {
      key: -1,
      label: intl.formatMessage(labelMessages.publicHoliday),
      short: intl.formatMessage(labelMessages.publicHolidayShort),
      sortIndex: 10
    }
  ]
  , [intl.locale])

  const toRender = useMemo(() => {
    return value ? sortBy(value.map((weekday) => find(wd, { key: weekday })), 'sortIndex') : []
  }, [value])

  if (toRender.length === 0) return null

  const ttLines = toRender.map(wd => {
    return <div key={wd.key}>{wd.label}</div>
  })

  if (toRender.length > 5) {
    return (
      <Tooltip
        side='left'
        title={<div className='flex flex-col'>{ttLines}</div>}
      >
        <Badge size='s' type='fbInfo'>{intl.formatMessage(labelMessages.nWeekdays, { amount: value.length })}</Badge>
      </Tooltip>
    )
  }

  return (
    <div className='flex overflow-hidden h-full items-center space-x-2'>
      {toRender.map((w) => <Badge key={w.key} size='s' type='fbInfo'>{w.short}</Badge>)}
    </div>
  )
}
