import { LOGIN_ROUTE, ROOT_ROUTE } from 'routes'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { Link } from 'react-router-dom'
import Logo from 'static/logo.svg'
import React from 'react'
import { useUser } from 'hooks'

const Header = () => {
  const { user } = useUser()
  return (
    <div className='flex flex-col px-20 py-6 mobile:px-3'>
      <LanguageSwitcher className='self-end max-w-10' />
      <Link className='self-center mt-1' to={user ? ROOT_ROUTE : LOGIN_ROUTE}>
        <img src={Logo} alt='Logo' className='h-16 w-68' />
      </Link>
    </div>
  )
}

export default Header
