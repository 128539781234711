import React, { useEffect, useState } from 'react'

import Badge from './'
import { OptionMenuButton } from 'components/Tags/OptionMenuButton'
import classNames from 'classnames'
import { withSize } from 'react-sizeme'
import { Tooltip } from 'components/Primitives'

const TagBadge = ({ color, hex, className, children, editable, withAppendix, onAction, size, noTooltip, noTruncate, id }) => {
  const [width, setWidth] = useState(null)
  // const textSpan = useRef()

  useEffect(() => {
    if (editable && size && size.width) {
      setWidth(size.width)
    }
  }, [editable, size])

  // FIXME: This will break very very weirdly…
  const isTruncated = true // textSpan && textSpan.current ? textSpan.current.scrollWidth > textSpan.current.clientWidth : false

  const showTooltip = noTooltip ? false : isTruncated

  const innerContent = (
    <Badge
      color={color}
      hex={hex}
      id={id}
      className={classNames('group', noTruncate ? null : 'min-w-1.5 max-w-10', { 'pr-0': withAppendix && editable, 'pr-1': withAppendix && !editable }, className)}
      style={{ width: width }}
      bold
      withAppendix={withAppendix}
      size={noTruncate ? 'full' : 'wide'}
    >
      <div className='flex justify-between w-full '>
        <span className='overflow-hidden leading-none text-ellipsis'>{children || <>&nbsp;</>}</span>
        {editable && (
          <OptionMenuButton inline onAction={onAction} />
        )}

      </div>
    </Badge>
  )

  return showTooltip ? <Tooltip title={children}><div>{innerContent}</div></Tooltip> : innerContent
}

export default withSize()(TagBadge)
