import { BASE_VALUE_FORMAT, PERCENT_VALUE_FORMAT } from 'constants/index'
import { isSameDay, parseISO } from 'date-fns'

import { Bar } from '../Bar'
import { DateValue } from 'components/DateValue'
import { FormattedMessage } from 'react-intl'
import { LOCATION } from 'hooks/useChartTabs'
import { RadioButton } from 'components/RadioButton'
import { Spin } from 'antd'
import Tabs from '../Tabs'
import cn from 'classnames'
import { isNil } from 'lodash'
import chartMessages from '../messages'
import s from '../ChartStylesBar.module.scss'
import { useDashboardRefiner } from 'hooks'
import { NoData } from 'components/NoData'
import globalMessages from 'components/globalMessages'
import { FlexibleScaledNumberFormat } from 'components/FlexibleScaledNumberFormat'

export const DONE = 'done'
export const LATE = 'late'
export const DELETED = 'deleted'
export const CORRECTED = 'corrected'
export const MANUALLY_ADDED = 'manuallyAdded'

const TABS = [
  { value: DONE, label: chartMessages.done },
  { value: LATE, label: chartMessages.late },
  { value: DELETED, label: globalMessages.deleted },
  { value: CORRECTED, label: chartMessages.corrected },
  { value: MANUALLY_ADDED, label: chartMessages.manuallyAdded }
]

const Bars = ({ maxCount, tab, setTab, fixed, data, loading, mode, modes, setMode, isInModal, onRefine }) => {
  const now = new Date()
  const refiner = useDashboardRefiner({ tab: LOCATION })

  const bars = data.map(
    ({ location, value, percentage, percentValue, info }, index) => {
      if (maxCount && index > maxCount - 1) {
        return null
      }
      const sameDay = isNil(info) ? null : isSameDay(parseISO(info), now)
      const numberValue = mode === 'relative' ? percentValue : value
      return (
        <Bar
          key={index}
          title={location}
          total={!isNil(numberValue) && (
            <FlexibleScaledNumberFormat value={numberValue} {...mode === 'relative' ? PERCENT_VALUE_FORMAT : BASE_VALUE_FORMAT} />
          )}
          info={isNil(info)
            ? null
            : sameDay
              ? <><FormattedMessage id='todoLocationComparisonChart.lastAt' defaultMessage='Last at' tagName='span' />{' '}<DateValue noSameDayDate withTime iso>{info}</DateValue></>
              : <><FormattedMessage id='todoLocationComparisonChart.lastOn' defaultMessage='Last on' tagName='span' />{' '}<DateValue noSameDayDate withTime iso>{info}</DateValue></>}
          infoClassName={cn(s.delta, s.primary)}
          percentage={mode === 'relative' ? percentValue : percentage}
          onDetailClick={() => {
            if (onRefine) { onRefine() }
            refiner.handleRefine({ name: location })
          }}
        />
      )
    }
  )

  return (
    <Spin spinning={loading} wrapperClassName={cn(s.wrapper, isInModal ? s.inModal : null)}>
      {modes
        ? (
          <div className='flex items-start justify-between'>
            <Tabs className='flex-grow mr-4' fixed={fixed} value={tab} onChange={setTab} options={TABS} />
            {modes && <RadioButton value={mode} onChange={setMode} options={modes} right />}
          </div>)
        : <Tabs fixed={fixed} value={tab} onChange={setTab} options={TABS} />}

      {bars.length > 0 ? <div className={s.bars}>{bars}</div> : <div className={s.noData}><NoData size='m' /></div>}
    </Spin>
  )
}

export default Bars
