import { defineMessages } from 'react-intl'

export default defineMessages({
  thisWeek: {
    id: 'rangePicker.thisWeek',
    defaultMessage: 'This week'
  },
  lastWeek: {
    id: 'rangePicker.lastWeek',
    defaultMessage: 'Last week'
  },
  nextWeek: {
    id: 'rangePicker.nextWeek',
    defaultMessage: 'Next week'
  },
  weekNumber: {
    id: 'rangePicker.weekNumber',
    defaultMessage: 'WN {weekNumber}'
  },
  week: {
    id: 'rangePicker.week',
    defaultMessage: 'WN '
  },
  customRange: {
    id: 'rangePicker.customRange',
    defaultMessage: 'Custom range'
  },
  customWeek: {
    id: 'rangePicker.customWeek',
    defaultMessage: 'Custom week'
  },
  thisMonth: {
    id: 'rangePicker.thisMonth',
    defaultMessage: 'This month'
  },
  lastMonth: {
    id: 'rangePicker.lastMonth',
    defaultMessage: 'Last month'
  },
  thisYear: {
    id: 'rangePicker.thisYear',
    defaultMessage: 'This year'
  },
  lastYear: {
    id: 'rangePicker.lastYear',
    defaultMessage: 'Last year'
  },
  thisQuarter: {
    id: 'rangePicker.thisQuarter',
    defaultMessage: 'This quarter'
  },
  lastQuarter: {
    id: 'rangePicker.lastQuarter',
    defaultMessage: 'Last quarter'
  },
  lastTwoWeeks: {
    id: 'rangePicker.lastTwoWeeks',
    defaultMessage: 'Last two weeks'
  }
})
