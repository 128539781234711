import { Filter } from 'hooks/useAvailableFilters'
import { find, forEach, intersection } from 'lodash'
import { getOrdering } from 'utils'

const convertColDefToBackendSortString = (colDef, replaceDict = {}) =>
`${colDef.sort === 'asc' ? '' : '-'}${replaceDict[colDef.colId] ? replaceDict[colDef.colId] : colDef.colId}`

export const getOrderingForSortModel = (sortModel = [], replaceDict = {}) =>
  sortModel.map(sm => convertColDefToBackendSortString(sm, replaceDict))

export const convertDataQueryParamsToLegacyParams = (pageSettings, params, overwrite, overwriteCombineFilters, combineFilters = true, orderId = true, sortKeysReplacements = {}, defaultSorter = ['id']) => {
  const locFilters = find(params, { field: 'sales_location' })
  // FIXME: We can't safely filter for og strings right now // TODO: Why not?
  // const og1Filters = find(params, { field: 'menuline' })
  // const og2Filters = find(params, { field: 'component' })

  const cFilters = {
    // Fallback to default if an empty date range was saved
    ...(pageSettings[Filter.DATE_RANGE]?.value ? combineFilters ? { date_range: pageSettings[Filter.DATE_RANGE].value } : { date_range: pageSettings[Filter.DATE_RANGE].value.join(',') } : undefined),
    ...(locFilters
      ? { sales_locations: Array.isArray(locFilters.value) ? locFilters : [locFilters.value] }
      : undefined),
    ...overwriteCombineFilters
    // FIXME: We can't safely filter for og strings right now // TODO: Why not?
    // ...(og1Filters ? { menuline_items: og1Filters.value } : undefined),
    // ...(og2Filters ? { component_items: og2Filters.value } : undefined)
  }

  let ordering
  if (pageSettings.sortModel) {
    const sortModel = getOrderingForSortModel(pageSettings.sortModel, sortKeysReplacements)
    forEach(defaultSorter, s => {
      if (intersection([`-${s}`, s], sortModel).length === 0) sortModel.push(s)
    })
    ordering = sortModel.join(',')
  } else {
    ordering = orderId
      ? getOrdering(pageSettings.sorter, sortKeysReplacements) ? `${getOrdering(pageSettings.sorter, sortKeysReplacements)},category,sales_location` : 'category,sales_location'
      : `${getOrdering(pageSettings.sorter, sortKeysReplacements)},category,sales_location` || 'category,sales_location'
  }

  return {
    ordering,
    page: pageSettings.pagination && pageSettings.pagination.current ? pageSettings.pagination.current : 1,
    page_size: pageSettings.pagination && pageSettings.pagination.pageSize ? pageSettings.pagination.pageSize : 100,
    ...(pageSettings.search && pageSettings.search.trim() !== '' ? { search: pageSettings.search } : undefined),
    ...(pageSettings.wasteCategory && pageSettings.wasteCategory.length > 0 ? { categories: pageSettings.wasteCategory.join(',') } : undefined),
    ...(combineFilters ? { combine_filters: JSON.stringify(cFilters) } : cFilters),
    ...overwrite
  }
}
