import { useDebugStore } from 'hooks/store/useDebugStore'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { omit } from 'lodash'
const { WebsocketConnectionStateToString } = require('components/Charts/utils')
const { useGeneralStore } = require('hooks/store/useGeneralStore')

export const collectDebugInformation = (error = null, context = {}) => {
  try {
    const { version, socketState, customer, token } = useGeneralStore.getState()
    const user = window.queryClient.getQueryData(['me', token]) || {}
    const settings = useSettingsStore.getState().settings
    const messages = useDebugStore.getState().messages

    return {
      ...(error ? { error: { message: error.message, stack: error.stack.split('\n').filter(i => i.trim() !== '') } } : {}),
      timestamp: new Date().toISOString(),
      appState: {
        route: window.location.pathname,
        frontend_version: window.localStorage.getItem('lastVisitedVersion'),
        backend_version: version ? version.version_backend : 'N/A',
        online: navigator.onLine,
        websocket: WebsocketConnectionStateToString(socketState)
      },
      device: {
        userAgent: navigator.userAgent,
        resolution: `${window.outerWidth}x${window.outerHeight}`,
        window: `${window.innerWidth}x${window.innerHeight}`
      },
      context,
      userSettings: settings,
      customer,
      user: omit(user, ['settings']),
      log: messages
    }
  } catch (e) {
    return {
      error: `Error collecting debug information: ${e.message}`
    }
  }
}

export const submitDebugReport = (data) => {
  try {
    const { fetch, token } = useGeneralStore.getState()

    return new Promise((resolve, reject) => {
      fetch(
        '/debug-report/',
        {
          method: 'POST',
          token,
          success: (res) => resolve(res),
          failure: (errors) => {
            console.error('Error logging debug information', errors)
            reject(errors)
          },
          body: data
        }
      )
    })
  } catch (e) {
    console.error('Error logging debug information', e)
    return Promise.reject(e)
  }
}
