import React, { useEffect, useMemo, useState } from 'react'

import { SearchSelect } from 'components/SearchSelect'
import { useAdminData } from 'hooks'
import { useIntl } from 'react-intl'
import labelMessages from 'components/labelMessages'
import { isEmpty } from 'utils'

export default ({ value, onChange, flat, medium, single, placeholder, className, required, onLoaded, allowClear, gray, disabled, withPortal, tagType, hasError }) => {
  const intl = useIntl()
  const [initialValue] = useState(value)

  // when no value is given on mount, we only want non-orphaned items
  const { data } = useAdminData('tags', { type: tagType || 'saleslocation', remove_orphans: !!isEmpty(initialValue) })
  const { isFetching, items, status, isLoading } = data

  const filteredItems = useMemo(() => {
    if (!items) return null
    // with empty value on mount, we can just return the data
    if (isEmpty(initialValue)) return items

    // otherwise, we need to filter out the orphaned items but make sure, the initial value is still in the list
    return items.filter((item) => item.objects_count > 0 || item.id === initialValue)
  }, [items, initialValue])

  useEffect(() => {
    if (filteredItems && !isFetching && status === 'success') {
      if (onLoaded) {
        onLoaded(filteredItems)
      }
    }
  }, [filteredItems, isFetching, status])

  return (
    <SearchSelect
      disabled={disabled}
      role={tagType === 'item' ? 'item-tag-picker' : 'location-tag-picker'}
      className={className}
      type={flat ? undefined : 'round'}
      fitWidth={flat}
      size={medium ? undefined : 'small'}
      placeholder={placeholder || intl.formatMessage(labelMessages.locationGroups)}
      value={value}
      onChange={onChange}
      loading={isLoading}
      optionsLabel={intl.formatMessage(labelMessages.locationGroups)}
      prefixIcon='Tag'
      required={required}
      single={single}
      itemsGroup={intl.formatMessage(labelMessages.locationGroups)}
      allowClear={allowClear}
      graybg={gray}
      options={filteredItems && filteredItems.map((item) => ({
        key: item.id,
        label: item.name,
        color: item.color,
        objects_count: item.objects_count,
        assignType: tagType
      }))}
      menuType='tag'
      withPortal={withPortal}
      hasError={hasError}
    />
  )
}
