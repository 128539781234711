import { FIELD } from './constants'

export const DEFINITIONS = [
  {
    id: '7038e050-4089-4de5-beb3-5a358b771945',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '7054e381-ea56-428a-983d-329e0d224955',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'transaction_revenue'
        }
      ]
    }
  },
  {
    id: '0dc844d3-4171-4a9d-93fa-35425126b60f',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'num_vanished'
        }
      ]
    }
  },
  {
    id: 'a8eefc89-e62c-4881-9e66-853e1f10ad6b',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'fulfilled_amount'
        }
      ]
    }
  },
  {
    id: 'bce868bf-8647-4e29-a03c-c3a7e24eadb7',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'ordered_amount'
        }
      ]
    }
  },
  {
    id: 'f04d1ec1-ca01-44a1-a2e5-4b8b57080766',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'requested_amount'
        }
      ]
    }
  },
  {
    id: 'ab7280ed-91c9-4300-bcbc-97937d8fae5e',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'fad78748-ca78-401a-aa40-e5558f86add7',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '459587f5-1aae-455b-bb49-f831c4608be0',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'transactions'
        }
      ]
    }
  },
  {
    id: '41938d04-0978-4ca2-9d1a-f9794de263f3',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'num_unsold'
        }
      ]
    }
  },
  {
    id: '0966c906-98ed-4f65-aaa4-0e15b4fcaebd',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'waste_amount'
        }
      ]
    }
  },
  {
    id: 'c3017de9-6eec-4222-b0b6-0e32bb16a997',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'cogs_cost'
        }
      ]
    }

  },
  {
    id: '363e9555-c508-4807-b514-1bf63ced64ce',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'co2'
        }
      ]
    }
  },
  {
    id: 'a6b21cb6-bf8b-456d-9d1e-7a6fa1507d95',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'water'
        }
      ]
    }
  },
  {
    id: 'bcac490f-63e5-4753-96ba-77a23d27489c',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'returnRate'
        }
      ]
    }
  },
  {
    id: '588901e7-ec49-497e-abd7-6bd663275d84',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'relCogs'
        }
      ]
    },
    onlyZerosIsEmpty: true
  },
  {
    id: '4b652a2f-efd7-4734-acdd-50815ca78962',
    type: 'summarized-area-with-comparison',
    data: {
      properties: [
        {
          propKey: 'amountPerSale'
        }
      ]
    }
  },
  {
    id: '61fabeff-9909-45eb-98ad-435d368a9e33',
    type: 'pie',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: 'cbb46fdb-cda2-4b19-8867-2030f51d4ee5',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: 'a4309990-36a9-40cf-aed5-b3d8b61ce93e',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '8e4b5fcc-aca0-48b5-bbd1-25270aa1737e',
    type: 'pie',
    data: {
      groupBy: FIELD.OFFERING_GROUP_1,
      properties: [
        {
          key: 'data',
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '2da5734e-981e-4e17-8c7b-1c978d68f4d7',
    type: 'pie',
    data: {
      groupBy: FIELD.OFFERING_GROUP_2,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '7418c0ef-2976-4970-a50a-bba704a238bd',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '7c0ccf29-5ce0-48af-961a-e6080ac23401',
    type: 'pie',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'a36fea23-b21f-420f-bc04-b7208f314027',
    type: 'pie',
    title: '',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'd95e8fe8-d5a7-4f40-8b45-8b6141253d62',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'bf8cc055-6add-42b2-8054-253477385702',
    type: 'pie',
    data: {
      groupBy: FIELD.OFFERING_GROUP_1,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: '93ae67eb-5c98-43cb-a5e9-560cb68b77f0',
    type: 'pie',
    data: {
      groupBy: FIELD.OFFERING_GROUP_2,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: '7090a5c1-aa96-49cf-9bd5-888242cf5580',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'ca8a2bd6-2c72-4864-8a1a-792d367e30f2',
    type: 'pie',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '27f1ed0f-c57d-47b5-9e35-5c8f0070df00',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '2c4c1d10-7935-4c07-b58b-c10030b389a7',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '37097406-e010-4ea6-a16b-4ca26a50f25d',
    type: 'pie',
    data: {
      groupBy: FIELD.OFFERING_GROUP_1,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: 'f304422c-da09-431b-8ab5-4f43bacf3d2a',
    type: 'pie',
    data: {
      groupBy: FIELD.OFFERING_GROUP_2,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: 'af21ee61-3375-4b91-a15a-64e2262b7908',
    type: 'pie',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '3ec0ed76-b5ac-4b85-96df-368d85898ee1',
    type: 'pie',
    data: {
      groupBy: FIELD.WASTE_CATEGORY,
      properties: [
        {
          propKey: 'waste_amount'
        }
      ]
    }
  },
  {
    id: '6454d601-c5f7-4674-ae7e-8099a474f674',
    type: 'pie',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'waste_amount'
        }
      ]
    }
  },
  {
    id: '64bf30a9-b93f-4d44-8ba5-f019ddea9659',
    type: 'bar',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '7640ccd4-757e-46a8-a67d-78a849535d1a',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '7a2e03db-4ff1-4104-bf30-1e274a6401a8',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '90fb6b68-45e3-4a90-9f11-63ded205530e',
    type: 'bar',
    data: {
      groupBy: FIELD.OFFERING_GROUP_2,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '9bd86f59-8a6b-434f-87fa-2f9f8917080b',
    type: 'bar',
    data: {
      groupBy: FIELD.OFFERING_GROUP_1,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '4e1cdb81-007a-4fab-8689-d4f3a84e9f1f',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: 'fa45a8f4-1640-4aa1-b428-b2410887e9ba',
    type: 'bar',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: '82364ea2-7ee5-40a7-a31d-59065a557b3e',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: '84e72188-ea1b-4001-a1c7-487c8ef9de80',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'a1df1e02-b1b8-445f-aa4a-83a752a05ed7',
    type: 'bar',
    data: {
      groupBy: FIELD.OFFERING_GROUP_2,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: '372bc068-e6b9-43a0-bd16-4bbcd09b63bd',
    type: 'bar',
    data: {
      groupBy: FIELD.OFFERING_GROUP_1,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: '23c6fb14-7f2e-452b-9a43-8d85e7e3f2de',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'a97b4173-bd38-4c48-8487-dd5bcfe95924',
    type: 'bar',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: 'f5d28be2-5078-473b-a6f7-7ff72813511f',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '9de4c622-c75a-4632-8f5b-5fbc8c25a1ff',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: 'a6b519c4-74da-4498-bfc3-fc13455129f3',
    type: 'bar',
    data: {
      groupBy: FIELD.OFFERING_GROUP_2,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '8f264262-a010-44d3-b2ad-ef3ef659dd1a',
    type: 'bar',
    data: {
      groupBy: FIELD.OFFERING_GROUP_1,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '8c7f139c-ffa6-4687-816c-505272ec6869',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '2c182c87-3e20-4174-b565-12ea54f2a8f3',
    type: 'bar',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: 'f767ba9e-12b1-4b68-84d6-4f642d0d8f2c',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: '3d5c529a-73e3-4e99-8a4b-f36278784537',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: '819aae64-9a48-4d1a-8b3d-31ff19f95dc9',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: '546ce14b-fd4d-4c0f-aeb8-26ccb07573d2',
    type: 'bar',
    bar: {
      barCategoryGap: '5%',
      barGap: '5%',
      unstacked: true
    },
    data: {
      properties: [
        {
          propKey: 'forecastAccuracy'
        }
      ]
    }
  },
  {
    id: '2e225e9a-6909-48b4-9129-df17e0b916c2',
    type: 'line',
    data: {
      properties: [
        {
          propKey: 'num_sold'
        },
        {
          propKey: 'num_forecast'
        },
        {
          propKey: 'num_planned'
        }
      ]
    }
  },
  {
    id: 'a1b015ef-4487-4afc-b1fc-5f9a63f10956',
    type: 'line',
    data: {
      properties: [
        {
          propKey: 'num_sold'
        },
        {
          propKey: 'ordered_amount'
        },
        {
          propKey: 'requested_amount'
        },
        {
          propKey: 'fulfilled_amount'
        },
        {
          propKey: 'num_unsold'
        },
        {
          propKey: 'returnRate',
          yAxis: 'right'
        }
      ]
    }
  },
  {
    id: 'fc046e55-6661-45f8-a4a2-577e30aadba1',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: '89922b68-1482-43ff-816e-ae0b62f9e428',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.OFFERING_GROUP_2,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'b9877a63-7ea6-4c2b-848d-4564c7693299',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '82327089-9c51-458d-a303-c81de6c67d5d',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  {
    id: '9bb553cc-aecc-4267-afcc-ce878c24bd11',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'transactions'
        }
      ]
    }
  },
  {
    id: '95902816-7143-4c54-b8a5-8e2ab7774beb',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'transaction_revenue'
        }
      ]
    }
  },
  {
    id: '7fa7b764-6e6b-4304-9de6-cd3c69656025',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'ordered_amount'
        }
      ]
    }
  },
  {
    id: 'b8027a73-ee3a-4a46-9e6f-b81db3be59b8',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'requested_amount'
        }
      ]
    }
  },
  {
    id: '04a1469b-4e2f-49d9-a857-5983acfd0e63',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'fulfilled_amount'
        }
      ]
    }
  },
  {
    id: '477fdb6c-089c-48a1-9dd3-0baf7f8e47e6',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'num_unsold'
        }
      ]
    }
  },
  {
    id: '3418ce3a-f8a9-4311-99fc-8ab26914cf88',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'num_vanished'
        }
      ]
    }
  },
  {
    id: '1d7c70f1-dc94-42aa-8168-b6ba5de48b7b',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'returnRate'
        }
      ]
    }
  },
  {
    id: 'b8a67a7b-bc76-4488-989f-ccc8a58ad4b4',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'relCogs'
        }
      ]
    }
  },
  {
    id: 'eb5807fa-ca09-4618-a809-9abf08438b70',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'revenue'
        }
      ]
    }
  },
  {
    id: 'd1f46974-1d2e-4429-9fdb-251f4c450b04',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'relCogs'
        }
      ]
    }
  },
  {
    id: 'eae30feb-42d6-4604-9d23-310e19abc983',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'total_cogs'
        }
      ]
    }
  },
  {
    id: '86649a1f-c5b9-49ee-820c-987a464e6c7d',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'num_sold'
        }
      ]
    }
  },
  // TODO: Extend this template for a real-world use case
  {
    id: '8f80b945-5004-4064-ac28-f32e43a0f9e5',
    type: 'table',
    data: {
      groupBy: FIELD.WASTE_CATEGORY,
      properties: [
        {
          propKey: 'waste_amount'
        },
        {
          propKey: 'co2'
        },
        {
          propKey: 'water'
        }
      ]
    }
  },
  {
    id: 'e8937469-a9c9-4313-a0dd-69c213b97684',
    type: 'table',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'revenue'
        },
        {
          propKey: 'num_sold'
        },
        {
          propKey: 'fulfilled_amount'
        },
        {
          propKey: 'num_unsold'
        },
        {
          propKey: 'returnRate'
        }
      ]
    }
  },
  {
    id: 'b81f60bc-44e6-44be-a521-db8713f9e69e',
    type: 'table',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'revenue'
        },
        {
          propKey: 'num_sold'
        },
        {
          propKey: 'fulfilled_amount'
        },
        {
          propKey: 'num_unsold'
        },
        {
          propKey: 'returnRate'
        }
      ]
    }
  },
  {
    id: '2cf5e422-ee9f-4b59-a2d3-d1cc60b2a4e3',
    type: 'table',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'revenue'
        },
        {
          propKey: 'num_sold'
        },
        {
          propKey: 'fulfilled_amount'
        },
        {
          propKey: 'num_unsold'
        },
        {
          propKey: 'returnRate'
        }
      ]
    }
  },
  {
    id: 'edc04a01-189a-41c4-a1cf-95f91ed222a3',
    type: 'table',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'revenue'
        },
        {
          propKey: 'num_sold'
        },
        {
          propKey: 'fulfilled_amount'
        },
        {
          propKey: 'num_unsold'
        },
        {
          propKey: 'returnRate'
        }
      ]
    }
  },
  {
    id: '47dc2e9d-4d44-40b5-a658-d788edc30396',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'waste_amount'
        }
      ]
    }
  },
  // {
  //   id: '1caaf240-199b-4c88-b0c2-aedbe1e14a07',
  //   type: 'list',
  //   data: {
  //     sort: 'desc',
  //     groupBy: FIELD.LOCATION,
  //     properties: [
  //       {
  //         propKey: 'amountPerSale'
  //       }
  //     ]
  //   }
  // },
  {
    id: '62691a6b-f438-46e1-82d5-8b1bfb4de923',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'enteredMeasurements'
        }
      ]
    }
  },
  {
    id: '9fe48a9c-face-4380-9c6d-bceb762ac9b4',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'openMeasurements'
        }
      ]
    }
  },
  {
    id: 'd91d6ca2-6ede-4111-85fb-bc38e886cba7',
    type: 'bar',
    data: {
      groupBy: FIELD.WASTE_CATEGORY,
      properties: [
        {
          propKey: 'waste_amount'
        }
      ]
    }
  },

  {
    id: '91d2b88c-26f3-49de-9d25-313fe661916a',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: '458f1392-632e-40f8-abee-99faaa1eb604',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksDoneInPercent'
        }
      ]
    }
  },
  {
    id: '3d449bed-1b38-49f7-a006-874f8663560d',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksLate'
        }
      ]
    }
  },
  {
    id: '6a154fea-843b-4dc7-b619-e9303ab46219',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksLateInPercent'
        }
      ]
    }
  },
  {
    id: '09084a6b-8c4c-41eb-a614-09041c41c17e',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksDeleted'
        }
      ]
    }
  },
  {
    id: '0f86c51d-191b-4250-8d6e-0b2f17ff3e27',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksDeletedInPercent'
        }
      ]
    }
  },
  {
    id: '7cec5cd7-2ee9-4c16-81c3-259b551b69b7',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksCorrected'
        }
      ]
    }
  },
  {
    id: 'a4afb95e-7a8b-4054-a91e-470d667ccf36',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksCorrectedInPercent'
        }
      ]
    }
  },
  {
    id: '5b1dd05f-d373-484c-94d6-fd01071334bf',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksManuallyAdded'
        }
      ]
    }
  },
  {
    id: 'f6d4229b-bfce-4688-ad8b-cbdd9f7b8d26',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksManuallyAddedInPercent'
        }
      ]
    }
  },
  {
    id: 'f2c73e3a-fb25-42bb-9bbf-3cc5feccb4d1',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: '62ae7883-efb7-40af-b1ba-544b64138547',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksDoneInPercent'
        }
      ]
    }
  },
  {
    id: 'd89b70b7-9717-4d70-b41c-342f22fa9005',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksLate'
        }
      ]
    }
  },
  {
    id: 'a720b87f-ef76-4cc6-b938-2146c829c0c5',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksLateInPercent'
        }
      ]
    }
  },
  {
    id: '13a1eae0-5ccd-41f0-84b0-1dea22059501',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksDeleted'
        }
      ]
    }
  },
  {
    id: '49a191cc-2c59-4985-a7dc-6609b0b75830',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksDeletedInPercent'
        }
      ]
    }
  },
  {
    id: '8daab888-346b-4877-92b2-c5b58375b5ea',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksCorrected'
        }
      ]
    }
  },
  {
    id: '5efaeb32-fd87-48b8-8556-774860342677',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksCorrectedInPercent'
        }
      ]
    }
  },
  {
    id: 'c9cfb4cb-13b9-4d64-a73d-4ee9ddca63a1',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksManuallyAdded'
        }
      ]
    }
  },
  {
    id: '182bb420-0697-439a-8332-4df8b3ba01f2',
    type: 'list',
    data: {
      sort: 'desc',
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksManuallyAddedInPercent'
        }
      ]
    }
  },
  {
    id: 'ae0ae6ca-c918-4152-989b-c78de2818b9b',
    type: 'bar',
    data: {
      groupBy: FIELD.LOCATION,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: 'a52a15f2-ccb8-4d26-87a1-d3ebb950bcf3',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_1,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: '8cec6956-5690-434e-85a2-ed2338fde851',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM_CATEGORY_2,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  },
  {
    id: 'ce9c8c4c-004e-4452-80fa-6fcfb93176e2',
    type: 'bar',
    data: {
      groupBy: FIELD.ITEM,
      properties: [
        {
          propKey: 'tasksDone'
        }
      ]
    }
  }
]
