import { Debugger } from './Debugger'
import { FormattedMessage } from 'react-intl'
import Heading from '../Heading'
import React from 'react'
import { Transition } from '@headlessui/react'
import s from './DebugConsole.module.scss'
import { useDebugStore } from 'hooks/store/useDebugStore'
import RoundedButton from 'components/RoundedButton'

export const DebugConsole = () => {
  const visible = useDebugStore((state) => state.visible)
  const toggle = useDebugStore((state) => state.toggle)

  return (
    <div className='absolute top-0 left-0 w-full z-150'>
      <Transition
        show={visible}
        enter='transform transition ease-in-out duration-500'
        enterFrom='opacity-0 -translate-y-full'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease duration-500 transform'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 -translate-y-full'
      >
        <div className={s.container}>
          <div className='flex items-center justify-between mb-6'>
            <Heading type='h3' className='ml-2'>
              <FormattedMessage id='debugConsole.title' defaultMessage='Debug Console' />
            </Heading>
            <RoundedButton
              className={s.closeButton}
              icon='Close'
              type='secondary'
              onClick={toggle}
            />
          </div>

          <Debugger />
        </div>
      </Transition>
    </div>

  )
}
