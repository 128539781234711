import classNames from 'classnames'
import { BarLoader } from 'react-spinners'
import s from './LoadingArea.module.scss'

/**
 * Takes the full content and renders a Loader in front, blending out the background
 */
export const LoadingArea = ({ children, loading, className }) => (
  <div className={classNames(s.root, className, loading && s.loading)} role='loading-area' data-loading={loading}>
    {children}
    {loading && <div className={s.backdrop} role='loading-area-backdrop' />}
    {loading && <BarLoader color='#4094da' cssOverride={{ position: 'absolute', top: 0, left: 0, zIndex: 21, backgroundColor: '#DEF0FE' }} height={4} width='100%' speedMultiplier={0.5} />}
  </div>
)
