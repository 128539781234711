import { useQuery } from '@tanstack/react-query'
import { usePermissions, useQueryFetcher, useUser } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { find } from 'lodash'
import queryString from 'query-string'
import { useCallback } from 'react'

export const useOwnFoodwasteConfigs = (options) => {
  const opts = {
    ...options,
    enabled: true
  }

  const { user } = useUser()
  const permissions = usePermissions()
  const selectedCustomer = useSelectedCustomer()
  const { fetch, token } = useQueryFetcher()

  const isEnabled = (opts.enabled && user != null && permissions != null && permissions.isInitialized && !permissions.isSuperUser && user.food_waste_access) == true

  const { data, status, error } = useQuery({
    queryKey: ['user-foodwaste-configs', { selectedCustomer }],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/configs/user-configs/?${queryString.stringify({
          customer: selectedCustomer
        })}`,
        {
          method: 'GET',
          token,
          success: resolve,
          failure: reject
        }
      )
    }),
    enabled: isEnabled
  })

  const isEditable = useCallback((record) => {
    if (!isEnabled) return true
    if (!data) return false

    const { category_id, sales_location_id } = record
    const match = find(data, { category: category_id, sales_location: sales_location_id })
    return match != null
  }, [data, isEnabled])

  return {
    data,
    status,
    error,
    isEditable,
    isEnabled
  }
}
