import classNames from 'classnames'
import { Badge } from 'components/Primitives'
import { useIsDeveloperMode } from 'hooks/useIsDeveloperMode'
import { useRef } from 'react'

const useRenderCount = () => {
  const renderCountRef = useRef(0)
  renderCountRef.current += 1
  return renderCountRef.current
}

export const RenderCount = ({ relative, className }) => {
  const renderCount = useRenderCount()
  const isDeveloperMode = useIsDeveloperMode()
  if (!isDeveloperMode) return null

  return <Badge className={classNames(relative ? null : 'absolute ml-4', className)} type={Badge.TYPES.accentMarigold} bold>{renderCount}</Badge>
}
