import s from './CellRenderer.module.scss'
import globalMessages from 'components/globalMessages'
import { Badge } from 'components/Primitives'

const getLabel = (value, variant, intl) => {
  switch (variant) {
    case 'truefalse':
    default:
      return value ? 'True' : 'False'
    case 'activeinactive':
      return value ? intl.formatMessage(globalMessages.active) : intl.formatMessage(globalMessages.inactive)
    case 'opendone':
      return value ? intl.formatMessage(globalMessages.done) : intl.formatMessage(globalMessages.open)
    case 'yesno':
      return value ? intl.formatMessage(globalMessages.yes) : intl.formatMessage(globalMessages.no)
  }
}

export const BooleanRenderer = (props) => {
  const { value, colDef } = props
  const valueToShow = (value == null && colDef.fallbackValue != null ? colDef.fallbackValue : value)
  if (valueToShow == null) return null

  const checkValue = colDef.invert ? !valueToShow : valueToShow
  const variant = colDef.variant || 'truefalse'

  const label = getLabel(checkValue, variant, colDef.intl)

  // const defaultColor = colDef.defaultColor || 'gray'
  return (
    <div className={s.tagLine}>
      <Badge size='s' type={checkValue ? 'fbSuccess' : 'fbError'}>{label}</Badge>
    </div>
  )
}
