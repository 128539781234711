import React, { useMemo } from 'react'
import { BASE_VALUE_FORMAT, SMALL_WEIGHT_VALUE_FORMAT, WEIGHT_VALUE_FORMAT } from 'constants/index'

import { Bar } from '../Bar'
import { LOCATION } from 'hooks/useChartTabs'
import { Spin } from 'antd'
import Tabs from '../Tabs'
import { calcBarChartData } from '../utils'
import cn from 'classnames'
import s from '../ChartStylesBar.module.scss'
import { useDashboardRefiner } from 'hooks'
import { NoData } from 'components/NoData'
import { isNaN, isNil } from 'lodash'
import labelMessages from 'components/labelMessages'
import titleMessages from 'components/titleMessages'
import { FlexibleScaledNumberFormat } from 'components/FlexibleScaledNumberFormat'

export const AMOUNT = 'amount'
export const G_PER_NUM_SOLD = 'grams_per_num_sold'
export const OPEN_MEASUREMENTS = 'open_measurements'
export const ENTERED_MEASUREMENTS = 'entered_measurements'

const TABS = [
  { value: AMOUNT, label: labelMessages.amount },
  {
    value: G_PER_NUM_SOLD,
    label: labelMessages.amountPerSale
  },
  {
    value: ENTERED_MEASUREMENTS,
    label: labelMessages.measurements
  },
  {
    value: OPEN_MEASUREMENTS,
    label: titleMessages.openMeasurements
  }
]

export default ({ maxCount, tab, setTab, fixed, data, loading, isInModal, onRefine }) => {
  const chartData = useMemo(() => calcBarChartData(data, { withAverage: false, valueProp: tab === G_PER_NUM_SOLD }), [data])
  const refiner = useDashboardRefiner({ tab: LOCATION })

  let numberFormat = BASE_VALUE_FORMAT
  switch (tab) {
    case G_PER_NUM_SOLD:
      numberFormat = SMALL_WEIGHT_VALUE_FORMAT
      break
    case AMOUNT:
      numberFormat = WEIGHT_VALUE_FORMAT
      break
  }

  const bars = chartData.map(({ location, value, percentage, average }, index) => {
    if (value == null || isNaN(value) || value === Infinity || (maxCount && index > maxCount - 1)) {
      return null
    }
    return (
      <Bar
        key={location}
        title={location}
        total={!isNil(value) && (<FlexibleScaledNumberFormat value={value} {...numberFormat} />)}
        percentage={percentage}
        isAverage={average}
        onDetailClick={() => {
          if (onRefine) { onRefine() }
          refiner.handleRefine({ name: location })
        }}
      />
    )
  })

  return (
    <Spin spinning={loading} wrapperClassName={cn(s.wrapper, isInModal ? s.inModal : null)}>
      <Tabs fixed={fixed} value={tab} onChange={setTab} options={TABS} />
      {bars.length > 0 ? <div className={s.bars}>{bars}</div> : <div className={s.noData}><NoData size='m' /></div>}
    </Spin>
  )
}
