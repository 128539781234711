import { useDocumentVisibility, useInterval, usePrevious } from 'ahooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { DASHBOARD_ROUTE, FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, LOGOUT_ROUTE, REGISTER_ROUTE, REPORT_ROUTE, SET_PASSWORD_ROUTE, routeRoleConfig } from 'routes'
import { MONTH, REFRESH_TOKEN_COOKIE, TOKEN_COOKIE, TOKEN_EXPIRATION, TOKEN_REFRESH_AFTER, ADMIN, OPERATOR, USER } from 'constants/index'
import { useEffect } from 'react'
import { usePermissions, useQueryFetcher, useUser } from 'hooks'
import { some } from 'lodash'
import { useAppContext } from '../../services/context'
import { addHours, addSeconds, formatISO, isAfter, parseISO } from 'date-fns'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useInitializationStore } from 'hooks/store/useInitializationStore'
import { useCustomerType } from 'hooks/useCustomerType'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import { getInternalPathName } from 'utils'

const noTokenWhitelist = [LOGIN_ROUTE, REGISTER_ROUTE, FORGOT_PASSWORD_ROUTE, SET_PASSWORD_ROUTE, LOGOUT_ROUTE]

const checkRole = (roles, permissions) => {
  if (!roles) {
    return true
  }

  const result = roles.map(r => {
    switch (r) {
      case OPERATOR:
        return permissions.isOperator
      case ADMIN:
        return permissions.isAdmin
      case USER:
        return true
      default:
        return false
    }
  })
  return some(result)
}

export const SKIP_LOADING_ROUTES = [LOGIN_ROUTE, REGISTER_ROUTE, FORGOT_PASSWORD_ROUTE, SET_PASSWORD_ROUTE, LOGOUT_ROUTE, REPORT_ROUTE]

/**
 * This component handles general authentication logic and checks for permissions
 */
export const AuthManager = () => {
  const { cookies } = useAppContext()
  const { pathname: currentRouteName } = useLocation()
  const previousRoute = usePrevious(currentRouteName)
  const { user } = useUser()
  const permissions = usePermissions()
  const docVisibility = useDocumentVisibility()
  const { fetch } = useQueryFetcher()
  const { logout } = useAuth()
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const setFinished = useInitializationStore((state) => state.setFinished)
  const resetInitialization = useInitializationStore((state) => state.reset)
  const customerType = useCustomerType()
  const setToken = useGeneralStore((state) => state.setToken)

  const selectedCustomer = useSelectedCustomer()
  const navigate = useNavigate()

  // dashboard is a dynamic route with a name parameter, so we need to check if it starts with /dashboard
  const checkRouteName = getInternalPathName(currentRouteName)

  useEffect(() => {
    if (previousRoute === currentRouteName) { return }
    if (previousRoute) {
      logDebugMessage(`Navigate: ${previousRoute} => ${currentRouteName}`)
    } else if (!previousRoute) {
      logDebugMessage(`Navigate: => ${currentRouteName}`)
    }

    if (SKIP_LOADING_ROUTES.includes(currentRouteName)) {
      setFinished(true)
    }
  }, [currentRouteName])

  useEffect(() => {
    if (user && selectedCustomer && permissions.isInitialized) {
      const roleConfig = routeRoleConfig[checkRouteName]
      if (!roleConfig) {
        console.warn(`Current route ${currentRouteName} has no role config!`)
        logDebugMessage(`AuthProvider redirecting to root… | ${currentRouteName} has no role config`)
        navigate(DASHBOARD_ROUTE, { replace: true })
        return
      }

      // check if the current user has the role required for the current route
      if (roleConfig.roles != null && !checkRole(roleConfig.roles, permissions)) {
        logDebugMessage(`AuthProvider redirecting to root… | ${JSON.stringify(permissions)} has no permission for ${currentRouteName}`)
        navigate(DASHBOARD_ROUTE, { replace: true })
      }

      // check if user has access to the current route
      if (roleConfig.permissions) {
        const permissionCheck = roleConfig.permissions.map(p => permissions[p] === true)
        if (permissionCheck.includes(false)) {
          logDebugMessage(`AuthProvider redirecting to root… | Permission check ${JSON.stringify(permissions)} for ${currentRouteName} not passed`)
          navigate(DASHBOARD_ROUTE, { replace: true })
        }
      }

      // check, if the customer is allowed to access the current route
      // we don't use it currently, but we could hardcode customer ids for routes
      if (roleConfig.customers) {
        if (!roleConfig.customers.includes(selectedCustomer)) {
          logDebugMessage(`AuthProvider redirecting to root… | Customer ${selectedCustomer} has no access to ${currentRouteName}`)
          navigate(DASHBOARD_ROUTE, { replace: true })
        }
      }

      if (roleConfig.customerTypes) {
        if (!roleConfig.customerTypes.includes(customerType)) {
          logDebugMessage(`AuthProvider redirecting to root… | Customer Type ${customerType} has no access to ${currentRouteName}`)
          navigate(DASHBOARD_ROUTE, { replace: true })
        }
      }
    }
  }, [user, currentRouteName, selectedCustomer, permissions, customerType])

  const refreshAuth = (refreshToken) => new Promise((resolve, reject) => {
    logDebugMessage('Refreshing Auth Token…')
    console.log('Refreshing Auth Token', refreshToken)
    const startTime = new Date().getTime() // Get the start time
    const requestTimeoutDuration = 3000

    const timeout = setTimeout(() => {
      logDebugMessage(`Request to refresh auth token exceeded the allowed time of ${requestTimeoutDuration}ms`)
    }, requestTimeoutDuration)

    fetch('/auth/token/', {
      omitToken: true,
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      body: {
        client_id: config.api.clientId,
        client_secret: config.api.clientSecret,
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      },
      success: async (res) => {
        clearTimeout(timeout)
        const endTime = new Date().getTime() // Get the end time
        const duration = endTime - startTime // Calculate the duration in milliseconds
        resolve({ ...res, duration })
      },
      failure: (res) => {
        clearTimeout(timeout)
        logDebugMessage(`Failure refreshing auth token, therefore performing logout: ${JSON.stringify(res)}`)
        reject(new Error('Failure refreshing auth token'))
      }
    })
  })

  const handleAuthExpiration = () => {
    const token = cookies.get(TOKEN_COOKIE)
    const refreshToken = cookies.get(REFRESH_TOKEN_COOKIE)
    const refreshAfter = cookies.get(TOKEN_REFRESH_AFTER) || formatISO(addHours(new Date(), -1))

    if (!refreshToken) {
      if (!noTokenWhitelist.includes(currentRouteName)) {
        console.log('No refresh_token, redirecting to login…')
        logDebugMessage('No refresh_token, redirecting to login…')
        navigate(`${LOGIN_ROUTE}?reason=invalid_token`, { replace: true })
      }
      return
    }

    if (isAfter(new Date(), parseISO(refreshAfter)) || !token) {
      logDebugMessage(`Refreshing token due to non-existence or refresh time ${refreshAfter} reached…`)
      refreshAuth(refreshToken)
        .then((auth) => {
          cookies.set(TOKEN_COOKIE, auth.access_token, { maxAge: auth.expires_in })
          cookies.set(TOKEN_EXPIRATION, formatISO(addSeconds(new Date(), auth.expires_in)), { maxAge: MONTH })
          cookies.set(TOKEN_REFRESH_AFTER, formatISO(addSeconds(new Date(), auth.expires_in / 2)), { maxAge: MONTH })
          cookies.set(REFRESH_TOKEN_COOKIE, auth.refresh_token, { maxAge: MONTH })
          setToken(auth.access_token, 'AuthManager.handleAuthExpiration')
          logDebugMessage(`Successfully refreshed auth tokens in ${auth.duration}ms using the refresh token.`)
          if (currentRouteName === LOGIN_ROUTE) {
            // We don't need to login anymore, so we can load the root page
            resetInitialization()
            navigate(DASHBOARD_ROUTE, { replace: true })
          }
        })
        .catch(() => {
          // if refreshing the token fails, there is a core problem, so we redirect
          logout()
        })
    } else {
      if (currentRouteName === LOGIN_ROUTE ||
         currentRouteName === REGISTER_ROUTE ||
         currentRouteName === SET_PASSWORD_ROUTE ||
        currentRouteName === FORGOT_PASSWORD_ROUTE
      ) {
        // We don't need to login anymore, so we can load the root page
        resetInitialization()
        navigate(DASHBOARD_ROUTE, { replace: true })
      }
    }
  }

  useEffect(() => {
    if (docVisibility === 'visible') {
      handleAuthExpiration()
    }
  }, [docVisibility])

  useInterval(() => handleAuthExpiration(), 30000)

  return null
}
