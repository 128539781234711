import globalMessages from 'components/globalMessages'
import message from 'services/message'
import { useMutation } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useIntl } from 'react-intl'

export const useTrainingDataset = () => {
  const intl = useIntl()
  const { fetch, token } = useQueryFetcher()

  const { mutate: download, isPending: downloadIsPending } = useMutation({
    mutationFn: () => new Promise((resolve, reject) => {
      message.info(intl.formatMessage(globalMessages.actionStarted))
      fetch(
        '/download-training-dataset/',
        {
          method: 'GET',
          fileName: 'training-dataset.csv',
          token,
          success: (res) => resolve(res),
          failure: (errors) => {
            message.error(intl.formatMessage(globalMessages.exportFailed))
            reject(errors)
          }
        }
      )
    })
  })

  const { mutate: update, isPending: updateIsPending } = useMutation({
    mutationFn: () => new Promise((resolve, reject) => {
      fetch(
        '/update-training-dataset/',
        {
          method: 'GET',
          token,
          success: (res) => {
            message.success(intl.formatMessage(globalMessages.actionSucceededDefault))
            resolve(res)
          },
          failure: (errors) => {
            message.error(intl.formatMessage(globalMessages.defaultError))
            reject(errors)
          }
        }
      )
    })
  })

  return {
    update: {
      mutate: update,
      isPending: updateIsPending
    },
    download: {
      mutate: download,
      isPending: downloadIsPending
    }
  }
}
