import { useMemo, useState } from 'react'

import { ACCENT_COLORS_LIST } from 'constants/index'
import { CirclePicker } from 'react-color'
import { find } from 'lodash'
import s from './ColorPicker.module.scss'
import classNames from 'classnames'

export const ColorPicker = ({ onChange, value, className }) => {
  const colors = useMemo(() => ACCENT_COLORS_LIST.map(o => o.fg), [])

  const [internalValue, setInternalValue] = useState(() => {
    const ac = find(ACCENT_COLORS_LIST, { bg: value })
    return ac ? ac.fg : value
  })

  const handleChange = (color) => {
    setInternalValue(color.hex)
    const ac = find(ACCENT_COLORS_LIST, { fg: color.hex })
    onChange(ac ? ac.bg : color.hex)
  }

  return (
    <div className={classNames(s.colorPicker, className)}>
      <CirclePicker color={internalValue} colors={colors} onChangeComplete={onChange ? handleChange : undefined} />
    </div>
  )
}
