import classNames from 'classnames'
import s from './Tabs.module.scss'
import * as RadixTabs from '@radix-ui/react-tabs'
import { useState, useRef, useMemo, useEffect } from 'react'
import { useDebounceEffect, useSize } from 'ahooks'

export const Tabs = ({ tabs, onChange, activeTab, className, spaced, ...props }) => {
  const [triggerWidths, setTriggerWidths] = useState([])
  const Triggers = useMemo(() => tabs.map(({ key, label, disabled }, idx) => (
    <RadixTabs.Trigger
      key={key}
      className={s.tab}
      value={key}
      disabled={disabled}
      ref={el => {
        if (el) {
          setTriggerWidths(prev => {
            const next = [...prev]
            next[idx] = el.clientWidth
            return next
          })
        }
      }}
      data-tab-name={key}
    >
      {label}
    </RadixTabs.Trigger>
  )), [tabs])

  const Contents = useMemo(() => tabs.map(({ key, content }) => (
    <RadixTabs.Content
      key={key}
      className={s.content}
      value={key}
    >
      {content}
    </RadixTabs.Content>
  )), [tabs])

  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [arrowsVisible, setArrowsVisible] = useState(false)
  const [translation, setTranslation] = useState(0)
  const [direction, setDirection] = useState('left')
  const [position, setPosition] = useState(0)
  const [forceCalculation, setForceCalculation] = useState(false)

  const docBody = useMemo(() => document.body, [])
  const windowSize = useSize(docBody)
  const wrapperRef = useRef(null)

  const centerTab = () => {
    setDirection('left')
    let tab = tabs.findIndex(tab => tab.key === activeTab)
    if (tab == -1) tab = 0
    setPosition(tab)
    setForceCalculation(!forceCalculation)
  }

  useDebounceEffect(() => {
    const element = wrapperRef.current
    centerTab()
    setArrowsVisible(element.clientWidth > element.parentNode.clientWidth)
  }, [windowSize.width], { wait: 300, leading: false, trailing: true })

  // Calculate where the tabs start and end
  const stopPoints = useMemo(() => {
    let stopPoint = 0
    const left = []
    const right = []
    triggerWidths.forEach((width, idx) => {
      left[idx] = stopPoint
      stopPoint += width
      right[idx] = stopPoint
      stopPoint += 32
    })
    right[right.length - 1] = stopPoint - 32
    return { left, right }
  }, [triggerWidths])

  useEffect(() => {
    centerTab()
  }, [triggerWidths])

  useEffect(() => {
    if (direction === 'left') {
      const max = wrapperRef.current.clientWidth - wrapperRef.current.parentNode.clientWidth
      const t = stopPoints[direction][position] - (hasPrev ? 34 : 0)
      if (t >= max) {
        setTranslation(-max)
        setHasNext(false)
      } else {
        setTranslation(-t)
        setHasNext(true)
      }
      setHasPrev(position !== 0)
    } else {
      const t = stopPoints[direction][position] - wrapperRef.current.parentNode.clientWidth + (hasNext ? 34 : 0)
      if (t <= 0) {
        setTranslation(0)
        setHasPrev(false)
      } else {
        setTranslation(-t)
        setHasPrev(true)
      }
      setHasNext(position !== stopPoints[direction].length - 1)
    }
  }, [position, direction, stopPoints, forceCalculation, hasPrev, hasNext])

  const onPrev = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let prev = stopPoints.left.findIndex((distance) => {
      return distance > -translation
    })
    prev -= 1
    // Edge case where the tab is the same width as the container
    if (prev == position) prev -= 1
    // if out of bounds
    if (prev < 0) prev = 0
    setPosition(prev)
    setDirection('right')
  }

  const onNext = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let next = stopPoints.right.findIndex((distance) => {
      return distance > -translation + wrapperRef.current.parentNode.clientWidth
    })
    // Edge case where the tab is the same width as the container
    if (next == position) next += 1
    // if out of bounds
    if (next < 0 || next >= triggerWidths.length) next = triggerWidths.length - 1
    setPosition(next)
    setDirection('left')
  }

  return (
    <RadixTabs.Root
      {...props}
      value={activeTab}
      onValueChange={onChange}
      defaultValue={tabs[0].key}
      className={classNames(s.root, className)}
    >
      <RadixTabs.List className={classNames(s.list, spaced && s.spaced, arrowsVisible && s.hasArrows, hasPrev && s.hasPrev, hasNext && s.hasNext)}>
        <div className={s.outer}>
          <div className={classNames(s.inner)} ref={wrapperRef} style={{ transform: `translateX(${arrowsVisible ? translation : 0}px)` }}>
            {Triggers}
          </div>
        </div>
        {arrowsVisible && hasPrev && <div className={s.prev} onClick={onPrev} />}
        {arrowsVisible && hasNext && <div className={s.next} onClick={onNext} />}
      </RadixTabs.List>
      <div className={s.contents}>
        {Contents}
      </div>
    </RadixTabs.Root>
  )
}
