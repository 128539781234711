import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { VIEWER, addReport, extendDashboard, getReportByDefinition } from 'components/Dashboard/utils'
import { useQueryFetcher } from 'hooks/useQueryFetcher'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { cloneDeep, find, findIndex, isEqual, kebabCase, omit } from 'lodash'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DASHBOARD_ROUTE } from 'routes'
import { useReportManagement } from './useReportManagement'
import { useDashboardStore } from 'hooks/store/useDashboardStore'
import queryString from 'query-string'
import { getMinSizesByReportType } from 'components/Report/utils'
import { v4 as uuidv4 } from 'uuid'
import { useUser } from './useUser'

export const useDashboardManagement = (options) => {
  const opts = {
    enabled: true,
    ...options
  }
  const { user } = useUser()
  const queryClient = useQueryClient()
  const customer = useSelectedCustomer()
  const setLayoutId = useDashboardStore(state => state.setLayoutId)
  const { fetch, token } = useQueryFetcher()
  const navigate = useNavigate()
  const loc = useLocation()
  const { data, isLoading } = useQuery({
    queryKey: ['dashboards', customer],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/dashboards/?${queryString.stringify({
          page_size: 999,
          customer
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res.results),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: opts.enabled
  })

  // Mutation for creating or updating a dashboard
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      // we have to check if the user is allowed to edit the dashboard
      if (values.id) {
        const allDashboards = useDashboardStore.getState().allDashboards
        const dashboard = allDashboards.find((d) => d.id === values.id && d.ownAccessType === VIEWER)
        if (dashboard) {
          return reject(new Error('You are not allowed to edit this dashboard!'))
        }
      }
      fetch(
        values.id
          ? `/dashboards/${values.id}/?${queryString.stringify({
            customer
          })}`
          : `/dashboards/?${queryString.stringify({
            customer
          })}`,
        {
          method: values.id ? 'PATCH' : 'POST',
          body: omit(values, ['reports']), // reports cannot be set like this, but we still may pass it for optimistic updating
          token,
          success: (res) => resolve(res),
          failure: (errors) => reject(errors)
        }
      )
    }),
    // we use optimistic updating to already update the dashboard collection to prevent flickering
    onMutate: (data) => {
      // Update the query cache with the new dashboard data
      queryClient.setQueryData(['dashboards', customer], (oldData) => {
        if (oldData) {
          // If updating an existing dashboard, find the index of the dashboard in the array
          const index = oldData.findIndex((dashboard) => dashboard.id === data.id)
          // if we find the dashboard in the list, we update it.
          // TODO: Align with props returned by trimmed down /dashboards route
          if (index !== -1) {
            const extendedData = {
              ...oldData[index],
              ...data,
              path: kebabCase(`${oldData[index].id}-${data.title || oldData[index].title}` || 'null'),
              ...(data.title ? { title: data.title } : undefined),
              ...(data.settings ? { settings: data.settings } : undefined)
            }
            // Create a new array with the updated dashboard
            const newData = [...oldData]
            newData[index] = extendedData
            return newData
          }
        }
        // if we can't find the new dashboard, we don't do anything yet, since we need the ID to build the path
      })

      // Update the single dashboard entity in the cache
      if (data.id) {
        queryClient.setQueryData(['dashboard', customer, data.id], (oldData) => {
          const merged = {
            ...oldData,
            ...data
          }
          const extended = extendDashboard(user, merged)
          return extended
        })
      }

      // whenever a dashboard is edited, we're going to the new url of that dashboard
      // this solves all issues e.g. in case the title (and therefore the URL) was changed
      if (data.id && data.title) {
        const newPath = kebabCase(`${data.id}-${data.title}`)
        navigate(`${DASHBOARD_ROUTE}/${newPath}${loc.hash}`, { replace: true })
      }
    },
    onSuccess: (result, data) => {
      // use the query response to update the cache again
      const extended = extendDashboard(user, result)
      queryClient.setQueryData(['dashboard', customer, data.id], extended)

      if (!data.id) {
        // we created a new dashboard. Now we should add it to the list of dashboards
        queryClient.setQueryData(['dashboards', customer], (oldData) => {
          if (oldData) {
            return [...oldData, extended]
          }
        })

        // also add the entity
        queryClient.setQueryData(['dashboard', customer, result.id], extended)
      }
    }
  })

  // Mutation for deleting a dashboard
  const { mutateAsync: deleteMutate, isPending: deleteIsPending } = useMutation({
    mutationFn: (id) => new Promise((resolve, reject) => {
      fetch(
        `/dashboards/${id}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'DELETE',
          token,
          success: (res) => resolve(res),
          failure: (errors) => reject(errors)
        }
      )
    }),
    onMutate: (id) => {
      // Update the query cache with the new dashboard data
      queryClient.setQueryData(['dashboards', customer], (oldData) => {
        if (oldData) {
          // If updating an existing dashboard, find the index of the dashboard in the array
          const index = oldData.findIndex((dashboard) => dashboard.id === id)
          if (index !== -1) {
            // we have the dashboard already in the list
            // Create a new array without the deleted dashboard
            const newData = oldData.filter((dashboard) => dashboard.id !== id)
            return newData
          }
        }
      })
    }
    // we don't do anything on dashboard delete success, we trust the cache to be updated correctly
    // onSuccess: (result) => {}
  })

  const { update, remove } = useReportManagement()
  const addReportAndSave = async (definition) => {
    const { currentDashboard, breakpoint } = useDashboardStore.getState()
    const def = cloneDeep(definition)

    // if there is already a report entity with that definition id, we're making a duplicate, so we want to take it's width and height
    // if dimensions are provided, we already got dimensions, probably from a shared report
    const existingReport = def.dimensions ? null : getReportByDefinition(def, currentDashboard)
    const forceLayout = existingReport ? find(currentDashboard.layouts[breakpoint], { i: def.id }) : null

    def.id = uuidv4()
    const created = await update.mutate({ dashboard: currentDashboard.id, definition: def })
    const result = addReport(created, forceLayout)

    await mutateAsync({
      id: created.dashboard,
      layouts: result.layouts,
      reports: result.reports
    })
    setLayoutId(uuidv4())

    window.setTimeout(() => {
      const chartElem = document.querySelector(`div[data-chart="${def.id}"]`)
      if (!chartElem) return
      // scroll down until we have the new chart fully in the viewport
      chartElem.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }, 500)
  }
  const removeReportAndSave = async (definition, title) => {
    const currentDashboard = useDashboardStore.getState().currentDashboard
    const reportEntity = currentDashboard.reports.find((report) => report.definition.id === definition.id)
    // remove definition from layouts
    const newLayouts = {}
    Object.keys(currentDashboard.layouts).forEach((key) => {
      newLayouts[key] = currentDashboard.layouts[key].filter((item) => item.i !== definition.id)
    })

    await remove.mutate(reportEntity.id)
    return mutateAsync({
      id: currentDashboard.id,
      layouts: newLayouts
    })
  }
  const updateReportAndSave = async (definition) => {
    const currentDashboard = useDashboardStore.getState().currentDashboard
    // it's possible, that the definition type has different minimum layout sizes
    const minSizes = getMinSizesByReportType(definition.type)

    // for all breakpoints, we need to update the minW and minH and if necessary the w and h
    const clonedLayouts = cloneDeep(currentDashboard.layouts)
    Object.keys(clonedLayouts).forEach((key) => {
      const layoutIndex = findIndex(clonedLayouts[key], { i: definition.id })
      if (layoutIndex === -1) return
      const rLayout = {
        ...clonedLayouts[key][layoutIndex],
        ...minSizes,
        ...(minSizes.minW > clonedLayouts[key][layoutIndex].w ? { w: minSizes.minW } : {}),
        ...(minSizes.minH > clonedLayouts[key][layoutIndex].h ? { h: minSizes.minH } : {})
      }
      clonedLayouts[key][layoutIndex] = rLayout
    })

    const reportEntity = getReportByDefinition(definition, currentDashboard)

    const layoutDiffers = !isEqual(clonedLayouts, currentDashboard.layouts)
    await update.mutate({ id: reportEntity.id, definition, dashboardId: currentDashboard.id })

    if (layoutDiffers) {
      await mutateAsync({
        id: currentDashboard.id,
        layouts: clonedLayouts
      })
    }
  }

  const result = useMemo(() => {
    return {
      dashboards: {
        data,
        isLoading
      },
      update: {
        mutate: mutateAsync,
        isPending: isPending
      },
      deletion: {
        mutate: deleteMutate,
        isPending: deleteIsPending
      },
      reports: {
        add: addReportAndSave,
        remove: removeReportAndSave,
        update: updateReportAndSave
      }
    }
  }, [data, isLoading, mutateAsync, isPending, deleteMutate, deleteIsPending])

  return result
}
