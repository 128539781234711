import { find, pick, omit } from 'lodash'
import { DEFINITIONS } from '../components/Report/definitions'

export const DEFAULT_PAGE = '_default'

export const getDefinition = (chart) => {
  switch (chart.i) {
    case 'revenue':
      return find(DEFINITIONS, { id: 'ab7280ed-91c9-4300-bcbc-97937d8fae5e' })
    case 'num_sold':
      return find(DEFINITIONS, { id: '7038e050-4089-4de5-beb3-5a358b771945' })
    case 'foodwaste_sales_costs':
      return find(DEFINITIONS, { id: 'c3017de9-6eec-4222-b0b6-0e32bb16a997' })
    case 'rel_cogs':
      return find(DEFINITIONS, { id: '588901e7-ec49-497e-abd7-6bd663275d84' })
    case 'total_cogs':
      return find(DEFINITIONS, { id: 'fad78748-ca78-401a-aa40-e5558f86add7' })
    case 'transactions':
      return find(DEFINITIONS, { id: '459587f5-1aae-455b-bb49-f831c4608be0' })
    case 'avg_transaction_revenue':
      return find(DEFINITIONS, { id: '7054e381-ea56-428a-983d-329e0d224955' })
    case 'foodwaste_amount_per_sale':
      return null
      // return find(DEFINITIONS, { id: '4b652a2f-efd7-4734-acdd-50815ca78962' })
    case 'foodwaste_co2':
      return find(DEFINITIONS, { id: '363e9555-c508-4807-b514-1bf63ced64ce' })
    case 'foodwaste_water':
      return find(DEFINITIONS, { id: 'a6b21cb6-bf8b-456d-9d1e-7a6fa1507d95' })
    case 'orders_overview':
      return find(DEFINITIONS, { id: 'a1b015ef-4487-4afc-b1fc-5f9a63f10956' })
    case 'ordered_amounts':
      return find(DEFINITIONS, { id: 'bce868bf-8647-4e29-a03c-c3a7e24eadb7' })
    case 'requested_amounts':
      return find(DEFINITIONS, { id: 'f04d1ec1-ca01-44a1-a2e5-4b8b57080766' })
    case 'fullfilled_amounts':
      return find(DEFINITIONS, { id: 'a8eefc89-e62c-4881-9e66-853e1f10ad6b' })
    case 'unsold_amounts':
      return find(DEFINITIONS, { id: '41938d04-0978-4ca2-9d1a-f9794de263f3' })
    case 'return_rate':
      return find(DEFINITIONS, { id: 'bcac490f-63e5-4753-96ba-77a23d27489c' })
    case 'num_vanished':
      return find(DEFINITIONS, { id: '0dc844d3-4171-4a9d-93fa-35425126b60f' })
    case 'forecast':
      return find(DEFINITIONS, { id: '2e225e9a-6909-48b4-9129-df17e0b916c2' })
    case 'forecast_difference':
      return find(DEFINITIONS, { id: '546ce14b-fd4d-4c0f-aeb8-26ccb07573d2' })
    case 'locations_comparison':
      // Tab
      switch (chart.tab) {
        case 'revenue':
        default:
          return find(DEFINITIONS, { id: 'fc046e55-6661-45f8-a4a2-577e30aadba1' })
        case 'rel_cogs':
          return find(DEFINITIONS, { id: 'b8a67a7b-bc76-4488-989f-ccc8a58ad4b4' })
        case 'total_cogs':
          return find(DEFINITIONS, { id: 'b9877a63-7ea6-4c2b-848d-4564c7693299' })
        case 'num_sold':
          return find(DEFINITIONS, { id: '82327089-9c51-458d-a303-c81de6c67d5d' })
        case 'transaction_count':
          return find(DEFINITIONS, { id: '9bb553cc-aecc-4267-afcc-ce878c24bd11' })
        case 'avg_transaction_revenue':
          return find(DEFINITIONS, { id: '95902816-7143-4c54-b8a5-8e2ab7774beb' })
      }
    case 'composition':
      // Tab
      switch (chart.tab) {
        default:
        case 'sales_location__name':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '61fabeff-9909-45eb-98ad-435d368a9e33' })
            case 'revenue':
              return find(DEFINITIONS, { id: '7c0ccf29-5ce0-48af-961a-e6080ac23401' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: 'ca8a2bd6-2c72-4864-8a1a-792d367e30f2' })
          }
        case 'menuline':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '8e4b5fcc-aca0-48b5-bbd1-25270aa1737e' })
            case 'revenue':
              return find(DEFINITIONS, { id: 'bf8cc055-6add-42b2-8054-253477385702' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: '37097406-e010-4ea6-a16b-4ca26a50f25d' })
          }
        case 'component':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '2da5734e-981e-4e17-8c7b-1c978d68f4d7' })
            case 'revenue':
              return find(DEFINITIONS, { id: '93ae67eb-5c98-43cb-a5e9-560cb68b77f0' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: 'f304422c-da09-431b-8ab5-4f43bacf3d2a' })
          }
        case 'item__category_1':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: 'cbb46fdb-cda2-4b19-8867-2030f51d4ee5' })
            case 'revenue':
              return find(DEFINITIONS, { id: 'a36fea23-b21f-420f-bc04-b7208f314027' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: '27f1ed0f-c57d-47b5-9e35-5c8f0070df00' })
          }
        case 'item__category_2':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: 'a4309990-36a9-40cf-aed5-b3d8b61ce93e' })
            case 'revenue':
              return find(DEFINITIONS, { id: 'd95e8fe8-d5a7-4f40-8b45-8b6141253d62' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: '2c4c1d10-7935-4c07-b58b-c10030b389a7' })
          }
        case 'item__name':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '7418c0ef-2976-4970-a50a-bba704a238bd' })
            case 'revenue':
              return find(DEFINITIONS, { id: '7090a5c1-aa96-49cf-9bd5-888242cf5580' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: 'af21ee61-3375-4b91-a15a-64e2262b7908' })
          }
      }
    case 'productions_comparison':
      switch (chart.tab) {
        case 'revenue':
        default:
          return find(DEFINITIONS, { id: 'eb5807fa-ca09-4618-a809-9abf08438b70' })
        case 'rel_cogs':
          return find(DEFINITIONS, { id: 'd1f46974-1d2e-4429-9fdb-251f4c450b04' })
        case 'total_cogs':
          return find(DEFINITIONS, { id: 'eae30feb-42d6-4604-9d23-310e19abc983' })
        case 'num_sold':
          return find(DEFINITIONS, { id: '86649a1f-c5b9-49ee-820c-987a464e6c7d' })
      }
    case 'foodwaste':
      return find(DEFINITIONS, { id: '0966c906-98ed-4f65-aaa4-0e15b4fcaebd' })
    case 'foodwaste_composition':
      switch (chart.tab) {
        case 'category__name':
        default:
          return find(DEFINITIONS, { id: '3ec0ed76-b5ac-4b85-96df-368d85898ee1' })
        case 'sales_location__name':
          return find(DEFINITIONS, { id: '6454d601-c5f7-4674-ae7e-8099a474f674' })
      }
    case 'foodwaste_detail_view':
      // TODO: implement line chart
      switch (chart.option) {
        case 'amount':
        default:
          return find(DEFINITIONS, { id: 'd91d6ca2-6ede-4111-85fb-bc38e886cba7' })
        case 'grams_per_num_sold':
          return null
      }
    case 'foodwaste_locations_comparison':
      switch (chart.tab) {
        case 'amount':
        default:
          return find(DEFINITIONS, { id: '47dc2e9d-4d44-40b5-a658-d788edc30396' })
        // case 'grams_per_num_sold':
        //   return find(DEFINITIONS, { id: '1caaf240-199b-4c88-b0c2-aedbe1e14a07' })
        case 'entered_measurements':
          return find(DEFINITIONS, { id: '62691a6b-f438-46e1-82d5-8b1bfb4de923' })
        case 'open_measurements':
          return find(DEFINITIONS, { id: '9fe48a9c-face-4380-9c6d-bceb762ac9b4' })
      }
    case 'detail_view':
      switch (chart.tab) {
        default:
        case 'sales_location__name':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '64bf30a9-b93f-4d44-8ba5-f019ddea9659' })
            case 'revenue':
              return find(DEFINITIONS, { id: 'fa45a8f4-1640-4aa1-b428-b2410887e9ba' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: 'a97b4173-bd38-4c48-8487-dd5bcfe95924' })
          }
        case 'menuline':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '9bd86f59-8a6b-434f-87fa-2f9f8917080b' })
            case 'revenue':
              return find(DEFINITIONS, { id: '372bc068-e6b9-43a0-bd16-4bbcd09b63bd' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: '8f264262-a010-44d3-b2ad-ef3ef659dd1a' })
          }
        case 'component':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '90fb6b68-45e3-4a90-9f11-63ded205530e' })
            case 'revenue':
              return find(DEFINITIONS, { id: 'a1df1e02-b1b8-445f-aa4a-83a752a05ed7' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: 'a6b519c4-74da-4498-bfc3-fc13455129f3' })
          }
        case 'item__category_1':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '7640ccd4-757e-46a8-a67d-78a849535d1a' })
            case 'revenue':
              return find(DEFINITIONS, { id: '82364ea2-7ee5-40a7-a31d-59065a557b3e' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: 'f5d28be2-5078-473b-a6f7-7ff72813511f' })
          }
        case 'item__category_2':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '7a2e03db-4ff1-4104-bf30-1e274a6401a8' })
            case 'revenue':
              return find(DEFINITIONS, { id: '84e72188-ea1b-4001-a1c7-487c8ef9de80' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: '9de4c622-c75a-4632-8f5b-5fbc8c25a1ff' })
          }
        case 'item__name':
          // Metric Dropdown
          switch (chart.option) {
            default:
            case 'num_sold':
              return find(DEFINITIONS, { id: '4e1cdb81-007a-4fab-8689-d4f3a84e9f1f' })
            case 'revenue':
              return find(DEFINITIONS, { id: '23c6fb14-7f2e-452b-9a43-8d85e7e3f2de' })
            case 'total_cogs':
              return find(DEFINITIONS, { id: '8c7f139c-ffa6-4687-816c-505272ec6869' })
          }
      }
    case 'todo_status_location':
      switch (chart.mode) {
        case 'absolute':
        default:
          switch (chart.tab) {
            case 'done':
            default:
              return find(DEFINITIONS, { id: 'f2c73e3a-fb25-42bb-9bbf-3cc5feccb4d1' })
            case 'late':
              return find(DEFINITIONS, { id: 'd89b70b7-9717-4d70-b41c-342f22fa9005' })
            case 'deleted':
              return find(DEFINITIONS, { id: '13a1eae0-5ccd-41f0-84b0-1dea22059501' })
            case 'corrected':
              return find(DEFINITIONS, { id: '8daab888-346b-4877-92b2-c5b58375b5ea' })
            case 'manuallyAdded':
              return find(DEFINITIONS, { id: 'c9cfb4cb-13b9-4d64-a73d-4ee9ddca63a1' })
          }
        case 'relative':
          switch (chart.tab) {
            case 'done':
            default:
              return find(DEFINITIONS, { id: '62ae7883-efb7-40af-b1ba-544b64138547' })
            case 'late':
              return find(DEFINITIONS, { id: 'a720b87f-ef76-4cc6-b938-2146c829c0c5' })
            case 'deleted':
              return find(DEFINITIONS, { id: '49a191cc-2c59-4985-a7dc-6609b0b75830' })
            case 'corrected':
              return find(DEFINITIONS, { id: '5efaeb32-fd87-48b8-8556-774860342677' })
            case 'manuallyAdded':
              return find(DEFINITIONS, { id: '182bb420-0697-439a-8332-4df8b3ba01f2' })
          }
      }
    case 'todo_item_comparison':
      switch (chart.mode) {
        case 'absolute':
        default:
          switch (chart.tab) {
            case 'done':
            default:
              return find(DEFINITIONS, { id: '91d2b88c-26f3-49de-9d25-313fe661916a' })
            case 'late':
              return find(DEFINITIONS, { id: '3d449bed-1b38-49f7-a006-874f8663560d' })
            case 'deleted':
              return find(DEFINITIONS, { id: '09084a6b-8c4c-41eb-a614-09041c41c17e' })
            case 'corrected':
              return find(DEFINITIONS, { id: '7cec5cd7-2ee9-4c16-81c3-259b551b69b7' })
            case 'manuallyAdded':
              return find(DEFINITIONS, { id: '5b1dd05f-d373-484c-94d6-fd01071334bf' })
          }
        case 'relative':
          switch (chart.tab) {
            case 'done':
            default:
              return find(DEFINITIONS, { id: '458f1392-632e-40f8-abee-99faaa1eb604' })
            case 'late':
              return find(DEFINITIONS, { id: '6a154fea-843b-4dc7-b619-e9303ab46219' })
            case 'deleted':
              return find(DEFINITIONS, { id: '0f86c51d-191b-4250-8d6e-0b2f17ff3e27' })
            case 'corrected':
              return find(DEFINITIONS, { id: 'a4afb95e-7a8b-4054-a91e-470d667ccf36' })
            case 'manuallyAdded':
              return find(DEFINITIONS, { id: 'f6d4229b-bfce-4688-ad8b-cbdd9f7b8d26' })
          }
      }
    case 'todo_done_tasks':
      switch (chart.tab) {
        case 'location__name':
        default:
          return find(DEFINITIONS, { id: 'ae0ae6ca-c918-4152-989b-c78de2818b9b' })
        case 'item__category_1':
          return find(DEFINITIONS, { id: 'a52a15f2-ccb8-4d26-87a1-d3ebb950bcf3' })
        case 'item__category_2':
          return find(DEFINITIONS, { id: '8cec6956-5690-434e-85a2-ed2338fde851' })
        case 'item__name':
          return find(DEFINITIONS, { id: 'ce9c8c4c-004e-4452-80fa-6fcfb93176e2' })
      }
  }
  return null
}

export const getDefinitionForDashbordChart = (chart) => {
  const definition = getDefinition(chart)

  if (!definition) {
    console.warn('[getDefinitionForDashbordChart] no definition found for chart', chart)
    return null
  }

  // Extend the definitions with positional and sizing information
  return {
    ...definition,
    layout: {
      i: definition.id,
      ...pick(chart, ['h', 'w', 'x', 'y', 'minH', 'minW'])
    }
  }
}

export const migrateDashboard = (userDashbord) => {
  const definitions = userDashbord.map(chart => getDefinitionForDashbordChart(chart)).filter(i => i)

  // Now we build the layout for the different responsive breakpoints
  const layouts = { lg: [], md: [], xxs: [] }
  const pureDefinitions = definitions.map((definition) => {
    layouts.lg.push(definition.layout)
    layouts.md.push(definition.layout)
    layouts.xxs.push(definition.layout)
    return omit(definition, ['layout'])
  })

  return {
    layouts,
    definitions: pureDefinitions
  }
}
