import { defineMessages } from 'react-intl'

export default defineMessages({
  113: {
    id: '113',
    defaultMessage: 'Sunny'
  },
  116: {
    id: '116',
    defaultMessage: 'Partly cloudy'
  },
  119: {
    id: '119',
    defaultMessage: 'Cloudy'
  },
  122: {
    id: '122',
    defaultMessage: 'Overcast'
  },
  143: {
    id: '143',
    defaultMessage: 'Mist'
  },
  176: {
    id: '176',
    defaultMessage: 'Patchy rain possible'
  },
  179: {
    id: '179',
    defaultMessage: 'Patchy snow possible'
  },
  182: {
    id: '182',
    defaultMessage: 'Patchy sleet possible'
  },
  185: {
    id: '185',
    defaultMessage: 'Patchy sleet possible'
  },
  200: {
    id: '200',
    defaultMessage: 'Thundery outbreaks'
  },
  227: {
    id: '227',
    defaultMessage: 'Blowing snow'
  },
  230: {
    id: '230',
    defaultMessage: 'Blizzard'
  },
  248: {
    id: '248',
    defaultMessage: 'Fog'
  },
  260: {
    id: '260',
    defaultMessage: 'Freezing fog'
  },
  263: {
    id: '263',
    defaultMessage: 'Patchy light drizzle'
  },
  266: {
    id: '266',
    defaultMessage: 'Light drizzle'
  },
  281: {
    id: '281',
    defaultMessage: 'Freezing drizzle'
  },
  284: {
    id: '284',
    defaultMessage: 'Heavy freezing drizzle'
  },
  293: {
    id: '293',
    defaultMessage: 'Patchy light rain'
  },
  296: {
    id: '296',
    defaultMessage: 'Light rain'
  },
  299: {
    id: '299',
    defaultMessage: 'Moderate rain at times'
  },
  302: {
    id: '302',
    defaultMessage: 'Moderate rain'
  },
  305: {
    id: '305',
    defaultMessage: 'Heavy rain at times'
  },
  308: {
    id: '308',
    defaultMessage: 'Heavy rain'
  },
  311: {
    id: '311',
    defaultMessage: 'Light freezing rain'
  },
  326: {
    id: '326',
    defaultMessage: 'Light snow'
  }
})
