import React from 'react'
import cn from 'classnames'
import s from './Steps.module.scss'
import { useIntl } from 'react-intl'
import globalMessages from '../globalMessages'

const Steps = ({ current, steps }) => {
  const intl = useIntl()

  const remainingSteps = steps - current + 1

  const stepWidth = 100 / remainingSteps
  const stepLabelWidth = 100 / current

  const renderProgressLine = () =>
    <div
      className={cn('transition-width ease-in-out duration-500', s.stepContainer)}
      style={{ width: `${stepWidth}%` }}
    />

  const stepLabels = []
  for (let i = 1; i <= current; i++) {
    stepLabels.push(
      <div
        key={i}
        className={cn(s.step, {
          [s.current]: i === current,
          'pl-2': i > 1
        })}
        style={{ width: `${stepLabelWidth}%` }}
      >
        {`${intl.formatMessage(globalMessages.step)} ${i}`}
      </div>
    )
  }

  return (
    <div className='absolute top-0 left-0 w-full'>
      {renderProgressLine()}
      <div className='flex w-full px-6'>
        {stepLabels}
      </div>
    </div>
  )
}

export default Steps
