import {
  DASHBOARD_ROUTE,
  FOODWASTE_ROUTE,
  FORECASTS_ROUTE,
  TODO_ROUTE,
  SETTINGS_GENERAL_ROUTE
} from 'routes'
import React, { useRef } from 'react'

import MenuButton from './MenuButton'
import { useAppleHomebar, usePermissions, useUser } from 'hooks'
import { useIntl } from 'react-intl'
import titleMessages from 'components/titleMessages'
import { useSettingsStore } from 'hooks/store'

const BottomMenu = () => {
  const intl = useIntl()
  const { user } = useUser()
  const permissions = usePermissions()
  const settings = useSettingsStore(state => state.globalSettings)
  const ref = useRef()

  const homebar = useAppleHomebar()

  if (!user) {
    return null
  }

  return (
    <div
      ref={ref}
      className='fixed bottom-0 left-0 z-40 flex flex-col justify-center w-full bg-white border-t-2 border-b-0 border-l-0 border-r-0 border-solid border-disabled'
      style={{
        height: homebar.offset
      }}
    >
      <div className='flex items-center w-full'>
        <MenuButton id='lnk-dashboard' route={settings.lastDashboard ? `${DASHBOARD_ROUTE}/${settings.lastDashboard}` : DASHBOARD_ROUTE} icon='ReportData'>{intl.formatMessage(titleMessages.dashboard)}</MenuButton>
        {permissions.offering && <MenuButton id='lnk-forecasts' route={FORECASTS_ROUTE} icon='Restaurant'>{intl.formatMessage(titleMessages.offering)}</MenuButton>}
        {permissions.foodwaste && <MenuButton id='lnk-foodwaste' route={FOODWASTE_ROUTE} icon='TrashCan'>{intl.formatMessage(titleMessages.foodwaste)}</MenuButton>}
        {permissions.todo && <MenuButton id='lnk-todo' route={TODO_ROUTE} icon='Measurement'>{intl.formatMessage(titleMessages.todo)}</MenuButton>}
        <MenuButton id='lnk-settings' route={SETTINGS_GENERAL_ROUTE} icon='Settings'>{intl.formatMessage(titleMessages.settings)}</MenuButton>
      </div>
      {/* Home Bar Spacer for iOS Devices */}
      <div className='w-full transition-all duration-200 transform' style={{ height: homebar.height }} />
    </div>
  )
}

export default BottomMenu
