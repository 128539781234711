import { LOGIN_ROUTE } from 'routes'
import { MONTH, REFRESH_TOKEN_COOKIE, TOKEN_COOKIE, TOKEN_EXPIRATION, TOKEN_REFRESH_AFTER } from 'constants/index'
import { useCallback } from 'react'
import { addSeconds, formatISO } from 'date-fns'
import { useQueryFetcher } from 'hooks'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import config from 'config'
import { useAppContext } from 'services/context'
import { useGeneralStore } from './store/useGeneralStore'
import { useModalStore } from './store/useModalStore'

const clientId = config.api.clientId
const clientSecret = config.api.clientSecret

export const useAuth = () => {
  const { cookies } = useAppContext()
  const { fetch, token } = useQueryFetcher()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const setToken = useGeneralStore((state) => state.setToken)
  const hide = useModalStore(state => state.hide)

  const { mutateAsync: login, isPending: loginIsPending } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      fetch(
        '/auth/token/',
        {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: {
            username: values.email.toLowerCase(),
            password: values.password,
            grant_type: 'password',
            client_id: clientId,
            client_secret: clientSecret
          },
          success: (auth) => {
            cookies.set(TOKEN_COOKIE, auth.access_token, { maxAge: auth.expires_in })
            cookies.set(TOKEN_EXPIRATION, formatISO(addSeconds(new Date(), auth.expires_in)), { maxAge: MONTH })
            cookies.set(TOKEN_REFRESH_AFTER, formatISO(addSeconds(new Date(), auth.expires_in / 2)), { maxAge: MONTH })
            cookies.set(REFRESH_TOKEN_COOKIE, auth.refresh_token, { maxAge: MONTH })
            setToken(auth.access_token)
            resolve(auth)
          },
          failure: (errors) => reject(errors)
        }
      )
    })
  })

  const { mutateAsync: logoutAsync } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      fetch(
        '/auth/revoke-token/',
        {
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          body: {
            client_id: clientId,
            client_secret: clientSecret,
            token
          },
          success: () => {
            cookies.remove(TOKEN_COOKIE, { path: '' })
            cookies.remove(REFRESH_TOKEN_COOKIE, { path: '' })
            cookies.remove(TOKEN_EXPIRATION, { path: '' })
            cookies.remove(TOKEN_REFRESH_AFTER, { path: '' })
            queryClient.setQueryData(['me'], null)
            resolve()
          },
          failure: () => {
            // if the revoking fails, we ignore it and pretend everything is fine
            cookies.remove(TOKEN_COOKIE, { path: '' })
            cookies.remove(REFRESH_TOKEN_COOKIE, { path: '' })
            cookies.remove(TOKEN_EXPIRATION, { path: '' })
            cookies.remove(TOKEN_REFRESH_AFTER, { path: '' })
            queryClient.setQueryData(['me'], null)
            resolve()
          }
        }
      )
    })
  })

  const { mutate: impersonate, isPending: impersonateIsPending } = useMutation({
    mutationFn: (userId) => new Promise((resolve, reject) => {
      fetch(
        `/auth/impersonate/${userId}/`,
        {
          method: 'POST',
          success: (res) => resolve(res)
        }
      )
    }),
    onSuccess: (result) => {
      hide('impersonateUser')
      // refresh the page and set the impersonate token in the URL as query parameter
      window.location.href = `${window.location.origin}?impersonate=${result.impersonated_access_token}`
    }
  })

  const performLogout = useCallback((noRedirect) => {
    logoutAsync().then(noRedirect === true ? undefined : () => navigate(LOGIN_ROUTE))
  }, [logoutAsync])

  return {
    logout: performLogout,
    login: {
      mutate: login,
      isPending: loginIsPending
    },
    impersonate: {
      mutate: impersonate,
      isPending: impersonateIsPending
    }
  }
}
