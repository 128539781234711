import React, { useState } from 'react'

import { LocationPicker } from 'components/Pickers'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import messages from './messages'
import { useIntercom } from 'react-use-intercom'
import { useIntl } from 'react-intl'
import { useLocations } from 'hooks/queries/useLocations'
import { useExport } from 'hooks/useExport'
import { usePageSettings } from 'hooks/usePageSettings'
import titleMessages from 'components/titleMessages'
import labelMessages from 'components/labelMessages'
import { Button } from 'components/Button'
import { DropdownMenu } from 'components/DropdownMenu'

export default ({ exportType, inToolPanel }) => {
  const withExcel = exportType === 'forecasts'
  const { settings } = usePageSettings()

  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState(null)
  const exportFile = useExport({ type: exportType })
  const [locations, setLocations] = useState([])
  const { trackEvent } = useIntercom()
  const eventName = exportType === 'forecasts' ? 'exported_offering_table' : 'exported_waste_table'

  const { data: allLocations } = useLocations()

  const onButtonClick = (type) => {
    const locationsAsArr = Array.isArray(settings.location) ? settings.location : [settings.location.toString()]
    if (settings.location) { setLocations(locationsAsArr) }

    // if customer has only one location, we can skip the location selection modal
    if (type === 'Excel' || allLocations.length === 1) {
      exportFile.mutateAsync({ type: type.toLowerCase(), sales_locations: locationsAsArr.map(l => parseInt(l)) })
      trackEvent(eventName, { type })
    } else if (allLocations.length > 1) {
      setType(type)
      setShowModal(true)
    }
  }

  const onCancel = () => {
    setType(null)
    setShowModal(false)
  }

  const handleSubmit = () => {
    exportFile.mutateAsync({
      sales_locations: locations.map(l => parseInt(l))
    })
    trackEvent(eventName, { type: 'CSV' })
    onCancel()
  }

  const renderButton = () => {
    if (inToolPanel) {
      return (
        <Button fullWidth type='secondary' size='m' onClick={withExcel ? undefined : () => onButtonClick('CSV')}>
          {intl.formatMessage(titleMessages.export)}
        </Button>
      )
    }
    return (
      <Button
        icon='Export'
        type='tertiary'
        name='btn-export'
        onClick={withExcel ? undefined : () => onButtonClick('CSV')}
      >
        {intl.formatMessage(titleMessages.export)}
      </Button>
    )
  }

  return (
    <>
      {withExcel
        ? (
          <DropdownMenu
            menuSections={[{
              key: 'export',
              menuItems: [
                {
                  key: 'csv',
                  label: intl.formatMessage(messages.csv),
                  onClick: () => onButtonClick('CSV')
                }, {
                  key: 'excel',
                  label: intl.formatMessage(messages.excel),
                  onClick: () => onButtonClick('Excel')
                }
              ]
            }]}
            name='drpdwn-export'
            smallWidth
          >
            {renderButton()}
          </DropdownMenu>
          )
        : renderButton()}

      <MobileFriendlyModal
        visible={showModal}
        title={intl.formatMessage(titleMessages.export)}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        footer={{
          primaryText: intl.formatMessage(titleMessages.export)
        }}
      >
        <p className='mb-6'>{intl.formatMessage(messages.selectLocations, { type })}</p>
        <p className='mb-1'>{intl.formatMessage(labelMessages.locations)}</p>
        <LocationPicker fitWidth medium flat value={locations} onChange={setLocations} withPortal={false} />
      </MobileFriendlyModal>
    </>
  )
}
