
import { OFFERING_COLUMN_TITLES } from 'constants/index'
import React, { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useOfferingsTable from 'hooks/queries/useOfferingsTable'
import { usePageSettings } from 'hooks/usePageSettings'
import { Grid } from 'components/Grid'
import { useCurrentColState } from 'components/Grid/hooks/useCurrentColState'
import { useColStateOrdering } from 'components/Grid/hooks/useColStateOrdering'
import { useProduction } from 'hooks/queries/useProduction'
import message from 'services/message'

export const INITIAL_FORECAST_COLUMNS_ORDER = [
  'date',
  'name',
  'menuline',
  'num_planned',
  'forecast',
  'forecast_limit',
  'confidence',
  'description',
  'component',
  'dispo_id',
  'num_sold',
  'num_produced',
  'price',
  'cogs',
  'revenue',
  'forecast_difference'
]

const COLUMN_DEFINITIONS = {
  date: { field: 'date', type: Grid.TYPE.DATEWEATHER, fromRecord: true, lockVisible: true },
  name: { field: 'name', type: Grid.TYPE.TEXTWITHTOOLTIP, lockVisible: true },
  menuline: { field: 'menuline', type: Grid.TYPE.TEXT },
  component: { field: 'component', type: Grid.TYPE.TEXT },
  num_planned: { field: 'num_planned', type: Grid.TYPE.INTEGER, editable: true },
  forecast: { field: 'forecast', type: Grid.TYPE.FORECAST, varianceField: 'forecast_variance', precision: 0 },
  forecast_limit: { field: 'forecast_limit', type: Grid.TYPE.CHECKBOX, sortable: false, nullAsFalse: true, nonEditableText: <FormattedMessage id='phrase.forecastLimitDisabledReason' defaultMessage='To enable or disable the forecast limit, you have to first define the planned amount.' /> },
  confidence: { field: 'confidence', type: Grid.TYPE.CONFIDENCE, sortable: false },
  description: { field: 'description', type: Grid.TYPE.TEXT, sortable: false, hide: true },
  dispo_id: { field: 'dispo_id', type: Grid.TYPE.TEXT },
  num_sold: { field: 'num_sold', type: Grid.TYPE.INTEGER, editable: true },
  num_produced: { field: 'num_produced', type: Grid.TYPE.INTEGER, editable: true },
  price: { field: 'price', type: Grid.TYPE.CURRENCY },
  cogs: { field: 'cogs', type: Grid.TYPE.CURRENCY, sortable: false },
  revenue: { field: 'revenue', type: Grid.TYPE.CURRENCY, sortable: false },
  forecast_difference: { field: 'forecast_difference', type: Grid.TYPE.DIFFERENCE }
}

const SORT_KEYS = {
  name: 'item__name',
  menuline: 'menuline_item__name',
  component: 'component_obj__name'
}

const isForecastLimitEditable = (data) => data.num_planned != null

export const Productions = () => {
  const intl = useIntl()
  const { settings, set } = usePageSettings()

  const currentColState = useCurrentColState(settings)
  const ordering = useColStateOrdering(currentColState, [{ key: 'date', desc: false }, { key: 'id', desc: false }], SORT_KEYS)

  // FIXME: We want to use data-query for these requests.
  // Filtering by offering groups is right now not working, needs some refactor, but it would be better if we just switch to data-query.
  // This needs to be solved: https://linear.app/delicious-data/issue/TEC-820/extend-data-query-with-search-capabibilities
  const { data, isFetching, error } = useOfferingsTable({ ordering })
  const { update } = useProduction({ enabled: false })

  useEffect(() => {
    if (!error) return
    if (error.detail === 'Invalid page.') {
      set({
        pagination: {
          current: 1,
          pageSize: settings.pagination.pageSize
        }
      })
    } else if (error.detail) {
      message.error(error.detail)
    }
  }, [error])

  const onCellValueChanged = ({ colDef, oldValue, value, data, node, type }) => {
    if (colDef.field !== 'forecast_limit') return

    const val = data[colDef.field]

    update.mutate({
      productionId: data.id,
      name: colDef.field,
      value: val,
      initialValue: oldValue
    })
      .then((resp) => node.setData({ ...data, ...resp })) // update the row data with the response from the server
      .catch((e) => node.setData({ ...data, [colDef.field]: oldValue })) // reset the row data to the old value in case of an error
  }

  const getColumn = (column) => {
    return {
      ...COLUMN_DEFINITIONS[column],
      headerName: OFFERING_COLUMN_TITLES[column]
        ? intl.formatMessage(OFFERING_COLUMN_TITLES[column])
        : column,
      ...(column === 'forecast_limit'
        ? {
            classesFunc: (params) => isForecastLimitEditable(params.data) ? 'editable' : null
          }
        : undefined)
    }
  }

  // useMemo is super important here, since we extend the column definitions with intl info, but if we ever
  // recreate the columns object, it will result in the sidebars being regenerated and closed.
  const columns = useMemo(() => INITIAL_FORECAST_COLUMNS_ORDER.map((column) => getColumn(column)), [INITIAL_FORECAST_COLUMNS_ORDER, intl])

  const onCellEditRequest = ({ colDef, oldValue, value, data, api, node }) => {
    node.setData({ ...data, [colDef.field]: value }) // update the row data with the given value, so it looks updated
    update.mutate({
      productionId: data.id,
      name: colDef.field,
      value,
      initialValue: oldValue
    })
      .then((resp) => node.setData({ ...data, ...resp })) // update the row data with the response from the server
      .catch((e) => node.setData({ ...data, [colDef.field]: oldValue })) // reset the row data to the old value in case of an error
  }

  return (
    <Grid
      data={data ? data.results : data}
      count={data ? data.count : 0}
      columns={columns}
      onCellEditRequest={onCellEditRequest}
      onCellValueChanged={onCellValueChanged}
      exportType='forecasts'
      exportDefaultFormat='Excel'
      performingRequest={isFetching || update.isPending}
    />
  )
}
