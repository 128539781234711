import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { handleMutationError } from 'utils'

export const useSftpUser = (options) => {
  const opts = {
    enabled: true,
    ...options
  }
  const selectedCustomer = useSelectedCustomer()
  const { fetch, token } = useQueryFetcher()
  const queryKey = ['sftp-user', selectedCustomer]
  const queryClient = useQueryClient()

  const { data, status, isLoading } = useQuery({
    queryKey: ['sftp-user', selectedCustomer],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/customers/${selectedCustomer}/sftp-user`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => reject(err)
        }
      )
    }),
    retry: 0,
    enabled: opts.enabled
  })

  const { mutateAsync, isPending: mutateIsPending } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      fetch(
        `/customers/${selectedCustomer}/sftp-user/change-password/`,
        {
          method: 'PATCH',
          body: values,
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey })
  })

  return {
    data,
    status,
    isLoading,
    update: {
      mutate: mutateAsync,
      isPending: mutateIsPending
    }
  }
}
