import { Button } from 'components/Button'
import globalMessages from 'components/globalMessages'
import { FormattedMessage } from 'react-intl'

export const FilterFooter = ({ onCancel, onSubmit, submitDisabled }) => (
  <div className='flex justify-end space-x-2 mt-4'>
    <Button onClick={onCancel} type='secondary'><FormattedMessage {...globalMessages.cancel} /></Button>
    <Button onClick={onSubmit} disabled={submitDisabled} data-action='submit'><FormattedMessage {...globalMessages.ok} /></Button>
  </div>
)
