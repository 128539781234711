import 'whatwg-fetch'
import 'moment/locale/de'

import { AppContext } from 'services/context'
import AppLayout from 'components/Layout'
import Cookies from 'universal-cookie'
import { LOCALE_COOKIE } from './constants'
import { Providers } from 'components/App/Providers'
import Router from 'components/App/Router'
import cookie from 'js-cookie'
import createFetch from 'services/createFetch'
import history from 'services/history'
import { LicenseManager } from 'ag-grid-enterprise'
import { getNavigatorsDefaultLocale } from 'utils'
import { setLocale } from 'services/IntlService'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import config from 'config'

const cookies = new Cookies()

const whatwgFetch = createFetch(window.fetch, {
  cookies
})

const baseContext = {
  history,
  fetch: whatwgFetch,
  cookies
}

LicenseManager.setLicenseKey(config.agGridLicense)

// Use the language from the cookie or the browser's default language if supported in the locales
// Fallback for unsupported languages is the German for now
const locale = cookie.get(LOCALE_COOKIE) || getNavigatorsDefaultLocale()
document.querySelector('html').setAttribute('lang', locale.substring(0, 2))
const intl = setLocale(locale)

const context = {
  ...baseContext,
  locale
}

useGeneralStore.setState({ fetch: whatwgFetch, cookies })

function App () {
  return (
    <AppContext.Provider value={context}>
      <Providers intl={intl}>
        <AppLayout>
          <Router />
        </AppLayout>
      </Providers>
    </AppContext.Provider>
  )
}

export default App
