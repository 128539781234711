import { Transition } from '@headlessui/react'
import LoadingScreen from 'components/LoadingScreen'
import { useEffect, useState } from 'react'
import { useMount, usePrevious } from 'ahooks'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { init } from 'utils/init'
import { collectDebugInformation, submitDebugReport } from 'utils/debug'
import { useInitializationStore } from 'hooks/store'
import { useAppContext } from 'services/context'
import { useLocation, useNavigate } from 'react-router-dom'
import { SKIP_LOADING_ROUTES } from 'components/AppCore/AuthManager'
import { LOGIN_ROUTE } from 'routes'

export const LoadingWrapper = ({ children }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { cookies } = useAppContext()
  const { fetch } = useQueryFetcher()
  const logDebugMessage = useDebugStore(state => state.logDebugMessage)
  const initialized = useInitializationStore((state) => state.initialized)
  const toInitialize = useInitializationStore((state) => state.toInitialize)
  const setFinished = useInitializationStore((state) => state.setFinished)
  const finished = useInitializationStore((state) => state.finished)

  const startTimout = () => {
    const id = setTimeout(() => {
      logDebugMessage(`Loading Screen: Hard Refresh after 12 seconds! Loaded: ${initialized.join(',')}, to load: ${toInitialize.join(',')}  Refreshing page.`)
      submitDebugReport(collectDebugInformation(null, { type: `Loading Screen Hard refresh after 12 seconds, Loaded: ${initialized.join(',')}, to load: ${toInitialize.join(',')}` })).then(res => {
        // reload the page
        try {
          window.location.reload(true)
          location.href = location.origin + location.pathname
        } catch (e) { }
      })
    }, 12000)
    return id
  }

  const prevFinished = usePrevious(finished)

  useMount(() => {
    if (SKIP_LOADING_ROUTES.includes(pathname)) {
      setLoading(false)
      setFinished(true)
      return
    }
    logDebugMessage('Loading Screen mounted.')
    const id = startTimout()
    init(queryClient, fetch, cookies, logDebugMessage).then((result) => {
      clearTimeout(id)
      if (!result) {
        navigate(LOGIN_ROUTE, { replace: true })
      }
      setLoading(false)
    })
  })

  useEffect(() => {
    if (prevFinished && !finished && !loading) {
      setLoading(true)
      const id = startTimout()
      init(queryClient, fetch, cookies, logDebugMessage).then((result) => {
        clearTimeout(id)
        if (!result) {
          navigate(LOGIN_ROUTE, { replace: true })
        }
        setLoading(false)
      })
    }
  }, [prevFinished, finished])

  return (
    <>
      <Transition
        show={loading}
        leave='transition-opacity duration-1000'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        className='fixed inset-0 z-3000 h-full w-full flex top-0 left-0 bg-white dura'
      >
        <LoadingScreen />
      </Transition>
      {!loading && children}
    </>
  )
}
