import { PASSWORD_RULES } from 'constants/formSchema'
import React, { useMemo, useEffect } from 'react'
import s from './CheckRules.module.scss'
import { useIntl } from 'react-intl'
import { every } from 'lodash'
import { Icon } from 'components/Primitives'
import classNames from 'classnames'

const CheckRules = ({ value = '', onValidated, noMargin }) => {
  const intl = useIntl()

  const validation = useMemo(() => {
    return PASSWORD_RULES.map((rule, index) => {
      const m = rule.pattern.exec(value)
      return { key: rule.key, passed: !!(m && m.length > 0), message: rule.message }
    })
  }, [value])

  const isValid = useMemo(() => every(validation.map(v => v.passed)))

  useEffect(() => {
    if (onValidated) {
      onValidated({ isValid, validation })
    }
  }, [isValid, validation])

  return (
    <div className={classNames('mt-2', noMargin ? null : 'mb-4')}>
      {validation.map((rule, index) => (
        <div
          key={index}
          className={classNames(s.rule, rule.passed && s.checked)}
        >
          <Icon name='CheckmarkFilled' className={classNames(s.icon, 'mr-2 flex-shrink-0')} />
          <span>{intl.formatMessage(rule.message)}</span>
        </div>
      ))}
    </div>
  )
}

export default CheckRules
