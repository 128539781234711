import { ReactComponent as HistoryIcon } from 'static/history.svg'
import s from './CellRenderer.module.scss'
import { useModalStore } from 'hooks/store/useModalStore'

export const ForecastRenderer = (props) => {
  const { valueFormatted, data } = props
  const show = useModalStore(state => state.show)

  return (
    <div className={s.flexBetweenCell}>
      <button className={s.hoverBtn} onMouseDown={() => show('forecastHistory', data.id)}>
        <HistoryIcon />
      </button>
      <span>{valueFormatted}</span>
    </div>
  )
}
