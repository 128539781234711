import VarianceChart from 'components/VarianceChart'

export const DifferenceRenderer = (props) => {
  const { value } = props
  if (value == null) return null

  return (
    <div className='flex h-full items-center justify-center'>
      <VarianceChart className='w-full' absoluteDifference={value} />
    </div>
  )
}
