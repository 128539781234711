import { useQuery } from '@tanstack/react-query'
import { usePermissions, useQueryFetcher } from 'hooks'
import { useDebugStore } from 'hooks/store/useDebugStore'
import { useMemo } from 'react'

const fakeScript = {
  name: 'demo_script',
  args: [
    {
      name: 'Customer',
      type: 'Customer'
    },
    {
      name: 'int',
      type: 'int'
    },
    {
      name: 'str',
      type: 'str'
    },
    {
      name: 'bool',
      type: 'bool'
    },
    {
      name: 'Decimal',
      type: 'Decimal'
    },
    {
      name: 'datetime',
      type: 'datetime'
    },
    {
      name: 'date',
      type: 'date'
    },
    {
      name: 'time',
      type: 'time'
    },
    {
      name: 'timedelta',
      type: 'timedelta'
    },
    {
      name: 'SalesLocation',
      type: 'SalesLocation'
    },
    {
      name: 'Item',
      type: 'Item'
    }
  ],
  return_type: 'DataFrame',
  description: 'Just test possible args and the resulting form. See the transmitted data in the console on submit.'
}

export const useBackendScripts = (options) => {
  const { isOperator } = usePermissions()
  const { fetch, token } = useQueryFetcher()
  const debugMode = useDebugStore((state) => state.debugMode)
  const opts = {
    enabled: true,
    ...options
  }

  const isEnabled = opts.enabled && isOperator

  const { data, status, error } = useQuery({
    queryKey: ['list-scripts'],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        '/scripts/',
        {
          method: 'GET',
          token,
          success: (data) => resolve(JSON.parse(data)),
          failure: reject
        }
      )
    }),
    enabled: isEnabled
  })

  const rData = useMemo(() => {
    if (!data) { return null }
    if (!debugMode) return data
    return [
      ...data,
      fakeScript
    ]
  }, [data, debugMode])

  return {
    scripts: {
      data: rData,
      status,
      error
    }
  }
}
