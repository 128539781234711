import { usePrevious, useScroll } from 'ahooks'

import useIsMobile from './useIsMobile'
import { useMemo } from 'react'

const useMobileScrollEvent = (options) => {
  const opts = {
    enabled: true,
    ...options
  }
  const mobile = useIsMobile()
  const document = typeof (window) === 'object' ? window.document : null
  const scroll = useScroll(document, () => mobile && opts.enabled)

  const scrollTop = scroll ? scroll.top || 0 : 0

  const previousScroll = usePrevious(scrollTop)

  const scrollEvent = useMemo(() => {
    if (!scrollTop) return null
    const direction = scrollTop > (previousScroll || 0) ? 'down' : 'up'
    return {
      top: scrollTop,
      direction
    }
  }, [scrollTop])

  return scrollEvent
}

export default useMobileScrollEvent
