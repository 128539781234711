import { useEffect, useMemo } from 'react'
import { usePartialDataQueryObjects } from './usePartialDataQueryObjects'
import { orderBy, uniqBy } from 'lodash'
import { useDashboardStore } from 'hooks/store/useDashboardStore'

/**
 * Fetches the items from a given model sorted by name
 */
export const useItems = (options) => {
  const opts = {
    enabled: true,
    fromItemOffering: false,
    fromOrder: false,
    range: null,
    locations: null,
    todoEnabled: false,
    itemGroup1: null,
    itemGroup2: null,
    offeringGroup1: null,
    offeringGroup2: null,
    ...options
  }

  const setCachedItems = useDashboardStore((state) => state.setCachedItems)

  // if we don't have a range, we should only fetch data from: 'Item'
  const fromItem = !!(opts.range == null || opts.range.length == 0)
  const fromItemOffering = fromItem ? false : (opts.fromItemOffering === true)
  const fromOrder = fromItem ? false : (opts.fromOrder === true)

  const relationWhereParams = [
    ...(opts.itemGroup1
      ? [
          {
            field: 'item__category_1',
            operator: 'in',
            value: opts.itemGroup1
          }
        ]
      : []),
    ...(opts.itemGroup2
      ? [
          {
            field: 'item__category_2',
            operator: 'in',
            value: opts.itemGroup2
          }
        ]
      : []),
    ...(opts.todoEnabled
      ? [
          {
            field: 'item__todo_tags',
            operator: '!=',
            value: []
          }
        ]
      : [])
  ]

  const { data: itemOfferingData, isLoading: itemOfferingIsLoading, isFetching: itemOfferingIsFetching } = usePartialDataQueryObjects({
    from: 'ItemOffering',
    where: [
      {
        field: 'meco_item__forecasts',
        operator: '=',
        value: true
      },
      {
        field: 'date',
        operator: 'range',
        value: opts.range
      },
      ...(opts.offeringGroup1
        ? [
            {
              field: 'menuline',
              operator: 'in',
              value: opts.offeringGroup1
            }
          ]
        : []),
      ...(opts.offeringGroup2
        ? [
            {
              field: 'component',
              operator: 'in',
              value: opts.offeringGroup2
            }
          ]
        : []),
      ...(opts.locations && opts.locations.filter(i => i).length > 0
        ? [
            {
              field: 'sales_location',
              operator: 'in',
              value: opts.locations
            }
          ]
        : []),
      ...relationWhereParams
    ],
    enabled: opts.enabled && fromItemOffering
  }, {
    name: 'item__name',
    id: 'item',
    remote_pk: 'item__remote_pk'
  })

  const { data: orderData, isLoading: orderIsLoading, isFetching: orderIsFetching } = usePartialDataQueryObjects({
    from: 'Order',
    where: [
      {
        field: 'date',
        operator: 'range',
        value: opts.range
      },
      ...(opts.locations && opts.locations.filter(i => i).length > 0
        ? [
            {
              field: 'location',
              operator: 'in',
              value: opts.locations
            }
          ]
        : []),
      ...relationWhereParams
    ],
    enabled: opts.enabled && fromOrder
  }, {
    name: 'item__name',
    id: 'item',
    remote_pk: 'item__remote_pk'
  })

  const { data: itemData, isLoading: itemIsLoading, isFetching: itemIsFetching } = usePartialDataQueryObjects({
    from: 'Item',
    where: [
      ...(opts.itemGroup1
        ? [
            {
              field: 'category_1',
              operator: 'in',
              value: opts.itemGroup1
            }
          ]
        : []),
      ...(opts.itemGroup2
        ? [
            {
              field: 'category_2',
              operator: 'in',
              value: opts.itemGroup2
            }
          ]
        : []),
      ...(opts.todoEnabled
        ? [
            {
              field: 'todo_tags',
              operator: '!=',
              value: []
            }
          ]
        : [])
    ],
    enabled: opts.enabled && fromItem
  }, {
    name: 'name',
    id: 'id',
    remote_pk: 'remote_pk'
  })

  const sortedData = useMemo(() => {
    if (!opts.enabled) return null
    if (fromItemOffering && !itemOfferingData) return null
    if (fromOrder && !orderData) return null
    if (fromItem && !itemData) return null
    return orderBy(uniqBy([
      ...(fromItemOffering ? itemOfferingData : []),
      ...(fromOrder ? orderData : []),
      ...(fromItem ? itemData : [])
    ], 'id'), ['name'], ['asc'])
  }, [itemOfferingData, orderData, itemData])

  useEffect(() => {
    setCachedItems(sortedData)
  }, [sortedData])

  const loading = useMemo(() => {
    return itemOfferingIsLoading || orderIsLoading || itemIsLoading
  }, [itemOfferingIsLoading, orderIsLoading, itemIsLoading])

  const isFetching = useMemo(() => {
    return itemOfferingIsFetching || orderIsFetching || itemIsFetching
  }, [itemOfferingIsFetching, orderIsFetching, itemIsFetching])

  return {
    data: sortedData,
    loading,
    isFetching
  }
}
