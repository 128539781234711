import DisconnectImage from 'static/undraw_server_down.svg'
import { FormattedMessage } from 'react-intl'
import Heading from 'components/Heading'
import React from 'react'
import { SHORT_TIME_FORMAT } from 'constants/index'
import { format } from 'date-fns'
import MobileFriendlyModal from 'components/MobileFriendlyModal'
import { useNetwork } from 'ahooks'

const OfflineNotification = () => {
  const networkState = useNetwork()
  return (
    <MobileFriendlyModal
      visible={!networkState.online}
      noFooter
      noHeader
      closable={false}
      width='auto'
    >
      <Heading type='h1' className='font-bold text-center text-feedback-error'>
        <FormattedMessage id='OfflineNotification.title' defaultMessage='Device offline!' />
      </Heading>
      <div className='flex flex-col items-center space-x-4'>
        <div>
          <img src={DisconnectImage} alt='' className='w-auto h-64 max-w-full my-12 tablet:h-48 mobile:h-48 mobile:my-8 tablet:my-8' />
        </div>
        <div className='flex-shrink-0 text-lg mobile:text-base'>
          <FormattedMessage tagName='p' id='OfflineNotification.description1' defaultMessage='It seems that your device is not connected to the Internet.' />
          {networkState.since && <FormattedMessage tagName='p' id='OfflineNotification.since' defaultMessage='We have not been able to connect to the servers since {time}.' values={{ time: format(networkState.since, SHORT_TIME_FORMAT) }} />}
          <div className='mt-4'>
            <p className='mb-2 font-bold'>
              <FormattedMessage id='OffineNotifcation.stepsTitle' defaultMessage='Please make sure that:' />
            </p>

            <ul>
              <li>
                <FormattedMessage tagName='p' id='OfflineNotification.step1' defaultMessage='Your device is connected to your mobile network or wifi.' />
              </li>
              <li>
                <FormattedMessage tagName='p' id='OfflineNotification.step2' defaultMessage='Your device is not in airplane mode.' />
              </li>
            </ul>
          </div>
        </div>

      </div>
    </MobileFriendlyModal>
  )
}

export default OfflineNotification
