import { Button } from 'components/Button'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { toast } from 'react-toastify'

const ToastContent = ({ children, onUndo }) => {
  const innerContent = Array.isArray(children)
    ? <ul className='mb-0'>{children.map(s => <li key={s}>{s}</li>)}</ul>
    : children

  return (
    <div>
      {innerContent}
      {onUndo && <Button className='underline inline-block ml-3' pure type='primary' onClick={onUndo}><FormattedMessage id='global.undoq' defaultMessage='Undo?' /></Button>}
    </div>
  )
}

const toaster = (type, content, duration = 5, onUndo, props = {}) =>
  toast[type](<ToastContent onUndo={onUndo}>{content}</ToastContent>, { autoClose: duration ? duration * 1000 : false, ...props })

export default {
  info: (content, duration, props) => toaster('info', content, duration, undefined, props),
  success: (content, duration, onUndo, props) => toaster('success', content, duration, onUndo, props),
  warning: (content, duration, props) => toaster('warning', content, duration, undefined, props),
  error: (content, duration, props) => toaster('error', content, duration, undefined, { toastId: 'error', ...props }),
  update: (toastId, content, duration, onUndo, props) => toast.update(toastId, {
    render: () => <ToastContent onUndo={onUndo}>{content}</ToastContent>,
    autoClose: duration * 1000,
    ...props
  })
}
