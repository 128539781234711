import { useMount } from 'ahooks'
import classNames from 'classnames'
import { Badge, Icon, Text } from 'components/Primitives'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

export const ProIcon = ({ noTooltip, className, iconProps, iconClassName, delayedTooltip, ...props }) => {
  const [renderTooltip, setRenderTooltip] = useState(!delayedTooltip)

  useMount(() => {
    if (delayedTooltip) {
      setRenderTooltip(true)
    }
  })

  const message = <FormattedMessage id='proIcon.tempFreeAccess' defaultMessage='This is a pro feature that can be tested for free while it is in beta' />
  if (noTooltip) {
    return (
      <div className='flex flex-col items-start'>
        <Badge type='accentGras' className={classNames('leading-none py-0 px-1', className)} bold size='s' {...props}>
          Pro <Icon name='StarFilled' color='text-accent-gras-75' size='xxs' className={classNames('', iconClassName)} style={{ marginBottom: '-1px' }} {...iconProps} />
        </Badge>
        <Text color='gray'>{message}.</Text>
      </div>
    )
  }
  return (
    <Badge tooltip={renderTooltip ? message : undefined} wrapped type='accentGras' className={classNames('ml-1 leading-none py-0 px-1', className)} bold size='s' {...props}>
      Pro <Icon name='StarFilled' color='text-accent-gras-75' size='xxs' className={classNames('', iconClassName)} style={{ marginBottom: '-1px' }} {...iconProps} />
    </Badge>
  )
}
