import { isSameDay, parseISO } from 'date-fns'

import React from 'react'
import { DateValue } from 'components/DateValue'
import { useIntl } from 'react-intl'
import valueMessages from 'components/valueMessages'

export const DateRangeValue = ({
  from,
  to
}) => {
  const intl = useIntl()
  if (!from && !to) return null

  const dFrom = typeof (from) === 'string' ? parseISO(from) : from
  const dTo = typeof (to) === 'string' ? parseISO(to) : to

  if (from && to && isSameDay(dFrom, dTo)) {
    return <DateValue date={dFrom} />
  }

  if (from && !to) {
    return intl.formatMessage(valueMessages.fromDate, { date: <DateValue date={dFrom} asString /> })
  }
  if (!from && to) {
    return intl.formatMessage(valueMessages.untilDate, { date: <DateValue date={dTo} asString /> })
  }
  if (from && to) {
    return (
      <>
        <DateValue date={dFrom} asString /> - <DateValue date={dTo} asString />
      </>
    )
  }
}
