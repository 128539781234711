import { getByPath } from 'utils'
import { useSettingsStore } from './store/useSettingsStore'
import { useMemo } from 'react'

const useDashboardRefreshInterval = () => {
  const refreshInterval = useSettingsStore(state => getByPath(state.settings, 'dashboardSettings.refreshInterval', null))
  return useMemo(() => refreshInterval != null && refreshInterval !== 'off' ? parseInt(refreshInterval) * 1000 * 60 : null, [refreshInterval])
}

export default useDashboardRefreshInterval
