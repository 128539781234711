import { useCounter, useDebounceEffect } from 'ahooks'

import Heading from 'components/Heading'
import React from 'react'
import cn from 'classnames'
import s from './Title.module.scss'
import { useDebugStore } from 'hooks/store/useDebugStore'

const Title = ({ className, children, small, onClick, onDoubleClick, ...props }) => {
  const [current, { inc, reset }] = useCounter(0)
  const toggle = useDebugStore((state) => state.toggle)

  const onHeadingClick = () => {
    if (onClick) onClick()
    if (onDoubleClick && current === 1) onDoubleClick()
    if (current === 4) {
      toggle()
    } else {
      inc()
    }
  }

  useDebounceEffect(() => {
    if (current !== 0) {
      reset()
    }
  }, [current], { wait: 700 })

  return (
    <Heading id='app-title' onClick={onHeadingClick} type={small ? 'h4' : 'h3'} color='brand' className={cn(s.title, className)} {...props}>
      {children}
    </Heading>
  )
}

export default Title
