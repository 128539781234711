import { saveAs } from 'file-saver'
import { utils, write } from 'xlsx'
import { useIntl } from 'react-intl'
import { ARTICLE_COMPARISON_CHART, AVG_TRANSACTION_REVENUE_CHART, CHART_TITLES, COMPOSITION_CHART, DETAIL_VIEW_CHART, DIFFERENCE_FROM_CHART, FOODWASTE_AMOUNT_PER_SALE, FOODWASTE_CHART, FOODWASTE_CO2_CHART, FOODWASTE_COMPOSITION_CHART, FOODWASTE_DETAIL_VIEW_CHART, FOODWASTE_LOCATIONS_COMPARISON_CHART, FOODWASTE_SALES_COSTS_CHART, FOODWASTE_WATER_CHART, FORECAST_ACCURACY_CHART, FORECAST_CHART, LOCATIONS_COMPARISON_CHART, NUM_SOLD_CHART, ORDERS_OVERVIEW_CHART, REL_COGS_CHART, RETURN_RATE_CHART, REVENUE_CHART, TODO_DONE_TASKS_CHART, TODO_ITEM_COMPARISON_CHART, TODO_LOCATION_COMPARISON_CHART, TOTAL_COGS_CHART } from 'components/Charts/constants'
import { forEach, isNumber, map, sum, orderBy, pick } from 'lodash'
import labelMessages from 'components/labelMessages'
import messages from 'components/Charts/messages'
import { CATEGORY1, CATEGORY2, COMPONENT, FOODWASTE_CATEGORY, ITEM, LOCATION, MENULINE, TODO_ITEM, TODO_LOCATION } from './useChartTabs'
import titleMessages from 'components/titleMessages'
import { AMOUNT, ENTERED_MEASUREMENTS, G_PER_NUM_SOLD, OPEN_MEASUREMENTS } from 'components/Charts/FoodwasteLocationComparision/Bars'
import { TRANSACTION_COUNT } from 'components/Charts/SalesLocationComparision/Bars'
import { CORRECTED, DELETED, DONE, LATE, MANUALLY_ADDED } from 'components/Charts/TodoLocationComparison/Bars'
import globalMessages from 'components/globalMessages'
import { format, isValid, parseISO } from 'date-fns'

const formatColumn = (worksheet, col, fmt) => {
  const range = utils.decode_range(worksheet['!ref'])
  // note: range.s.r + 1 skips the header row
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = utils.encode_cell({ r: row, c: col })
    if (worksheet[ref] && worksheet[ref].t === 'n') {
      worksheet[ref].z = fmt
    }
  }
}

const formatAdditionalColumns = (ws, additionalExportColumns) => {
  if (!additionalExportColumns) return
  additionalExportColumns.forEach((col, i) => {
    formatColumn(ws, i + 1, col.format)
  })
}

const getAdditionalColumns = (def, rowData) => {
  const obj = {}
  if (!def) return obj
  forEach(def, (c) => {
    obj[c.label] = rowData[c.id]
  })
  return obj
}

const dateIfValid = (date) => isValid(date) ? new Date(date) : date

const getColumnTitle = (intl, key) => {
  switch (key) {
    case REVENUE_CHART:
      return intl.formatMessage(labelMessages.revenue)
    case REL_COGS_CHART:
      return intl.formatMessage(labelMessages.relCogs)
    case TOTAL_COGS_CHART:
      return intl.formatMessage(labelMessages.cogs)
    case NUM_SOLD_CHART:
      return intl.formatMessage(labelMessages.soldNo)
    case AMOUNT:
      return intl.formatMessage(labelMessages.amount)
    case TRANSACTION_COUNT:
      return intl.formatMessage(labelMessages.transactionCount)
    case AVG_TRANSACTION_REVENUE_CHART:
      return intl.formatMessage(messages.avgTransactionRevenue)
    case LOCATION:
    case TODO_LOCATION:
      return intl.formatMessage(labelMessages.location)
    case MENULINE:
      return intl.formatMessage(labelMessages.offeringGroup1)
    case COMPONENT:
      return intl.formatMessage(labelMessages.offeringGroup2)
    case CATEGORY1:
      return intl.formatMessage(labelMessages.itemGroup1)
    case CATEGORY2:
      return intl.formatMessage(labelMessages.itemGroup2)
    case FOODWASTE_CATEGORY:
      return intl.formatMessage(labelMessages.wasteCategory)
    case TODO_ITEM:
    case ITEM:
      return intl.formatMessage(labelMessages.item)
    case G_PER_NUM_SOLD:
      return intl.formatMessage(labelMessages.amountPerSale)
    case 'planned_sold_diff':
      return intl.formatMessage(titleMessages.plannedSoldDiff)
    case 'forecast_sold_diff':
      return intl.formatMessage(titleMessages.forecastSoldDiff)
    case OPEN_MEASUREMENTS:
      return intl.formatMessage(titleMessages.openMeasurements)
    case ENTERED_MEASUREMENTS:
      return intl.formatMessage(labelMessages.measurements)
    case DONE:
      return intl.formatMessage(messages.done)
    case LATE:
      return intl.formatMessage(messages.late)
    case DELETED:
      return intl.formatMessage(globalMessages.deleted)
    case CORRECTED:
      return intl.formatMessage(messages.corrected)
    case MANUALLY_ADDED:
      return intl.formatMessage(messages.manuallyAdded)
    default:
      return key
  }
}

export const useChartExport = (identifier, data, meta = {}) => {
  const intl = useIntl()
  const sheetTitle = intl.formatMessage(CHART_TITLES[identifier])
  const fileTitle = [
    sheetTitle,
    ...(meta.tab ? [getColumnTitle(intl, meta.tab)] : []),
    ...(meta.option ? [getColumnTitle(intl, meta.option)] : []),
    ...(meta.mode && meta.mode === 'relative' ? ['in %'] : [])
  ].join(' ')

  const getValidDate = (date) => {
    const dateString = isNumber(date) ? new Date(date).toISOString() : date
    if (meta.groupBy === 'hour') {
      return parseISO(dateString)
    } else if (meta.groupBy === 'week') {
      if (dateString.includes('W')) {
        const p = dateString.split('W')
        return `${p[0]}${intl.formatMessage(globalMessages.weekNumber)}${p[1]}`
      } else {
        const d = parseISO(dateString)
        return `${format(d, 'yyyy')}-${intl.formatMessage(globalMessages.weekNumber)}${format(d, 'I')}`
      }
    } else if (meta.groupBy === 'date') {
      return parseISO(dateString)
    }
    return dateIfValid(dateString)
  }

  const exportXlsx = () => {
    if (!data) return
    console.info('Export Chart', identifier, meta, data)
    const wb = utils.book_new()

    let rows = []
    switch (identifier) {
      default:
        rows = data.chartData.map(i => {
          return ({
            [intl.formatMessage(labelMessages.date)]: getValidDate(i.date),
            ...(getAdditionalColumns(meta.additionalExportColumns, i.additionalExportColumns)),
            [fileTitle]: i.value
          })
        })
        break
      case LOCATIONS_COMPARISON_CHART:
      case ARTICLE_COMPARISON_CHART:
        let title
        switch (identifier) {
          case LOCATIONS_COMPARISON_CHART:
            title = intl.formatMessage(labelMessages.location)
            break
          default:
            title = intl.formatMessage(labelMessages.item)
            break
        }
        forEach(data.chartData.totalValues, (value, location) => {
          rows.push({
            [title]: location,
            [getColumnTitle(intl, meta.tab)]: value
          })
        })
        if (meta.tab === REL_COGS_CHART) {
          rows = orderBy(rows, [getColumnTitle(intl, meta.tab)], ['desc'])
        }
        break
      case TODO_LOCATION_COMPARISON_CHART:
      case TODO_ITEM_COMPARISON_CHART:
        let ftitle
        switch (identifier) {
          case TODO_LOCATION_COMPARISON_CHART:
            ftitle = intl.formatMessage(labelMessages.location)
            break
          default:
            ftitle = intl.formatMessage(labelMessages.item)
            break
        }
        rows = data.map(i => ({
          [ftitle]: i.location,
          [meta.mode === 'relative' ? `${getColumnTitle(intl, meta.tab)} in %` : getColumnTitle(intl, meta.tab)]: meta.mode === 'relative' ? i.percentValue / 100 : i.value,
          ...(identifier === TODO_LOCATION_COMPARISON_CHART && i.info ? { [intl.formatMessage(labelMessages.lastOn)]: parseISO(i.info) } : undefined)
        }))
        break
      case FOODWASTE_LOCATIONS_COMPARISON_CHART:
        forEach(data.chartData.totalValues, (value, location) => {
          if (value != null) {
            if (meta.tab === G_PER_NUM_SOLD) {
              rows.push({
                [intl.formatMessage(labelMessages.location)]: location,
                [intl.formatMessage(labelMessages.wasteAmount)]: value.amount,
                [intl.formatMessage(labelMessages.soldNo)]: value.sales,
                [getColumnTitle(intl, meta.tab)]: value.value
              })
            } else {
              rows.push({
                [intl.formatMessage(labelMessages.location)]: location,
                [getColumnTitle(intl, meta.tab)]: value
              })
            }
          }
        })
        break
      case COMPOSITION_CHART:
      case FOODWASTE_COMPOSITION_CHART:
        const valSum = sum(map(data.data, i => i))
        forEach(data.data, (value, key) => {
          if (identifier === COMPOSITION_CHART) {
            rows.push({
              [getColumnTitle(intl, meta.tab)]: key,
              [getColumnTitle(intl, meta.option)]: value,
              [`${getColumnTitle(intl, meta.option)} in %`]: valSum > 0 ? (value / valSum) : ''
            })
          } else {
            rows.push({
              [getColumnTitle(intl, meta.tab)]: key,
              [intl.formatMessage(titleMessages.foodwaste)]: value,
              [`${intl.formatMessage(titleMessages.foodwaste)} in %`]: valSum > 0 ? (value / valSum) : ''
            })
          }
        })
        break
      case DETAIL_VIEW_CHART:
        forEach(data.data, (dict, date) => {
          forEach(dict, (value, key) => {
            rows.push({
              [intl.formatMessage(labelMessages.date)]: getValidDate(date),
              [getColumnTitle(intl, meta.tab)]: key,
              ...(meta.mode === 'relative'
                ? { [`${getColumnTitle(intl, meta.option)} in %`]: valSum > 0 ? (value / valSum) : '' }
                : { [getColumnTitle(intl, meta.option)]: value }
              )
            })
          })
        })
        break
      case TODO_DONE_TASKS_CHART:
        forEach(data.data, (dict, date) => {
          forEach(dict, (value, key) => {
            rows.push({
              [intl.formatMessage(labelMessages.date)]: getValidDate(date),
              [getColumnTitle(intl, meta.tab)]: key,
              [sheetTitle]: value
            })
          })
        })
        break
      case FOODWASTE_DETAIL_VIEW_CHART:
        const keys = Object.keys(data.data)
        if (meta.mode === G_PER_NUM_SOLD) keys.sort()
        forEach(keys, (date) => {
          const dict = data.data[date]
          forEach(dict, (value, key) => {
            rows.push({
              [intl.formatMessage(labelMessages.date)]: getValidDate(date),
              [intl.formatMessage(labelMessages.wasteCategory)]: key,
              ...(getAdditionalColumns(meta.additionalExportColumns, value.additionalExportColumns)),
              [getColumnTitle(intl, meta.option)]: meta.additionalExportColumns ? value.value : value
            })
          })
        })
        break
      case DIFFERENCE_FROM_CHART:
        forEach(data.data, (dict, date) => {
          forEach(dict, (value, key) => {
            if (value != null) {
              rows.push({
                [intl.formatMessage(labelMessages.date)]: getValidDate(date),
                [getColumnTitle(intl, meta.tab)]: key,
                [getColumnTitle(intl, meta.option)]: value
              })
            }
          })
        })
        break
      case ORDERS_OVERVIEW_CHART:
        rows = data.map(d => ({
          [intl.formatMessage(labelMessages.date)]: getValidDate(d.date),
          [intl.formatMessage(labelMessages.totalSoldNo)]: d['num-sold'],
          [intl.formatMessage(labelMessages.requestedAmount)]: d['num-requested'],
          [intl.formatMessage(labelMessages.orderedAmount)]: d['num-ordered'],
          [intl.formatMessage(labelMessages.fullfilledAmount)]: d['num-fulfilled'],
          [intl.formatMessage(labelMessages.unsoldAmount)]: d['num-unsold'],
          [intl.formatMessage(labelMessages.returnRate)]: d['return-rate']
        }))
        break
      case FORECAST_CHART:
        rows = data.map(d => ({
          [intl.formatMessage(labelMessages.date)]: getValidDate(d.date),
          [intl.formatMessage(labelMessages.totalSoldNo)]: d.num_sold,
          [intl.formatMessage(labelMessages.totalPlannedNo)]: d.num_planned,
          [intl.formatMessage(labelMessages.totalForecastNo)]: d.num_forecast,
          [`${intl.formatMessage(labelMessages.forecastVariance)} (min)`]: d.variance[0],
          [`${intl.formatMessage(labelMessages.forecastVariance)} (max)`]: d.variance[1]
        }))
        break
      case FORECAST_ACCURACY_CHART:
        rows = data.map(d => ({
          [intl.formatMessage(labelMessages.date)]: getValidDate(d.date),
          ...(meta.mode === 'relative'
            ? { [`${intl.formatMessage(titleMessages.plannedSoldDiff)} in %`]: d.plannedSoldRel / 100 }
            : { [intl.formatMessage(titleMessages.plannedSoldDiff)]: d.plannedSoldAbs }
          ),
          ...(meta.mode === 'relative'
            ? { [`${intl.formatMessage(titleMessages.forecastSoldDiff)} in %`]: d.forecastSoldRel / 100 }
            : { [intl.formatMessage(titleMessages.forecastSoldDiff)]: d.forecastSoldAbs }
          )
        }))
        break
    }

    const ws = utils.json_to_sheet(rows, {})
    const additionalColumnsDef = meta.additionalExportColumns ? meta.additionalExportColumns.map(c => pick(c, ['wch'])) : []

    // Column Formatting
    switch (identifier) {
      case REVENUE_CHART:
      case TOTAL_COGS_CHART:
      case AVG_TRANSACTION_REVENUE_CHART:
      case FOODWASTE_SALES_COSTS_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 16 }
        ]
        if (meta.groupBy === 'hour') {
          formatColumn(ws, 0, 'dd/mm/yyyy hh:mm')
        }
        formatColumn(ws, 1, '#,##0.00 €')
        break
      case REL_COGS_CHART:
      case RETURN_RATE_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 16 }
        ]
        formatColumn(ws, 1, '0.00" "%')
        break
      case LOCATIONS_COMPARISON_CHART:
      case ARTICLE_COMPARISON_CHART:
        ws['!cols'] = [
          { wch: 40 },
          { wch: 16 }
        ]
        switch (meta.tab) {
          case REVENUE_CHART:
          case TOTAL_COGS_CHART:
          case AVG_TRANSACTION_REVENUE_CHART:
            formatColumn(ws, 1, '#,##0.00 €')
            break
          case REL_COGS_CHART:
            formatColumn(ws, 1, '0.00" "%')
            break
          default:
            formatColumn(ws, 1, '#,##0')
            break
        }
        break
      case TODO_LOCATION_COMPARISON_CHART:
      case TODO_ITEM_COMPARISON_CHART:
        ws['!cols'] = [
          { wch: 40 },
          { wch: 16 },
          { wch: 24 }
        ]
        if (meta.mode === 'relative') {
          formatColumn(ws, 1, '0.00" "%')
        } else {
          formatColumn(ws, 1, '#,##0')
        }
        if (identifier === TODO_LOCATION_COMPARISON_CHART) {
          formatColumn(ws, 2, 'yyyy-mm-dd hh:mm:ss')
        }
        break
      case FOODWASTE_LOCATIONS_COMPARISON_CHART:
        if (meta.tab === G_PER_NUM_SOLD) {
          ws['!cols'] = [
            { wch: 40 },
            { wch: 14 },
            { wch: 10 },
            { wch: 16 }
          ]
        } else {
          ws['!cols'] = [
            { wch: 40 },
            { wch: 20 }
          ]
        }
        switch (meta.tab) {
          case AMOUNT:
            formatColumn(ws, 1, '#,##0" kg"')
            break
          case G_PER_NUM_SOLD:
            formatColumn(ws, 1, '#,##0.00" kg"')
            formatColumn(ws, 2, '#,##0')
            formatColumn(ws, 3, '#,##0.00" g"')
            break
          default:
            formatColumn(ws, 1, '#,##0')
            break
        }
        break
      case COMPOSITION_CHART:
      case FOODWASTE_COMPOSITION_CHART:
        ws['!cols'] = [
          { wch: 40 },
          { wch: 16 },
          { wch: 16 }
        ]
        if (identifier === COMPOSITION_CHART) {
          switch (meta.option) {
            case REVENUE_CHART:
            case TOTAL_COGS_CHART:
            case AVG_TRANSACTION_REVENUE_CHART:
              formatColumn(ws, 1, '#,##0.00 €')
              break
            case REL_COGS_CHART:
              formatColumn(ws, 1, '0.00" "%')
              break
            default:
              formatColumn(ws, 1, '#,##0')
              break
          }
        } else {
          formatColumn(ws, 1, '#,##0" kg"')
        }
        formatColumn(ws, 2, '0.00" "%')
        break
      case DETAIL_VIEW_CHART:
      case FOODWASTE_DETAIL_VIEW_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: identifier === DETAIL_VIEW_CHART ? 40 : 30 },
          { wch: identifier === DETAIL_VIEW_CHART ? 16 : 22 },
          { wch: identifier === DETAIL_VIEW_CHART ? 16 : 22 }
        ]
        if (meta.groupBy === 'hour') {
          formatColumn(ws, 0, 'dd/mm/yyyy hh:mm')
        }
        if (meta.mode === 'relative') {
          formatColumn(ws, 2, '0.00" "%')
        } else {
          switch (meta.option) {
            case REVENUE_CHART:
            case TOTAL_COGS_CHART:
            case AVG_TRANSACTION_REVENUE_CHART:
              formatColumn(ws, 2, '#,##0.00 €')
              break
            case REL_COGS_CHART:
              formatColumn(ws, 2, '0.00" "%')
              break
            case G_PER_NUM_SOLD:
              formatColumn(ws, 2, '#,##0" g"')
              break
            default:
              formatColumn(ws, 2, '#,##0')
              break
          }
        }
        break
      case TODO_DONE_TASKS_CHART:
        if (meta.groupBy === 'hour') {
          formatColumn(ws, 0, 'dd/mm/yyyy hh:mm')
        }
        ws['!cols'] = [
          { wch: 20 },
          { wch: 40 },
          { wch: 18 }
        ]
        formatColumn(ws, 2, '#,##0')
        break
      case DIFFERENCE_FROM_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 40 },
          { wch: 26 }
        ]
        switch (meta.option) {
          case 'revenue':
          case 'total_cogs':
          case 'avg_transaction_revenue':
            formatColumn(ws, 2, '#,##0.00 €')
            break
          case 'rel_cogs':
            formatColumn(ws, 2, '0.00" "%')
            break
          default:
            formatColumn(ws, 2, '#,##0')
            break
        }
        break
      case ORDERS_OVERVIEW_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 24 },
          { wch: 24 },
          { wch: 24 },
          { wch: 24 },
          { wch: 24 },
          { wch: 24 }
        ]
        formatColumn(ws, 1, '#,##0')
        formatColumn(ws, 2, '#,##0')
        formatColumn(ws, 3, '#,##0')
        formatColumn(ws, 4, '#,##0')
        formatColumn(ws, 5, '#,##0')
        formatColumn(ws, 6, '0.00" "%')
        break
      case FORECAST_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 24 },
          { wch: 24 },
          { wch: 24 },
          { wch: 24 },
          { wch: 24 }
        ]
        formatColumn(ws, 1, '#,##0')
        formatColumn(ws, 2, '#,##0')
        formatColumn(ws, 3, '#,##0')
        formatColumn(ws, 4, '#,##0')
        formatColumn(ws, 5, '#,##0')
        break
      case FORECAST_ACCURACY_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 32 },
          { wch: 32 }
        ]
        if (meta.mode === 'relative') {
          formatColumn(ws, 1, '0.00" "%')
          formatColumn(ws, 2, '0.00" "%')
        } else {
          formatColumn(ws, 1, '#,##0')
          formatColumn(ws, 2, '#,##0')
        }
        break
      case FOODWASTE_CHART:
      case FOODWASTE_CO2_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 12 }
        ]
        formatColumn(ws, 1, '#,##0" kg"')
        break
      case FOODWASTE_WATER_CHART:
        ws['!cols'] = [
          { wch: 20 },
          { wch: 12 }
        ]
        formatColumn(ws, 1, '#,##0" l"')
        break
      case FOODWASTE_AMOUNT_PER_SALE:
        ws['!cols'] = [
          { wch: 20 },
          ...additionalColumnsDef,
          { wch: 16 }
        ]
        formatColumn(ws, additionalColumnsDef.length + 1, '#,##0.00" g"')
        break
      default:
        ws['!cols'] = [
          { wch: 20 },
          ...additionalColumnsDef,
          { wch: 16 }
        ]
        formatColumn(ws, additionalColumnsDef.length + 1, '#,##0')
        break
    }
    formatAdditionalColumns(ws, meta.additionalExportColumns)
    utils.book_append_sheet(wb, ws, sheetTitle)
    const bdata = write(wb, {
      type: 'array'
    })
    const blob = new Blob([new Uint8Array(bdata)], { type: 'application/octet-stream' })
    saveAs(blob, `${fileTitle}.xlsx`)
  }

  const exportData = (format) => {
    switch (format) {
      case 'xlsx':
        return exportXlsx()
      default:
        throw new Error(`Invalid export format ${format}`)
    }
  }

  return {
    exportData
  }
}
