import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import queryString from 'query-string'
import message from 'services/message'
import { IMPORT_ERRORS } from 'constants/index'
import notifyMessages from 'components/notifyMessages'
import { useIntl } from 'react-intl'
import { useModalStore } from 'hooks/store/useModalStore'
import { useFileImportStore } from 'hooks/store/useFileImportStore'

export const useImportData = (options) => {
  const opts = {
    enabled: !!options?.fileId,
    ...options
  }
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const hide = useModalStore((state) => state.hide)
  const storeFile = useFileImportStore((state) => state.file)
  const storeConfig = useFileImportStore((state) => state.config)
  const resetStore = useFileImportStore((state) => state.reset)

  const fileKey = ['getFile', opts.fileId, customer]
  const getFile = useQuery({
    queryKey: fileKey,
    queryFn: () => new Promise((resolve, reject) => {
      if (!opts.fileId) return resolve({ file: null, config: {} })
      fetch(
        `/sales-data-files/${opts.fileId}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'GET',
          success: (file) => resolve({
            file,
            config: {
              ...((file?.hints && file.hints.default) || {}),
              ...(file.format_params || {})
            }
          }),
          failure: (err) => reject(err)
        }
      )
    }),
    initialData: {
      file: null,
      config: {}
    }
  })

  const uploadFile = useMutation({
    mutationFn: ({ file, ...values }) => new Promise((resolve, reject) => {
      fetch(
        `/sales-data-files/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'POST',
          contentType: 'multipart/form-data',
          body: {
            file,
            config: JSON.stringify(values)
          },
          token,
          success: (uploadedFile) => resolve(uploadedFile),
          failure: (err) => {
            message.error(
              err && err.code && IMPORT_ERRORS[err.code]
                ? IMPORT_ERRORS[err.code] ? intl.formatMessage(IMPORT_ERRORS[err.code]) : intl.formatMessage(notifyMessages.importingError)
                : intl.formatMessage(notifyMessages.importingError)
            )
            reject(err)
          }
        }
      )
    }),
    onSuccess: (uploadedFile) => {
      queryClient.setQueryData(['getFile', uploadFile.id, customer], {
        file: uploadedFile,
        config: {
          ...((uploadedFile?.hints && uploadedFile.hints.default) || {}),
          ...(uploadedFile.format_params || {})
        }
      })
    }
  })

  const clear = () => {
    resetStore()
    queryClient.setQueryData(fileKey, {
      file: null,
      config: {}
    })
  }

  const importFile = useMutation({
    mutationFn: () => new Promise((resolve, reject) => {
      if (!storeFile) {
        return reject(new Error('No file'))
      }
      fetch(
        `/sales-data-files/${storeFile.id}/import/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'POST',
          body: storeConfig,
          token,
          success: () => {
            message.success(intl.formatMessage(notifyMessages.importingSuccess))
            resolve()
          },
          failure: (err) => {
            message.error(
              (err && err.message) || intl.formatMessage(notifyMessages.importingError)
            )
            reject(err)
          }
        }
      )
    }),
    onSuccess: () => {
      clear()
      hide('uploadFileModal')
    }
  })

  const restoreFile = useMutation({
    mutationFn: (fileId) => new Promise((resolve, reject) => {
      fetch(
        `/sales-data-files/${fileId}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'PATCH',
          body: {
            hidden: false
          },
          token,
          success: () => resolve(),
          failure: (err) => {
            message.error(intl.formatMessage(notifyMessages.restoringError))
            reject(err)
          }
        }
      )
    })
  })

  const removeFile = useMutation({
    mutationFn: (fileId) => new Promise((resolve, reject) => {
      fetch(
        `/sales-data-files/${fileId}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'DELETE',
          token,
          success: () => resolve(),
          failure: (err) => {
            message.error(intl.formatMessage(notifyMessages.removingError))
            reject(err)
          }
        }
      )
    }),
    onSuccess: () => {
      clear()
      hide('removeFileModal')
    }
  })

  const downloadFile = (file) => {
    fetch(
      `/sales-data-files/${file.id}/download/?${queryString.stringify({
        customer
      })}`,
      {
        method: 'GET',
        fileName: file.original_name,
        success: () => { },
        failure: () => { }
      }
    )
  }

  return {
    getFile: {
      data: getFile.data,
      status: getFile.status,
      error: getFile.error
    },
    uploadFile: {
      mutate: uploadFile.mutate,
      isPending: uploadFile.isPending
    },
    importFile: {
      mutate: importFile.mutate,
      isPending: importFile.isPending,
      error: importFile.error
    },
    restoreFile: {
      mutate: restoreFile.mutate,
      isPending: restoreFile.isPending
    },
    removeFile: {
      mutateAsync: removeFile.mutateAsync,
      isPending: removeFile.isPending
    },
    downloadFile
  }
}
