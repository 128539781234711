import queryString from 'query-string'
import { useQuery } from '@tanstack/react-query'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'

export const useAllUsers = () => {
  const { fetch, token } = useQueryFetcher()
  const selectedCustomer = useSelectedCustomer()
  const { data, status, error, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['allUsers', { selectedCustomer }],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/users/?${queryString.stringify({
          page: 1,
          page_size: 9999,
          customer: selectedCustomer
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res.results),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: selectedCustomer != null,
    retry: 2,
    staleTime: 120000
  })
  return {
    data,
    status,
    error,
    isFetching,
    isLoading,
    refetch
  }
}
