import { useGridSelectionStore } from 'components/Grid'
import { BulkActionMenu } from './BulkActionMenu'
import AddButton from './AddButton'
import Media from 'components/Media'
import ExportButton from 'components/ExportButton'
import ShowOpenButton from './ShowOpenButton'

export const ActionButtons = ({ columns }) => {
  const selection = useGridSelectionStore(state => state.selection.foodwaste) || []

  if (selection.length > 1) {
    return <BulkActionMenu />
  }

  return (
    <>
      <Media greaterThanOrEqual='tablet'>
        <ExportButton exportType='foodwaste' />
      </Media>
      <AddButton columns={columns} />
      <ShowOpenButton />
    </>
  )
}
