import { useState } from 'react'
import { DatePicker } from 'antd'
import m from 'moment'
import { DATE_FORMAT } from 'constants/index'
import { Button } from 'components/Button'
import { FormattedMessage } from 'react-intl'
import globalMessages from 'components/globalMessages'

const PeriodPicker = ({ value, onChange, allowReset, ...props }) => {
  // FIXME: moment needed for AntDesign
  const date = value ? value.map((item) => m(item, DATE_FORMAT)) : null
  const [open, setOpen] = useState(false)
  const onDateChange = (date) => {
    onChange(date.map((item) => item.format(DATE_FORMAT)))
  }

  const onReset = () => {
    onChange([])
    setOpen(false)
  }

  return (
    <div {...{ 'data-name': props['data-name'], 'data-type': props['data-type'] }}>
      <DatePicker.RangePicker
        value={date} onChange={onDateChange} {...props}
        open={open}
        onOpenChange={setOpen}
        renderExtraFooter={allowReset && (() => (
          <div className='my-2'>
            <Button onClick={onReset}>
              <FormattedMessage {...globalMessages.reset} tagName='p' />
            </Button>
          </div>
        ))}
      />
    </div>
  )
}

export default PeriodPicker
