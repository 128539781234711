import { differenceWith, fromPairs, isEqual, toPairs } from 'lodash'

const objectDiff = (a, b) =>
  fromPairs(differenceWith(toPairs(b), toPairs(a), isEqual))

export const compareTask = (original, updated) => {
  const cUpdated = { ...updated }

  // keys, which are not defined in the form data will be initialized with null.
  Object.keys(original).forEach((k) => {
    if (cUpdated[k] === undefined) cUpdated[k] = null
  })

  return objectDiff(original, cUpdated)
}

export const compareTaskCollection = (original, updated) => {
  const diff = []
  original.forEach((o) => {
    const index = updated.findIndex((u) => u.id === o.id)
    if (index === -1) {
      diff.push({
        type: 'removed',
        value: o
      })
    } else {
      const u = updated[index]
      const d = compareTask(o, u)
      if (Object.keys(d).length > 0) {
        diff.push({
          type: 'updated',
          value: d
        })
      }
    }
  })
  updated.forEach((u) => {
    const index = original.findIndex((o) => o.id === u.id)
    if (index === -1) {
      diff.push({
        type: 'added',
        value: u
      })
    }
  })
  return diff
}
