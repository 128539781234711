import { DEFAULT_WHERE } from 'constants/index'
import { useDataQuery } from 'hooks'
import { findAndReplaceField } from 'utils'
import { useEffect, useMemo, useState } from 'react'
import { uniq } from 'lodash'

export const useItemNames = (range, location, scope, enabled = true) => {
  let whereParams, isEnabled
  switch (scope) {
    case 'todo':
      whereParams = [
        {
          field: 'item__todo_tags',
          operator: '!=',
          value: []
        }
      ]
      isEnabled = enabled
      break
    default:
      whereParams = [
        { field: 'date', operator: 'range', value: range },
        ...(location
          ? [
              {
                field: 'sales_location',
                operator: 'in',
                value: location
              }
            ]
          : [])

      ]
      isEnabled = enabled && range != null && range.length === 2
      break
  }

  const { data, status, isFetching, error } = useDataQuery({
    from: 'ItemOffering',
    select: ['set(item__name)'],
    where: [
      ...DEFAULT_WHERE,
      ...whereParams
    ]
  }, isEnabled)

  const { data: orderItemNames, status: statusOrder, isFetching: isFetchingOrder, error: errorOrder } = useDataQuery({
    from: 'Order',
    select: ['set(item__name)'],
    where: findAndReplaceField(whereParams, 'sales_location', 'location')
  }, isEnabled && scope !== 'todo')

  const filteredAndSorted = useMemo(() => {
    if (!data) return null
    if (scope === 'todo') {
      return data.filter(i => i).sort()
    } else {
      if (!orderItemNames) return null
      return uniq([...data, ...orderItemNames].filter(i => i)).sort()
    }
  }, [data, orderItemNames])

  const [errors, setErros] = useState([])
  useEffect(() => {
    if (error && !errorOrder) return setErros([error])
    if (!error && errorOrder) return setErros([errorOrder])
    if (error && errorOrder) return setErros([error, errorOrder])
    return setErros([])
  }, [error, errorOrder])

  const [statuses, setStatuses] = useState('loading')
  useEffect(() => {
    if (status === 'error' || statusOrder === 'error') return setStatuses('error')
    if (isFetching || isFetchingOrder) return setStatuses('loading')
    return setStatuses('success')
  }, [status, statusOrder])

  return {
    data: filteredAndSorted,
    status: statuses,
    isFetching: isFetching || isFetchingOrder,
    error: errors
  }
}
