import { Button } from 'components/Button'
import React from 'react'

export default React.forwardRef(({
  outline,
  ...props
}, forwardRef) => (
  <Button
    pure={!outline}
    {...props}
    ref={forwardRef}
  />
))
