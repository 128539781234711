import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { usePermissions, useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from 'hooks/useSelectedCustomer'
import { differenceBy } from 'lodash'
import queryString from 'query-string'
import { usePageSettings } from 'hooks/usePageSettings'
import { handleMutationError } from 'utils'

export const useMecoSafetySettings = (options) => {
  const opts = {
    enabled: true,
    ...options
  }

  const queryClient = useQueryClient()
  const { token, fetch } = useQueryFetcher()
  const permissions = usePermissions()

  const { settings } = usePageSettings()
  const customer = useSelectedCustomer()

  const { data, status } = useQuery({
    queryKey: ['mecos-safety-settings', settings.location],
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `/mecos/?${queryString.stringify({
          customer,
          sales_locations: settings.location,
          page_size: 9990
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res.results),
          failure: (err) => reject(err)
        }
      )
    }),
    enabled: opts.enabled && permissions.safetySettings
  })

  // TODO make it in one request when backend is refactored
  const { mutateAsync, status: mutationStatus } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      const removedExceptions = differenceBy(
        data.filter((item) => item.safety_setting || item.forecast_limit),
        values.exceptions,
        'id'
      ).map((item) => ({ ...item, safety_setting: null, forecast_limit: false }))
      const exceptions = [...removedExceptions, ...values.exceptions]
      // TODO send requests only for changed values, we always send all exceptions!
      // FIXME: Does this really work as expected? These fetch methods do not reture promises
      Promise.all([
        fetch(
          `/sales-locations/${settings.location}/?${queryString.stringify({
            customer
          })}`,
          {
            method: 'PATCH',
            body: { safety_setting: values.safetySetting },
            success: () => { },
            failure: (errors) => handleMutationError(errors, reject)
          }
        ),
        ...exceptions.map(({ id, safety_setting, forecast_limit }) =>
          fetch(
            `/mecos/${id}/?${queryString.stringify({
              customer
            })}`,
            {
              method: 'PATCH',
              body: { safety_setting, forecast_limit },
              success: () => { },
              failure: (errors) => handleMutationError(errors, reject)
            }
          )
        )
      ]).then(() => {
        const newData = data.map((item) => {
          const updatedMeco = exceptions.find(
            (exception) => exception.id === item.id
          )
          return updatedMeco
            ? {
                ...item,
                safety_setting: updatedMeco.safety_setting,
                forecast_limit: updatedMeco.forecast_limit
              }
            : item
        })
        queryClient.setQueryData(['mecos-safety-settings', settings.location], newData)
        queryClient.invalidateQueries({ queryKey: ['sales-locations'] })
        queryClient.invalidateQueries({ queryKey: ['offerings-table'] })
        queryClient.invalidateQueries({ queryKey: ['offerings-grid'] })
        resolve()
      })
    })
  })

  return {
    mecos: data,
    status,
    update: {
      mutate: mutateAsync,
      status: mutationStatus
    }
  }
}
