import { calcFoodwasteChartData } from 'components/Charts/utils'

import { getDomain } from 'utils'
import { useChartData, useChartGrouping } from 'hooks'
import { usePageSettings } from 'hooks/usePageSettings'
import { useChartQueryDebugger } from 'hooks/useChartQueryDebugger'
import { useChartQueryResultSetter } from 'hooks/useChartQueryResultSetter'
import { useMemo } from 'react'
import { useChartExport } from 'hooks/useChartExport'
import { DEFAULT_OMIT_FOODWASTE_CHART, useChartQueryModifier } from 'hooks/useChartQueryModifier'

export const useFoodwasteChartData = (identifier, select) => {
  const params = useChartQueryModifier(DEFAULT_OMIT_FOODWASTE_CHART)
  const { shouldAggregateByWeek: byWeek } = usePageSettings()
  const { queryData, onDebugClick, setResult, setResponse, result } = useChartQueryDebugger(identifier, {
    data: params
      ? {
          from: 'Waste',
          select: [`sum(${select})`],
          group_by: byWeek ? 'week' : 'date',
          where: params
        }
      : null,
    dataTotal: params
      ? {
          from: 'Waste',
          select: [`sum(${select})`],
          where: params
        }
      : null
  })
  const { chartData: data, loading: loadingData } = useChartData(identifier, queryData && queryData.data, null, true)
  const { chartData: dataTotal, loading: loadingTotal } = useChartData(identifier, queryData && queryData.dataTotal, null, true)
  const groupBy = useChartGrouping(data)

  const { exportData } = useChartExport(identifier, result, { groupBy: byWeek ? 'week' : 'date' })
  useChartQueryResultSetter(setResponse, { data, dataTotal })

  const foodwasteData = useMemo(() => calcFoodwasteChartData(data), [data])
  const domain = getDomain(foodwasteData)
  useChartQueryResultSetter(setResult, { chartData: foodwasteData })

  return {
    loading: loadingData || loadingTotal,
    totalValue: dataTotal,
    chartData: foodwasteData,
    domain,
    onDebugClick,
    exportData,
    realGroupBy: groupBy
  }
}
