import { LocationPicker, UserPicker } from './index'
import { FormField } from 'components/Form'
import Heading from 'components/Heading'
import React from 'react'
import messages from './messages'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import { Icon } from 'components/Primitives'
import { Button } from 'components/Button'
import s from './LocationResponsiblePicker.module.scss'

const LocationResponsiblePicker = ({ arrayHelpers }) => {
  const intl = useIntl()
  const { values } = useFormikContext()

  const addItem = () =>
    arrayHelpers.push({ sales_location: undefined, users: [] })

  const removeItem = (index) =>
    arrayHelpers.remove(index)

  const canDelete = values.locations.length > 1

  return (
    <>
      <div className={s.row}>
        <div className={s[canDelete ? 'col-10' : 'col-12']}>
          <Heading type='h4'>{intl.formatMessage(labelMessages.location)}</Heading>
        </div>
        <div className={s[canDelete ? 'col-10' : 'col-12']}>
          <Heading type='h4'>
            {intl.formatMessage(labelMessages.responsibles)}
          </Heading>
        </div>
      </div>
      {values.locations.map((_, index) => (
        <div key={index} className={s.row}>
          <div className={s[canDelete ? 'col-10' : 'col-12']}>
            <FormField
              as={LocationPicker}
              name={`locations[${index}].sales_location`}
              inputType='location'
              withPortal={false}
              wrapperClassName='mb-4'
            />
          </div>
          <div className={s[canDelete ? 'col-10' : 'col-12']}>
            <FormField
              name={`locations[${index}].users`}
              as={UserPicker}
              filterMode='foodwasteAccess'
              placeholder={intl.formatMessage(globalMessages.select)}
              optionsLabel={intl.formatMessage(labelMessages.responsibles)}
              withPortal={false}
              wrapperClassName='mb-4'
            />
          </div>
          {canDelete &&
            <div className={s['col-4']}>
              <Button type='neutral' pure icon='TrashCan' onClick={() => removeItem(index)} />
            </div>}
        </div>
      ))}
      <div className='flex justify-end mb-4'>
        <a className='flex items-center px-2 text-primary-lighter' onClick={addItem}>
          <Icon name='Add' color='blue' className='mr-1' />
          {intl.formatMessage(messages.addRow)}
        </a>
      </div>
    </>
  )
}

export default LocationResponsiblePicker
